import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCategories, selectFilterOptions, updateFilters} from "../../../store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {getAllWarehousesAsync, selectWarehouses} from "../../../store/reducers/warehouse/warehouseReducer";
import EventBus from "../../../eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../eventbus/itemEvents";
import {debounce} from "underscore";
import {CheckScanBarcodeOrMarking} from "../../../enum/warehouse-operation/warehouseOperation";
import {Col, Collapse, Row, Form, FloatingLabel, Card} from "react-bootstrap";
import {BRANCH, WAREHOUSE} from "../../../pages/warehouse/item/ItemDataTable";
import SelectBranch from "../../common/SelectBranch";
import WarehouseSelect from "../../common/WarehouseSelect";
import SelectYesOrNo from "./SelectYesOrNo";


const isNullOrUndefined = (value) => {
    if (typeof value === 'undefined')
        return true;

    return value === null;
}

const DataTableFilter = ({
                             remainder,
                             setRemainder,
                             setSelectedBranch,
                             selectedBranch,
                             setSelectedWarehouse,
                             setSort,
                             setSortByDesc,
                             isOpenFilterCollapse
                         }) => {
    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const filters = useSelector(selectFilterOptions, shallowEqual);
    const activeOrganization = useSelector(selectActiveOrganization)
    const categories = useSelector(selectCategories);
    const warehouses = useSelector(selectWarehouses)

    const [filter, setFilter] = useState({
        searchTerm: filters.searchTerm,
        sku: filters.sku,
        categoryId: filters.categoryId,
        code: filters.code,
        hasCategory: filters.hasCategory,
        hasSku: filters.hasSku,
        hasCatalog: filters.hasCatalog,
        hasBarcode: filters.hasBarcode
    })

    useEffect(() => {
        dispatch(getAllWarehousesAsync({id: activeOrganization.id}))
    }, [activeOrganization])

    useEffect(() => {
        dispatchFilter(filter.searchTerm, filter.sku, filter.categoryId, filter.code, filter.hasCategory, filter.hasSku, filter.hasCatalog, filter.hasBarcode);

        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, (value) => {
            setFilter({...filter, searchTerm: CheckScanBarcodeOrMarking(value)})
        });

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [filter])

    const dispatchFilter = useCallback(debounce((searchTerm, sku, categoryId, code, hasCategory, hasSku, hasCatalog, hasBarcode) => {
        const filters = {}

        if (searchTerm)
            filters["searchTerm"] = searchTerm;

        if (sku)
            filters["sku"] = sku;

        if (categoryId)
            filters["categoryId"] = categoryId;

        if (code)
            filters["code"] = code;

        filters["hasSku"] = hasSku;
        filters["hasCategory"] = hasCategory;
        filters["hasCatalog"] = hasCatalog;
        filters["hasBarcode"] = hasBarcode;

        dispatch(updateFilters(filters))
    }, 400), [])

    const isAdditionalFiltersEnabled = !isNullOrUndefined(filter.hasCategory) || !isNullOrUndefined(filter.hasSku) || !isNullOrUndefined(filter.hasCatalog) || !isNullOrUndefined(filter.hasBarcode) || !isNullOrUndefined(filter.categoryId);

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <Card className={'p-1 mt-2'}>
                    <Row className='g-3'>
                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <FloatingLabel label={t(lang, 'items.common.datatable.filter.deep_search')}>
                                <Form.Control name="searchTerm"
                                              placeholder={t(lang, 'items.common.datatable.filter.deep_search')}
                                              defaultValue={filter.searchTerm}
                                              onChange={(e) => setFilter({...filter, searchTerm: e.target.value})}

                                />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <FloatingLabel label={t(lang, 'items.common.sku')}>
                                <Form.Control name="sku"
                                              placeholder={t(lang, 'items.common.sku')}
                                              defaultValue={filter.sku}
                                              onChange={(e) => setFilter({...filter, sku: e.target.value})}
                                />
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <FloatingLabel label={t(lang, 'items.common.state')}>
                                <Form.Select
                                    size={"sm"}
                                    name={'remainder'}
                                    value={remainder}
                                    onChange={(e) => setRemainder(e.target.value)}>
                                    <option
                                        value="warehouse">{t(lang, 'warehouse.common.item.title.warehouses')}</option>
                                    <option value="branch">{t(lang, 'roaming.invoice.branch')}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>

                        {remainder === BRANCH && <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <SelectBranch
                                hideSelectedOptions
                                label={t(lang, 'roaming.invoice.branch')}
                                onChange={(e) => setSelectedBranch(e)}
                            />
                        </Form.Group>}

                        {remainder === WAREHOUSE && <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <WarehouseSelect
                                onChange={setSelectedWarehouse}
                                placeholder={t(lang, 'warehouse.common.item.title.warehouses')}
                            />
                        </Form.Group>}

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <FloatingLabel label={t(lang, 'items.common.sort')}>
                                <Form.Select onChange={e => setSort(e.target.value)}>
                                    <option value="name">{t(lang, 'items.common.sort.name')}</option>
                                    <option value="created_at">{t(lang, 'items.common.sort.created_at')}</option>
                                    <option value="state">{t(lang, 'items.common.sort.state')}</option>
                                    <option value="sku">{t(lang, 'items.common.sort.sku')}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <FloatingLabel label={t(lang, 'items.common.sort_by_desc')}>
                                <Form.Select onChange={e => setSortByDesc(!!e.target.value)}>
                                    <option value="">{t(lang, 'items.common.sort_by_desc.yes')}</option>
                                    <option value="1">{t(lang, 'items.common.sort_by_desc.no')}</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <SelectYesOrNo
                                placeholder={t(lang, 'items.common.has_catalog')}
                                onChange={(option) => setFilter({
                                    ...filter,
                                    hasCatalog: option.value
                                })}
                            />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <SelectYesOrNo
                                defaultValue={filter.hasBarcode}
                                placeholder={t(lang, 'items.common.has_barcode')}
                                onChange={(option) => setFilter({
                                    ...filter,
                                    hasBarcode: option.value
                                })}
                            />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <SelectYesOrNo
                                defaultValue={filter.hasCategory}
                                placeholder={t(lang, 'items.common.has_category')}
                                onChange={(option) => setFilter({
                                    ...filter,
                                    hasCategory: option.value
                                })}
                            />
                        </Form.Group>

                        <Form.Group as={Col} xs={12} sm={6} lg={4} xl={3} xxl={2}>
                            <SelectYesOrNo
                                defaultValue={filter.categoryId}
                                placeholder={t(lang, 'items.common.category')}
                                onChange={(category) => setFilter({
                                    ...filter,
                                    categoryId: category ? category.id : null
                                })}
                            />
                        </Form.Group>
                    </Row>
                </Card>
            </Collapse>
        </Fragment>
    );
};

export default DataTableFilter;
