import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectAllContractor} from "../../store/reducers/contractor/contractorReducer";
import {Translate} from "../../lang/lang";
import {debounce} from "underscore";
import AddContractor from "./AddContractor";
import EditContractor from "./EditContractor";
import IconButton from "../IconButton";
import {InputGroup, Spinner} from "react-bootstrap";
import CustomFloatingLabelSelectAsync from "../floating-custom-select/CustomFloatingLabelSelectAsync";

const ContractorSelect = ({
                              defaultValue,
                              onChange,
                              allowEdit,
                              allowAdd,
                              executor,
                              buyer,
                              onFocus,
                              isEditing,
                              changeContractor,
                              loading,
                              placeholder,
                              ...props
                          }) => {

    const [value, setValue] = useState(null);
    const contractors = useSelector(selectAllContractor);
    const lang = useSelector(selectLang);
    const t = Translate;

    const filterOptions = {page: 0, limit: 10}

    useEffect(() => {
        if (defaultValue !== null) {
            setValue(contractors?.find(x => x.id === defaultValue))
        } else {
            setValue(null)
        }
    }, [contractors, defaultValue])

    const loadOptions = (query, callback) => {
        if (query) {
            callback(contractors?.filter(i => {
                const searchLower = query?.trim()?.toLowerCase();
                const phoneType = 1

                if (i?.name?.toLowerCase()?.includes(searchLower) ||
                    i?.inn?.includes(searchLower) ||
                    (i?.contacts.find(contact => contact.type === phoneType && contact.value.includes(searchLower)) !== undefined))
                    return true

            }).slice(filterOptions?.page, filterOptions?.limit))
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 100);

    const onChangeContractor = option => {
        if (isEditing) {
            setValue(option)
        } else {
            setValue(option)
            onChange && onChange(option)
        }
    }

    const onContractorChanged = (e) => {
        changeContractor(e, value, setValue, defaultValue)
    }

    return (
        <Fragment>
            <InputGroup className={'select flex-nowrap'}>
                <CustomFloatingLabelSelectAsync
                    left={props.left}
                    right={props.right}
                    loadOptions={debouncedLoadOptions}
                    onChange={(option) => onChangeContractor(option)}
                    value={value}
                    className={'w-100'}
                    autoFocus={onFocus}
                    hideSelectedOptions
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.name}
                    label={placeholder}
                    defaultOptions={contractors?.slice(filterOptions?.page, filterOptions?.limit)}
                    noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderTopLeftRadius: allowAdd ? '8px' : "8px",
                            borderBottomLeftRadius: allowAdd ? '8px' : "8px",
                            borderBottomRightRadius: allowAdd ? '0px' : "8px",
                            borderTopRightRadius: allowAdd ? '0px' : "8px",
                        }),
                    }}
                    {...props}
                />
                {allowAdd && !value &&
                    <AddContractor
                        executor={executor}
                        buyer={buyer}
                        selectBtn={true}
                    />
                }
                {(value?.id !== defaultValue) || allowEdit && value &&
                    <EditContractor selectBtn={true} contractor={value}/>
                }
                {isEditing && value && value?.id !== defaultValue && <>
                    <IconButton
                        variant={"phoenix-secondary"}
                        title={"Save"}
                        size="md"
                        style={{zIndex: 0}}
                        className="position-relative bg-transparent"
                        onClick={() => onContractorChanged("Save")}
                        icon={!loading && "check"}
                    >
                        {loading && <Spinner size={'sm'} className='align-middle' animation='border' role='switch'/>}
                    </IconButton>
                    <IconButton
                        variant="danger"
                        title={"Cancel"}
                        size="md"
                        style={{zIndex: 0}}
                        className="position-relative"
                        onClick={() => onContractorChanged("Cancel")}
                        icon="cancel"
                    />
                </>}
            </InputGroup>
        </Fragment>
    );
};

export default ContractorSelect;
