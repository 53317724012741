import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useParams} from "react-router-dom";
import {getPurchaseItemAsync} from "../../../../store/reducers/warehouse/purchaseReducer";
import {loadContractorAsync, updateCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS
} from "../../../../eventbus/warehouse/purchaseEvent";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import Purchase from "../../../../components/warehouse/operation/purchase/Purchase";

const ViewPurchase = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const [purchase, setPurchase] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()

    const loadPurchase = () => {
        getPurchaseItemAsync({purchaseId: id})
            .then(response => {
                setPurchase(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setPurchase(null))
    }

    useEffect(() => {
        loadPurchase()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, () => {
            loadPurchase()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, () => {
            loadPurchase()
        })

        const onRegisterPayoutHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, () => {
            loadPurchase()
        })

        const onUpdatePayoutHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, () => {
            loadPurchase()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, onRegisterPayoutHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, onUpdatePayoutHandler)
        }
    },[])

    if (!purchase) return <SpinnerComponent />

    return (
        <Purchase purchase={purchase} />
    );
};

export default ViewPurchase;
