import React, {Fragment, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectItems} from "../../../store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import WarehouseOperationItemSelect from "../../../components/warehouse/operation/WarehouseOperationItemSelect";
import {Col, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";
import PackageTypeItem from "../../../components/warehouse/operation/PackageTypeItem";
import InputGroupWithCurrency from "../../../components/warehouse/operation/price/InputGroupWithCurrency";

const TransferFormModal = ({categoryItems, onChangeItem, commonPrice, priceType, constantPrice, onChangePriceType}) => {
    const lang = useSelector(selectLang)
    const t = Translate
    const items = useSelector(selectItems)
    const ref = useRef()
    const {errors, setValue, trigger, register} = useFormContext()
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const itemId = useWatch({name: 'item_id', exact: true})

    return (
        <Fragment>
            <Form.Group as={Col} xs={12}>
                <Controller
                    name="item_id"
                    rules={{
                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                    }}
                    render={({field}) => {
                        return (
                            <WarehouseOperationItemSelect
                                // onFocus={onFocusItem()}
                                placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                warehouseId={warehouseId}
                                options={categoryItems || items}
                                defaultValue={field?.value}
                                isClearable
                                onChange={option => {
                                    field?.onChange(option ? option?.item?.id : null)
                                    onChangeItem(option)
                                }}
                                className={classNames({
                                    'is-invalid': errors?.item_id
                                })}
                            />
                        )
                    }}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors?.item_id?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} lg={6}>
                <InputGroup>
                    <FloatingLabel label={t(lang, "warehouse.operation.item.common.quantity")}>
                        <Form.Control
                            type="text"
                            id="quantity"
                            {...register("quantity", {
                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                pattern: {
                                    value: /^[0-9]*[\.,]?[0-9]*$/,
                                    message: "100 | 1.2 | 1,5"
                                }
                            })}
                            placeholder={t(lang, "warehouse.operation.item.common.quantity")}
                            className={classNames("form-control w-100", {
                                "border-right-0": ref?.current?.getPackageMeasurement()
                            })}
                        />
                    </FloatingLabel>
                    <Controller
                        name="package_measurements"
                        render={({field}) => {
                            return (
                                <PackageTypeItem
                                    ref={ref}
                                    className={'w-100'}
                                    itemId={itemId}
                                    defaultValue={field?.value}
                                    onChange={option => {
                                        field?.onChange(option)
                                    }}
                                />
                            )
                        }}
                    />
                </InputGroup>
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors?.quantity?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} lg={6}>
                <Controller
                    name='commonPrice'
                    render={({field}) => (
                        <InputGroupWithCurrency
                            placeholder={t(lang, "warehouse.operation.item.common.sum")}
                            defaultCurrencyId={commonPrice?.currency_id}
                            defaultPrice={commonPrice?.amount}
                            constantDefaultPrice={constantPrice}
                            defaultBulkPrice={priceType}
                            onChangeBulkPrice={(priceTypeOne) => {
                                onChangePriceType(priceTypeOne)
                            }}
                            allowBulkPrice={true}
                            onChangePrice={(price) => {
                                setValue('commonPrice.amount', price);
                                trigger('commonPrice');
                            }}
                            onChangeCurrency={(currency) => {
                                setValue('commonPrice.currency_id', currency ? currency?.id : null);
                                trigger('commonPrice');
                            }}
                            inputProps={{
                                className: classNames({
                                    'is-invalid': errors?.commonPrice
                                }, 'form-control border-right-0'),
                                placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                options: {
                                    numeral: true,
                                    delimiter: ' ',
                                    numeralDecimalScale: 4
                                }
                            }}
                            className={classNames({
                                'is-invalid': errors?.commonPrice
                            })}
                        />
                    )}
                />
            </Form.Group>
            <Form.Group as={Col} xs={12}>
                <FloatingLabel label={t(lang, "warehouse.dashboard.items.transfer.view.note")}>
                    <Form.Control
                        placeholder={t(lang, "warehouse.dashboard.items.transfer.view.note")}
                        as="textarea"
                        rows={10}
                        name="note"
                        {...register("note")}
                    />
                </FloatingLabel>
            </Form.Group>
        </Fragment>
    );
};

export default TransferFormModal;