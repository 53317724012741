import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {
    selectCategoriesTree,
    selectFilterOptions,
    selectItems,
    selectLoading,
    selectPaginationOptions,
    updatePagination
} from "../../../store/reducers/item/itemReducer";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {transliterate} from "transliteration";
import itemWrapper from "../../../enum/item/itemWrapper";
import naturalCompare from "natural-compare"
import Badge from "../../../components/common/Badge";
import {Link} from "react-router-dom";
import {Card, Dropdown, Image, OverlayTrigger, Popover} from "react-bootstrap";
import CatalogStatusBadge from "../../../components/item/components/CatalogStatusBadge";
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import AdvanceTable from "../../../components/table/AdvanceTable";
import dayjs from "dayjs";
import numeral from "numeral";
import WarehouseTabs from "../../../components/warehouse/WarehouseTabs";
import ItemDataTableHeader from "./ItemDataTableHeader";
import IconCurrencyInfo from "../../../assets/icon/IconCurrencyInfo";
import AdvanceTableCustomPagination2 from "../../../components/table/AdvanceTableCustomPagination2";
import ViewItemModal from "./ViewItemModal";
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import AddRevisionModal from "../../../components/item/components/revision/AddRevisionModal";
import PrintItemDropdownItem from "../../../components/item/components/PrintItemDropdownItem";
import DeleteItemDropdownItem from "../../../components/item/components/DeleteItemDropdownItem";

export const WAREHOUSE = 'warehouse'
export const BRANCH = 'branch'

const ItemDataTable = () => {

    const dispatch = useDispatch();
    const t = Translate;
    const lang = useSelector(selectLang);
    const {page, limit} = useSelector(selectPaginationOptions, shallowEqual);
    const items = useSelector(selectItems);
    const currencies = useSelector(selectCurrency);
    const filters = useSelector(selectFilterOptions, shallowEqual);
    const loading = useSelector(selectLoading);
    const numberFormat = useSelector(selectNumberFormat);
    const categoriesTree = useSelector(selectCategoriesTree);
    const [remainder, setRemainder] = useState(WAREHOUSE)
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [sort, setSort] = useState(null)
    const [sortByDesc, setSortByDesc] = useState(true)
    const [selectedItems, setSelectedItems] = useState([]);
    const [show, setShow] = useState(false);
    const [itemId, setItemId] = useState(null);

    const data = useMemo(() => {
        const filteredItems = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            if (filters.searchTerm) {
                let found = false;
                const searchTermLower = filters.searchTerm.trim().toLowerCase();
                const itemNameLower = item.item.name.trim().toLowerCase();

                // search by barcode
                if (item.item.barcodes.some((b) => b.toLowerCase().includes(searchTermLower)))
                    found = true;

                // search by name
                const transliteratedQuery = transliterate(searchTermLower);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
                const transliteratedItemName = transliterate(itemNameLower);
                if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    found = true;

                if (!found)
                    continue;
            }

            if (filters.sku && !item.item.sku?.trim().toLowerCase().includes(filters.sku.trim().toLowerCase()))
                continue;

            if (filters.code && !item.item.code?.trim().toLowerCase().includes(filters.code.trim().toLowerCase()))
                continue;

            if (filters.categoryId && !itemWrapper.filterCategory(categoriesTree, filters.categoryId, item.item.category?.id))
                continue;

            if (filters.hasCategory === true && !item.item.category)
                continue;

            if (filters.hasCategory === false && item.item.category)
                continue;

            if (filters.hasSku === true && !item.item.sku)
                continue;

            if (filters.hasSku === false && item.item.sku)
                continue;

            if (filters.hasCatalog === true && !item.item.tax?.catalog?.code)
                continue;

            if (filters.hasCatalog === false && item.item.tax?.catalog?.code)
                continue;

            if (filters.hasBarcode === true && !item.item.barcodes.length)
                continue;

            if (filters.hasBarcode === false && item.item.barcodes.length)
                continue;

            filteredItems.push(item);
        }

        switch (sort) {
            case 'state':
                if (sortByDesc)
                    return filteredItems.sort((a, b) => naturalCompare(b.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0), a.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0)));
                return filteredItems.sort((a, b) => naturalCompare(a.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0), b.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0)));

            case 'created_at':
                if (sortByDesc)
                    return filteredItems.sort((a, b) => naturalCompare(b.item.created_at, a.item.created_at));

                return filteredItems.sort((a, b) => naturalCompare(a.item.created_at, b.item.created_at));
            case 'sku':
                if (sortByDesc)
                    return filteredItems.sort((a, b) => naturalCompare(b.item.sku, a.item.sku));

                return filteredItems.sort((a, b) => naturalCompare(a.item.sku, b.item.sku));
            default:
                if (sortByDesc)
                    return filteredItems.sort((a, b) => naturalCompare(b.item.name, a.item.name));
                return filteredItems.sort((a, b) => naturalCompare(a.item.name, b.item.name));
        }
    }, [items, filters, categoriesTree, sort, sortByDesc])

    const skip = (page - 1) * limit;
    const calculationItems = selectedItems?.length > 0 ? selectedItems : data;
    const totalItemsCommonPricesAmount = useMemo(() => itemWrapper.calculateItemsCommonPriceTotal(calculationItems.slice(skip, skip + limit)), [calculationItems, page, limit]);
    const totalItemsBulkPricesAmount = useMemo(() => itemWrapper.calculateItemsBulkPriceTotal(calculationItems.slice(skip, skip + limit)), [calculationItems, page, limit]);
    const totalItemsPurchasePricesAmount = useMemo(() => itemWrapper.calculateItemsPurchasePricesTotal(calculationItems.slice(skip, skip + limit)), [calculationItems, page, limit]);
    const totalItemsWarehousesState = useMemo(() => itemWrapper.calculateItemsWarehouseStates(calculationItems.slice(skip, skip + limit)), [calculationItems, page, limit]);
    const totalWarehousesState = useMemo(() => totalItemsWarehousesState.reduce((acc, itemWarehouse) => acc + itemWarehouse.itemWarehouseState, 0), [totalItemsWarehousesState]);

    const showViewModal = (id) => {
        setItemId(id)
        setShow(true);
    }

    const hideViewModal = () => {
        setItemId(null)
        setShow(false);
    }

    useEffect(() => {
        if (remainder === WAREHOUSE) {
            setSelectedBranch(null)
        } else if (remainder === BRANCH) {
            setSelectedWarehouse(null)
        }
    }, [remainder])

    const columns = useMemo(() => {
        const datatableColumns = [
            {
                accessor: 'item.created_at',
                Header: t(lang, 'items.common.title.created_at'),
                headerProps: {
                    className: 'text-center',
                    width: 140
                },
                cellProps: {
                    className: 'text-center'
                },
                Cell: ({row: {original: {item}}}) => {
                    return dayjs(item.created_at).format("DD-MM-YYYY")
                }
            },
            {
                accessor: 'name',
                Header: t(lang, "items.common.name"),
                cellProps: {
                    className: 'px-2'
                },
                Cell: ({row: {original: {item}}}) => {
                    return <div className={'d-flex align-items-center gap-2'}>
                        <Badge bg={'secondary-davr'} variant="phoenix" className={'cursor-pointer px-1'}>
                            {item.images.length > 0 &&
                                <Image
                                    style={{
                                        maxWidth: "50px",
                                        maxHeight: "50px"
                                    }}
                                    src={`${process.env.REACT_APP_HOST}/cdn/${item.images[0].path}`}
                                    alt={`${item.images[0].name}`}
                                />
                            }
                            {item.images.length === 0 &&
                                <Image
                                    style={{
                                        maxWidth: "50px",
                                        maxHeight: "50px"
                                    }}
                                    src={require("../../../assets/img/imagePlaceholder.png")}
                                    alt="imagePlaceholder.png"
                                />
                            }
                        </Badge>
                        <div className={'d-flex flex-column gap-1'}>
                            {item.category &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'right'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Body>
                                                            <Badge bg={'info'}>{t(lang, 'items.common.category')}</Badge>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                >
                                    <span className='second-text'>{item.category.name}</span>
                                </OverlayTrigger>
                            }

                            {item.code &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'right'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Body>
                                                            <Badge bg={'warning'}>{t(lang, 'items.common.code')}</Badge>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                >
                                    <span className='second-text'>{item.code}</span>
                                </OverlayTrigger>
                            }

                            {/*{item.sku &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'right'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <Badge bg={'dark'}>{t(lang, 'items.common.sku')}</Badge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <span className='second-text'>{item.sku}</span>
                                </OverlayTrigger>
                            }*/}
                            {/*{item.package_measurements?.length > 0 && item.package_measurements.map((packageMeasurement, index) => (
                                <OverlayTrigger
                                    key={index}
                                    trigger={['hover', 'focus']}
                                    placement="right"
                                    overlay={
                                        <Popover id="popover-basic" className='ms-0'>
                                            <Popover.Body className={'py-2 px-3'}>
                                                <h6 className={'mb-0'}>
                                                    <span
                                                        className='me-2'>{t(lang, 'items.common.package_measurement.quantity')}:</span>
                                                    <Badge bg={'primary'}>{packageMeasurement.quantity}</Badge>
                                                </h6>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <span className={'second-text'}>{packageMeasurement.name}</span>
                                </OverlayTrigger>
                            ))}*/}

                            <div style={{maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                                  onClick={() => showViewModal(item.id)}
                                  title={item.name}
                                  className={'fs-8 text-truncate fw-semi-bold font-sans-serif text-black text-800 cursor-pointer'}>{item.name}</div>

                            {item.barcodes?.length > 0 && item.barcodes.map((barcode, index) =>
                                <OverlayTrigger key={index}
                                                trigger={['hover', 'focus']}
                                                placement={'right'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Body>
                                                            <Badge bg={'secondary'}>{t(lang, 'items.common.barcode')}</Badge>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                >
                                    <span className='second-text'>{barcode}</span>
                                </OverlayTrigger>
                            )}
                        </div>
                    </div>
                },
            },
            {
                accessor: "category",
                Header: t(lang, "items.common.import_from_excel.column_selector.options.category"),
                headerProps: {
                    className: 'text-center',
                    width: 140
                },
                cellProps: {
                    className: 'text-center'
                },
                Cell: ({row: {original: {item}}}) => {
                    return <span className={'second-text'}>{item.category?.name}</span>
                }
            },
            {
                accessor: "catalog_code",
                Header: t(lang, "items.common.import_from_excel.column_selector.options.tax.catalog.code"),
                headerProps: {
                    className: 'text-center',
                    width: 140
                },
                cellProps: {
                    className: 'text-center'
                },
                Cell: ({row: {original: {item}}}) => {
                    return <div>
                        {item.tax?.catalog?.code &&
                            <CatalogStatusBadge catalogCode={item.tax.catalog.code} tasnif_info={item.tasnif_info}
                                                badgeProps={{
                                                    className: 'me-1'
                                                }}/>
                        }
                    </div>
                }
            },
            {
                accessor: 'price',
                Header: t(lang, "items.common.price"),
                Cell: ({row: {original: {price}}}) => {
                    return <span className={'d-flex align-items-center'}>
                        <strong className={'fs-8'}>
                            {numeral.formats[numberFormat].format(price.bulk_price?.amount)}
                        </strong>
                        <span className={'second-text fs-9 ms-2 d-flex align-items-stretch gap-2'}>
                            {currencies.find(c => c.id === price.bulk_price?.currency?.global_currency_id)?.name}
                            <IconCurrencyInfo/>
                        </span>
                    </span>
                }
            },
            {
                accessor: 'action',
                Header: "",
                disableSortBy: true,
                cellProps: {
                    width: '80px',
                    className: 'text-end'
                },
                Cell: ({row: {original: {item, price}}}) => {
                    return (
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                            <RevealDropdown>
                                <Dropdown.Item as={Link} to={`/items/edit/${item.id}`}
                                               className="border-bottom align-center">
                                    <span>{t(lang, 'items.common.edit')}</span>
                                </Dropdown.Item>
                                <AddRevisionModal itemDisabled={true} item={item}/>
                                <PrintItemDropdownItem class={'border-bottom'} item={item} price={price}/>
                                <DeleteItemDropdownItem item={item}/>
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                    );
                }
            },


        ].filter(i => {
            if ('remainder' in i) {
                if (i.remainder !== true) return i
            } else return i
        })

        if (!selectedWarehouse && !selectedBranch) {
            datatableColumns.splice(4, 0, {
                accessor: 'states-sum',
                Header: t(lang, 'items.common.state'),
                headerProps: {
                    className: 'text-center'
                },
                cellProps: {
                    className: 'text-center'
                },
                Cell: ({row: {original}}) => {

                    if (remainder === WAREHOUSE) {
                        return <Fragment>
                            <span className={'fs-0 fw-bold'}>
                                {original.warehouse_states.warehouse_items.reduce((sum, warehouseItem) => warehouseItem.state + sum, 0)}
                                <span
                                    className={'ms-2 text-lowercase'}>{t(lang, "crm.common.measurement_options.0")}</span>
                            </span>
                        </Fragment>
                    } else if (remainder === BRANCH) {
                        return <Fragment>
                            <span className={'fs-0 fw-bold'}>
                                {original.branch_states.reduce((sum, branchItems) => branchItems.state + sum, 0)}
                            </span>
                        </Fragment>
                    }
                }
            });
        }

        const isMultipleWarehouse = items.every(i => i.warehouse_states.warehouse_items.length > 1);
        /*if (isMultipleWarehouse) {
            const columnPositionIndex = selectedWarehouse ? 4 : 5;
            datatableColumns.splice(columnPositionIndex, 0, {
                accessor: 'warehouse-states',
                remainder: remainder !== WAREHOUSE,
                Header: t(lang, 'items.common.warehouses_state'),
                headerProps: {
                    className: 'text-center',
                    width: 400
                },
                totalInfo:
                    <Flex className={'fs-1'}>
                        {
                            !!selectedWarehouse
                                ? totalItemsWarehousesState.filter(itemWarehouse => itemWarehouse.itemWarehouseId === selectedWarehouse?.id).map((itemWarehouse, index) => {
                                    const colors = ['primary', 'info', 'success', 'dark'];
                                    const color = colors[index % colors.length]
                                    return (
                                        <Badge bg={color} className='mb-1 flex-fill me-1' key={itemWarehouse.itemWarehouseId}>
                                            {itemWarehouse.itemWarehouseState}
                                            <br/>
                                            {itemWarehouse.itemWarehouseName}
                                        </Badge>
                                    )
                                })
                                : totalItemsWarehousesState.map((itemWarehouse, index) => {
                                    const colors = ['primary', 'info', 'success', 'dark'];
                                    const color = colors[index % colors.length]
                                    return (
                                        <Badge bg={color} className='mb-1 flex-fill me-1' key={itemWarehouse.itemWarehouseId}>
                                            {itemWarehouse.itemWarehouseState}
                                            <br/>
                                            {itemWarehouse.itemWarehouseName}
                                        </Badge>
                                    )
                                })
                        }
                    </Flex>
                ,
                totalInfoClassName: "fw-bold fs-1 text-center text-nowrap",
                Cell: ({row: {original: {warehouse_states}}}) => {

                    if (warehouse_states.warehouse_items?.length <= 1) {
                        return false;
                    }

                    return <WarehouseStates warehouse_states={warehouse_states} selectedWarehouse={selectedWarehouse}/>
                }

            },)
        }*/

        return datatableColumns;
    }, [items, currencies, lang, remainder, selectedBranch, selectedWarehouse, page, limit, selectedItems])

    useEffect(() => {
        dispatch(updatePagination({limit: limit, page: 1}));
    }, [filters])

    const onSelectedItemsChanged = updatedSelectedItems => setSelectedItems(updatedSelectedItems);
    const onLimitChange = (limit) => dispatch(updatePagination({limit: limit, page: page}));
    const onPageChange = (page) => dispatch(updatePagination({limit: limit, page: page}));


    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                selection
                width={'50px'}
                pagination
                perPage={limit}
                currentPage={page - 1}
            >
                <ItemDataTableHeader
                    remainder={remainder}
                    setRemainder={setRemainder}
                    table
                    items={data}
                    setSelectedBranch={setSelectedBranch}
                    selectedBranch={selectedBranch}
                    setSelectedWarehouse={setSelectedWarehouse}
                    setSort={setSort}
                    setSortByDesc={setSortByDesc}
                    onSelectedItemsChanged={onSelectedItemsChanged}
                />
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <WarehouseTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            isLoading={loading}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                               onPageChange={onPageChange}
                                               onLimitChange={onLimitChange}
                                               table
                />
            </AdvanceTableWrapper>

            {show && <ViewItemModal show={show} setShow={hideViewModal} itemId={itemId}/>}
        </Fragment>
    );
};

export default ItemDataTable;
