import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../store/reducers/warehouse/warehouseReducer";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectItems} from "../../../store/reducers/item/itemReducer";
import {Translate} from "../../../lang/lang";
import {Table} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";
const WarehouseOperationDatatableTab = ({dataItems}) => {

    const numberFormat = useSelector(selectNumberFormat)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(dataItems)

    return (
        <Fragment>
            <div className={'border border-1 rounded-3 overflow-hidden'}>
                <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                    <thead className="text-dark border-bottom bg-card second-text text-center">
                    <tr>
                        <th className="fw-semi-bold align-middle">№</th>
                        <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                        {/*<th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.image")}</th>*/}
                        <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                        <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                        <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                        {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                            <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                        }
                        {warehouses?.length > 1 &&
                            <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                        }
                        {dataItems?.some(d => d?.is_deleted) &&
                            <th className="fw-semi-bold align-middle">{t(lang, "Удаленное время")}</th>
                        }
                    </tr>
                    </thead>
                    <tbody>

                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                        return (
                            <Fragment key={rowIndex}>
                                {item?.sub?.map((subItem, subRowIndex) => {
                                    return (
                                        <tr key={subItem.id}>
                                            <td key={rowIndex}
                                                className={subRowIndex === 0 ? "text-start" : "d-none"}
                                                rowSpan={item?.sub?.length}
                                                style={{width: "50px"}}
                                            >
                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                            </td>
                                            <td
                                                className={subRowIndex === 0 ? "text-start" : "d-none"}
                                                rowSpan={item?.sub?.length}>
                                                {subItem?.warehouse_item?.name}
                                            </td>
                                            {/*<td
                                                className={subRowIndex === 0 ? "text-center p-1" : "d-none"}
                                                style={{width: "50px"}} rowSpan={item?.sub?.length}>
                                                {items.find(x => x?.item?.id === subItem?.warehouse_item?.id)?.item?.images.length > 0 &&
                                                    <WarehouseOperationImageModal
                                                        image={items.find(x => x?.item?.id === item?.item?.id)?.item?.images}/>
                                                }
                                            </td>*/}
                                            <th className="text-end">
                                                {subItem?.quantity}
                                            </th>
                                            <th className="text-end text-nowrap">
                                                {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                            </th>
                                            <th className="text-end text-nowrap">
                                                {numeral.formats[numberFormat].format(subItem?.quantity * subItem?.price?.amount)}
                                                <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                            </th>
                                            {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                                <td className="text-center">
                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                    <span> => </span>
                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                </td>
                                            }
                                            {warehouses?.length > 1 &&
                                                <th className="text-end">
                                                    {subItem?.warehouse_item?.warehouse?.name}
                                                </th>
                                            }
                                            {subItem?.is_deleted &&
                                                <>
                                                    <th className="text-nowrap text-center text-danger"
                                                        style={{width: "150px"}}>
                                                        {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                    </th>
                                                </>
                                            }
                                        </tr>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                    </tbody>
                </Table>
            </div>
        </Fragment>
    );
};

export default WarehouseOperationDatatableTab;
