import {Controller, useForm, useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {CertificatePfx} from "../../../../plugins/eimzo";
import {getInnExistsAsync} from "../../../../store/reducers/auth/authReducer";
import React, {ChangeEvent, Fragment, useEffect} from "react";
import {Card, Col, FloatingLabel, Form, InputGroup, Row} from 'react-bootstrap'
import SelectCertificate from "../../../eimzo/SelectCertificate";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import IconEImzoSecondary from "../../../../assets/icon/IconEImzoSecondary";

const RegistrationFormStepOne = ({hasLabel,withoutInn}) => {

    const {register, setValue, watch, formState: {errors}, control} = useFormContext()

    const lang = useSelector(selectLang);
    const t = Translate;

    const onCertificateChanged = (certificate) => {
        if (certificate) {
            const pfxCertificate = new CertificatePfx(certificate)

            setValue('company.certificate', certificate);
            setValue('company.name', `${pfxCertificate.companyName || pfxCertificate.issuedPerson}`.toUpperCase());
            setValue('company.inn', pfxCertificate.innNumber || pfxCertificate.pinfl);
            setValue('account.name', `${pfxCertificate.issuedPerson}`.toUpperCase())
        }
    };

    const validateInnExist = async (inn) => {
        return await getInnExistsAsync(inn);
    }

    useEffect(() => {
        if (!withoutInn) {
            register('company.certificate', {
                required: t(lang, 'edi.common.forms.validations.is_required')
            })
        }
    }, []);


    return (
        <Fragment>
            {!withoutInn && <Card className={`${errors.company?.certificate ? "border-danger border" : ""} p-1`}>
                <InputGroup className={'flex-nowrap align-items-center gap-2'}>
                    <div>
                        <IconEImzoSecondary/>
                    </div>
                    <div className={'w-100'}>
                        <SelectCertificate onChange={onCertificateChanged}
                                           auth={true}
                                           label={!hasLabel ? t(lang, 'authorization.select_certificate.placeholder') : ''}
                                           className={classNames('', {'is-invalid': errors.company?.certificate})}
                        />
                    </div>
                </InputGroup>
            </Card>}
            <Form.Label className={'mt-2 p-0 text-uppercase'}>{t(lang, "authorization.register.full_name")}</Form.Label>
            <Card className={'p-1'}>
                <Form.Group>
                    <FloatingLabel label={t(lang, "authorization.register.name")}>
                        <Form.Control
                            type="text"
                            placeholder={t(lang, "authorization.register.name")}
                            isInvalid={!!errors.account?.name}
                            {...register('account.name', {
                                required: t(lang, 'edi.common.forms.validations.is_required')
                            })}
                        />
                    </FloatingLabel>

                    <Form.Control.Feedback type="invalid">
                        {errors.account?.name?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Card>

            <Form.Label className={'mt-2 p-0 text-uppercase'}>{t(lang, "admin.dashboard.organization.title")}</Form.Label>
            <Card className={'p-1'}>
                <Form.Group>
                    <FloatingLabel label={t(lang, 'authorization.form.enter_company_name.placeholder')}>
                        <Form.Control
                            placeholder={!hasLabel ? t(lang, 'authorization.form.enter_company_name.placeholder') : ''}
                            isInvalid={!!errors.company?.name}
                            type="text"
                            {...register('company.name', {
                                required: t(lang, 'edi.common.forms.validations.is_required')
                            })}
                        />
                    </FloatingLabel>

                    <Form.Control.Feedback
                        type='invalid'>{errors.company?.name?.message}</Form.Control.Feedback>
                </Form.Group>
            </Card>

            <Form.Label className={'mt-2 p-0 text-uppercase'}>{t(lang, "authorization.register.contact")}</Form.Label>
            <Card className={'p-1'}>
                <Form.Group className="input-group gap-2 flex-nowrap">
                        <FloatingLabel label={"Код"} className={'w-25'}>
                            <Form.Control value={"+998"} placeholder={"Код"}/>
                        </FloatingLabel>
                        <Controller control={control} name={'account.username'} rules={{
                            required: t(lang, "edi.common.forms.validations.is_required")
                        }} render={({field}) => (
                            <FloatingLabel label={"Номер телефона"} className={'w-75'}>
                                <Cleave
                                    placeholder={"Номер телефона"}
                                    options={{
                                        delimiters: [' ', ' ', ' ', ' '],
                                        blocks: [2, 3, 2, 2],
                                        numericOnly: true
                                    }}
                                    onChange={(username) => field.onChange(username)}
                                    className={classNames('form-control', {
                                        'is-invalid': errors.account?.username,
                                    })}
                                />
                            </FloatingLabel>

                        )}/>
                    <Form.Control.Feedback type="invalid">{errors.account?.username?.message}</Form.Control.Feedback>
                </Form.Group>
            </Card>

            <Form.Label className={'mt-2 p-0 text-uppercase'}>{t(lang, "authorization.register.security")}</Form.Label>
            <Card className="p-1">
                <Form.Group>
                    <FloatingLabel label={t(lang, 'authorization.form.password.placeholder')}>
                        <Form.Control
                            placeholder={!hasLabel ? t(lang, 'authorization.form.password.placeholder') : ''}
                            isInvalid={!!errors.account?.password}
                            type="password"
                            {...register('account.password', {
                                required: t(lang, ' ')
                            })}
                        />
                    </FloatingLabel>

                    <Form.Control.Feedback
                        type='invalid'>{errors.account?.password?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={'mt-2'}>
                    <FloatingLabel label={t(lang, 'authorization.form.repeat_password.placeholder')}>
                        <Form.Control
                            placeholder={!hasLabel ? t(lang, 'authorization.form.repeat_password.placeholder') : ''}
                            isInvalid={!!errors.account?.confirmPassword}
                            type="password"
                            {...register('account.confirmPassword', {
                                validate: value => value === watch('account.password') || 'Enter exact the same password'
                            })}
                        />
                    </FloatingLabel>

                    <Form.Control.Feedback
                        type='invalid'>{errors.account?.confirmPassword?.message}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mt-2">
                <Form.Check type="checkbox" className="form-check">
                    <Form.Check.Input
                        type="checkbox"
                        isInvalid={!!errors.terms}
                        // value={true}
                        // feedback={"Terms must be agreed to continue"}
                        {...register('terms', {
                            required: t(lang, 'authorization.form.terms_must_be_agreed')
                        })}
                    />
                    <Form.Check.Label className="form-label">
                        Я соглашаюсь с <a href="/free-form-document/system-offer/pdf"
                                          target="_blank">условием</a> оферты .
                    </Form.Check.Label>
                </Form.Check>
            </Form.Group>
            </Card>
        </Fragment>
    );
};

export default RegistrationFormStepOne;
