import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {
    selectTransferDefaultsSettings,
    transferApproveAsync,
    transferDeleteItemAsync,
    transferUpdateDateAsync,
    transferUpdateItemAsync,
    transferUpdateNumberAsync
} from "../../../store/reducers/warehouse/transferReducer";
import {selectItems} from "../../../store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Translate} from "../../../lang/lang";
import {useNavigate, useParams} from "react-router-dom";
import EventBus from "../../../eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../eventbus/itemEvents";
import {ADD_EMPLOYEE_SUCCEED} from "../../../eventbus/employee/employeeEvent";
import {UZS} from "../../../enum/item/itemWrapper";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {Card, Col, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import IconButton from "../../../components/IconButton";
import DatePickerComponent from "../../../components/common/DatePickerComponent";
import classNames from "classnames";
import WarehouseOperationWarehouseSelect
    from "../../../components/warehouse/operation/WarehouseOperationWarehouseSelect";
import TransferModal from "./TransferModal"
import numeral from "numeral";
import Badge from "../../../components/common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import WarehouseOperationItemSelect from "../../../components/warehouse/operation/WarehouseOperationItemSelect";
import WarehouseOperationFooterCurrencyTotalSum
    from "../../../components/warehouse/operation/WarehouseOperationFooterCurrencyTotalSum";
import {OperationTotalSum} from "../../../enum/warehouse-operation/warehouseOperation";
import Flex from "../../../components/common/Flex";
import {useMainLayoutContext} from "../../../pheonix/providers/MainLayoutProvider";

const colIndexItem = 0
const colIndexQuantity = 5
const colIndexPrice = 6
const colIndexTotal = 7


const colCoordinateZero = 0
const rowCoordinateOne = 1
const subCoordinateTwo = 2


const TransferForm = ({transfer, addItem, loading, show, onShow, onHide}) => {

    const transferDefaultsSettings = useSelector(selectTransferDefaultsSettings)
    const numberFormat = useSelector(selectNumberFormat)
    const currencies = useSelector(selectCurrency)
    const dateFormat = useSelector(selectDateFormat)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const {id} = useParams()


    // state
    const [categoryItems, setCategoryItems] = useState(null)
    const [priceType, setPriceType] = useState(true)
    const [isHover, setIsHover] = useState([])
    const [filter, setFilter] = useState('')
    const {errors} = form?.formState


    // watch
    const packageMeasurements = useWatch({name: 'package_measurements', exact: true})
    const constantPrice = useWatch({name: 'constantPrice', exact: true})
    const warehouseFrom = useWatch({name: 'warehouse_from_id', exact: true})
    const warehouseTo = useWatch({name: 'warehouse_to_id', exact: true})
    const numberWatch = useWatch({name: 'number', exact: true})
    const commonPrice = form.watch('commonPrice')
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})

    const totalSum = +quantity * (+packageMeasurements?.quantity || 0) * +commonPrice?.amount
    const totalSumCurrencyName = currencies?.find(x => x.id === commonPrice?.currency_id)?.name


    useEffect(() => {
        if (!warehouseFrom) {
            form?.setValue("warehouse_from_id", transferDefaultsSettings?.defaultWarehouseId)
        }
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);
        const onEmployeeAddedHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, (res) => {
            form?.setValue("employee_id", res?.data?.id)
        })


        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeAddedHandler)
        }
    }, [transferDefaultsSettings])

    useEffect(() => {
        form?.setValue("employee_id", transferDefaultsSettings?.defaultEmployeeId)
    }, [transferDefaultsSettings?.defaultEmployeeId]);


    const onScan = (value) => {
        if (value.length > 14) {
            const quantityGet = form?.getValues('quantity')

            form?.setValue('marks_from', [value])
            form?.setValue('marks_to', [value])
            form?.setValue('quantity', +quantityGet + 1)

            addItem("addOrEditTransfer")
        }
    }

    const onChangeCategory = (option) => {
        setCategoryItems(items.filter(i => i?.item?.category?.id === option?.id))
    }

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
        setPrice({priceType: priceType, item: option})
    }

    const onChangePriceType = (option) => {
        const product = items?.find(item => item?.item?.id === itemId)
        setPriceType(option)
        setPrice({priceType: option, item: product})
    }

    const setPrice = ({priceType, item}) => {
        if (priceType) {
            form.setValue('commonPrice.amount', item ? item?.price?.common_price?.amount : 0)
            form.setValue('commonPrice.currency_id', item ? item?.price?.common_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.common_price?.amount : 0)
        } else {
            form.setValue('commonPrice.amount', item ? item?.price?.bulk_price?.amount : 0)
            form.setValue('commonPrice.currency_id', item ? item?.price?.bulk_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.bulk_price?.amount : 0)
        }
    }


    // focus start
    const onFocusItem = () => {
        if (!warehouseFrom && !warehouseTo && !itemId) {
            return true
        }
    }

    const onFocusWarehouseFrom = () => {
        if (!warehouseFrom && itemId) {
            return true
        }
    }

    const onFocusWarehouseTo = () => {
        if (warehouseFrom && !warehouseTo) {
            return true
        }
    }

    const onFocusQuantity = () => {
        if (itemId && warehouseFrom && warehouseTo && !quantity) {
            document.getElementById('quantity')?.focus()
            return true
        }
    }

    useEffect(() => {
        onFocusQuantity()
    }, [warehouseTo])
    // focus end


    const data = useMemo(() => {
        return transfer?.items?.filter(i => {
            const searchLower = filter.trim().toLowerCase();

            // search by barcode
            {
                if (searchLower.length >= 8 && searchLower.length <= 13 && !isNaN(searchLower)) {
                    const searchBarcode = items.filter(it => it.item?.barcodes?.some((b) => b?.includes(searchLower)))

                    if (searchBarcode.some(bar => bar?.item?.name.trim().toLowerCase() === i?.warehouse_item_from?.name.trim().toLowerCase()))
                        return true;
                }
            }

            // search by name
            {
                const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                const itemLowerName = i.warehouse_item_from?.name.toLowerCase();
                if (filterNameParts?.length === filterNameParts?.filter(fnp => itemLowerName?.indexOf(fnp) > -1)?.length)
                    return true;
            }
        })
    }, [transfer, filter])

    const groupByItemIdAndPrice = (filterItems) => {
        let groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item_from?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item_from?.id,
                            name: item?.warehouse_item_from?.name,
                            warehouse_from: item?.warehouse_item_from,
                            warehouse_to: item?.warehouse_item_to,
                        },
                        sub: [item]
                    }
                }
            }
        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(data?.filter(f => !f?.is_deleted))


    // hover mouser over
    const handleMouseOver = ({col, row, subRow}) => {
        setIsHover([col, row, subRow])
    }


    const updateItem = async ({itemOne, quantity, price, totalSum, item}) => {
        const operationItem = {
            operation_item_id: item?.id,
            item_id: item?.warehouse_item_from?.id,
            quantity: item?.quantity,
            price: {
                amount: item?.price?.amount,
                currency_id: item?.price?.currency?.id,
            },
            warehouse_from_id: item.warehouse_item_from?.warehouse?.id,
            warehouse_to_id: item.warehouse_item_to?.warehouse?.id,
        }


        if (itemOne) {
            operationItem.item_id = itemOne?.item?.id
            operationItem.price.amount = itemOne?.price?.common_price.amount
            operationItem.price.currency_id = itemOne?.price?.common_price?.currency?.global_currency_id
        }
        if (quantity) {
            operationItem.quantity = +quantity
        }
        if (price) {
            operationItem.price.amount = +price
        }
        if (totalSum) {
            operationItem.quantity = +(+totalSum / item?.price?.amount).toFixed(2)
        }

        await transferUpdateItemAsync({
            transferId: transfer?.id,
            operation: operationItem
        })
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setIsHover([])

            })
    }

    // delete item
    const onDeleteItem = async ({id, itemId}) => {
        await transferDeleteItemAsync({transferId: id, operationId: itemId})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }
    const onDateChanged = async (e, date) => {
        if (e === "Save") {
            await transferUpdateDateAsync({
                transferId: transfer?.id,
                date: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : dayjs(transfer?.date).format("YYYY-MM-DD HH:mm:ss")
            })
                .then(() => {
                    form.setValue('date', date ? dayjs(date, "YYYY-MM-DD HH:mm").toDate() : dayjs(transfer?.date, "YYYY-MM-DD HH:mm").toDate())
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
        if (e === "Cancel") {
            form.setValue('date', dayjs(transfer?.date, "YYYY-MM-DD HH:mm").toDate())
        }
    }

    const onNumberChanged = (e, number) => {
        if (e === "Save") {
            if (number?.trim() && number !== transfer?.number) {
                transferUpdateNumberAsync({transferId: transfer?.id, number: number})
                    .then(() => {
                        form.setValue('number', number ? number : transfer?.number)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch(() => {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    })
            }
        }
        if (e === "Cancel") {
            form.setValue('number', transfer?.number)
        }
    }

    const {loading: contextLoading, setLoading: setContextLoading} = useMainLayoutContext();


    const onApprovedHandler = async () => {
        setContextLoading(true)
        await transferApproveAsync({transferId: transfer?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            }).finally(() => {
              setContextLoading(false)
            })
    }
    return (
        <Fragment>
            <Row id={'warehouse-transfer-register'}
                 className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, !id ? "warehouse.operation.item.common.new_transfer" : "warehouse.operation.item.common.edit_transfer")}</h5>
                        {/*<span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>*/}
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <IconButton
                        size="sm"
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                        onClick={() => onApprovedHandler()}
                        disabled={!(transfer?.items?.length > 0 && !transfer?.is_approved)}
                    >
                        {/*<IconPlus/>*/}
                        {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">
                            {t(lang, !transfer?.is_approved ? "items.common.save" : "warehouse.dashboard.items.transfer.approved")}
                        </span>
                    </IconButton>
                </Col>
            </Row>
            <Card className={'mt-3 p-2'}>
                <Row>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name={'date'}
                            rules={{
                                required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                            }}
                            render={({field}) => (
                                <InputGroup className={!(dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== transfer?.date && transfer) ? 'select' : 'border-right-0'}>
                                    <DatePickerComponent
                                        containerClass={
                                            !(dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== transfer?.date && transfer) ? '' : 'w-75'
                                        }
                                        field={field}
                                        placeholderText={<>
                                            {t(lang, "Дата")}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                                        wrapperClassName={classNames({
                                            'is-invalid': errors?.date
                                        })}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.date,
                                            'border-right-0': dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== transfer?.date && transfer,
                                        })}
                                    />
                                    {dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== transfer?.date && transfer &&
                                        <Flex className={'w-25'}>
                                            <IconButton
                                                variant={"phoenix"}
                                                title={"Save"}
                                                className={'fs-8 w-50 rounded-3 cursor-pointer border-right-0 border-left-0 btn-primary-davr'}
                                                size="md"
                                                style={{height: 39}}
                                                onClick={(e) => onDateChanged("Save", field?.value)}
                                                icon={faCheck}
                                            />
                                            <IconButton
                                                variant="phoenix-secondary"
                                                title={"Cancel"}
                                                size="md"
                                                style={{height: 39}}
                                                className={'fs-8 w-50 rounded-3 cursor-pointer btn-davr border-left-0'}
                                                onClick={() => onDateChanged("Cancel")}
                                                icon={faCancel}
                                            />
                                        </Flex>
                                    }
                                </InputGroup>
                            )}/>
                        <Form.Control.Feedback type="invalid">
                            {errors?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name="warehouse_from_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                validate: value => warehouseTo !== value || t(lang, "warehouse.operation.item.common.same_warehouse.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        placeholder={<>
                                            {t(lang, 'warehouse.operation.item.common.warehouse_from')}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        defaultValue={field?.value}
                                        options={warehouse}
                                        onFocus={onFocusWarehouseFrom()}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_from_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.warehouse_from_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name="warehouse_to_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                validate: value => warehouseFrom !== value || t(lang, "warehouse.operation.item.common.same_warehouse.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        placeholder={<>
                                            {t(lang, 'warehouse.operation.item.common.warehouse_to')}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        defaultValue={field?.value}
                                        options={warehouse}
                                        onFocus={onFocusWarehouseFrom()}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_to_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.warehouse_to_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card>
            <Card className={'mt-3 p-2'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold"
                       style={{maxHeight: "560px"}}>
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th className="fw-medium  align-middle text-center">№</th>
                        <th className="align-middle text-center text-nowrap">{t(lang, "warehouse.operation.item.common.warehouse_from")}</th>
                        <th className="align-middle text-center text-nowrap">{t(lang, "warehouse.operation.item.common.warehouse_to")}</th>
                        <th className="align-middle"
                            style={{width: "300px"}}>{t(lang, "warehouse.operation.item.common.name")}</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.sum")}</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                        <th className={'col'}>
                            <TransferModal
                                addItem={addItem}
                                loading={loading}
                                categoryItems={categoryItems}
                                onChangeItem={onChangeItem}
                                commonPrice={commonPrice}
                                priceType={priceType}
                                constantPrice={constantPrice}
                                onChangePriceType={onChangePriceType}
                                show={show}
                                onHide={onHide}
                                onShow={onShow}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                        return (
                            <React.Fragment key={rowIndex}>
                                {item?.sub?.map((subItem, subRowIndex) => {
                                    return (
                                        <tr key={subItem.id} className="fw-bold" style={{height: "62px"}}>
                                            <td key={rowIndex}
                                                rowSpan={item?.sub?.length}
                                                style={{width: "50px"}}
                                            >
                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                            </td>
                                            <th>
                                                {subItem?.warehouse_item_from.warehouse?.name}
                                                <br/>
                                                {transfer?.is_approved &&
                                                    <div className="text-black" style={{fontSize: "16px"}}>
                                                        {subItem?.warehouse_item_from?.warehouse_item_use?.before_quantity}
                                                        <span> => </span    >
                                                        {subItem?.warehouse_item_from?.warehouse_item_use?.after_quantity}
                                                    </div>
                                                }
                                            </th>
                                            <th>
                                                {subItem?.warehouse_item_to.warehouse?.name}
                                                <br/>
                                                {transfer?.is_approved &&
                                                    <div className="text-black" style={{fontSize: "16px"}}>
                                                        {subItem?.warehouse_item_to?.warehouse_item_use?.before_quantity}
                                                        <span> => </span>
                                                        {subItem?.warehouse_item_to?.warehouse_item_use?.after_quantity}
                                                    </div>
                                                }
                                            </th>
                                            <td
                                                style={{minWidth: "300px"}}
                                                rowSpan={item?.sub?.length}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexItem,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}
                                            >
                                                {isHover[colCoordinateZero] === colIndexItem && isHover[rowCoordinateOne] === rowIndex && isHover[subCoordinateTwo] === subRowIndex ?
                                                    <WarehouseOperationItemSelect
                                                        right={true}
                                                        options={items}
                                                        defaultValue={subItem?.warehouse_item_from?.id}
                                                        menuPortalTarget={document.body}
                                                        onChange={option => {
                                                            updateItem({
                                                                itemOne: option,
                                                                item: subItem
                                                            }).then()
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        {subItem?.warehouse_item_from?.name}
                                                    </>
                                                }
                                            </td>
                                            <th
                                                style={{maxWidth: "100px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexQuantity,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexQuantity && isHover[rowCoordinateOne] === rowIndex && isHover[subCoordinateTwo] === subRowIndex ?
                                                    <Form.Control
                                                        placeholder={t(lang, "Quantity")}
                                                        defaultValue={subItem?.quantity}
                                                        onFocus={(e) => e.target.select()}
                                                        autoFocus
                                                        onBlur={(e) => {
                                                            updateItem({
                                                                quantity: e.target.value,
                                                                item: subItem,
                                                            }).then()
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        {subItem?.quantity}
                                                    </>
                                                }
                                            </th>
                                            <th
                                                style={{width: "200px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexPrice,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexPrice && isHover[rowCoordinateOne] === rowIndex && isHover[subCoordinateTwo] === subRowIndex ?
                                                    <Form.Control
                                                        placeholder={t(lang, "Price")}
                                                        defaultValue={subItem?.price?.amount}
                                                        onFocus={(e) => e.target.select()}
                                                        autoFocus
                                                        onBlur={(e) => {
                                                            updateItem({
                                                                price: e.target.value,
                                                                item: subItem,
                                                            }).then()
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                        <span
                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </>
                                                }
                                            </th>
                                            <th
                                                style={{width: "300px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexTotal,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexTotal && isHover[rowCoordinateOne] === rowIndex && isHover[subCoordinateTwo] === subRowIndex ?
                                                    <Form.Control
                                                        placeholder={t(lang, "totalSum")}
                                                        defaultValue={subItem?.quantity * subItem?.price?.amount}
                                                        onFocus={(e) => e.target.select()}
                                                        autoFocus
                                                        onBlur={(e) => {
                                                            updateItem({
                                                                totalSum: +e.target.value,
                                                                item: subItem,
                                                            }).then()
                                                        }}
                                                    />
                                                    : <>
                                                        {numeral.formats[numberFormat].format(subItem?.quantity * subItem?.price?.amount)}
                                                        <span
                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </>
                                                }
                                            </th>
                                            <td
                                                style={{width: "40px"}}>
                                                {subItem?.is_deleted &&
                                                    <span className="text-end text-nowrap text-danger">
                                                                        {t(lang, "warehouse.operation.item.common.removed")}
                                                                    </span>
                                                }
                                                <Badge
                                                    className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                                                    bg="danger-davr"
                                                    variant="phoenix"
                                                    onClick={() => onDeleteItem({
                                                        id: transfer?.id,
                                                        itemId: subItem?.id
                                                    })}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                </Badge>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                </Table>
                <WarehouseOperationFooterCurrencyTotalSum
                    netPriceType={"debtHidden"}
                    netPrice={OperationTotalSum(transfer?.items?.filter(f => !f?.is_deleted))}
                    pay={OperationTotalSum(transfer?.items?.filter(f => !f?.is_deleted))}
                />
            </Card>
        </Fragment>
    );
};

export default TransferForm;