export const BILLING = "billing"
export const PACKAGES = "packages"
export const CURRENCY = "currency"
export const NOTIFICATION = "notification"
export const ORGANIZATION = "organization"
export const SYSTEMS = "systems"
export const SAMPLE = "sample"

export const settingsServices = [
    /*{
        title: 'main.top.nav.profile_dropdown.dropdown_item.billing',
        to: '/settings/billing',
        query: 'billing',
        pathName: 'default-dashboard',
        topNavIcon: 'file-plus',
        active: true
    },
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.packages',
        to: '/settings/packages',
        query: 'packages',
        pathName: 'default-dashboard',
        topNavIcon: 'file-plus',
        active: true
    },*/
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.currency',
        to: '/settings/currency',
        query: 'currency',
        pathName: 'default-dashboard',
        topNavIcon: 'dollar-sign',
        active: true
    },
    /*{
        title: 'main.top.nav.profile_dropdown.dropdown_item.notification',
        to: '/settings/notification',
        query: 'notification',
        pathName: 'default-dashboard',
        topNavIcon: 'bell',
        active: true
    },
    {
        title: 'hippo.user.settings.profile.title',
        to: '/settings/info-organization',
        query: 'organization',
        pathName: 'default-dashboard',
        topNavIcon: 'info',
        active: true
    },*/
    {
        title: 'main.top.nav.profile_dropdown.dropdown_item.system',
        to: '/settings/systems',
        query: 'systems',
        pathName: 'default-dashboard',
        topNavIcon: 'sliders',
        active: true
    },
    {
        title: 'main.settings.tab.templates.title',
        to: '/settings/sample',
        query: 'sample',
        pathName: 'default-dashboard',
        topNavIcon: 'trello',
        active: true
    },
]


export const settingsQuery = (query) => {
    switch (query[0]) {
        case BILLING :
            return "main.top.nav.profile_dropdown.dropdown_item.billing"
        case PACKAGES :
            return "main.top.nav.profile_dropdown.dropdown_item.packages"
        case CURRENCY :
            return "main.top.nav.profile_dropdown.dropdown_item.currency"
        case NOTIFICATION :
            return "main.top.nav.profile_dropdown.dropdown_item.notification"
        case ORGANIZATION :
            return "hippo.user.settings.profile.title"
        case SYSTEMS :
            return "main.top.nav.profile_dropdown.dropdown_item.system"
        case SAMPLE :
            return "main.settings.tab.templates.title"
        default :
            return "main.billing.info.title"
    }
}
