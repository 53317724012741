import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import hippoImg from '../../assets/img/hippo-img-2.png'
import error404 from '../../pheonix/assets/img/spot-illustrations/404.png'
import {Link} from "react-router-dom";

const Error404 = () => {
    return (
        <div className="px-3">
            <Row className="min-vh-75 flex-center p-5">
                <Col xs={12} xl={10} xxl={8}>
                    <Row className="justify-content-center align-items-center">
                      {/*  <Col xs={12} lg={6} className="text-center">
                            <img
                                src={hippoImg}
                                alt=""
                                width={400}
                                className="img-fluid w-lg-100 d-dark-none"
                            />
                        </Col>*/}
                        <Col xs={12} lg={6} className="text-center">
                            <img
                                src={error404}
                                className="img-fluid mb-6 w-50 w-lg-75 d-dark-none"
                                alt=""
                            />
                            <h2 className="text-800 fw-bolder mb-3">Страница отсутствует!</h2>
                            <p className="text-900 mb-5">
                                Но не беспокойтесь! Наш гиппопотам ищет повсюду
                                <br className="d-none d-sm-block" />
                                пока вы ждете в безопасности.
                            </p>
                            <Button className={'d-block mt-3'} variant="primary" size="lg" as={Link} to="/">
                                На главную
                            </Button>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Error404;
