import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../../../lang/lang";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {useSelector} from "react-redux";
import {selectCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import {selectCurrency} from "../../../../store/reducers/currency/currencyReducer";
import {
    refundApproveAsync,
    refundPayoutRegisterAsync,
    refundPayoutUpdateAsync
} from "../../../../store/reducers/warehouse/refundReducer";
import toast from "react-hot-toast";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import {Card, Col, Row} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import purchase from "../purchase/Purchase";
import {useNavigate, Link} from "react-router-dom";
import IconEditPencil from "../../../../assets/icon/IconEditPencil";
import IconButton from "../../../IconButton";
import UploadRefundToExcel from "./UploadRefundToExcel";
import RefundPrint from "./RefundPrint";
import RefundDownloadPdf from "./RefundDownloadPdf";
import {isApprovedCheckedWarehouseOperation} from "../../../../enum/warehouse-operation/warehouseOperation";
import {
    PaymentTypeBankTransferCode,
    PaymentTypeBankTransferText, PaymentTypeCashCode, PaymentTypeCashText, PaymentTypeClickCode, PaymentTypeClickText,
    PaymentTypeHumoCode,
    PaymentTypeHumoText, PaymentTypePaymeCode, PaymentTypePaymeText,
    PaymentTypeUzCardCode,
    PaymentTypeUzCardText,
    PaymentTypeVisaCode,
    PaymentTypeVisaText
} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import WarehouseOperationDatatableTab from "../WarehouseOperationDatatableTab";
import WarehouseOperationFooterCurrencyTotalSum from "../WarehouseOperationFooterCurrencyTotalSum";

const Refund = ({refund}) => {

    const [activeTab, setActiveTab] = useState("all")
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate
    const navigate = useNavigate()

    useEffect(() => {
        if (refund?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])

    const onSubmitPay = async (pay) => {
        if (refund?.payout) {
            await refundPayoutUpdateAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!refund?.payout && refund?.is_approved) {
            await refundPayoutRegisterAsync({refundId: pay?.id, payout: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!refund?.is_approved) {
            await refundApproveAsync({refundId: pay?.id, payout: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',() => scrollEvent('warehouse-refund-view'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-refund-view'))
    }, []);


    return (
        <Fragment>

            <Row id={'warehouse-refund-view'} className="flex-between-center align-items-center my-3 position-sticky"
                 style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.items.history.operation.type.refund")} :
                            № {refund?.number}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col sm={12} lg={8} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <IconButton
                        variant="phoenix-secondary"
                        as={Link}
                        to={`/operation/refunds/update/${refund?.id}`}
                        className="btn-white-davr d-flex gap-2 align-items-center"
                    >
                        <IconEditPencil/>
                        {t(lang, "warehouse.operation.item.common.edit")}
                    </IconButton>
                    <UploadRefundToExcel id={refund?.id} number={refund?.number}/>
                    <RefundPrint refundId={refund?.id}/>
                    <RefundDownloadPdf
                        refundId={refund?.id}
                        number={refund?.number}
                    />
                </Col>
            </Row>
            <Card className={'p-1'}>
                <Card.Footer as={Row} className={'border-0 py-1'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, 'admin.organization.view.active_sessions.session.created_at')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{refund?.date}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, 'warehouse.operation.item.common.sale_status')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{t(lang, isApprovedCheckedWarehouseOperation(refund?.is_approved))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, 'partners.common.sales.client')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text w-100 text-truncate d-block h-100'}>{refund?.contractor?.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeCashText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeCashCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeCashCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeUzCardText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeUzCardCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeUzCardCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeHumoText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeHumoCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeHumoCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeBankTransferText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeBankTransferCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeBankTransferCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeClickText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeClickCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeClickCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypePaymeText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypePaymeCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypePaymeCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeVisaText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(refund?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeVisaCode)?.amount || 0)}</span>
                                        <span>{refund?.payout?.cash_box_states?.find(i => i.payment_type === PaymentTypeVisaCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <h4 className={'text-center my-2'}>{t(lang, "warehouse.operation.item.common.list.register")}</h4>

            <Card className={'p-1'}>
                <WarehouseOperationDatatableTab
                    dataItems={refund?.items?.filter(f => !f.is_deleted)}
                />
                <WarehouseOperationFooterCurrencyTotalSum
                    netPrice={refund?.net_price}
                    pay={refund?.payout}
                />
            </Card>
        </Fragment>
    );
};

export default Refund;
