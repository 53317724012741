import React, {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../store/reducers/item/itemReducer";
import {useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../store/reducers/currency/currencyReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS, WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS
} from "../../../../eventbus/warehouse/refundEvent";
import {getRefundItemAsync} from "../../../../store/reducers/warehouse/refundReducer";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import WarehouseNo from "../../../../components/warehouse/operation/WarehouseNo";
import ItemNo from "../../../../components/warehouse/operation/ItemNo";
import EditRefundForm from "../../../../components/warehouse/operation/refund/form/EditRefundForm";

const EditRefund = () => {

    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [refund, setRefund] = useState(null)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const {id} = useParams()

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, (id) => {
            loadRefund()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, (id) => {
            loadRefund()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`/operation/refunds/view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,  (id) => {
            navigate(`/operation/refunds/view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS,  (id) => {
            navigate(`/operation/refunds/view/${id}`)
        })

        const onDeletePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS,  (id) => {
            navigate(`/operation/refunds/view/${id}`)
        });

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, (id) => {
            loadRefund()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, (id) => {
            loadRefund()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS, (response) => {
            setRefund(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, (id) => {
            loadRefund()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_PAYOUT_SUCCESS, onDeletePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, onChangedNumberHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
        }
    }, [])

    useEffect(() => {
        loadRefund()
    },[activeOrganization, id])

    const loadRefund = async () => {
        await getRefundItemAsync({refundId: id})
            .then((response) => {
                setRefund(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setRefund(null)
            })
    }

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!refund)
        return (
            <SpinnerComponent />
        )



    return (
        <EditRefundForm refund={refund} />
    );
};

export default EditRefund;
