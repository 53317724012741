// order
export const EventCodeOrderCreated = 1
export const EventCodeOrderEdited = 2
export const EventCodeOrderApproved = 3
export const EventCodeOrderDeclined = 4
export const EventCodeOrderDeclineCanceled = 5
export const EventCodeOrderStopped = 6
export const EventCodeOrderResumed = 7

// return order
export const EventCodeReturnOrderApprovedByCustomer = 101

// invoice
export const EventCodeInvoiceCustomerApproved = 200
export const EventCodeInvoiceExecutorApproved = 201
export const EventCodeInvoiceDeleted = 202

// return invoice
export const EventCodeReturnInvoiceCustomerApproved = 300
export const EventCodeReturnInvoiceExecutorApproved = 301
export const EventCodeReturnInvoiceDeleted = 302

// act
export const EventCodeActIncomeCreated = 401
export const EventCodeActOutCreated = 402

// crm order
export const EventCodeCrmOrderCreated = 3601


export const EdiDefaultNotification = [
    {
        // order
        text: "notification.orders",
        notifications: [
            {
                text: 'system.notification.event.order.create',
                value: EventCodeOrderCreated
            },
            {
                text: 'system.notification.event.order.edit',
                value: EventCodeOrderEdited
            },
            {
                text: 'system.notification.event.order.approve',
                value: EventCodeOrderApproved
            },
            {
                text: 'system.notification.event.order.decline',
                value: EventCodeOrderDeclined
            },
            {
                text: 'system.notification.event.order.decline_cancel',
                value: EventCodeOrderDeclineCanceled
            },
            {
                text: 'system.notification.event.order.stop',
                value: EventCodeOrderStopped
            },
            {
                text: 'system.notification.event.order.resume',
                value: EventCodeOrderResumed
            }
        ]
    },
    {
        // return order
        text: "notification.return_orders",
        notifications: [
            {
                text: 'system.notification.event.return_order.approve',
                value: EventCodeReturnOrderApprovedByCustomer
            }
        ]
    },
    {
        // invoice
        text: "notification.invoice",
        notifications: [
            {
                text: 'system.notification.event.invoice.customer_approve',
                value: EventCodeInvoiceCustomerApproved
            },
            {
                text: 'system.notification.event.invoice.executor_approve',
                value: EventCodeInvoiceExecutorApproved
            },
            {
                text: 'system.notification.event.invoice.delete',
                value: EventCodeInvoiceDeleted
            }
        ]
    },
    {
        // return invoice
        text: "notification.return_invoice",
        notifications: [
            {
                text: 'system.notification.event.return_invoice.customer_approve',
                value: EventCodeReturnInvoiceCustomerApproved
            },
            {
                text: 'system.notification.event.return_invoice.executor_approve',
                value: EventCodeReturnInvoiceExecutorApproved
            },
            {
                text: 'system.notification.event.return_invoice.delete',
                value: EventCodeReturnInvoiceDeleted
            }
        ]
    },
    {
        // act
        text: "notification.act",
        notifications: [
            {
                text: 'system.notification.event.act.income_create',
                value: EventCodeActIncomeCreated
            },
            {
                text: 'system.notification.event.act.create',
                value: EventCodeActOutCreated
            }
        ]
    },
    {
        // crm order
        text: "notification.crm_order",
        notifications: [
            {
                text: 'system.notification.event.crm_order.create',
                value: EventCodeCrmOrderCreated
            }
        ]
    }
]
