export const ADD_ROLE_REQUESTED = 'ADD_ROLE_REQUESTED';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAILED = 'ADD_ROLE_FAILED';

export const EDIT_ROLE_REQUESTED = "EDIT_ROLE_REQUESTED";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILED = "EDIT_ROLE_FAILED";

export const DELETE_ROLE_REQUESTED = "DELETE_ROLE_REQUESTED";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED";

export const EDIT_ACCOUNT_ROLE_REQUESTED = "EDIT_ACCOUNT_ROLE_REQUESTED";
export const EDIT_ACCOUNT_ROLE_SUCCESS = "EDIT_ACCOUNT_ROLE_SUCCESS";
export const EDIT_ACCOUNT_ROLE_FAILED = "EDIT_ACCOUNT_ROLE_FAILED";
