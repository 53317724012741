import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


const WarehouseNo = () => {




    return(
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="text-center">
                            <Card.Title>
                                Склад не создан,
                                &nbsp;
                                <Link to="../../warehouses">Пожалуйста, Создайте Склад</Link>
                            </Card.Title>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default WarehouseNo;