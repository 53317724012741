import React, {Fragment} from 'react';
import ProfileSettings from "../../components/settings/info-organization/ProfileSettings";
import UsersDatatable from "../../components/settings/info-organization/UsersDatatable";

const InfoOrganization = () => {
    return (
        <Fragment>
            {/*<ProfileSettings />*/}
            <UsersDatatable/>
        </Fragment>
    );
};

export default InfoOrganization;
