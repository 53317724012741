import React, {Fragment, useEffect, useMemo, useState} from 'react';
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {updatePagination} from "../../../store/reducers/item/itemReducer";
import {UZS} from "../../../enum/item/itemWrapper";
import {
    selectAllContractor,
    selectContractorFilterOptions,
    selectPaginationOptions
} from "../../../store/reducers/contractor/contractorReducer";
import {transliterate} from "transliteration";
import naturalCompare from "natural-compare";
import numeral from "numeral";
import {
    ContractorDebtStateFilterIAmOwedDebt,
    ContractorDebtStateFilterIOweDebt,
    ContractorTypeBuyer,
    ContractorTypeProvider
} from "../../../enum/ContractorWrapper";
import SoftBadge from "../../../components/common/SoftBadge";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import ItemDataTableHeader from "./ItemDataTableHeader";
import ContractorTabs from "./ContractorTabs";
import AdvanceTable from "../../../components/table/AdvanceTable";
import AdvanceTableCustomPagination2 from "../../../components/table/AdvanceTableCustomPagination2";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EditContractor from "../../../components/contractor/EditContractor";
import DeleteContractorModal from "./DeleteContractorModal";
import Badge from "../../../components/common/Badge";

const ContractorDataTable = () => {

    const contractors = useSelector(selectAllContractor);
    const filters = useSelector(selectContractorFilterOptions);
    const numberFormat = useSelector(selectNumberFormat);
    const currency = useSelector(selectCurrency)
    const {page, limit} = useSelector(selectPaginationOptions, shallowEqual);
    const lang = useSelector(selectLang);


    const t = Translate;
    const dispatch = useDispatch();
    const [sort, setSort] = useState({
        label: t(lang, "Название"),
        value: 'name'
    })

    const [sortByDesc, setSortByDesc] = useState({
        label: t(lang, 'По возрастанию'),
        value: false
    });

    const [editingContractor, setEditingContractor] = useState(null);
    const [deletingContractorId, setDeletingContractorId] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [filter, setFilter] = useState(false)

    useEffect(() => {
        setSelectedCurrency(currency.find(currency => currency.code === UZS))
    }, [currency])

    useEffect(() => {
        dispatch(updatePagination({limit: limit, page: 1}));
    }, [filters])

    const separationCurrenciesAndTotalSum = (states) => {
        let totalSum = 0
        states.forEach(state => {
            totalSum += state?.amount * currency.find(f => f.id === state.currency.id)?.rate
        })
        return totalSum
    }

    const data = useMemo(() => {
        let filtered_contractors = [];

        // filter by name
        for (let i = 0; i < contractors.length; i++) {
            const contractor = contractors[i];

            if (filters.name && filter) {
                let found = false;
                const searchName = filters.name.trim().toLowerCase();
                const contractorNameLower = contractor.name.trim().toLowerCase();

                const transliteratedQuery = transliterate(searchName);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');

                const transliteratedItemName = transliterate(contractorNameLower);

                if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    found = true;

                // search by contact
                if (contractor.contacts.some((contact) => contact.value.toLowerCase().includes(searchName))) {
                    found = true;
                }

                if (!found)
                    continue;
            }
            filtered_contractors.push(contractor);
        }

        //filter by type
        if (filters.type && filter) {
            filtered_contractors = filtered_contractors.filter(contractor => {
                const isExistType = contractor.type.some(t => t === filters.type)
                if (isExistType)
                    return contractor
            })
        }

        //filter by label
        if (filters.label && filter) {
            filtered_contractors = filtered_contractors.filter(contractor => {
                const isExist = contractor.labels.some(label => label.id === filters.label)
                if (isExist)
                    return contractor
            })
        }

        // filter by debt
        if (filters?.debt && filter) {
            filtered_contractors = filtered_contractors.filter(item => {
                if (item?.debts?.length) {
                    let totalSum = 0;
                    item.debts.forEach(debt => {
                        totalSum += debt?.amount * currency.find(f => f.id === debt.currency.id)?.rate
                    })
                    if (filters.debt === ContractorDebtStateFilterIOweDebt && totalSum > 0) {
                        return {...item}
                    }
                    if (filters.debt === ContractorDebtStateFilterIAmOwedDebt && totalSum < 0) {
                        return {...item}
                    }
                }
            })
        }

        switch (sort?.value && filter) {
            case 'debt':
                if (sortByDesc?.value && filter)
                    return filtered_contractors.sort((a, b) => naturalCompare(separationCurrenciesAndTotalSum(b.debts), separationCurrenciesAndTotalSum(a.debts)));

                return filtered_contractors.sort((a, b) => naturalCompare(separationCurrenciesAndTotalSum(a.debts), separationCurrenciesAndTotalSum(b.debts)));
            default:
                if (sortByDesc?.value && filter)
                    return filtered_contractors.sort((a, b) => naturalCompare(b.name, a.name));
                return filtered_contractors.sort((a, b) => naturalCompare(a.name, b.name));
        }
    }, [contractors, filter, page, limit])

    const separationCurrenciesAndTotalSums = useMemo(() => {
        let totalSum = 0
        data.filter((item, index) => index >= (page - 1) * limit && index < page * limit).forEach(contractor => {
            contractor.debts.forEach(debt => {
                totalSum += debt.amount * currency.find(f => f.id === debt.currency.id)?.rate
            })
        })
        return isNaN(totalSum) ? 0 : totalSum
    }, [contractors, data, filters, page, limit])

    const totalGroupCurrency = useMemo(() => {
        const groupedSums = {}
        data.filter((item, index) => index >= (page - 1) * limit && index < page * limit).forEach(contractor => {
            contractor.debts.forEach(debt => {
                if (!groupedSums[debt?.currency?.id]) {
                    groupedSums[debt?.currency?.id] = {
                        total: 0,
                        currency_id: debt?.currency?.id,
                        name: debt.currency?.name
                    }
                }
                groupedSums[debt?.currency?.id].total += debt?.amount
            })
        })
        return Object.values(groupedSums)
    }, [contractors, data, page, limit])

    const onClickEdit = (contractor) => {
        let con = JSON.parse(JSON.stringify(contractor));
        con.contacts = con.contacts.map(contact => {
            if (contact.type === 1) {
                let phone = contact.value;
                if (phone.length === 12 && phone.slice(0, 3) === '998') {
                    phone = phone.slice(3, 12)
                }
                return {
                    type: contact.type,
                    value: phone,
                    description: contact.description
                }
            } else {
                return contact
            }
        })
        setEditingContractor(con)
    }

    const columns = useMemo(() => {
        return [
            {
                Header: "#",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: ({row}) => {
                    return `${row.index + 1}`;
                }
            },
            {
                accessor: 'info.name',
                Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
                headerProps: {className: 'pe-7'},
                Cell: ({row: {original}}) => {
                    return (
                        <strong>{original.name}</strong>
                    );
                }
            },
            {
                accessor: 'type',
                Header: 'Тип',
                headerProps: {className: 'pe-7'},
                // totalInfo: `${t(lang, "Итого:")}`,
                // totalInfoClassName: 'fs-5 fw-bold text-nowrap text-end',
                Cell: ({row: {original}}) => {
                    if (original.type?.length > 0) {
                        return original.type?.map((item, index) => {
                            if (item === ContractorTypeBuyer) {
                                return (
                                    <Badge
                                        bg="success"
                                        key={index}
                                        variant={'phoenix'}
                                        className={'px-3 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}
                                    >
                                        {t(lang, "edi.common.buyer")}
                                    </Badge>
                                )
                            }
                            if (item === ContractorTypeProvider) {
                                return (
                                    <Badge
                                        bg="warning"
                                        variant={'phoenix'}
                                        className={'px-3 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}
                                        key={index}>{t(lang, "edi.common.executor")}
                                    </Badge>
                                )
                            }
                        })
                    } else {
                        return (<Badge
                            bg={'primary'}
                            variant={'phoenix'}
                            className={'px-3 py-1 fs-9 text-primary text-uppercase text-decoration-none fw-medium border-0 rounded-4'}>
                            Не указано
                        </Badge>)
                    }
                }
            },

            // {
            //     Header: t(lang, 'crm.contractor.datatable.label'),
            //     accessor: 'labels',
            //     headerProps: {className: 'pe-2 text-center'},
            //     cellProps: {className: 'text-center'},
            //     Cell: ({row: {original}}) => {
            //         if (original.labels?.length > 0) {
            //             return original.labels?.map((label, index) => {
            //                 return (
            //                     <Badge className={'me-1'} key={index}>{label.name}</Badge>
            //                 )
            //             })
            //         } else {
            //             return (<span/>)
            //         }
            //     }
            // },
            // {
            //     accessor: 'organization_code',
            //     Header: t(lang, "partners.common.organization_code"),
            //     headerProps: {className: 'pe-7 text-center'},
            //     cellProps: {className: 'text-center'},
            //     totalInfo: `${t(lang, "Итого:")}`,
            //     totalInfoClassName: 'fs-5 fw-bold text-nowrap text-end',
            //     Cell: ({row: {original}}) => {
            //         return (
            //             <strong className={'fs-0'}>{original.organization_code}</strong>
            //         );
            //     }
            // },
            {
                Header: t(lang, "edi.contractor.datatable.header.row.state"),
                accessor: 'debts',
                headerProps: {className: 'pe-7'},
                // totalInfoClassName: "fw-bold fs--1 text-end text-nowrap",
                // totalInfo: totalGroupCurrency.length > 0 && totalGroupCurrency.map((debt, index) => {
                //     return (
                //         <div key={index} className='me-3'>
                //             <span>{numeral['formats'][numberFormat].format(debt.total)}</span>
                //             <small className={'ms-2 fw-bold text-info'}>{debt.name}</small>
                //         </div>
                //     )
                // }) || <span className={'me-3'}>0</span>,
                cellProps: {className: 'text-end'},
                Cell: ({row: {original}}) => {
                    if (original?.debts?.length !== 0) {
                        return original.debts.map((payment, index) => {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        {
                                            payment.amount ?
                                                <>
                                                    <strong
                                                        className={'fs-7'}>{numeral.formats[numberFormat].format(payment.amount)}</strong>
                                                    <small
                                                        className={'ms-2 fw-bold second-text'}>{payment.currency.name}</small>
                                                </>
                                                : null}
                                    </div>
                                )
                            }
                        )
                    } else {
                        return <SoftBadge bg='primary' className='me-2'>
                            0
                        </SoftBadge>
                    }
                }
            },
            // {
            //     Header: t(lang, 'crm.common.datatable.total.sum'),
            //     accessor: 'total_debts',
            //     headerProps: {className: 'pe-2 text-end'},
            //     totalInfoClassName: "fw-bold fs-0 text-end text-nowrap",
            //     totalInfo: <div className={'mt-2'}>
            //         <span>{numeral['formats'][numberFormat].format(separationCurrenciesAndTotalSums / selectedCurrency?.rate)}</span>
            //         <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
            //     </div>
            //     ,
            //     Cell: ({row: {original}}) => {
            //         if (original?.debts?.length) {
            //             return (
            //                 <Flex className={'fw-bold justify-content-end fs-0 align-items-center'}>
            //                     <span>{separationCurrenciesAndTotalSum(original.debts) ? numeral.formats[numberFormat].format(separationCurrenciesAndTotalSum(original.debts) / selectedCurrency?.rate) : 0}</span>
            //                     <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
            //                 </Flex>
            //             )
            //         } else {
            //             return <Flex className={'fw-bold justify-content-end fs-0 align-items-center'}>
            //                 <span>0</span>
            //                 <small className={'ms-2 fw-bolder text-info'}>{selectedCurrency?.name}</small>
            //             </Flex>
            //         }
            //     }
            // },
            {
                accessor: 'action',
                Header: "",
                disableSortBy: true,
                cellProps: {
                    width: '80px',
                    className: 'text-end'
                },
                Cell: ({row}) => {
                    return (
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                            <RevealDropdown>
                                <Dropdown.Item
                                    onClick={() => {
                                        onClickEdit(row.original)
                                    }}>
                                    <span>{t(lang, "edi.common.edit_button")}</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) => {
                                        setDeletingContractorId(row.original.id);
                                    }}>
                                    <span>{t(lang, "edi.common.delete_button")}</span>
                                </Dropdown.Item>
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                    );
                }
            },
        ].filter(i => i)
    }, [contractors, filter, page, limit, selectedCurrency])

    const onLimitChange = (limit) => dispatch(updatePagination({limit: limit, page: page}));
    const onPageChange = (page) => dispatch(updatePagination({limit: limit, page: page}));

    if (!currency.length) return <Spinner className={'position-absolute top-50'} style={{left: '50%'}}
                                          animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                selection
                width={'50px'}
                pagination
                perPage={limit}
                currentPage={page - 1}
            >
                <ItemDataTableHeader
                    setSelectedCurrency={setSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                    setSortByDesc={setSortByDesc}
                    sortByDesc={sortByDesc}
                    setFilter={setFilter}
                    currency={currency}
                    setSort={setSort}
                    sort={sort}
                    data={data}
                    table
                />
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <ContractorTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0',
                            }}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                               onPageChange={onPageChange}
                                               onLimitChange={onLimitChange}
                                               table
                />
            </AdvanceTableWrapper>
            <EditContractor contractor={editingContractor} show={!!editingContractor} onClose={() => {
                setEditingContractor(null)
            }}/>

            <DeleteContractorModal id={deletingContractorId} show={!!deletingContractorId} onClose={() => {
                setDeletingContractorId(null)
            }}/>

        </Fragment>
    );
};

export default ContractorDataTable;
