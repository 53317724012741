import React, {Fragment, useState} from 'react';
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";
import {refundDeleteAsync} from "../../../../store/reducers/warehouse/refundReducer";
import {Button, Dropdown, Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";

const RefundDelete = ({id}) => {

    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    const onDeleteItem = async () => {
        setLoading(true)

        await refundDeleteAsync({refundId: id})
            .then(() => {
                handlerClose()
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <Dropdown.Item className="text-danger align-center"
                           onClick={() => handleShow()}
            >
                <span>{t(lang, 'items.common.delete')}</span>
            </Dropdown.Item>

            <Modal className={'davr-modal'} show={show} onHide={handlerClose}>
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-davr'} variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.close")}
                    </Button>
                    <Button className={'btn-primary-davr'} onClick={() => onDeleteItem()}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' size="sm"
                                     role='switch'/>
                        }
                        {t(lang, "warehouse.operation.item.common.delete_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RefundDelete;
