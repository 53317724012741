export const ContractorSourcePayment = 0
export const ContractorSourceSale = 1
export const ContractorSourceReturn = 2

export const ContractorPaymentSources = [
    {
        value: ContractorSourcePayment,
        label: "crm.contractor.operation.type.payment.label",
        variant: 'primary'
    },
    {
        value: ContractorSourceSale,
        label: "crm.contractor.operation.type.sale.label",
        variant: 'success'
    },
    {
        value: ContractorSourceReturn,
        label: "crm.contractor.operation.type.return.label",
        variant: 'danger'
    },
]

export const FindContractorPaymentSource = (Source) => {
    return ContractorPaymentSources.find(lt => lt.value === Source);
}
