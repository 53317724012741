import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {currencyConverterConvert} from "../../../store/reducers/currency/currencyReducer";
import printJS from "print-js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {Button, Col, FloatingLabel, InputGroup, Row, Form} from "react-bootstrap";
import dayjs from "dayjs";
import SelectItemBarcodeType from "./SelectItemBarcodeType";
import CleaveWithCommaMark from "../../common/CleaveWithCommaMark";
import SelectCurrency from "./SelectCurrency";
import RangeSlider from "react-bootstrap-range-slider";
import numeral from "numeral";
import Barcode from 'react-jsbarcode';

const PrintItemBarcode = ({itemName, defaultBarcode, defaultPrice, defaultCurrency, sku}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const numberFormat = useSelector(selectNumberFormat);

    useEffect(() => {
        if (localStorage.getItem('barcodeSettings')) {
            let barcodeSettings = JSON.parse(localStorage.getItem('barcodeSettings'));

            if (barcodeSettings.barcodeType)
                setBarcodeType(barcodeSettings.barcodeType)
            if (barcodeSettings.barcodeWidth)
                setBarcodeWidth(barcodeSettings.barcodeWidth)
            if (barcodeSettings.barcodeHeight)
                setBarcodeHeight(barcodeSettings.barcodeHeight)
            if (barcodeSettings.barcodeMarginBottom)
                setBarcodeMarginBottom(barcodeSettings.barcodeMarginBottom)
            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)
            if (barcodeSettings.barcodeFontSize)
                setBarcodeFontSize(barcodeSettings.barcodeFontSize)
            if (barcodeSettings.viewItemSku)
                setViewItemSku(barcodeSettings.viewItemSku)
            if (barcodeSettings.viewBarcode)
                setViewBarcode(barcodeSettings.viewBarcode)
            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)

            if (typeof barcodeSettings.viewItemName !== "undefined")
                setViewItemName(barcodeSettings.viewItemName)

            if (typeof barcodeSettings.viewItemPrice !== "undefined")
                setViewItemPrice(barcodeSettings.viewItemPrice)

            if (barcodeSettings.itemNameFontSize)
                setItemNameFontSize(barcodeSettings.itemNameFontSize)

            if (barcodeSettings.itemPriceFontSize)
                setItemPriceFontSize(barcodeSettings.itemPriceFontSize)

            if (barcodeSettings.itemNameAndPriceWidth)
                setItemNameAndPriceWidth(barcodeSettings.itemNameAndPriceWidth)

            if (typeof barcodeSettings.viewNowDateTime !== "undefined")
                setViewNowDateTime(barcodeSettings.viewNowDateTime)

            if (typeof barcodeSettings.viewItemSku !== "undefined")
                setViewItemSku(barcodeSettings.viewItemSku)

            if (typeof barcodeSettings.viewBarcode !== "undefined")
                setViewBarcode(barcodeSettings.viewBarcode)

            if (barcodeSettings.itemSkuFontSize)
                setItemSkuFontSize(barcodeSettings.itemSkuFontSize)
        }
    }, [])

    useEffect(() => {
        if (defaultBarcode)
            setBarcode(defaultBarcode);
    }, [defaultBarcode])

    useEffect(() => {
        setPriceAmount(defaultPrice);
    }, [defaultPrice])

    useEffect(() => {
        setItemCurrency(defaultCurrency);
    }, [defaultCurrency])

    const [isValid, setIsValid] = useState(false);
    const [priceAmount, setPriceAmount] = useState(0);
    const [itemCurrency, setItemCurrency] = useState(0);
    const [barcode, setBarcode] = useState('12345670');
    const [barcodeType, setBarcodeType] = useState(null);
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const [barcodeHeight, setBarcodeHeight] = useState(70);
    const [barcodeMarginBottom, setBarcodeMarginBottom] = useState(10);
    const [barcodeFontSize, setBarcodeFontSize] = useState(16);
    const [viewItemName, setViewItemName] = useState(true);
    const [viewItemPrice, setViewItemPrice] = useState(true);
    const [itemNameFontSize, setItemNameFontSize] = useState(16);
    const [itemPriceFontSize, setItemPriceFontSize] = useState(16);
    const [itemNameAndPriceWidth, setItemNameAndPriceWidth] = useState(160);
    const [viewNowDateTime, setViewNowDateTime] = useState(false);
    const [viewItemSku, setViewItemSku] = useState(false);
    const [viewBarcode, setViewBarcode] = useState(true);
    const [itemSkuFontSize, setItemSkuFontSize] = useState(true);

    const options = {
        format: barcodeType ? barcodeType.value : 'EAN8',
        width: barcodeWidth,
        height: barcodeHeight,
        marginBottom: barcodeMarginBottom,
        fontSize: barcodeFontSize,
        valid: function (val) {
            setIsValid(val)
        }
    }

    const onItemPriceCurrencyChanged = async (oldCurrency, newCurrency) => {
        try {
            if (oldCurrency && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: Number(priceAmount),
                    currency_id_to: newCurrency.id
                });
                setPriceAmount(value ? Number(value.toFixed(2)) : 0);
                setItemCurrency(newCurrency);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onPrint = () => {
        printJS({printable: 'printBarcode', type: 'html', targetStyles: ['*'], font_size: ''});
        localStorage.setItem('barcodeSettings', JSON.stringify({
            barcodeType: barcodeType,
            barcodeWidth: barcodeWidth,
            barcodeHeight: barcodeHeight,
            barcodeMarginBottom: barcodeMarginBottom,
            barcodeFontSize: barcodeFontSize,
            viewItemName: viewItemName,
            viewItemPrice: viewItemPrice,
            itemNameFontSize: itemNameFontSize,
            itemPriceFontSize: itemPriceFontSize,
            itemNameAndPriceWidth: itemNameAndPriceWidth,
            viewNowDateTime: viewNowDateTime,
            viewItemSku: viewItemSku,
            viewBarcode: viewBarcode,
            itemSkuFontSize: itemSkuFontSize,
        }));
    }

    return (
        <Fragment>
            <Row>
                <Col xs={12} className={'text-end'}>
                    <Button onClick={onPrint} size={"lg"}>
                        <FontAwesomeIcon className="me-1" icon={faPrint}/>
                        {t(lang, "edi.common.button.print")}
                    </Button>
                </Col>
            </Row>

            <div className={'text-center'}>
                <div id={'printBarcode'} className={'d-inline-block'}>
                    {!isValid &&
                        <div className={'text-danger'}>{t(lang, "edi.common.forms.validations.invalid_format")}</div>}
                    {viewBarcode && <Barcode value={barcode} options={options}/>}

                    <div className={'m-auto'} style={{width: itemNameAndPriceWidth + 'px'}}>
                        {viewItemName && itemName &&
                            <div className='fw-bold'
                                 style={{lineHeight: 1, fontSize: itemNameFontSize + 'px'}}>{itemName}</div>
                        }
                        {viewItemPrice && priceAmount &&
                            <div className='fw-bold' style={{lineHeight: 1, fontSize: itemPriceFontSize + 'px'}}>
                                {numeral.formats[numberFormat].format(priceAmount)}
                                &nbsp;
                                {itemCurrency?.name}
                            </div>
                        }
                    </div>

                    {viewNowDateTime &&
                        <div className={'fw-bold'}
                             style={{lineHeight: 1}}>{dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")}</div>
                    }

                    {viewItemSku &&
                        <div className={'fw-bold'} style={{lineHeight: 1, fontSize: itemSkuFontSize + 'px'}}>{sku}</div>
                    }
                </div>
            </div>

            <Row className={"mt-5"}>
                <Col xs={12}>
                    <InputGroup>
                        <FloatingLabel label={'Штрих код'}>
                            <Form.Control
                                className={'border-right-0'}
                                value={barcode}
                                onChange={(e) => setBarcode(e.target.value)}
                                placeholder="Штрих код"
                            />
                        </FloatingLabel>
                        <SelectItemBarcodeType
                            left={true}
                            defaultTypeValue={barcodeType ? barcodeType.value : null}
                            onChange={(type) => {
                                setBarcodeType(type)
                            }}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className={'my-4'}>
                <Col xs={12}>
                    <InputGroup>
                        <CleaveWithCommaMark
                            placeholder={t(lang, "items.print.barcode.price.value")}
                            value={priceAmount} onChange={value => setPriceAmount(Number(value))} ref={null}/>
                        <SelectCurrency
                            left={true}
                            defaultCurrencyId={itemCurrency?.id}
                            onChange={(oldCurrency, newCurrency) => onItemPriceCurrencyChanged(oldCurrency, newCurrency)}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <div className={'d-flex flex-column gap-2'}>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.width")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={barcodeWidth}
                        size={"lg"}
                        min={1}
                        step={0.1}
                        max={10}
                        onChange={changeEvent => setBarcodeWidth(+changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.height")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={barcodeHeight}
                        size={"lg"}
                        step={0.1}
                        min={10}
                        max={150}
                        onChange={changeEvent => setBarcodeHeight(changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.fontsize")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={barcodeFontSize}
                        size={"lg"}
                        step={0.1}
                        min={2}
                        max={50}
                        onChange={changeEvent => setBarcodeFontSize(changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.name.fontsize")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={itemNameFontSize}
                        size={"lg"}
                        step={0.1}
                        min={2}
                        max={50}
                        onChange={changeEvent => setItemNameFontSize(changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.price.fontsize")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={itemPriceFontSize}
                        size={"lg"}
                        step={0.1}
                        min={2}
                        max={50}
                        onChange={changeEvent => setItemPriceFontSize(changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>
                        {t(lang, "items.print.barcode.textWidth")}
                    </Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={itemNameAndPriceWidth}
                        size={"lg"}
                        step={0.1}
                        min={20}
                        max={500}
                        onChange={changeEvent => setItemNameAndPriceWidth(changeEvent.target.value)}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className={'fs-7 p-0'}>Размер артикул</Form.Label>
                    <RangeSlider
                        style={{width: '100%'}}
                        value={itemSkuFontSize}
                        size={"lg"}
                        step={0.1}
                        min={2}
                        max={50}
                        onChange={changeEvent => setItemSkuFontSize(changeEvent.target.value)}
                    />
                </Form.Group>
            </div>

            <div className={'d-flex justify-content-between align-content-center my-5'}>
                <div className={'d-flex'}>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <Form.Check.Label
                            htmlFor={"viewName"}
                            className="mb-0 switch-label">{t(lang, "items.print.barcode.viewName")}</Form.Check.Label>
                        <label className="switch">
                            <Form.Check type="switch" id={"viewName"} className="d-flex align-items-center">
                                <Form.Check.Input checked={viewItemName} onChange={(e) => {
                                    setViewItemName(e.target.checked)
                                }} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                    </Form.Group>
                </div>
                <div className={'d-flex'}>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <Form.Check.Label
                            htmlFor={"viewPrice"}
                            className="mb-0 switch-label">{t(lang, "items.print.barcode.viewPrice")}</Form.Check.Label>
                        <label className="switch">
                            <Form.Check type="switch" id={"viewPrice"} className="d-flex align-items-center">
                                <Form.Check.Input checked={viewItemPrice} onChange={(e) => {
                                    setViewItemPrice(e.target.checked)
                                }} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                    </Form.Group>
                </div>
                <div className={'d-flex'}>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <Form.Check.Label
                            htmlFor={"viewDateTime"}
                            className="mb-0 switch-label">{t(lang, "items.print.barcode.viewDateTime")}</Form.Check.Label>
                        <label className="switch">
                            <Form.Check type="switch" id={"viewDateTime"} className="d-flex align-items-center">
                                <Form.Check.Input checked={viewNowDateTime} onChange={(e) => {
                                    setViewNowDateTime(e.target.checked)
                                }} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                    </Form.Group>
                </div>
            </div>

            <div className={'d-flex align-content-center justify-content-center gap-3 my-5'}>
                <div className={'d-flex me-5'}>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <Form.Check.Label
                            htmlFor={"viewItemSku"}
                            className="mb-0 switch-label">{t(lang, "items.print.barcode.viewItemSku")}</Form.Check.Label>
                        <label className="switch">
                            <Form.Check type="switch" id={"viewItemSku"} className="d-flex align-items-center">
                                <Form.Check.Input checked={viewItemSku} onChange={(e) => {
                                    setViewItemSku(e.target.checked)
                                }} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                    </Form.Group>
                </div>
                <div className={'d-flex'}>
                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                        <Form.Check.Label
                            htmlFor={"viewBarcode"}
                            className="mb-0 switch-label">{t(lang, "items.print.barcode.viewBarcode")}</Form.Check.Label>
                        <label className="switch">
                            <Form.Check type="switch" id={"viewBarcode"} className="d-flex align-items-center">
                                <Form.Check.Input checked={viewBarcode} onChange={(e) => {
                                    setViewBarcode(e.target.checked)
                                }} type="checkbox"/>
                                <span className="slider"></span>
                            </Form.Check>
                        </label>
                    </Form.Group>
                </div>
            </div>
        </Fragment>
    );
};

export default PrintItemBarcode;
