import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {
    changeFilterOptionsAsync,
    getRevisionsAsync,
    getRevisionsCountAsync,
    revisionDownloadPdfAsync,
    selectRevisionCount,
    selectRevisionFilterOptions,
    selectRevisions
} from "../../../../store/reducers/warehouse/revisionReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import printJS from "print-js";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {Card, Dropdown} from "react-bootstrap";
import {isApprovedCheckedWarehouseOperation} from "../../../../enum/warehouse-operation/warehouseOperation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye, faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS
} from "../../../../eventbus/warehouse/revisionEvent";
import AdvanceTableWrapper from "../../../../components/table/AdvanceTableWrapper";
import AdvanceTable from "../../../../components/table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../../components/table/AdvanceTableCustomPagination";
import WarehouseTabs from "../../../../components/warehouse/WarehouseTabs";
import RevisionsDatatableHeader from "./RevisionsDatatableHeader"
import Badge from "../../../../components/common/Badge";
import RevealDropdown, {RevealDropdownTrigger} from "../../../../components/common/RevealDropdown";
import {selectOrganizationTemplates, uploadRevisionToExcelAsync} from "../../../../store/reducers/excel/excelReducer";
import {downloadFile} from "../../../../helpers/excel-uploader";
import {TYPE_REVISION} from "../../../../enum/excel/ExcelTemplateTypes";

const RevisionsDataTable = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const revisions = useSelector(selectRevisions)
    const dispatch = useDispatch()
    const filters = useSelector(selectRevisionFilterOptions)
    const count = useSelector(selectRevisionCount)
    const lang = useSelector(selectLang)
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const t = Translate

    const [revisionTypeTemplates, setRevisionTypeTemplates] = useState([]);

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_REVISION) || [];
        setRevisionTypeTemplates(templates);
    }, [organizationTemplates])

    const downloadPdfRevision = ({id, number}) => {
        revisionDownloadPdfAsync({revisionId: id})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.revision_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPrint = ({id}) => {
        revisionDownloadPdfAsync({revisionId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const uploadRevision = (uri, id, number) => {
        uploadRevisionToExcelAsync({uri, id})
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, 'warehouse.item.download_excel.revision_name', {number}),
                });
            })
            .catch((error) => console.log("error", error))
    }

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),
            Cell: ({row: {original}}) => {
                return (
                    dayjs(original?.date).format("YYYY-MM-DD HH:mm:ss")
                )
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Пользователь"),
            headerProps: {
                className: "text-center"
            },
            cellProps: {
                className: "text-center"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-center">
                        <span
                            className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "Информация"),
            headerProps: {
                className: "text-start"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div style={{maxWidth: "250px", minWidth: "250px"}}>
                        <span className="d-flex text-wrap">{original?.note}</span>
                    </div>
                )
            }
        },
        {
            id: 'name',
            Header: t(lang, "warehouse.operation.item.common.sale_status"),
            Cell: ({row: {original}}) => {
                return (
                    <Badge variant={"phoenix"}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                           bg={original?.is_approved ? 'success-davr' : 'warning-davr'}
                    >
                        {t(lang, isApprovedCheckedWarehouseOperation(original?.is_approved))}
                    </Badge>
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <RevealDropdownTrigger drop={'start'}>
                        <RevealDropdown>
                            <Dropdown.Item
                                as={Link} to={`update/${original?.id}`}>
                                <span>{t(lang, "edi.common.edit_button")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => downloadPdfRevision({id: original?.id, number: original?.number})}>
                                <span>{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onPrint({id: original?.id})}>
                                <span>{t(lang, "warehouse.operation.item.common.print")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={`view/${original?.id}`}>
                                <span>{t(lang, "warehouse.dashboard.items.revision.view")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    const [defaultTemplate] = revisionTypeTemplates;
                                    uploadRevision(defaultTemplate.uri, original?.id, original?.number);
                                }}>
                                <span>{t(lang, "warehouse.dashboard.items.transfer.download_excel")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                </div>
            )
        }
    ]


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    const loadRevisions = () => {
        dispatch(getRevisionsAsync({...filters}))
        dispatch(getRevisionsCountAsync({...filters}))
    }


    useEffect(() => {
        loadRevisions()

        const onRevisionDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS, () => {
            loadRevisions()
        })
        const onRevisionRegisterSucceedHandler = EventBus.on(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, () => {
            loadRevisions();
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_DELETE_SUCCESS, onRevisionDeletedHandler);
            EventBus.remove(WAREHOUSE_OPERATION_REVISION_REGISTER_SUCCESS, onRevisionRegisterSucceedHandler);
        }
    }, [activeOrganization, filters])

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={revisions}
            pagination
            selection
            perPage={1000}
            width={'50px'}
        >
            <RevisionsDatatableHeader table/>
            <Card className={'p-0 rounded-4'}>
                <Card.Body className={'p-0'}>
                    <WarehouseTabs/>
                    <AdvanceTable
                        table
                        headerClassName="text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            className: 'fs--1 mb-0'
                        }}
                    />
                </Card.Body>
            </Card>
            <AdvanceTableCustomPagination
                count={count}
                limit={filters.limit}
                page={filters.page}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
            />
        </AdvanceTableWrapper>
    );
};

export default RevisionsDataTable;
