import React, {Fragment, useState} from 'react';
import {Button, Card, Form, Modal, Spinner} from "react-bootstrap";
import IconButton from "../../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import SaleFormModal from "./form/SaleFormModal";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import CheckSaleItemStateAvailableForSaleWrapper from "./CheckSaleItemStateAvailableForSaleWrapper";
import {useFormContext} from "react-hook-form";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Flex from "../../../common/Flex";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";

const SaleModal = ({
                       categoryItems,
                       onChangeItem,
                       priceType,
                       onChangePriceType,
                       commonPrice,
                       constantPrice,
                       itemId,
                       quantity,
                       warehouseId,
                       loading,
                       loadingMore,
                       addItem
                   }) => {
    const lang = useSelector(selectLang)
    const t = Translate
    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <IconButton icon={faPlus}
                        variant={'phoenix-primary'}
                        onClick={onShow}
                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                        iconClassName={'text-white'}
            />
            <Modal show={show} onHide={onHide} className={'davr-modal'}>
                <Modal.Header>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => onHide()} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            <div>
                                <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.view.item.title")}</h5>
                                <span className={'second-text fs-9'}>{t(lang, 'items.common.add_new.title')}</span>
                            </div>
                        </Flex>
                    </Modal.Title>                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => onHide()}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={'p-1'}>
                        <Form id={'sale-add'}>
                            <SaleFormModal
                                categoryItems={categoryItems}
                                onChangeItem={onChangeItem}
                                priceType={priceType}
                                onChangePriceType={onChangePriceType}
                                commonPrice={commonPrice}
                                constantPrice={constantPrice}
                            />
                        </Form>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <CheckSaleItemStateAvailableForSaleWrapper
                        itemId={itemId}
                        quantityAmount={quantity}
                        itemWarehouseId={warehouseId}
                    >
                        <Button type="submit" form={'sale-add'} className={'btn-white-davr'} disabled={loading} name="addOrEditSale"
                                size={'lg'}>
                            {loading &&
                                <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                            }
                            {t(lang, "warehouse.operation.item.common.button.add.more")}
                        </Button>
                    </CheckSaleItemStateAvailableForSaleWrapper>
                    <CheckSaleItemStateAvailableForSaleWrapper
                        itemId={itemId}
                        quantityAmount={quantity}
                        itemWarehouseId={warehouseId}
                    >
                        <Button type="submit" onClick={onHide} form={'sale-add'} className={'btn-primary-davr'} disabled={loadingMore} name="addOrEditSaleMore"
                                size={'lg'}>
                            {loadingMore &&
                                <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                            }
                            {t(lang, "warehouse.operation.item.common.button.add.and.hide.modal")}
                        </Button>
                    </CheckSaleItemStateAvailableForSaleWrapper>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SaleModal;
