import axios from "axios";

class ContractorLabelService {
    getContractorLabelCount(params) {
        return axios.get('/contractor/label/get/count', {params: params})
    }
    getContractorLabels(params){
        return axios.get('/contractor/label/get', {params: params})
    }
    getContractorLabel(id) {
        return axios.get(`/contractor/label/get/${id}`)
    }

    addContractorLabel(payload) {
        return axios.post("/contractor/label/register", payload);
    }

    editContractorLabel(id, payload) {
        return axios.post(`/contractor/label/update/${id}`, payload);
    }

    deleteContractorLabel({id}) {
        return axios.post(`/contractor/label/delete/${id}`)
    }

    bindContractorLabel(payload) {
        return axios.post("/contractor/label/bind-by-one", payload);
    }
    removeContractorLabel(payload){
        return axios.post("/contractor/label/remove-by-one", payload);
    }

    bindContractorLabelBulk(payload) {
        return axios.post("/contractor/label/bind/bulk", payload);
    }
}

export default new ContractorLabelService();
