import React, {useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {roamingInvoiceTypes} from "../../enum/roaming/roaming-invoice/RoamingInvoiceWrapper";
import {selectWarehouses} from "../../store/reducers/warehouse/warehouseReducer";
import ReactSelect from "react-select";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const WarehouseSelect = ({defaultValue, onChange, placeholder, ...selectProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);
    const warehouses = useSelector(selectWarehouses)

    useEffect(() => {
        setValue(warehouses.find(w => w.id === defaultValue))
    }, [defaultValue]);

    const handleChange = (option) => {
        setValue(option)
        onChange(option)
    }

    return (
            <CustomFloatingLabelSelect
                options={warehouses}
                getOptionLabel={(option) => option?.id}
                getOptionValue={(option) => option?.name}
                formatOptionLabel={(option) => option?.name}
                hideSelectedOptions
                isClearable
                value={value}
                onChange={(e) => handleChange(e)}
                label={placeholder}
                {...selectProps}
            />
    );
};

export default WarehouseSelect;
