import React from 'react';
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const data = [
    { date: '08', sum: 0 },
    { date: '09', sum: 6000 },
    { date: '10', sum: 9000 },
    { date: '11', sum: 0 },
    { date: '12', sum: 30000 },
    { date: '13', sum: 15000 },
    { date: '14', sum: 50000 },
];

const WARNING_THRESHOLD = 10000;
const PRIMARY_THRESHOLD = 30000;

const getColor = (sum) => {
    if (sum === 0) {
        return '#9BA2B1'; // secondary
    } else if (sum > 0 && sum < WARNING_THRESHOLD) {
        return '#FF3B307F'; // red
    } else if (sum >= WARNING_THRESHOLD && sum < PRIMARY_THRESHOLD) {
        return '#58B3D37F'; // primary
    } else if (sum >= PRIMARY_THRESHOLD) {
        return '#58B3D3'; // success
    }
};

const formatYAxis = (tickItem) => {
    if (tickItem >= 1000000000) {
        return `${tickItem / 1000000000}B`;
    }
    if (tickItem >= 1000000) {
        return `${tickItem / 1000000}M`;
    }
    if (tickItem >= 1000) {
        return `${tickItem / 1000}K`;
    }
    return tickItem;
};

const getTotalSum = (data) => {
    return data.reduce((acc, item) => acc + item.sum, 0);
};

const DashboardChart = () => {
    const totalSum = getTotalSum(data);

    return (
        <div className={"w-100 position-relative"}>
            <h3 style={{color: '#9BA2B1', fontWeight:660,fontSize: 16, textAlign: 'left', marginLeft: '30px' }}>Сегодня</h3>
            <div className={'position-absolute top-0'} style={{ position: 'absolute', top: '0', right: '0',
                fontSize: 16, fontWeight: 600 }}>
                {totalSum > 0 ? '+' : totalSum < 0 ? '-' : ''} {totalSum.toLocaleString()} UZS
            </div>
            <ResponsiveContainer width="100%" height={350}>
                <BarChart data={data}>
                    <CartesianGrid vertical={false} horizontal={false} strokeDasharray="3 3" />
                    <XAxis axisLine={false} tickLine={false} dataKey="date" />
                    <YAxis axisLine={false} tickLine={false} tickFormatter={formatYAxis} />
                    <Tooltip />
                    {/*<Legend />*/}
                    <Bar dataKey="sum" minPointSize={5}>
                        {data?.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={getColor(entry.sum)} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default DashboardChart;
