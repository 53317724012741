import React, {useEffect, useState} from 'react';
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Translate} from "../../../lang/lang";
import {findCurrencyReturnRate} from "../../../enum/warehouse-operation/warehouseOperation";
import {UZS} from "../../../enum/item/itemWrapper";
import {Table} from "react-bootstrap";
import classNames from "classnames";
import numeral from "numeral";

const WarehouseOperationFooterCurrencyTotalSum = ({netPriceType, netPrice, pay, exactDiscounts, percentDiscount}) => {

    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const numberFormat = useSelector(selectNumberFormat)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate


    const totalSumCurrency = netPrice?.reduce((acc, cur) => acc + (cur?.amount * findCurrencyReturnRate(currencies, cur?.currency?.id)), 0)

    const debtTypeCurrency = []

    useEffect(() => {
        setSelectedCurrency(currencies.find(cur => cur?.code === UZS))
    }, [currencies])

    // debt calc
    {
        netPrice?.forEach(price => {
            if (price?.currency?.id) {
                const foundDebtState = pay?.debt_states?.find(debtState => debtState.currency.id === price?.currency?.id);
                const totalSum = foundDebtState ? price?.amount - foundDebtState.amount : price?.amount;

                debtTypeCurrency.push({
                    id: price?.currency?.id,
                    name: price?.currency?.name,
                    totalSum: totalSum
                });
            }
        });

        pay?.debt_states?.forEach(debt => {
            if (debt.currency.id) {
                const foundSeparationCurrency = netPrice?.find(price => price?.currency?.id === debt.currency.id);
                const totalSum = foundSeparationCurrency ? debt.amount - foundSeparationCurrency.totalSum : debt.amount;

                if (!foundSeparationCurrency) {
                    debtTypeCurrency.push({
                        id: debt.currency.id,
                        name: debt.currency.name,
                        totalSum: totalSum
                    });
                }
            }
        });
    }


    return (
        <div
            className={debtTypeCurrency?.filter(x => x?.totalSum > 0)?.length && !netPriceType ? "d-flex justify-content-between " : "d-flex justify-content-end"}>
            {debtTypeCurrency?.filter(x => x?.totalSum > 0)?.length > 0 && !netPriceType &&
                <div>
                    <div className="d-inline-flex ms-3">
                        <p className="fw-bold text-nowrap text-danger fs-8 m-0">{t(lang, "В долг")}:</p>
                        <Table borderless size="sm" className="fs-8 text-end">
                            <tbody>
                            {debtTypeCurrency?.filter(x => x?.totalSum > 0)?.map((x, index) => {
                                return (
                                    <tr key={index}>
                                        <td className={classNames("fw-semi-bold text-nowrap", x?.totalSum > 0 ? "text-danger" : "text-warning")}>
                                            {numeral.formats[numberFormat].format(x?.totalSum || 0)}
                                            &nbsp;
                                            <span
                                                className={x?.totalSum > 0 ? "text-danger" : "text-warning"}>{x?.name}</span>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>
                </div>
            }


            <div className="d-flex flex-column">
                <div className="d-inline-flex">
                    <p className="text-900 fw-bold text-nowrap fs-8 m-0 mt-1">{t(lang, "warehouse.operation.item.common.total_sum")}:</p>
                    <Table borderless size="sm" className="fs-8 text-end mb-1">
                        <tbody>
                        {netPrice?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format(item?.amount || 0)}
                                        <span className="text-info">{item?.currency?.name}</span></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>
                {(exactDiscounts?.filter(x => x?.amount > 0)?.length > 0 || !!percentDiscount) &&
                    <div className="d-inline-flex">
                        <p className="text-900 fw-bold text-nowrap fs-8 m-0">{t(lang, "Скидка")}:</p>
                        <Table borderless size="sm" className="fs-8 text-end">
                            <tbody>
                            {exactDiscounts &&
                                exactDiscounts?.filter(x => x?.amount > 0)?.map((dis, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format(dis?.amount || 0)}
                                                <span className="text-info">{dis?.currency?.name}</span></td>
                                        </tr>
                                    )
                                })
                            }
                            {!!percentDiscount &&
                                <tr>
                                    <td className="fw-semi-bold text-nowrap">{numeral.formats[numberFormat].format(percentDiscount || 0)}
                                        <span className="text-info">%</span></td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                }
            </div>
        </div>
    );
};

export default WarehouseOperationFooterCurrencyTotalSum;
