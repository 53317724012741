import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Modal, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {selectLang} from "../../../store/reducers/main/main";
import IconButton from "../../IconButton";
import FalconCloseButton from "../../common/FalconCloseButton";
import {Translate} from "../../../lang/lang";
import Badge from "../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Flex from "../../common/Flex";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";


const WarehouseOperationDeleteItem = ({onClick, itemId, id, ...props}) => {
    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate;

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }


    const onDeleteItem = () => {
        onClick({id, itemId})
        setShow(false)
    }

    return(
        <>
            <Badge
                className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                bg="danger-davr"
                variant="phoenix"
                onClick={() => handlerShow()}
            >
                <FontAwesomeIcon icon={faTrashAlt}/>
            </Badge>

            <Modal show={show} onHide={handlerClose} backdrop="static"
                   keyboard={false}
                   className={"davr-modal"}>
                <Modal.Header>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => handlerClose()} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            {t(lang, "edi.common.delete_button")}
                        </Flex>
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Modal.Title className="text-center">{t(lang, "warehouse.operation.item.common.delete_item_modal.title")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton
                        variant='phoenix-warning'
                        size="sm"
                        as={'div'}
                        icon={''}
                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                        iconClassName={"text-primary-davr fs-8"}
                        onClick={() => handlerClose()}
                    >
                        <span
                            className="d-none d-sm-inline-block">
                             {t(lang, 'edi.common.button.close')}
                        </span>
                    </IconButton>
                    <IconButton
                        size={"sm"}
                        icon={''}
                        onClick={() => onDeleteItem()}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    >
                        {t(lang, 'edi.common.delete_button')}
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default WarehouseOperationDeleteItem;