import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../store/reducers/warehouse/warehouseReducer";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../store/reducers/currency/currencyReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useNavigate, useParams} from "react-router-dom";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS
} from "../../../../eventbus/saleEvents";
import {getSaleItemAsync} from "../../../../store/reducers/warehouse/saleReducer";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import EditSaleForm from "../../../../components/warehouse/operation/sale/form/EditSaleForm";

const EditSale = () => {

    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [sale, setSale] = useState(null)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const {id} = useParams()

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, (id) => {
            loadSale()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, (id) => {
            loadSale()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`/operation/sales/view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,  (id) => {
            navigate(`/operation/sales/view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS,  (id) => {
            navigate(`/operation/sales/view/${id}`)
        })

        const onDeleteSalePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, () => {
            navigate(`/operation/sales/view/${id}`)
        });

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, (id) => {
            loadSale()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, (id) => {
            loadSale()
        })

        const onChangeExactDiscountsHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, (id) => {
            loadSale()
        })

        const onChangePercentDiscountHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS, (id) => {
            loadSale()
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, (id) => {
            loadSale()
        })

        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, (response) => {
            setSale(response.data, response.data.items.reverse())
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_PAYMENT_SUCCESS, onDeleteSalePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_CHANGE_EXACT_DISCOUNT_SUCCESS, onChangeExactDiscountsHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_CHANGE_PERCENT_DISCOUNT_SUCCESS, onChangePercentDiscountHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_NUMBER_SUCCESS, onChangedNumberHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
        }
    }, [])

    useEffect(() => {
        loadSale()
    },[activeOrganization, id])

    const loadSale = async () => {
        await getSaleItemAsync({saleId: id})
            .then((response) => {
                setSale(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setSale(null)
            })
    }

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouses?.length === 0)
        return (
            <>
                WarehouseNo
                {/*<WarehouseNo/>*/}
            </>
        )

    if (items?.length === 0)
        return (
            <>
                ItemNo
                {/*<ItemNo/>*/}
            </>
        )

    if(!sale)
        return (
            <SpinnerComponent />
        )


    return (
        <Fragment>
            <EditSaleForm sale={sale}/>
        </Fragment>
    );
};

export default EditSale;
