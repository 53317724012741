import React from "react";
import PropTypes from "prop-types";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";
import {measurements} from "../../enum/item/itemWrapper";
import {selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";

const SelectMeasurement = React.forwardRef(({onChange, defaultMeasurement, id, placeholder, ...props}, ref) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
            <CustomFloatingLabelSelect
                {...props}
                label={placeholder}
                value={measurements.find(m => m.value === defaultMeasurement)}
                options={measurements}
                onChange={(option) => onChange(option.value)}
                hideSelectedOptions={true}
                getOptionLabel={option => t(lang, option.label)}
                getOptionValue={option => option.value}
            />
    )
});

SelectMeasurement.propTypes = {
    onChange: PropTypes.func
}

SelectMeasurement.defaultProps = {
    onChange: () => {
    }
}

export default SelectMeasurement;
