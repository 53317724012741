import axios from 'axios'

class ReturnOrderService {
    getReturnOrders (params) {
        return axios.get('/return-order/get', {
            params
        })
    }

    getCountReturnOrders (params) {
        return axios.get('/return-order/count', {
            params
        })
    }

    getReturnOrder(id) {
        return axios.get(`return-order/get/${id}`)
    }

    registerReturnOrder(payload) {
        return axios.post('/return-order/customer/register', payload)
    }

    returnOrderCustomerApprove({id, comment}) {
        return axios.post(`/return-order/customer/approve/${id}`, {
            comment: comment
        })
    }

    returnOrderExecutorApprove({id, comment}) {
        return axios.post(`/return-order/executor/approve/${id}`, {
            comment: comment
        })
    }

    executorDecline({id, reason, comment}) {
        return axios.post(`/return-order/executor/decline/${id}`, {
            reason: reason,
            comment: comment,
        })
    }

    executorCancelDecline({id, comment}) {
        return axios.post(`/return-order/executor/cancel-declined/${id}`, {
            comment: comment,
        })
    }

    stopReturnOrder({id, comment}) {
        return axios.post(`/return-order/executor/stop/${id}`, {
            comment: comment
        })
    }

    resumeReturnOrder({id, comment}) {
        return axios.post(`/return-order/executor/resume/${id}`, {
            comment: comment
        })
    }

    printReturnOrder(id) {
        return axios.get(`/pdf/return-order/${id}`, {
            responseType: "blob"
        })
    }

    uploadReturnOrderToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadReturnOrdersToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadReturnOrdersWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    returnOrderIdentifyItems({orderId}) {
        return axios.post(`/return-order/return-order/set-executor-items/${orderId}`)
    }
}

const returnOrderService = new ReturnOrderService()

export default returnOrderService
