import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import dayjs from "dayjs";
import {
    editContractorPaymentAsync, editContractorPaymentContractorAsync, editContractorPaymentDateAsync,
    editContractorPaymentNoteAsync
} from "../../../store/reducers/contractor/contractorPaymentReducer";
import toast from "react-hot-toast";
import {Modal} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import ContractorPaymentForm from "./ContractorPaymentForm";


const EditContractorPayment = ({payment, onClose, show}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        const payload = {
            contractor_id: formData.contractor.id,
            date: dayjs(formData.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
            notes: formData.notes,
            cash_box_states: formData.cash_box_states.map((act) => {
                return {
                    type: act.type,
                    amount: typeof (act.currency.amount) === 'string' ? +act.currency.amount.replace(/ /g, '') : +act.currency.amount,
                    currency_id: act.currency.currency_id
                }
            }),
            debt_states: formData.debt_states.map((debt) => {
                return {
                    amount: typeof (debt.amount) === 'string' ? +debt.amount.replace(/ /g, '') : +debt.amount,
                    currency_id: debt.currency_id
                }
            }),
        }

        setLoading(true)
        dispatch(editContractorPaymentAsync({payment_id: payment.id, payload: payload}))
            .then(re => {
                onClose()
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }

    const handleEditNote = (note) => {
        const payload = {
            note: note
        }
        dispatch(editContractorPaymentNoteAsync({payment_id: payment.id, payload: payload}))
            .then(res => {
                toast.success(t(lang, 'crm.common.toast.success'));
            })
            .catch(err => console.log(err));
    }

    const handleEditContractor = (contractor_id) => {
        if (payment.contractor.id !== contractor_id) {
            const payload = {
                contractor_id: contractor_id
            }
            dispatch(editContractorPaymentContractorAsync({payment_id: payment.id, payload: payload}))
                .then(re => {
                    toast.success(t(lang, 'crm.common.toast.success'));
                })
                .catch(err => console.log(err));
        }
    }

    const handleEditPaymentDate = (date) => {
        const payload = {
            date: dayjs(date).format('YYYY-MM-DD HH:mm:ss'),
        }
        dispatch(editContractorPaymentDateAsync({payment_id: payment.id, payload: payload}))
            .then(re => {
                toast.success(t(lang, 'crm.common.toast.success'));
            })
            .catch(err => console.log(err));
    }
    return (<>
        <Modal show={show} onHide={onClose} size="xl">
            <Modal.Header>
                <Modal.Title>
                    <Flex className={'gap-2'}>
                        <button onClick={() => onClose()} className={'main-text btn-back'}
                                style={{width: 48, height: 48, borderRadius: 16}}>
                            <IconArrowLeft/>
                        </button>
                        {t(lang, "crm.edit_contractor_payment_form.modal.title")}
                    </Flex>
                </Modal.Title>
                <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={onClose}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorPaymentForm payment={payment} onClose={onClose} loading={loading}
                                       onSubmit={handleOnSubmit}
                                       onEditNote={handleEditNote}
                                       onEditDate={handleEditPaymentDate}
                                       onEditContractor={handleEditContractor}/>
            </Modal.Body>
        </Modal>
    </>)
}

export default EditContractorPayment;