import React from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {itemLegalTypes} from "../../../enum/item/itemWrapper";

const ItemLegalTypeSelector = React.forwardRef(({onChange, legalType, selectorProps, placeholder}, ref) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <CustomFloatingLabelSelect
            ref={ref}
            hideSelectedOptions
            isSearchable={false}
            options={itemLegalTypes}
            getOptionValue={option => option.value}
            onChange={(option) => onChange(option?.value)}
            getOptionLabel={option => t(lang, option.label)}
            value={itemLegalTypes.find(m => m.value === legalType) || null}
            label={placeholder}
            {...selectorProps}
        />
    );
});


export default ItemLegalTypeSelector;
