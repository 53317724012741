import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../store/reducers/main/main";
import {selectActiveOrganization} from "../store/reducers/auth/authReducer";
import {loadCategoriesAsync, updateAllCategories} from "../store/reducers/category/categoryReducer";
import EventBus from "../eventbus/EventBus";
import {
    ADD_CATEGORY_SUCCESS,
    BULK_DELETE_CATEGORY_SUCCESS, CHANGE_SETTINGS_SUCCESS,
    DELETE_CATEGORY_SUCCESS,
    EDIT_CATEGORY_SUCCESS
} from "../eventbus/itemEvents";
import {loadOrganizationAsync} from "../store/reducers/draft-item/draftItemReducer";
import {loadMeasurementsAsync} from "../store/reducers/roaming/roamingReducer";
import {Outlet} from "react-router-dom";

const ItemLayout = () => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);

    const loadCategories = () => {
        loadCategoriesAsync()
            .then((categories) => dispatch(updateAllCategories(categories)))
            .catch((error) => dispatch(updateAllCategories([])))
    }

    useEffect(() => {
        loadCategories();
        dispatch(loadOrganizationAsync());

        const onAddCategorySuccessHandler = EventBus.on(ADD_CATEGORY_SUCCESS, loadCategories);
        const onEditCategorySuccessHandler = EventBus.on(EDIT_CATEGORY_SUCCESS, loadCategories);
        const onDeleteCategorySuccessHandler = EventBus.on(DELETE_CATEGORY_SUCCESS, loadCategories);
        const onBulkDeleteCategoriesSuccessHandler = EventBus.on(BULK_DELETE_CATEGORY_SUCCESS, loadCategories);
        const onAnySettingsChangedHandler = EventBus.on(CHANGE_SETTINGS_SUCCESS, () => {
            dispatch(loadOrganizationAsync());
        })

        return () => {
            EventBus.remove(ADD_CATEGORY_SUCCESS, onAddCategorySuccessHandler);
            EventBus.remove(DELETE_CATEGORY_SUCCESS, onDeleteCategorySuccessHandler);
            EventBus.remove(EDIT_CATEGORY_SUCCESS, onEditCategorySuccessHandler);
            EventBus.remove(BULK_DELETE_CATEGORY_SUCCESS, onBulkDeleteCategoriesSuccessHandler);
            EventBus.remove(CHANGE_SETTINGS_SUCCESS, onAnySettingsChangedHandler);
        }
    }, [activeOrganization])

    useEffect(() => {
        dispatch(loadMeasurementsAsync(lang))
    }, [lang])


    return (
        <Outlet />
    );
};

export default ItemLayout;
