import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_CONTRACTOR_PAYMENT_FAILED,
    ADD_CONTRACTOR_PAYMENT_REQUESTED,
    ADD_CONTRACTOR_PAYMENT_SUCCESS,
    DELETE_CONTRACTOR_PAYMENT_FAILED,
    DELETE_CONTRACTOR_PAYMENT_REQUESTED,
    DELETE_CONTRACTOR_PAYMENT_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_FAILED,
    EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_REQUESTED,
    EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_DATE_FAILED,
    EDIT_CONTRACTOR_PAYMENT_DATE_REQUESTED,
    EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_FAILED,
    EDIT_CONTRACTOR_PAYMENT_NOTE_FAILED,
    EDIT_CONTRACTOR_PAYMENT_NOTE_REQUESTED,
    EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS,
    EDIT_CONTRACTOR_PAYMENT_REQUESTED,
    EDIT_CONTRACTOR_PAYMENT_SUCCESS
} from "../../../eventbus/contractor/contractorPaymentEvents";
import dayjs from "dayjs";
import queryString from "query-string";
import contractorPaymentService from "../../services/contractor/contractorPaymentService";

export const contractorPaymentSlice = createSlice({
    name: 'contractorPayment',
    initialState: {
        filterOptions: {page: 1, limit: 10},
        cardFilterOptions: {
            date_start: dayjs(new Date()).format('YYYY-MM-DD'),
            date_end: dayjs(new Date()).format('YYYY-MM-DD'),
        },
        contractorPayments: [],
        cardContractorPayments: [],
        isLoading: false,
        count: 0,
        contractor: null,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        updateCardFilterOptions: (state, action) => {
            state.cardFilterOptions = {...state.cardFilterOptions, ...action.payload}
        },
        updateContractorPaymentsCount: (state, action) => {
            state.count = action.payload
        },
        updateContractorPayments: (state, action) => {
            state.contractorPayments = action.payload
        },
        updateContractor: (state, action) => {
            state.contractor = action.payload || null
        },
        updateCardContractorPayments: (state, action) => {
            state.cardContractorPayments = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    }
})
export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorPaymentSlice.actions.updateFilterOptions({...params}))
}
export const changeCardFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorPaymentSlice.actions.updateCardFilterOptions({...params}))
}

export const updateContractor = (contractor) => dispatch => {
    dispatch(contractorPaymentSlice.actions.updateContractor(contractor))
}

export const registerContractorPaymentAsync = (payload) => () => {
    EventBus.dispatch(ADD_CONTRACTOR_PAYMENT_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.registerContractorPayment(payload)
            .then(response => {
                EventBus.dispatch(ADD_CONTRACTOR_PAYMENT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_CONTRACTOR_PAYMENT_FAILED, error)
                reject(error);
            })
    });
}
export const editContractorPaymentAsync = ({payment_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPayment({payment_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPaymentNoteAsync = ({payment_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_NOTE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPaymentNote({payment_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_NOTE_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPaymentContractorAsync = ({payment_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPaymentContractor({payment_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}

export const editContractorPaymentDateAsync = ({payment_id, payload}) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_DATE_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorPaymentService.editContractorPaymentDate({payment_id, payload})
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_PAYMENT_DATE_FAILED, error)
                reject(error);
            })
    });
}

export const loadContractorPaymentAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}
    dispatch(contractorPaymentSlice.actions.setLoading(true));
    contractorPaymentService.getContractorPayments(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorPaymentSlice.actions.updateContractorPayments(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorPaymentSlice.actions.setLoading(false));
    });
}

export const loadContractorPaymentCountAsync = (params) => dispatch => {
    let {limit, page, ...other} = params

    contractorPaymentService.getContractorPaymentsCount(queryString.stringify(other))
        .then((response) => {
            dispatch(contractorPaymentSlice.actions.updateContractorPaymentsCount(response.data));
        })
        .catch(error => {
            console.log(error);
        });
}

export const loadContractorCardPaymentAsync = ({...filters}) => dispatch => {
    const params = {...filters}
    dispatch(contractorPaymentSlice.actions.setLoading(true));
    contractorPaymentService.getContractorPayments(queryString.stringify(params))
        .then((response) => {
            dispatch(contractorPaymentSlice.actions.updateCardContractorPayments(response.data));
        })
        .catch(error => {
            console.log(error);
        }).finally(() => {
        dispatch(contractorPaymentSlice.actions.setLoading(false));
    });
}

export const loadOneContractorPaymentAsync = (id) => {
    return new Promise((resolve, reject) => {
        contractorPaymentService.getContractorPayment(id)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const deleteContractorPaymentAsync = ({id}) => {
    EventBus.dispatch(DELETE_CONTRACTOR_PAYMENT_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorPaymentService.deleteContractorPayment({id})
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_PAYMENT_SUCCESS, response.data);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_PAYMENT_FAILED, error.message);
                reject(error)
            })
    });
};

export const selectContractorPayments = (state) => state.contractorPayment.contractorPayments;
export const selectCardContractorPayments = (state) => state.contractorPayment.cardContractorPayments;

export const selectFilterOptionsContractorPayments = (state) => state.contractorPayment.filterOptions;
export const selectCardFilterOptionsContractorPayments = (state) => state.contractorPayment.cardFilterOptions;

export const selectCountContractorPayments = (state) => state.contractorPayment.count;
export const selectContractor = (state) => state.contractorPayment.contractor;

export const selectLoading = state => state.contractorPayment.isLoading;

export default contractorPaymentSlice.reducer;