import React, {useState} from 'react'
import {useSelector} from "react-redux";
import {Modal, Spinner} from "react-bootstrap";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import Flex from "../../../components/common/Flex";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {deleteContractorAsync} from "../../../store/reducers/contractor/contractorReducer";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import IconButton from "../../../components/IconButton";

const DeleteContractorModal = ({id, onClose, show}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);

    const onConfirm = () => {
        setLoading(true);
        deleteContractorAsync({
            id: id
        }).then(res => {
            onClose();
        }).catch(error => console.log(error)).finally(() => {
            setLoading(false);
        })
    }
    return (
        <Modal
            show={show}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={"davr-modal"}
        >
            <Modal.Header>
                <Modal.Title>
                    <Flex className={'gap-2'}>
                        <button onClick={() => onClose()} className={'main-text btn-back'}
                                style={{width: 48, height: 48, borderRadius: 16}}>
                            <IconArrowLeft/>
                        </button>
                        {t(lang, "edi.common.delete_button")}
                    </Flex>
                </Modal.Title>
                <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={onClose}/>
            </Modal.Header>
            <Modal.Footer>
                <IconButton
                    variant='phoenix-warning'
                    size="sm"
                    as={'div'}
                    icon={''}
                    className={'btn-davr bg-white text-dark d-flex gap-2'}
                    iconClassName={"text-primary-davr fs-8"}
                    onClick={() => onClose()}
                >
                        <span
                            className="d-none d-sm-inline-block">
                             {t(lang, 'edi.common.button.close')}
                        </span>
                </IconButton>
                <IconButton
                    size={"sm"}
                    icon={''}
                    onClick={() => onConfirm()}
                    disabled={loading}
                    variant="phoenix-primary"
                    className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                >
                    {loading &&
                        <Spinner className="align-middle me-2" animation="border" role="switch"
                                 size="sm"/>}
                    {t(lang, 'edi.common.delete_button')}
                </IconButton>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteContractorModal