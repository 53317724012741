import React, {useEffect, useState} from 'react';
import ReactSelect from "react-select";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCashbox} from "../../../store/reducers/cashbox/CashboxReducer";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";

const CashBoxSelect = ({defaultValue, onChange, label, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const cashbox = useSelector(selectCashbox)

    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(defaultValue && cashbox.find(item => item.id === defaultValue))
    }, [defaultValue])

    return (
        <CustomFloatingLabelSelect
            label={label}
            value={value}
            isClearable
            hideSelectedOptions
            options={cashbox}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={(e) => {
                onChange(e)
                setValue(e)
            }}
            placeholder={t(lang, "edi.common.button.select")}
            {...props}
        />

    );
};

export default CashBoxSelect;
