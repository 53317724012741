import axios from "axios";


const priceServiceHostName = "price";
class PriceService {
    getPrice(id, params) {
        return axios.get(`${priceServiceHostName}/price/get/${id}`, {
            params: params
        })
    }

    updatePrice(payload) {
        return axios.post(`${priceServiceHostName}/price/update`, payload)
    }

    updateItemPriceContractor(payload)  {
        return axios.post(`${priceServiceHostName}/price/update/bulk`, {records: payload})
    }


}

const priceService = new PriceService();
export default priceService;
