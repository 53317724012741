import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../store/reducers/warehouse/warehouseReducer";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../../store/reducers/currency/currencyReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../store/reducers/item/itemReducer";
import {useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS
} from "../../../../eventbus/warehouse/purchaseEvent";
import {getPurchaseItemAsync} from "../../../../store/reducers/warehouse/purchaseReducer";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import EditPurchaseForm from "../../../../components/warehouse/operation/purchase/form/EditPurchaseForm";
import ItemNo from "../../../../components/warehouse/operation/ItemNo";
import WarehouseNo from "../../../../components/warehouse/operation/WarehouseNo";

const EditPurchase = () => {

    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [purchase, setPurchase] = useState(null)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const {id} = useParams()

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, (id) => {
            loadPurchase()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, (id) => {
            loadPurchase()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`/operation/purchases/view/${id}`)
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS,  (id) => {
            navigate(`/operation/purchases/view/${id}`)
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS,  (id) => {
            navigate(`/operation/purchases/view/${id}`)
        })

        const onDeletePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS, () => {
            navigate(`/operation/purchases/view/${id}`)
        })

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, (id) => {
            loadPurchase()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS, (id) => {
            loadPurchase()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS, (response) => {
            setPurchase(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS, (id) => {
            loadPurchase()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_REGISTER_PAYOUT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_UPDATE_PAYOUT_SUCCESS, onUpdatePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_PAYOUT_SUCCESS, onDeletePaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        loadPurchase()
    },[activeOrganization, id])

    const loadPurchase = async () => {
        await getPurchaseItemAsync({purchaseId: id})
            .then((response) => {
                setPurchase(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setPurchase(null)
            })
    }

    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!purchase)
        return (
            <SpinnerComponent />
        )

    return (
        <EditPurchaseForm purchase={purchase} />
    );
};

export default EditPurchase;
