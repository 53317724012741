import React, {Fragment, useState} from 'react';
import {Button, Card, Col, Collapse, FloatingLabel, Form, Row} from "react-bootstrap";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";
import {useDispatch, useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {ContractorDebtStateFilterIAmOwedDebt, ContractorDebtStateFilterIOweDebt} from "../../../enum/ContractorWrapper";
import {
    changeFilterOptionsAsync,
    selectContractorFilterOptions
} from "../../../store/reducers/contractor/contractorReducer";
import {useAsyncDebounce} from "react-table";
import Flex from "../../../components/common/Flex";
import {UZS} from "../../../enum/item/itemWrapper";
import {selectContractorLabels} from "../../../store/reducers/contractor/contractorLabelReducer";

const ContractorDataTableFilter = ({
                                       isOpenFilterCollapse,
                                       selectedCurrency,
                                       setSelectedCurrency,
                                       currency,
                                       sort,
                                       setSort,
                                       setSortByDesc,
                                       setFilter,
                                       sortByDesc
                                   }) => {
    const filterOptions = useSelector(selectContractorFilterOptions);

    const [contractorName, setContractorName] = useState(filterOptions.name || '');
    const [type, setType] = useState(filterOptions.type || null);
    const [debt, setDebt] = useState(filterOptions.type || null);
    const [contractorLabel, setContractorLabel] = useState(filterOptions.label || null);

    const contractorLabels = useSelector(selectContractorLabels);

    const t = Translate;
    const lang = useSelector(selectLang);

    const dispatch = useDispatch();

    const changedSearchFilter = useAsyncDebounce(value => {
        dispatch(changeFilterOptionsAsync({
            name: contractorName,
        }));
    }, 1000);

    const onChangeTypeHandler = (option) => {
        const value = option ? option.value : null;
        dispatch(changeFilterOptionsAsync({
            type: value,
            name: filterOptions.name,
        }));
        setType(value);
    };

    const onChangeDebtHandler = (option) => {
        const value = option ? option.value : null;
        dispatch(changeFilterOptionsAsync({
            debt: value
        }));
        setDebt(value);
    };

    const onChangeContractorLabelHandler = (option) => {
        const value = option ? option.id : null;
        dispatch(changeFilterOptionsAsync({
            name: filterOptions.name,
            label: value
        }));
        setContractorLabel(value);
    };

    const types = [
        {
            label: t(lang, 'edi.common.buyer'),
            value: 1
        },
        {
            label: t(lang, 'roaming.common.executor'),
            value: 2
        }
    ];

    const debtTypes = [
        {
            label: t(lang, 'edi.contractor.card.filter_by_debts.i_owe_debt'),
            value: ContractorDebtStateFilterIOweDebt
        },
        {
            label: t(lang, "edi.contractor.card.filter_by_debts.i_am_owed_debt"),
            value: ContractorDebtStateFilterIAmOwedDebt
        }
    ];

    const sortOption = [
        {
            label: t(lang, "Название"),
            value: 'name'
        },
        {
            label: t(lang, 'Задолжности'),
            value: 'debt'
        }
    ];

    const sortAscOrDescOption = [
        {
            label: t(lang, "По убыванию"),
            value: true
        },
        {
            label: t(lang, 'По возрастанию'),
            value: false
        }
    ];

    const resetFilter = () => {
        setContractorName('');
        changedSearchFilter();
        setSelectedCurrency(currency.find(currency => currency.code === UZS));
        setSort(sortOption[0]);
        setSortByDesc(sortAscOrDescOption[1]);
        onChangeTypeHandler(undefined);
        onChangeContractorLabelHandler(undefined);
        onChangeDebtHandler(undefined);
        setFilter(false)
    };

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div className={'mt-2'}>
                    <Card className={'p-2'}>
                        <Row className='mb-3 g-3'>
                            <Col sm={12} md={3}>
                                <FloatingLabel label={'Поиск по контрагенту'}>
                                    <Form.Control
                                        name={'search'}
                                        value={contractorName}
                                        onChange={event => {
                                            setContractorName(event.target.value);
                                            changedSearchFilter();
                                        }}
                                        type="search"
                                        className="search-input"
                                        placeholder="Поиск по контрагенту"
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12} md={3}>
                                <CustomFloatingLabelSelect
                                    options={types}
                                    value={types.find(t => t.value === type) || type}
                                    onChange={onChangeTypeHandler}
                                    hideSelectedOptions
                                    isClearable
                                    label="Поиск по тип"
                                    placeholder="Поиск по тип"
                                    size={'sm'}
                                />
                            </Col>
                            {/*<Col sm={12} md={3}>*/}
                            {/*    <SelectContractorLabel*/}
                            {/*        label={"Поиск по ярлык"}*/}
                            {/*        placeholder={"Поиск по ярлык"}*/}
                            {/*        defaultContractorLabelId={contractorLabel}*/}
                            {/*        onChange={onChangeContractorLabelHandler}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            {/*<Col sm={12} md={3}>*/}
                            {/*    <CustomFloatingLabelSelect*/}
                            {/*        value={selectedCurrency}*/}
                            {/*        onChange={(option) => setSelectedCurrency(option)}*/}
                            {/*        getOptionLabel={(option) => option.name}*/}
                            {/*        getOptionValue={(option) => option.name}*/}
                            {/*        options={currency}*/}
                            {/*        hideSelectedOptions*/}
                            {/*        label={t(lang, 'edi.contractor.select.currency')}*/}
                            {/*        placeholder={t(lang, 'edi.contractor.select.currency')}*/}
                            {/*        size={'sm'}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                            <Col sm={12} md={3}>
                                <CustomFloatingLabelSelect
                                    value={debtTypes.find(t => t.value === debt) || debt}
                                    onChange={onChangeDebtHandler}
                                    options={debtTypes}
                                    hideSelectedOptions
                                    isClearable
                                    label={t(lang, 'edi.contractor.card.filter_by_debts.placeholder')}
                                    placeholder={t(lang, 'edi.contractor.card.filter_by_debts.placeholder')}
                                    size={'sm'}
                                />
                            </Col>
                            <Col sm={12} md={3}>
                                <CustomFloatingLabelSelect
                                    options={sortOption}
                                    value={sort}
                                    onChange={option => setSort(option)}
                                    hideSelectedOptions
                                    isClearable
                                    label="Поиск по тип"
                                    placeholder="Поиск по тип"
                                    size={'sm'}
                                />
                            </Col>

                            <Col sm={12} md={3}>
                                <CustomFloatingLabelSelect
                                    value={sortByDesc}
                                    onChange={option => setSortByDesc(option)}
                                    options={sortAscOrDescOption}
                                    hideSelectedOptions
                                    isClearable
                                    label="Сорт."
                                    placeholder="Сорт."
                                    size={'sm'}
                                />
                            </Col>
                            <Col sm={12} md={9}>
                                <Flex className={'gap-2 align-items-center justify-content-end h-100'}>
                                    <Button className={'btn-davr bg-white-davr text-dark'} variant="phoenix-primary"
                                            type='button'
                                            onClick={() => resetFilter()}>{t(lang, "items.common.clear_imported_file")}</Button>
                                    <Button className={'btn-davr bg-primary-davr text-white'} variant="primary"
                                            onClick={() => setFilter(Symbol(true))}>{t(lang, "roaming.invoices.table_header.show")}</Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default ContractorDataTableFilter;
