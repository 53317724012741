import React, {useEffect, useState} from 'react';
import ReactSelect from 'react-select';
import {useSelector} from 'react-redux';
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {selectBranches} from "../../store/reducers/branch/branchReducer";
import PropTypes from "prop-types";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectBranch = React.forwardRef(({defaultValue, onChange, id, label, ...props}, ref) => {
    const branches = useSelector(selectBranches);
    const lang = useSelector(selectLang);
    const t = Translate;
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (defaultValue !== null) {
            const val = branches.find(branch => branch?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    },[defaultValue])

    const onChangeItem = option => {
        setValue(option)
        onChange(option)
    }

    const getOptionValue = option => option?.id
    const getOptionLabel = option => option?.name
    const formatOptionLabel = option => {
        return(
            <span>{option.name}</span>
        )
    }

    return (
            <CustomFloatingLabelSelect
                ref={ref}
                options={branches}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                value={value}
                onChange={onChangeItem}
                isClearable
                hideSelectedOptions
                noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                {...props}
            />
    )
});

SelectBranch.propTypes = {
    onChange: PropTypes.func
}

SelectBranch.defaultProps = {
    onChange: () => {}
}

export default SelectBranch;
