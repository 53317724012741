import React from 'react';
import {selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectCategory = ({onChange, defaultCategoryId, categories, selectProps, placeholder}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <CustomFloatingLabelSelect
            {...selectProps}
            options={categories}
            isSearchable={!defaultCategoryId}
            isClearable
            hideSelectedOptions
            label={placeholder}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={onChange}
            value={categories.find(c => c.id === defaultCategoryId) || null}
        />
    );
};

export default SelectCategory;
