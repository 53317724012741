import {faArrowDown, faArrowUp, faBookmark, faUserCircle} from "@fortawesome/free-solid-svg-icons";

export const VIEW_INBOX = "inbox";
export const VIEW_OUTBOX = "outbox";
export const VIEW_PERSON = "person";
export const VIEW_DRAFT = "draft";

export const GetViewerText = (viewer) => {
    switch (viewer) {
        case VIEW_INBOX:
            return "roaming.common.viewer.incoming";
        case VIEW_OUTBOX:
            return "roaming.common.viewer.outcoming";
        case VIEW_PERSON:
            return "roaming.common.viewer.agent";
        case VIEW_DRAFT:
            return "roaming.common.viewer.draft";
        default:
            return "Не определено";
    }
};

export const GetViewerIcon = (viewer) => {
    switch (viewer) {
        case VIEW_INBOX:
            return faArrowDown;
        case VIEW_OUTBOX:
            return faArrowUp;
        case VIEW_PERSON:
            return faUserCircle;
        case VIEW_DRAFT:
            return faBookmark;
        default:
            return "";
    }
};

export const TypeViewers = [
    { id: VIEW_INBOX, name: GetViewerText(VIEW_INBOX) },
    { id: VIEW_OUTBOX, name: GetViewerText(VIEW_OUTBOX) },
    { id: VIEW_PERSON, name: GetViewerText(VIEW_PERSON) },
    { id: VIEW_DRAFT, name: GetViewerText(VIEW_DRAFT) },
];
