import React, {Fragment, useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {getCompanies, getCompany} from "../../store/reducers/roaming/roamingReferenceReducer";
import {debounce} from "underscore";
import {InputGroup} from "react-bootstrap";
import AsyncSelect from 'react-select/async'
import classNames from "classnames";
import CustomFloatingLabelSelectAsync from "../floating-custom-select/CustomFloatingLabelSelectAsync";
const innFormatRegEx = /^(\d{9}|\d{14})$/


const FindCompanySearchable = React.forwardRef(({onChange, inputGroupProps, inputProps, defaultInn, disabled, placeholder, id}, ref) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [value, setValue] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const onChangeHandler = (OrganizationInfo) => {
        onChange(OrganizationInfo)
        setValue(OrganizationInfo)
        setSelectedOption(OrganizationInfo)
    };
    const loadOptions = (query, callback) => {
        if (query) {
            getCompanies({
                q: query,
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            })
                .then((organizations) => {
                    callback(organizations);
                })
                .catch(() => {
                    callback([])
                })
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const formatOptionLabel = (option) => {
        return (
            <div className="fs-9">
                <span className="fw-bold me-1 ">{option?.inn}</span>
                {option?.person_num &&
                    <Fragment>
                        <span className="text-black fw-bold mx-1">{option?.person_num}</span>-
                    </Fragment>
                }
                <span className="ms-1 text-facebook">{option?.name}</span>
            </div>
        )
    };
    useEffect(() => {
        findCompanyAsync()
    }, [defaultInn]);

    const findCompanyAsync = async () => {
        if (innFormatRegEx.test(defaultInn)) {
            if (value?.inn === defaultInn || value?.person_num === defaultInn)
                return;
            let organization = await getCompany(defaultInn, {
                active_accounts: true,
                frozen_accounts: true,
                main_accounts: true
            });
            setValue(organization);
            onChange(organization);
            setSelectedOption(organization)
        }
    }

    return (
        <InputGroup {...inputGroupProps}>
            <div className={classNames('select-container w-100', {
                'border-0': disabled,
            })}>
                <CustomFloatingLabelSelectAsync
                    loadOptions={debouncedLoadOptions}
                    label={t(lang, 'edi.toast.find_company_searchable:placeholder')}
                    ref={ref}
                    isDisabled={disabled}
                    formatOptionLabel={formatOptionLabel}
                    getOptionValue={option => option?.person_num || option?.inn}
                    getOptionLabel={option => `${option?.person_num} ${option?.inn} / ${option?.name}`}
                    placeholder={t(lang, '')}
                    className={classNames('w-100 select', { 'rounded-3': disabled })}
                    style={{ width: '100%' }}
                    value={value}
                    defaultOptions
                    onChange={onChangeHandler}
                    isClearable
                    noOptionsMessage={() => t(lang, 'edi.toast.find_company_no_option')}
                    onInputChange={(e) => setSelectedOption(e)}
                    inputId={id}
                    {...inputProps}
                />

                <label htmlFor={id} className={selectedOption || value ? "label-active" : "label-inactive"}>{placeholder}</label>
            </div>

        </InputGroup>
    )
});

export default FindCompanySearchable;
