import React from 'react';

const IconMagnetCoin = ({width = 18, height = 18, color = "none"}) => {
    return (
        <svg width={width} height={height}  viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.36902 12.8276L9.0748 11.1218M11.1217 9.07484L12.8275 7.36906M3.58138 9.58138L2.52072 8.52072M9.58138 3.58138L8.52072 2.52072M8.60895 14.0675L5.87969 11.3383C5.69873 11.1573 5.59707 10.9119 5.59707 10.6559C5.59707 10.4 5.69873 10.1546 5.87969 9.97362L6.22085 9.63247C6.40181 9.4515 6.64725 9.34984 6.90317 9.34984C7.15908 9.34984 7.40452 9.4515 7.58548 9.63247L10.3147 12.3617C10.5862 12.6332 10.9543 12.7857 11.3382 12.7857C11.7221 12.7857 12.0902 12.6332 12.3617 12.3617C12.6331 12.0903 12.7856 11.7221 12.7856 11.3383C12.7856 10.9544 12.6331 10.5862 12.3617 10.3148L9.63242 7.58552C9.45146 7.40456 9.3498 7.15913 9.3498 6.90321C9.3498 6.64729 9.45146 6.40185 9.63242 6.22089L9.97358 5.87974C10.1545 5.69878 10.4 5.59711 10.6559 5.59711C10.9118 5.59711 11.1572 5.69878 11.3382 5.87974L14.0675 8.60899C14.7913 9.33284 15.198 10.3146 15.198 11.3383C15.198 12.3619 14.7913 13.3437 14.0675 14.0675C13.3436 14.7914 12.3619 15.198 11.3382 15.198C10.3145 15.198 9.3328 14.7914 8.60895 14.0675ZM7.24432 5.19743C7.24432 6.32792 6.32787 7.24437 5.19738 7.24437C4.06689 7.24437 3.15044 6.32792 3.15044 5.19743C3.15044 4.06694 4.06689 3.15049 5.19738 3.15049C6.32787 3.15049 7.24432 4.06694 7.24432 5.19743Z"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default IconMagnetCoin;
