import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import toast from "react-hot-toast";
import {getSize, isIterableArray} from "../../../helpers/utils";
import {Modal, Image} from "react-bootstrap";
import Slider from "react-slick";
import classNames from "classnames";
import Dropzone from 'react-dropzone';
import Flex from "../../common/Flex";
import IconButton from "../../IconButton";
import {faTrash} from "@fortawesome/free-solid-svg-icons";


const MAX_FILE_SIZE = 5120;

const UploadImages = ({
                          placeholder,
                          className,
                          files,
                          onChange,
                          preview,
                          ...rest
                      }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false)
    const [initialSlide, setInitialSlide] = useState(0)

    const readFileAsync = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onDrop = async (droppedFiles) => {
        const acceptedFiles = [];
        for (const file of droppedFiles) {
            // check if file type is image
            {
                if (file.type.split('/')[0] !== "image") {
                    toast.error(t(lang, 'edi.common.toast.error'));
                    return;
                }
            }

            // check image size
            {
                if ((file.size / MAX_FILE_SIZE) > MAX_FILE_SIZE) {
                    toast.error(t(lang, 'items.common.warning_image'));
                    return;
                }
            }

            try {
                const content = await readFileAsync(file);
                acceptedFiles.push({
                    id: file.id || null,
                    content: content,
                    name: file.name,
                    size: getSize(file.size)
                });
            } catch (e) {
                console.log(e);
            }
        }
        onChange([...files, ...acceptedFiles]);
    };

    const onDeleteFile = (index) => {
        onChange(files.filter((file, i) => i !== index));
    };

    let slider1;
    let slider2;
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const handlerClose = () => {
        setShow(false)
    }
    const handlerShow = () => {
        setShow(true)
    }

    const setCurrentSlide = (index) => {
        setInitialSlide(index)
        handlerShow()
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: initialSlide,
        autoPlay: true,
        autoplaySpeed: 2000,
    }

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    return (
        <Fragment>
            <Modal show={show} size={"xl"} onHide={handlerClose} centered>
                <Modal.Body>
                    <div className="position-relative overflow-hidden">
                        <div className="d-flex align-items-center justify-content-center">
                            {files?.length === 1 && (
                                <Image
                                    fluid
                                    className="w-sm-100 h-sm-100 rounded"
                                    src={files[0]?.content ? files[0]?.content : `${process.env.REACT_APP_HOST}/cdn/${files[0]?.path}`}
                                    alt={`${files[0]?.name}`}
                                    style={{
                                        maxWidth: "808px",
                                        maxHeight: "385px",
                                        backgroundPosition: "center",
                                        objectFit: "contain"
                                    }}
                                />
                            )}
                        </div>
                        {files.length > 1 && (
                            <>
                                <div className="product-slider">
                                    <Slider
                                        {...settings}
                                        asNavFor={nav2}
                                        ref={slider => (slider1 = slider)}
                                        className="slick-slider-arrow-inner h-100 full-height-slider"
                                    >
                                        {files?.map((img, index) => (
                                            <div key={index}
                                                 className="d-flex align-items-center justify-content-center m-auto">
                                                <Image
                                                    fluid
                                                    rounded
                                                    className="w-sm-100 h-sm-100"
                                                    src={img?.content ? img?.content : `${process.env.REACT_APP_HOST}/cdn/${img?.path}`}
                                                    alt={img?.name}
                                                    key={index}
                                                    style={{
                                                        maxWidth: "808px",
                                                        maxHeight: "385px",
                                                        backgroundPosition: "center",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
            <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                    <div
                        {...getRootProps({
                            className: classNames('dropzone-area', className)
                        })}
                    >
                        <input {...{...getInputProps(), ...rest}} />
                        {placeholder}
                        <span
                            className="">{t(lang, "items.common.warning_image")}</span>
                    </div>
                )}
            </Dropzone>
            {preview && isIterableArray(files) && (
                <div className="border-top mt-3">
                    {files.map(({name, content, path, size}, index) => (
                        <Flex
                            alignItems="center"
                            className="py-3 border-bottom btn-reveal-trigger"
                            key={index}
                        >
                            <span>{index + 1})</span>
                            <img className="img-fluid ms-2 cursor-pointer"
                                 width={38}
                                 src={content ? `${content}` : `${process.env.REACT_APP_HOST}/cdn/${path}`}
                                 alt={name}
                                 onClick={() => {
                                     setCurrentSlide(index)
                                 }}
                            />
                            <Flex justifyContent="between" alignItems="center" className="ms-3 w-100">
                                <div>
                                    <h6 data-dz-name="">{name}</h6>
                                    <Flex className="position-relative" align="center">
                                        <p className="mb-0 fs--1 text-400 line-height-1">
                                            {size}
                                        </p>
                                    </Flex>
                                </div>
                                <IconButton
                                    onClick={() => onDeleteFile(index)}
                                    variant="phoneix-danger"
                                    iconClassName={'text-danger'}
                                    icon={faTrash}
                                    size="sm"
                                />
                            </Flex>
                        </Flex>
                    ))}
                </div>
            )}
        </Fragment>
    );
};

export default UploadImages;
