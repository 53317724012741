import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const SoftBadge = forwardRef(({ bg = 'primary', pill, children, className, ...props }, ref) => (
    <div
        className={classNames(className, `badge badge-soft-${bg}`, {
          'rounded-pill': pill
        })}
        ref={ref}
        {...props}
    >
      {children}
    </div>
))

SoftBadge.propTypes = {
  bg: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark'
  ]),
  pill: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default SoftBadge;
