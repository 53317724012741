import React from 'react';
import OrganizationTemplates from "../../components/settings/templates/OrganizationTemplates";

const Sample = () => {
    return (
        <div>
            <OrganizationTemplates />
        </div>
    );
};

export default Sample;
