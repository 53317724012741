import React, {Fragment, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {
    currencyActivateAsync,
    currencyDeactivate,
    currencyUpdateRate,
    selectCurrency
} from "../../../store/reducers/currency/currencyReducer";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Card, Dropdown} from "react-bootstrap";
import AdvanceTable from "../../table/AdvanceTable";
import AdvanceTableWrapper from "../../table/AdvanceTableWrapper";
import RevealDropdown, {RevealDropdownTrigger} from "../../common/RevealDropdown";
import CurrencyModal from "./CurrencyModal";

const CurrencySelectForm = () => {
    const {register, getValues} = useFormContext()
    const currency = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const [show, setShow] = useState(false)
    const [cur, setCur] = useState(null)
    const [loading, setLoading] = useState(false)

    const onChangeSwitch = async (index) => {
        const currencyCheck = getValues(`currency.${index}.is_active`)

        if (!currencyCheck) {
            await currencyActivateAsync({id: currency[index].id})
        } else {
            await currencyDeactivate({id: currency[index].id})
        }
    }

    const UpdateRate = async (id, index) => {
        const currencyRate = +getValues(`currency.${index}.rate`)
        setLoading(true)
        if (currencyRate) {
            await currencyUpdateRate({id, rate: currencyRate}).then(() => {
                setShow(false)
            }).finally(() => {
                setLoading(false)
            })
        }
    }


    const columns = [
        {
            accessor: 'name',
            Header: () => t(lang, "main.common.currency.name"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {width: 300, className: 'ps-3'},
            Cell: ({row: {original}}) => <span>{original.name}</span>
        },
        {
            accessor: 'rate',
            Header: () => t(lang, "main.common.currency.rate"),
            cellProps: {className: 'text-end'},
            headerProps: {className: 'text-end'},
            Cell: ({row: {original}}) => <span>{original?.rate}</span>
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    setShow(true)
                                    setCur(row)
                                }}>
                                <span>{t(lang, "roaming.common.edit")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={currency}
                perPage={100}
                pagination
                selection
            >
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs-8 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>
            </AdvanceTableWrapper>
            <CurrencyModal setShow={setShow} loading={loading} show={show} UpdateRate={UpdateRate} cur={cur}/>
        </Fragment>
    );
};

export default CurrencySelectForm;
