import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Dropdown} from "react-bootstrap";
import withDeleteItem from "./withDeleteItem";

const DeleteItemDropdownItem = ({item, handleShow}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Dropdown.Item className="align-center"
                       onClick={() => handleShow(item)}
        >
            <span>{t(lang, 'items.common.delete')}</span>
        </Dropdown.Item>
    );
};

export default withDeleteItem(DeleteItemDropdownItem);
