import React from 'react';

const IconBranches = ({width = 46, height = 46, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 40 40" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 28V26.5C12 25.3954 12.8954 24.5 14 24.5M12 28H28M12 28H11H28M14 24.5V17M14 24.5H20M26 24.5C27.1046 24.5 28 25.3954 28 26.5V28M26 24.5V17M26 24.5H20M28 28H29M20 17H14M20 17H26M20 17V24.5M14 17H11.4902C10.9637 17 10.8084 16.26 11.2876 16.0353L19.7974 12.0453C19.9261 11.9849 20.0739 11.9849 20.2026 12.0453L28.7124 16.0353C29.1916 16.26 29.0363 17 28.5098 17H26"
                stroke="#58B3D3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

export default IconBranches;