import React from 'react';
import {Card, Dropdown, ListGroup} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonWalkingArrowRight, faPhone} from "@fortawesome/free-solid-svg-icons";
import Flex from "../../common/Flex";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import PaymentInstruction from "./PaymentInstruction";
import {faTelegram} from "@fortawesome/free-brands-svg-icons";
import Avatar from "../../common/Avatar";
import {logoutAsync, selectActiveOrganization, selectUsers} from "../../../store/reducers/auth/authReducer";
import {selectDefaultSetting} from "../../../store/reducers/settings/settingsReducer";

const ProfileDropdown = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const defaultSettings = useSelector(selectDefaultSetting);
    const accounts = useSelector(selectUsers);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Dropdown navbar={true} as="li">
           {/* <Dropdown.Toggle
                as={'div'}
                bsPrefix="toggle"
                className="p-1 nav-link bg-transparent cursor-pointer"
            >*/}
                <div className={'d-flex align-items-center gap-2'}>
                    <Avatar variant={'name'} size="md"
                            name={accounts?.find(i => i.id === defaultSettings?.account_id)?.name || activeOrganization?.name}/>
                    <div className={'d-flex align-content-between flex-column'}>
                        <h5 className={'main-text'}>{accounts?.find(i => i.id === defaultSettings?.account_id)?.name}</h5>
                        <h6 className={'fw-semi-bold second-text'}>{activeOrganization?.name || ""}</h6>
                    </div>
                </div>

            {/*</Dropdown.Toggle>*/}

            {/*<Dropdown.Menu className="navbar-dropdown-caret py-0 shadow border border-300 dropdown-menu-center">
                <Card className="dropdown-menu-notification shadow-none border-0">
                    <ListGroup variant="flush" className=" fw-normal fs--1 scrollbar" style={{width: '14rem'}}>
                        <ListGroup.Item as={Link} className={'text-decoration-none'}>
                            <PaymentInstruction/>
                        </ListGroup.Item>
                        <ListGroup.Item as={Link} to={'tel:+998712006363'} className={'text-decoration-none'}>
                            <Flex className={'gap-2'} alignItems={'center'}>
                                <FontAwesomeIcon className={'fs-8'} icon={faPhone}/>
                                <span className={'text-decoration-none'}> +998 71 200 63 63</span>
                            </Flex>
                        </ListGroup.Item>
                        <ListGroup.Item as={Link} to={'https://t.me/HippoEDI'} target="_blank"
                                        className={'text-decoration-none'}>
                            <Flex className={'gap-2'} alignItems={'center'}>
                                <FontAwesomeIcon className={'fs-8'} icon={faTelegram}/>
                                <span
                                    className="text-decoration-none">{t(lang, 'auth.layout.link.telegram_bot.title')}</span>
                            </Flex>
                        </ListGroup.Item>
                        <ListGroup.Item as={Link} onClick={() => {
                            dispatch(logoutAsync())
                            setTimeout(() => {
                                navigate('/authentication')
                            }, 1000)
                        }}
                                        className={'text-decoration-none'}>
                            <Flex className={'gap-2'} alignItems={'center'}>
                                <FontAwesomeIcon className={'fs-8'} icon={faPersonWalkingArrowRight}/>
                                <span>{t(lang, 'main.top.nav.profile_dropdown.dropdown_item.logout')}</span>
                            </Flex>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </Dropdown.Menu>*/}
        </Dropdown>
    );
};

export default ProfileDropdown;
