import React from 'react';
import {Nav} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";

const contractorRoutes = [
    {
        name: 'warehouse.operation.item.common.executors',
        to: '/contractor',
    },
    {
        name: 'warehouse.operation.item.common.repayments',
        to: '/contractor-payments',
    },
    {
        name: 'warehouse.operation.item.common.contact',
        to: '/contractor-contact',
    }
]

const ContractorTabs = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const {pathname} = useLocation()

    return (
        <Nav className={'d-flex justify-content-start px-2 pt-2'} variant="tabs">
            {contractorRoutes.map((item, index) => <Nav.Item key={index}>
                <Nav.Link as={Link} to={item.to} className={classNames('mb-1 py-1', {
                    'main-text': pathname === item.to,
                    'second-text': pathname !== item.to,
                })}>
                    <span className={'fw-medium'}>{t(lang, item.name)}</span>
                </Nav.Link>
                {pathname === item.to && <span style={{height: 6}}
                                               className={'w-100 d-block bg-primary-davr rounded-3 rounded-top rounded-bottom-0'}/>}
            </Nav.Item>)}
        </Nav>
    );
};

export default ContractorTabs;
