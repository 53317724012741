import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../store/reducers/item/itemReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {useNavigate, useParams} from "react-router-dom";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS
} from "../../../eventbus/warehouse/transferEvent";
import {getTransferItemAsync} from "../../../store/reducers/warehouse/transferReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import WarehouseNo from "../../../components/warehouse/operation/WarehouseNo";
import ItemNo from "../../../components/warehouse/operation/ItemNo";
import EditTransferForm from "./EditTransferForm";

const EditTransfer = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [transfer, setTransfer] = useState(null)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const {id} = useParams()


    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, (id) => {
            loadTransfer().then()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, (id) => {
            loadTransfer().then()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, (id) => {
            loadTransfer().then()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, (id) => {
            loadTransfer().then()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`../transfers/view/${id}`)
        })

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS, (id) => {
            loadTransfer().then()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, (id) => {
            loadTransfer().then()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS, (response) => {
            setTransfer(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, (id) => {
            loadTransfer().then()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        loadTransfer().then()
    }, [activeOrganization, id])


    const loadTransfer = async () => {
        await getTransferItemAsync({transferId: id})
            .then((response) => {
                setTransfer(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setTransfer(null)
            })
    }


    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if (!transfer)
        return (
            <SpinnerComponent/>
        )


    return (
        <>
            <EditTransferForm transfer={transfer}/>
        </>
    )
};

export default EditTransfer;