import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../../store/reducers/main/main";
import {Translate} from "../../../../../lang/lang";
import {selectItems} from "../../../../../store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, FloatingLabel, Form, InputGroup, Row} from "react-bootstrap";
import WarehouseOperationItemSelect from "../../WarehouseOperationItemSelect";
import classNames from "classnames";
import PackageTypeItem from "../../PackageTypeItem";
import InputGroupWithCurrency from "../../price/InputGroupWithCurrency";
import {ErrorMessage} from "@hookform/error-message";

const ReturnPurchaseFormModal = ({categoryItems,onChangeItem,commonPrice}) => {

    const lang = useSelector(selectLang)
    const t = Translate;
    const items = useSelector(selectItems)

    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const itemId = useWatch({name: 'item_id', exact: true})

    const {errors, setValue, trigger,register} = useFormContext()

    return (
        <Fragment>
            <Row>
                <Form.Group as={Col} xs={12}>
                    <Controller
                        name="item_id"
                        rules={{
                            required: t(lang, "warehouse.operation.item.common.validation.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <WarehouseOperationItemSelect
                                    placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                    // onFocus={onFocusItem()}
                                    warehouseId={warehouseId}
                                    options={categoryItems || items}
                                    allowEdit={true}
                                    allowAdd={true}
                                    allowImage={true}
                                    defaultValue={field?.value}
                                    right={true}
                                    // defaultsSettings={purchaseDefaultsSettings}
                                    isClearable
                                    onChange={option => {
                                        field?.onChange(option ? option?.item?.id : null)
                                        onChangeItem(option)
                                    }}
                                    className={classNames({
                                        'is-invalid': errors?.item_id
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors?.item_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6}>
                    <InputGroup>
                        <FloatingLabel label={t(lang, "warehouse.operation.item.common.quantity")}>
                            <Form.Control
                                type="text"
                                id="quantity"
                                {...register("quantity", {
                                    required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                    pattern: {
                                        value: /^[0-9]*[\.,]?[0-9]*$/,
                                        message: "100 | 1.2 | 1,5"
                                    }
                                })}
                                placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                // isInvalid={errors?.quantity}
                                className={classNames("form-control border-right-0")}
                            />
                        </FloatingLabel>
                        <Controller
                            name="package_measurements"
                            render={({field}) => {
                                return (
                                    <PackageTypeItem
                                        left={true}
                                        itemId={itemId}
                                        defaultValue={field?.value}
                                        onChange={option => {
                                            field?.onChange(option)
                                        }}
                                    />
                                )
                            }}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6}>
                    <Controller
                        name='commonPrice'
                        render={({field}) => (
                            <InputGroupWithCurrency
                                left={true}
                                placeholder={t(lang, "warehouse.operation.item.common.sum")}
                                defaultCurrencyId={commonPrice?.currency_id}
                                defaultPrice={commonPrice?.amount}
                                onChangePrice={(price) => {
                                    setValue('commonPrice.amount', price);
                                    trigger('commonPrice');
                                }}
                                onChangeCurrency={(currency) => {
                                    setValue('commonPrice.currency_id', currency ? currency?.id : null);
                                    trigger('commonPrice');
                                }}
                                inputProps={{
                                    className: classNames({
                                        'is-invalid': errors?.commonPrice
                                    }, 'form-control border-right-0'),
                                    placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                    options: {
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 4
                                    }
                                }}
                                className={classNames('border-right-0',{
                                    'is-invalid': errors?.commonPrice
                                })}
                            />
                        )}
                    />
                    <ErrorMessage name='commonPrice' errors={errors} as={<Form.Control.Feedback type="invalid"/>}/>
                </Form.Group>
            </Row>
        </Fragment>
    );
};

export default ReturnPurchaseFormModal;
