import {forwardRef, useImperativeHandle, useState} from "react";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import Button from "../../common/Button";
import {Col, ProgressBar, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
const roundingValue = 2;

const ResultCounter = forwardRef((props, ref) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [loadedCount, setLoadedCount] = useState(0);
    const [succeedCount, setSucceedCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);

    useImperativeHandle(ref, () => ({
        updateLoadedCount(value) {
            setLoadedCount(value);
        },
        incrementSucceedCount() {
            setSucceedCount(prev => prev + 1);
        },
        incrementFailedCount() {
            setFailedCount(prev => prev + 1);
        },
    }));

    return loadedCount === 0 ? null : (
        <div className="position-fixed d-flex flex-column justify-content-center align-items-center" style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(255,255,255, .8)",
            zIndex: 1030
        }}>

            {succeedCount + failedCount !== loadedCount ?
                <Spinner
                    animation="border"
                    className="mb-3"
                    variant="primary"
                    style={{width: "65px", height: "65px"}}
                />
                :
                <h2>
                    <FontAwesomeIcon icon={faCheckCircle}
                                     className="text-success"
                                     fontVariant={'falcon-success'}
                    />
                    <span className="ms-2">{t(lang, 'items.common.result_counter.completed')}</span>
                </h2>
            }
            <div className="mt-5 w-100 w-sm-75 w-xl-50 px-sm-0 px-3 text-center">
                <ProgressBar animated={true}
                             now={(100 * (succeedCount + failedCount)) / loadedCount}
                             style={{height: 30}}
                             className="border border-400 shadow"
                />
                <span
                    className="fw-bold text-primary fs-1">{((100 * (succeedCount + failedCount)) / loadedCount).toFixed(roundingValue)} %</span>
            </div>
            <Row className="g-3 mt-3 w-100 justify-content-center">
                <Col xl={1} xs={12} sm={4} className="text-center">
                    <h4 className="fs-4 fw-medium text-700">
                        <p className="text-success">{succeedCount}</p>
                    </h4>
                    <h6 className="text-success">({t(lang, 'items.common.successful_imports')})</h6>
                </Col>
                <Col xl={2} xs={12} sm={4} className="text-center">
                    <h4 className="fs-4 fw-medium text-700">
                        <p>{t(lang, 'items.common.progress_bar.count_from', {
                            count: (succeedCount + failedCount !== loadedCount) ? succeedCount + failedCount + 1 : loadedCount,
                            from: loadedCount
                        })}</p>
                    </h4>
                </Col>
                <Col xl={1} xs={12} sm={4} className="text-center">
                    <h4 className="fs-4 fw-medium text-700">
                        <p className="text-danger">{failedCount}</p>
                    </h4>
                    <h6 className="text-danger">({t(lang, 'items.common.failed_imports')})</h6>
                </Col>
                <Col xs={12} className="text-center">
                    <Button disabled={loadedCount > succeedCount + failedCount}
                            onClick={() => {
                                setLoadedCount(0);
                                setSucceedCount(0);
                                setFailedCount(0);
                            }}
                    >
                        {t(lang, 'items.common.result_counter.click_to_continue')}
                    </Button>
                </Col>
            </Row>
        </div>
    )

})

export default ResultCounter
