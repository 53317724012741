export const ADD_CONTRACTOR_PAYMENT_REQUESTED = 'ADD_CONTRACTOR_PAYMENT_REQUESTED';
export const ADD_CONTRACTOR_PAYMENT_SUCCESS = 'ADD_CONTRACTOR_PAYMENT_SUCCESS';
export const ADD_CONTRACTOR_PAYMENT_FAILED = 'ADD_CONTRACTOR_PAYMENT_FAILED';

export const ADD_CONTRACTOR_PAYOUT_REQUESTED = 'ADD_CONTRACTOR_PAYOUT_REQUESTED';
export const ADD_CONTRACTOR_PAYOUT_SUCCESS = 'ADD_CONTRACTOR_PAYOUT_SUCCESS';
export const ADD_CONTRACTOR_PAYOUT_FAILED = 'ADD_CONTRACTOR_PAYOUT_FAILED';

export const DELETE_CONTRACTOR_PAYMENT_REQUESTED = 'DELETE_CONTRACTOR_PAYMENT_REQUESTED';
export const DELETE_CONTRACTOR_PAYMENT_SUCCESS = 'DELETE_CONTRACTOR_PAYMENT_SUCCESS';
export const DELETE_CONTRACTOR_PAYMENT_FAILED = 'DELETE_CONTRACTOR_PAYMENT_FAILED';

export const EDIT_CONTRACTOR_PAYMENT_REQUESTED = 'EDIT_CONTRACTOR_PAYMENT_REQUESTED';
export const EDIT_CONTRACTOR_PAYMENT_SUCCESS = 'EDIT_CONTRACTOR_PAYMENT_SUCCESS';
export const EDIT_CONTRACTOR_PAYMENT_FAILED = 'EDIT_CONTRACTOR_PAYMENT_FAILED';

export const EDIT_CONTRACTOR_PAYMENT_NOTE_REQUESTED = 'EDIT_CONTRACTOR_PAYMENT_NOTE_REQUESTED';
export const EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS = 'EDIT_CONTRACTOR_PAYMENT_NOTE_SUCCESS';
export const EDIT_CONTRACTOR_PAYMENT_NOTE_FAILED = 'EDIT_CONTRACTOR_PAYMENT_NOTE_FAILED';

export const EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_REQUESTED = 'EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_REQUESTED';
export const EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS = 'EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_SUCCESS';
export const EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_FAILED = 'EDIT_CONTRACTOR_PAYMENT_CONTRACTOR_FAILED';

export const DELETE_CONTRACTOR_PAYOUT_REQUESTED = 'DELETE_CONTRACTOR_PAYOUT_REQUESTED';
export const DELETE_CONTRACTOR_PAYOUT_SUCCESS = 'DELETE_CONTRACTOR_PAYOUT_SUCCESS';
export const DELETE_CONTRACTOR_PAYOUT_FAILED = 'DELETE_CONTRACTOR_PAYOUT_FAILED';


export const EDIT_CONTRACTOR_PAYOUT_REQUESTED = 'EDIT_CONTRACTOR_PAYOUT_REQUESTED';
export const EDIT_CONTRACTOR_PAYOUT_SUCCESS = 'EDIT_CONTRACTOR_PAYOUT_SUCCESS';
export const EDIT_CONTRACTOR_PAYOUT_FAILED = 'EDIT_CONTRACTOR_PAYOUT_FAILED';

export const EDIT_CONTRACTOR_PAYOUT_NOTE_REQUESTED = 'EDIT_CONTRACTOR_PAYOUT_NOTE_REQUESTED';
export const EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS = 'EDIT_CONTRACTOR_PAYOUT_NOTE_SUCCESS';
export const EDIT_CONTRACTOR_PAYOUT_NOTE_FAILED = 'EDIT_CONTRACTOR_PAYOUT_NOTE_FAILED';

export const EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_REQUESTED = 'EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_REQUESTED';
export const EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS = 'EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_SUCCESS';
export const EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_FAILED = 'EDIT_CONTRACTOR_PAYOUT_CONTRACTOR_FAILED';

export const EDIT_CONTRACTOR_PAYMENT_DATE_REQUESTED = 'EDIT_CONTRACTOR_PAYMENT_DATE_REQUESTED';
export const EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS = 'EDIT_CONTRACTOR_PAYMENT_DATE_SUCCESS';
export const EDIT_CONTRACTOR_PAYMENT_DATE_FAILED = 'EDIT_CONTRACTOR_PAYMENT_DATE_FAILED';

export const EDIT_CONTRACTOR_PAYOUT_DATE_REQUESTED = 'EDIT_CONTRACTOR_PAYOUT_DATE_REQUESTED';
export const EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS = 'EDIT_CONTRACTOR_PAYOUT_DATE_SUCCESS';
export const EDIT_CONTRACTOR_PAYOUT_DATE_FAILED = 'EDIT_CONTRACTOR_PAYOUT_DATE_FAILED';