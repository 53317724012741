import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {ContractorPaymentTypes} from "../../../enum/ContractorWrapper";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";

const SelectContractorPaymentType = ({label, defaultValue, onChange, changeColor, ...props}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const [value, setValue] = useState(null)

    useEffect(() => {
        setValue(ContractorPaymentTypes.find(b => b.value === defaultValue) || {label: 'Наличный', value: 1})
    }, [defaultValue])

    return (
        <div className={'w-25'} {...props}>
            <CustomFloatingLabelSelect
                right={true}
                height={'40px'}
                label={label}
                value={value}
                options={ContractorPaymentTypes}
                hideSelectedOptions
                onChange={(val) => onChange(val)}
                getOptionLabel={option => t(lang, option.label)}
                getOptionValue={option => option.value}
            />
        </div>
    )
}
export default SelectContractorPaymentType
