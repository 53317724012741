import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../../eventbus/EventBus";
import {attachPkcs7Async, attachTimestampAsync, createPkcs7Async} from "../../eimzo/eimzoReducer";
import {
    CARRIER_CANCEL_WAYBILL_FAILED,
    CARRIER_CANCEL_WAYBILL_REQUESTED,
    CARRIER_CANCEL_WAYBILL_SUCCEED,
    CUSTOMER_ACCEPT_WAYBILL_FAILED,
    CUSTOMER_ACCEPT_WAYBILL_REQUESTED,
    CUSTOMER_ACCEPT_WAYBILL_SUCCEED,
    CUSTOMER_DECLINE_WAYBILL_FAILED,
    CUSTOMER_DECLINE_WAYBILL_REQUESTED,
    CUSTOMER_DECLINE_WAYBILL_SUCCEED, SAVE_DRAFT_WAYBILL_FAILED,
    SAVE_DRAFT_WAYBILL_REQUESTED,
    SAVE_DRAFT_WAYBILL_SUCCEED,
    SEND_WAYBILL_FAILED,
    SEND_WAYBILL_REQUESTED,
    SEND_WAYBILL_SUCCEED
} from "../../../../eventbus/romaing/waybill/roamingWaybillEvents";
import RoamingService from "../../../services/roaming/roamingService";
import WaybillService from "../../../services/roaming/waybill/roamingWaybillService"
import {Translate} from "../../../../lang/lang";

const t = Translate;

export const DeliveryTypeOptions = [
    {value: 1, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_warehouse_to_warehouse"},
    {value: 2, label: "roaming.waybill.send.delivery_details_form.delivery_type.from_seller_to_buyer"},
];

export const TrailerTypeOptions = [
    {value: 1, label: "roaming.waybill.send.delivery_details_form.trailer_type.trailer"},
    {value: 2, label: "roaming.waybill.send.delivery_details_form.trailer_type.semi_trailer"},
]

export const roamingWaybillSlice = createSlice({
    name: 'roamingWaybill',
    initialState: {
        waybills: [],
        waybillsCount: 0,
        isLoading: false,
        filterOptions: {page: 1, limit: 10},
        viewPage: {
            id: null,
            draftId: null,
            showModal: false
        }
    },
    reducers: {
        setShowModal: (state, action) => {
            if (!action.payload) {
                state.viewPage = {id: null, draftId: null, showModal: false}
            }  else {
                state.viewPage.showModal = action.payload
            }
        },
        setId:(state, {payload}) => {
            const {draftId, id} = payload
            if (draftId) {
                state.viewPage.draftId = draftId
            }else if (id) {
                state.viewPage.id = id
            }
        },
        updateWaybills: (state, action) => {
            state.waybills = action.payload;
        },
        updateIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        updateWaybillsCount: (state, action) => {
            state.waybillsCount = action.payload;
        },
        updateWaybillsFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        }
    }
});

export const selectWaybills = state => state.roamingWaybill.waybills;
export const selectWaybillsLoading = state => state.roamingWaybill.isLoading;
export const selectWaybillsCount = state => state.roamingWaybill.waybillsCount;
export const selectWaybillsFilterOptions = state => state.roamingWaybill.filterOptions;
export const viewPage = state => state.roamingWaybill.viewPage

export const sendWaybillAsync = async (certificate, lang, waybill) => {
    try {
        EventBus.dispatch(SEND_WAYBILL_REQUESTED);
        const {id, hashCode} = waybill;

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7Async(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        await WaybillService.sendRoamingWaybill({
            id: id,
            signature: roamingSignature,
            lang: lang,
        })
            .then(response => EventBus.dispatch(SEND_WAYBILL_SUCCEED, response.data))
    } catch (error) {
        let errorMessage;
        const errorResponse = error?.response?.data;

        if (errorResponse.content) {
            const parsedError = JSON.parse(errorResponse.content);
            errorMessage = {message: parsedError?.ErrorMessage}
        } else if (errorResponse?.not_allowed_inn) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.not_allowed_inn')}
        } else if (errorResponse?.content_parse_failed) {
            errorMessage = {error: error, message: t(lang, 'roaming.waybill.error.message.content_parse_failed')}
        } else {
            errorMessage = {message: t(lang, 'roaming.waybill.error.message.default_error')}
        }
        EventBus.dispatch(SEND_WAYBILL_FAILED, errorMessage);
        throw errorMessage
    }
}
export const loadCustomerWaybillsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        WaybillService.getCustomerWaybills(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybills(response.data));
                dispatch(roamingWaybillSlice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => reject(error))
    })
}
export const getCustomerWaybillsCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        WaybillService.getCustomerWaybillsCount(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybillsCount(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}
export const loadCarrierWaybillsAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        WaybillService.getCarrierWaybills(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybills(response.data));
                dispatch(roamingWaybillSlice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => reject(error))
    })
}
export const getCarrierWaybillsCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        WaybillService.getCarrierWaybillsCount(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybillsCount(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}
export const changeWaybillsFilterOptions = (params) => dispatch => {
    return dispatch(roamingWaybillSlice.actions.updateWaybillsFilterOptions(params));
}
export const loadWaybillAsync = id => {
    return new Promise((resolve, reject) => {
        WaybillService.getWaybill(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}
export const carrierCancelWaybillAsync = async ({id, certificate}) => {
    EventBus.dispatch(CARRIER_CANCEL_WAYBILL_REQUESTED, id);

    try {
        const {data: hashCode} = await WaybillService.getCancelHashCode(id);

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7Async(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await WaybillService.carrierCancelWaybill({id, signature: roamingSignature});
        EventBus.dispatch(CARRIER_CANCEL_WAYBILL_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(CARRIER_CANCEL_WAYBILL_FAILED, error);
    }
}
export const customerDeclineWaybillAsync = async (certificate, payload) => {
    EventBus.dispatch(CUSTOMER_DECLINE_WAYBILL_REQUESTED, payload);

    try {
        const {id, comment} = payload;
        const {data: hashCode} = await WaybillService.getDeclineHashCode(id, {comment});

        const {pkcs7: signature, signatureHex, signerSerialNumber} = await createPkcs7Async(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(signature, signerSerialNumber, timeStamp);

        const response = await WaybillService.customerDeclineWaybill({
            id: id,
            signature: roamingSignature,
            comment: comment,
        });
        EventBus.dispatch(CUSTOMER_DECLINE_WAYBILL_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(CUSTOMER_DECLINE_WAYBILL_FAILED, error);
    }
}
export const customerAcceptWaybillAsync = async ({id, certificate}) => {
    EventBus.dispatch(CUSTOMER_ACCEPT_WAYBILL_REQUESTED, id);

    try {
        const {data: hashCode} = await WaybillService.getAcceptHashCode(id);

        const {pkcs7: roamingPkcs7, signerSerialNumber, signatureHex} = await attachPkcs7Async(hashCode, certificate.keyId);
        const {data: timeStamp} = await RoamingService.getTimestamp(signatureHex);
        const {pkcs7: roamingSignature} = await attachTimestampAsync(roamingPkcs7, signerSerialNumber, timeStamp);

        const response = await WaybillService.customerAcceptWaybill({id, signature: roamingSignature});
        EventBus.dispatch(CUSTOMER_ACCEPT_WAYBILL_SUCCEED, response.data);
    } catch (error) {
        EventBus.dispatch(CUSTOMER_ACCEPT_WAYBILL_FAILED, error);
    }

}

export const saveDraftWaybillAsync = ({id, content}) => {
    EventBus.dispatch(SAVE_DRAFT_WAYBILL_REQUESTED);

    return new Promise((resolve, reject) => {
        WaybillService.saveDraftWaybill({id: id, content: content})
            .then(response => {
                EventBus.dispatch(SAVE_DRAFT_WAYBILL_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(SAVE_DRAFT_WAYBILL_FAILED, error.response.data);
                reject(error);
            })
    })
}
export const loadWaybillDraftsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit;
    const params = {skip, limit, ...filters};
    dispatch(roamingWaybillSlice.actions.updateIsLoading(true));

    return new Promise((resolve, reject) => {
        WaybillService.getWaybillDrafts(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybills(response.data));
                dispatch(roamingWaybillSlice.actions.updateIsLoading(false));
                resolve();
            })
            .catch(error => reject(error))
    })
}
export const getWaybillDraftsCount = (params) => dispatch => {
    return new Promise((resolve, reject) => {
        WaybillService.getWaybillDraftsCount(params)
            .then(response => {
                dispatch(roamingWaybillSlice.actions.updateWaybillsCount(response.data));
                resolve();
            }).catch(error => reject(error))
    })
}
export const loadDraftWaybillAsync = id => {
    return new Promise((resolve, reject) => {
        WaybillService.getDraftWaybill(id)
            .then(response => resolve(response.data))
            .catch(error => reject(error))
    })
}

export default roamingWaybillSlice.reducer;
export const {setShowModal, setId} = roamingWaybillSlice.actions
