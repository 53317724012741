import {combineReducers} from "@reduxjs/toolkit";
import main from "./main/main";
import eimzoReducer from "./eimzo/eimzoReducer";
import authReducer from "./auth/authReducer";
import roleReducer from "./role/roleReducer";
import orderSlice from "./edi/order/orderReducer";
import billingReducer from "./billing/billingReducer";
import ediSettingsReducer from "./settings/ediSettingsReducer";
import settingsReducer from "./settings/settingsReducer";
import shipmentReducer from "./edi/shipment/shipmentReducer";
import returnShipmentReducer from "./edi/return-shipment/returnShipmentReducer";
import branchReducer from "./branch/branchReducer";
import excelReducer from "./excel/excelReducer";
import contractReducer from "./contract/contractReducer";
import roamingReferenceReducer from "./roaming/roamingReferenceReducer";
import roamingReducer from "./roaming/roamingReducer";
import protocolReducer from "./protocol/protocolReducer";
import itemReducer from "./item/itemReducer";
import invoiceReducer from "./edi/invoice/invoiceReducer";
import returnInvoiceReducer from "./edi/return-invoice/returnInvoiceReducer";
import productMapReducer from "./productMap/productMapReducer";
import returnOrderReducer from "./edi/return-order/returnOrderReducer";
import roamingInvoiceReducer from "./roaming/roamingInvoiceReducer/roamingInvoiceReducer";
import attorneyReducer from "./edi/attorney/attorneyReducer";
import contractFormationReducer from "./edi/contract-formation/contractFormationReducer";
import warehouseReducer from "./warehouse/warehouseReducer";
import roamingContractReducer from "./roaming/contracts/roamingContractReducer";
import saleReducer from "./warehouse/saleReducer";
import roamingWaybillReducer from "./roaming/waybill/roamingWaybillReducer";
import currencyReducer from "./currency/currencyReducer";
import excelRegistryReducer from "./roaming/excel-registry/excelRegistryReducer";
import empowermentReducer from "./roaming/empowerment/empowermentReducer";
import vertificationActReducer from "./roaming/vertification-act/vertificationActReducer";
import actReducer from "./roaming/act/actReducer";
import freeFormDocumentReducer from "./roaming/free-form/freeFormDocumentReducer";
import telegramNotification from "./telegram-notification/telegramNotification";
import pushUpdateNotificationReducer from './pushupdate/pushUpdateNotification'
import organizationBindingReducer from "./organization-binding/organizationBindingReducer";
import contractorReducer from "./contractor/contractorReducer";
import categoryReducer from "./category/categoryReducer";
import draftItemReducer from "./draft-item/draftItemReducer";
import contractorLabelReducer from "./contractor/contractorLabelReducer";
import revisionReducer from "./warehouse/revisionReducer";
import purchaseReducer from "./warehouse/purchaseReducer";
import refundReducer from "./warehouse/refundReducer";
import returnPurchase from "./warehouse/returnPurchase";
import writeOffReducer from "./warehouse/writeOffReducer";
import transferReducer from "./warehouse/transferReducer";
import employeeReducer from "./employee/employeeReducer";
import cashboxReducer from "./cashbox/CashboxReducer";
import contractorPaymentReducer from "./contractor/contractorPaymentReducer";

export const root = combineReducers({
    main,
    eimzo: eimzoReducer,
    auth: authReducer,
    role: roleReducer,
    ediOrder: orderSlice,
    billing: billingReducer,
    ediSettings: ediSettingsReducer,
    settings: settingsReducer,
    shipment: shipmentReducer,
    returnShipment: returnShipmentReducer,
    branch: branchReducer,
    excel: excelReducer,
    contract: contractReducer,
    roaming: roamingReducer,
    roamingReference: roamingReferenceReducer,
    protocol: protocolReducer,
    item: itemReducer,
    invoice: invoiceReducer,
    returnInvoice: returnInvoiceReducer,
    productMap: productMapReducer,
    returnOrder: returnOrderReducer,
    roamingInvoice: roamingInvoiceReducer,
    attorney: attorneyReducer,
    contractFormation: contractFormationReducer,
    warehouses: warehouseReducer,
    roamingContract: roamingContractReducer,
    sale: saleReducer,
    roamingWaybill: roamingWaybillReducer,
    currency: currencyReducer,
    excelRegistry: excelRegistryReducer,
    roamingEmpowerment: empowermentReducer,
    roamingVerificationAct: vertificationActReducer,
    roamingActSlice: actReducer,
    roamingFreeFormDocument: freeFormDocumentReducer,
    telegramNotification: telegramNotification,
    pushUpdateNotification: pushUpdateNotificationReducer,
    organizationBinding: organizationBindingReducer,
    contractors: contractorReducer,
    contractorLabel: contractorLabelReducer,
    category: categoryReducer,
    draftItem: draftItemReducer,
    revision: revisionReducer,
    purchase: purchaseReducer,
    refund: refundReducer,
    returnPurchase: returnPurchase,
    writeOff: writeOffReducer,
    transfer: transferReducer,
    employee: employeeReducer,
    cashbox: cashboxReducer,
    contractorPayment: contractorPaymentReducer,
})

