import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectEmployees} from "../../store/reducers/employee/employeeReducer";
import {Translate} from "../../lang/lang";
import {debounce} from "underscore";
import CustomFloatingLabelSelectAsync from "../floating-custom-select/CustomFloatingLabelSelectAsync";
import {InputGroup} from "react-bootstrap";
import AddEmployeeModal from "./AddEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";

const EmployeeSelect = ({defaultValue, onChange, allowEdit, allowAdd, placeholder, ...props}) => {

    const [value, setValue] = useState(null)
    const employees = useSelector(selectEmployees);
    const lang = useSelector(selectLang)
    const t = Translate
    const filterOptions = {page: 0, limit: 10}

    useEffect(() => {
        if (defaultValue !== null) {
            setValue(employees?.find(x => x.id === defaultValue))
        } else {
            setValue(null)
        }
    }, [employees, defaultValue])

    const loadOptions = (query, callback) => {
        if (query) {
            callback(employees?.filter(i => {
                const searchLower = query?.trim()?.toLowerCase();

                if (i?.name?.toLowerCase()?.includes(searchLower))
                    return true

            }).slice(filterOptions?.page, filterOptions?.limit))
        } else {
            callback([]);
        }
    };
    const debouncedLoadOptions = debounce(loadOptions, 100);

    const onChangeEmployee = option => {
        setValue(option)
        onChange && onChange(option)
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: state.isFocused ? 'blue' : 'gray', // Change the border color based on focus
            '&:hover': {
                borderColor: state.isFocused ? 'blue' : 'gray' // Change the border color on hover
            }
        })
    };

    return (
        <Fragment>
            <InputGroup className={'select flex-nowrap'}>
                    <CustomFloatingLabelSelectAsync
                        {...props}
                        style={customStyles}
                        label={placeholder}
                        loadOptions={debouncedLoadOptions}
                        onChange={onChangeEmployee}
                        value={value}
                        classNamePrefix={'border-left-0'}
                        className={'border-left-0'}
                        hideSelectedOptions
                        getOptionValue={(option) => option?.id}
                        getOptionLabel={(option) => option?.name}
                        defaultOptions={employees?.slice(filterOptions?.page, filterOptions?.limit)}
                        noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderTopLeftRadius: '8px',
                                borderBottomLeftRadius: '8px',
                                borderBottomRightRadius: '0px',
                                borderTopRightRadius: '0px',
                            }),
                        }}
                    />
                {allowAdd && !value &&
                    <AddEmployeeModal noName={true}/>
                }
                {allowEdit && value &&
                    <EditEmployeeModal employee={value}/>
                }
            </InputGroup>
        </Fragment>
    );
};

export default EmployeeSelect;
