import axios from "axios";

class RoamingFreeFormDocumentService {
    registerFreeFormDocument(payload) {
        return axios.post('/free-form-document/free-form-document/save', payload)
    }

    getFreeFormDocument(id) {
        return axios.get(`/free-form-document/free-form-document/${id}`)
    }

    getFreeFormSenderDocuments(params) {
        return axios.get('/free-form-document/free-form-document/sender', {
            params: params,
        })
    }

    getFreeFormSenderDocumentsCount(params) {
        return axios.get('free-form-document/free-form-document/sender/count', {
            params: params,
        })
    }

    getFreeFormReceiverDocuments(params) {
        return axios.get('/free-form-document/free-form-document/receiver', {
            params: params,
        })
    }

    getFreeFormReceiverDocumentsCount(params) {
        return axios.get('free-form-document/free-form-document/receiver/count', {
            params: params,
        })
    }

    getFreeFormDocumentPdfFile(id) {
        return axios.get(`/free-form-document/free-form-document/pdf/${id}`, {
            responseType: "blob",
        })
    }

    getFreeFormDocumentZipFile(id) {
        return axios.get(`/free-form-document/free-form-document/zip/${id}`, {
            responseType: "blob"
        })
    }

    getSignHashCode(id) {
        return axios.get(`/free-form-document/free-form-document/sign-hash/${id}`)
    }

    signFreeFormDocument({id, signature}) {
        return axios.post(`/free-form-document/free-form-document/sign/${id}`, {
            signature: signature,
        })
    }

    getCancelHashCode(id) {
        return axios.get(`/free-form-document/free-form-document/cancel-hash/${id}`)
    }

    cancelFreeFormDocument({id, signature}) {
        return axios.post(`/free-form-document/free-form-document/cancel/${id}`, {
            signature: signature,
        })
    }

    getAcceptHashCode(id) {
        return axios.get(`/free-form-document/free-form-document/accept-hash/${id}`)
    }

    acceptFreeFormDocument({id, signature}) {
        return axios.post(`/free-form-document/free-form-document/accept/${id}`, {
            signature: signature,
        })
    }

    getDeclineHashCode(id) {
        return axios.get(`/free-form-document/free-form-document/decline-hash/${id}`)
    }

    declineFreeFormDocument({id, signature}) {
        return axios.post(`/free-form-document/free-form-document/decline/${id}`, {
            signature: signature,
        })
    }
}

const roamingFreeFormDocumentService = new RoamingFreeFormDocumentService();

export default roamingFreeFormDocumentService;
