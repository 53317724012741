import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    getTransferItemAsync,
    selectTransferDefaultsSettings,
    transferAddItemAsync,
    transferChangeEmployeeAsync,
    transferRegisterAsync,
    transferUpdateNoteAsync
} from "../../../store/reducers/warehouse/transferReducer";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {useNavigate} from "react-router-dom";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectDefaultSetting} from "../../../store/reducers/settings/settingsReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../enum/item/itemWrapper";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS
} from "../../../eventbus/warehouse/transferEvent";
import {Form} from "react-bootstrap";
import TransferForm from "./TransferForm";
import toast from "react-hot-toast";

const AddTransferForm = () => {

    const transferDefaultsSettings = useSelector(selectTransferDefaultsSettings)
    const [transfer, setTransfer] = useState(null)
    const [loading, setLoading] = useState(false)
    const currencies = useSelector(selectCurrency)
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)

    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }

    const form = useForm({
        defaultValues: {
            employee_id: transferDefaultsSettings?.defaultEmployeeId || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse_from_id: settings?.warehouse?.default_id || null,
            warehouse_to_id: null,
            quantity: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })

    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee?.id || formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: formData.warehouse_id,
            warehouse_from_id: formData.warehouse_id,
            warehouse_to_id: null,
            warehouse: null,
            package_measurements: null,
            marks_from: [],
            marks_to: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }

    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity
        const item = {
            item_id: formData?.item_id,
            quantity: quantity,
            marks_from: formData?.marks_from || [],
            marks_to: formData?.marks_to || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
            warehouse_from_id: formData?.warehouse_from_id,
            warehouse_to_id: formData?.warehouse_to_id,
        }
        if ((buttonType === "addOrEditTransferAndContinue" || buttonType === 'addOrEditTransferAndClose') || (event === "addOrEditTransferAndContinue" || event === "addOrEditTransferClose")) {
            setLoading(true)
            if (!transfer) {
                await transferRegisterAsync({
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                })
                    .then(({data}) => {
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data.error.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                        if (buttonType === "addOrEditTransferAndClose" || event === "addOrEditTransferClose") {
                            onHide()
                        }
                    })
            }
            if (transfer) {
                await transferAddItemAsync({
                    transferId: transfer?.id,
                    operation: item,
                })
                    .then(() => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data.error.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                        if (buttonType === "addOrEditTransferAndClose" || event === "addOrEditTransferClose") {
                            onHide()
                        }
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || transfer?.employee) {
            if (formData?.employee_id !== transfer?.employee?.id) {
                transferChangeEmployeeAsync({transferId: transfer?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setTransfer(data, data.items.reverse())
                    })
                    .catch(() => {
                    })
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== transfer?.note) {
            transferUpdateNoteAsync({transferId: transfer?.id, note: formData?.note})
                .then(({data}) => {
                    setTransfer(data, data.items.reverse())
                })
                .catch(() => {
                })
        }
    }

    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`../transfers/view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, (id) => {
            loadTransfer(id).then()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
        form.setValue('warehouse_from_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadTransfer = async (id) => {
        await getTransferItemAsync({transferId: id})
            .then((response) => {
                setTransfer(response.data, response.data.items.reverse())
            })
            .catch(() => {
                setTransfer(null)
            })
    }


    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <TransferForm
                    transfer={transfer}
                    loading={loading}
                    addItem={form.handleSubmit(onSubmit)}
                    show={show}
                    onShow={onShow}
                    onHide={onHide}
                />
            </Form>
        </FormProvider>
    );
};

export default AddTransferForm;