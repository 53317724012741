import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {selectAllContractor} from "../../../store/reducers/contractor/contractorReducer";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";

const SelectContractor = ({label, onChange, defaultValue, ...props}) => {
    const contractors = useSelector(selectAllContractor)
    const [contractor, setContractor] = useState(contractors.find(s => s.id === defaultValue) || null)

    useEffect(() => {
        onChange(contractor ? contractor : null);
    }, [contractor])

    return (
        <CustomFloatingLabelSelect
            {...props}
            hideSelectedOptions
            label={label}
            style={{width: '100%'}}
            onChange={option => setContractor(option)}
            options={contractors}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            value={contractors.find(s => s.id === defaultValue)}
            isClearable/>
    );
};

SelectContractor.propTypes = {
    onChange: PropTypes.func
}

SelectContractor.defaultProps = {
    onChange: () => {
    }
}

export default SelectContractor;
