import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Table} from "react-bootstrap";
import dayjs from "dayjs";
import numeral from "numeral";

const TransferViewDataTable = ({dataItems}) => {
    const numberFormat = useSelector(selectNumberFormat)
    const lang = useSelector(selectLang)
    const t = Translate


    const groupByItemIdAndPrice = (filterItems) => {
        let groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item_from?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item_from?.id,
                            name: item?.warehouse_item_from?.name,
                            warehouse_from_id: item?.warehouse_item_from?.warehouse,
                            warehouse_to_id: item?.warehouse_item_to?.warehouse,
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(dataItems)


    return (
        <Fragment>
            <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                <thead style={{position: "sticky", top: '-1px'}}
                       className="text-dark border-bottom bg-card second-text text-center">
                <tr>
                    <th className="fw-semi-bold align-middle">№</th>
                    {/*<th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.warehouse_from")}</th>*/}
                    {/*<th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.warehouse_to")}</th>*/}
                    <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                    <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                    {dataItems?.some(d => d?.is_deleted) &&
                        <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                    }
                </tr>
                </thead>
                <tbody>

                {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                    return (
                        <React.Fragment key={rowIndex}>
                            {item?.sub?.map((subItem, subRowIndex) => {
                                return (
                                    <tr key={subItem.id}>
                                        <td key={rowIndex}
                                            className={subRowIndex === 0 ? "fw-semi-bold align-middle" : "d-none"}
                                            rowSpan={item?.sub?.length}
                                            style={{width: "50px"}}
                                        >
                                            {groupByItemPriceCurrencyId?.length - rowIndex}
                                        </td>
                                        {/*<th className={"fw-semi-bold align-middle"}>*/}
                                        {/*    {subItem?.warehouse_item_from.warehouse?.name}*/}
                                        {/*    {dataItems?.some(item => item?.warehouse_item_from?.warehouse_item_use) &&*/}
                                        {/*        <div className="text-center text-black" style={{fontSize: "16px"}}>*/}
                                        {/*            {subItem?.warehouse_item_from?.warehouse_item_use?.before_quantity}*/}
                                        {/*            <span> => </span>*/}
                                        {/*            {subItem?.warehouse_item_from?.warehouse_item_use?.after_quantity}*/}
                                        {/*        </div>*/}
                                        {/*    }*/}
                                        {/*</th>*/}
                                        {/*<th className={"fw-semi-bold align-middle"}>*/}
                                        {/*    {subItem?.warehouse_item_to.warehouse?.name}*/}
                                        {/*    {dataItems?.some(item => item?.warehouse_item_to?.warehouse_item_use) &&*/}
                                        {/*        <div className="text-center text-black" style={{fontSize: "16px"}}>*/}
                                        {/*            {subItem?.warehouse_item_to?.warehouse_item_use?.before_quantity}*/}
                                        {/*            <span> => </span>*/}
                                        {/*            {subItem?.warehouse_item_to?.warehouse_item_use?.after_quantity}*/}
                                        {/*        </div>*/}
                                        {/*    }*/}
                                        {/*</th>*/}
                                        <td
                                            className={subRowIndex === 0 ? "fw-semi-bold align-middle" : "d-none"}
                                            rowSpan={item?.sub?.length}>
                                            {subItem?.warehouse_item_from?.name}
                                        </td>
                                        <th className="fw-semi-bold text-end">
                                            {subItem?.quantity}
                                        </th>
                                        <th className="fw-semi-bold text-end">
                                            {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                            <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                        </th>
                                        <th className="fw-semi-bold text-end">
                                            {numeral.formats[numberFormat].format(subItem?.quantity * subItem?.price?.amount)}
                                            <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                        </th>
                                        {subItem?.is_deleted &&
                                            <>
                                                <th className="fw-semi-bold align-middle text-danger"
                                                    style={{width: "150px"}}>
                                                    {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                </th>
                                            </>
                                        }
                                    </tr>
                                )
                            })}
                        </React.Fragment>
                    )
                })}
                </tbody>
            </Table>
        </Fragment>
    );
};
export default TransferViewDataTable;