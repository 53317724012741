import {Link, useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {
    selectActiveOrganization,
    selectIsLoginWithCertificate,
    selectOrganizations,
    switchActiveOrganization
} from "../../store/reducers/auth/authReducer";
import {ListGroup} from "react-bootstrap";
import PropTypes from "prop-types";


const Organization = ({organization, onClick}) => {
    const onClicked = e => {
        e.preventDefault();
        onClick(organization);
    };

    return (
        <Link to="#" className={classNames('notification text-decoration-none rounded-0')}
              onClick={onClicked}>
            <div className={classNames('notification-body')}>
                <p className="mb-1 fw-bold text-dark">
                    {organization.name}
                </p>
                <span className="notification-time">
                    {organization.inn}
                </span>
            </div>
        </Link>
    )
};



const OrganizationChooser = (props) => {
    const isLoginWithCertificate = useSelector(selectIsLoginWithCertificate);
    const activeOrganization = useSelector(selectActiveOrganization);
    const organizations = useSelector(selectOrganizations);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const onOrganizationClicked = organization => {
        dispatch(switchActiveOrganization(organization));
        navigate("/")
    }

    return (
        <ListGroup variant="flush" className="fw-normal fs--1 scrollbar" {...props}>
            {organizations.map(organization => {
                if (organization.inn === activeOrganization.inn && isLoginWithCertificate === 1) {
                    return (
                        <ListGroup.Item key={organization.id} className={classNames( {'bg-300': organization.isActive})}>
                            <Organization organization={organization} onClick={onOrganizationClicked}/>
                        </ListGroup.Item>
                    )
                }
                else if(isLoginWithCertificate === 0) {
                    return (
                        <ListGroup.Item key={organization.id} className={classNames( {'bg-300': organization.isActive})}>
                            <Organization organization={organization} onClick={onOrganizationClicked}/>
                        </ListGroup.Item>
                    )
                }
            })}
        </ListGroup>
    );
};

Organization.propTypes = {
    organization: PropTypes.shape({
        isActive: PropTypes.bool,
        name: PropTypes.string,
        inn: PropTypes.string
    }).isRequired,
    onClick: PropTypes.func.isRequired
}
export default OrganizationChooser;
