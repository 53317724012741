import React, {useEffect} from 'react';
import lightImg from '../../../pheonix/assets/img/spot-illustrations/1.png';
import darkImg from '../../../pheonix/assets/img/spot-illustrations/dark_1.png';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {logoutAsync} from "../../../store/reducers/auth/authReducer";
import {useDispatch} from "react-redux";
import Flex from "../../common/Flex";

const LogoutContent = () => {
    const dispatch = useDispatch()

    return (
        <div className="w-100 h-100 overflow-hidden bg-main-davr rounded-5 p-3">
            <Flex className={"flex-column h-100 justify-content-between"}>
                <div>
                    <img className="mb-7 d-dark-none" src={lightImg} alt="phoenix" width={222} height={179}/>
                    <div className="mb-6">
                        <h4 className="text-1000"> Увидимся снова!</h4>
                        <p className="text-700">
                            Спасибо за использование <b>Hippo</b>!
                            Вы успешно вышли из системы.
                        </p>
                    </div>
                </div>
                <div className="d-grid">
                    <Button
                        className="bg-primary-davr"
                        as={Link}
                        to={`/authentication`}
                    >
                        Войти в систему
                    </Button>
                </div>
            </Flex>

        </div>
    );
};

export default LogoutContent;
