import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useParams} from "react-router-dom";
import {getSaleItemAsync} from "../../../../store/reducers/warehouse/saleReducer";
import {loadContractorAsync, updateCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS
} from "../../../../eventbus/saleEvents";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import Sale from "../../../../components/warehouse/operation/sale/Sale";

const ViewSale = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const [sale, setSale] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()

    const loadSale = () => {
        getSaleItemAsync({saleId: id})
            .then(response => {
                setSale(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setSale(null))
    }

    useEffect(() => {
        loadSale()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, () => {
            loadSale()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, () => {
            loadSale()
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, () => {
            loadSale()
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, () => {
            loadSale()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_SALE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_SALE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
        }
    },[])

    if (!sale) return <SpinnerComponent />

    return (
        <Sale sale={sale}/>
    );
};

export default ViewSale;
