import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {
    CASHBOX_BINDING_SUCCESS,
    CASHBOX_CASH_IN_DELETE_SUCCESS,
    CASHBOX_CASH_IN_REGISTER_SUCCESS,
    CASHBOX_CASH_IN_UPDATE_SUCCESS,
    CASHBOX_CASH_OUT_DELETE_SUCCESS,
    CASHBOX_CASH_OUT_REGISTER_SUCCESS,
    CASHBOX_CASH_OUT_UPDATE_SUCCESS,
    CASHBOX_DELETE_SUCCESS,
    CASHBOX_EXPENSE_DELETE_SUCCESS,
    CASHBOX_EXPENSE_REGISTER_SUCCESS,
    CASHBOX_EXPENSE_UPDATE_SUCCESS,
    CASHBOX_GET_CASH_IN_SUCCESS,
    CASHBOX_GET_CASH_OUT_SUCCESS,
    CASHBOX_GET_EXPENSE_SUCCESS,
    CASHBOX_REGISTER_SUCCESS,
    CASHBOX_TRANSFER_DELETE_SUCCESS,
    CASHBOX_TRANSFER_REGISTER_SUCCESS,
    CASHBOX_TRANSFER_UPDATE_SUCCESS,
    CASHBOX_UPDATE_SUCCESS
} from "../../../eventbus/cashbox/cashboxEvents";
import settingsService from "../../services/settings/settingsService";
import cashboxService from "../../services/cashbox/cashboxService";

const cashboxSlice = createSlice({
    name: 'cashbox',
    initialState: {
        currency: null,
        cashbox: [],
        accountCashbox: [],

        expense: [],
        expenseCount: 0,
        expenseFilter: {page: 1, limit: 10},

        cashIn: [],
        cashInCount: 0,
        cashInFilter: {page: 1, limit: 10},

        cashOut: [],
        cashOutCount: 0,
        cashOutFilter: {page: 1, limit: 10},

        operation: [],
        operationCount: 0,
        operationFilter: {page: 1, limit: 10},

        transfer: [],
        transferCount: 0,
        transferFilter: {page: 1, limit: 10},

        cash_box_operation_item: null
    },
    reducers: {
        setCashbox: (state, action) => {
            state.cashbox = action.payload
        },
        setAccountCashbox: (state, action) => {
            state.accountCashbox = action.payload
        },

        setExpense: (state, action) => {
            state.expense = action.payload
        },
        setExpenseCount: (state, action) => {
            state.expenseCount = action.payload
        },
        setFilterExpense: (state, action) => {
            state.expenseFilter = {...state.expenseFilter, ...action.payload}
        },

        setCashIn: (state, action) => {
            state.cashIn = action.payload
        },
        setCashInCount: (state, action) => {
            state.cashInCount = action.payload
        },
        setFilterCashIn: (state, action) => {
            state.cashInFilter = {...state.cashInFilter, ...action.payload}
        },

        setCashOut: (state, action) => {
            state.cashOut = action.payload
        },
        setCashOutCount: (state, action) => {
            state.cashOutCount = action.payload
        },
        setFilterCashOut: (state, action) => {
            state.cashOutFilter = {...state.cashOutFilter, ...action.payload}
        },

        setOperation: (state, action) => {
          state.operation = action.payload
        },
        setOperationCount: (state, action) => {
          state.operationCount = action.payload
        },
        setFilterOperation: (state, action) => {
            state.operationFilter = {...state.operationFilter, ...action.payload}
        },

        setTransfer: (state, action) => {
            state.transfer = action.payload
        },
        setTransferCount: (state, action) => {
            state.transferCount = action.payload
        },
        setFilterTransfer: (state, action) => {
            state.transferFilter = {...state.operationFilter, ...action.payload}
        },

        setCurrency: (state, action) => {
            state.currency = action.payload
        }
    }
})

export const selectedCurrencyForCashBox = (state) => state.cashbox.currency

export const selectCashbox = (state) => state.cashbox.cashbox
export const selectAccountCashbox = (state) => state.cashbox.accountCashbox

export const selectCashBoxExpense = (state) => state.cashbox.expense
export const selectCashBoxExpenseCount = (state) => state.cashbox.expenseCount
export const selectCashBoxExpenseFilter = (state) => state.cashbox.expenseFilter

// cash in
export const selectCashBoxCashIn = (state) => state.cashbox.cashIn
export const selectCashBoxCashInCount = (state) => state.cashbox.cashInCount
export const selectCashBoxCashInFilter = (state) => state.cashbox.cashInFilter

// cash out
export const selectCashBoxCashOut = (state) => state.cashbox.cashOut
export const selectCashBoxCashOutCount = (state) => state.cashbox.cashOutCount
export const selectCashBoxCashOutFilter = (state) => state.cashbox.cashOutFilter

// operation
export const selectCashBoxOperation = (state) => state.cashbox.operation
export const selectCashBoxOperationCount = (state) => state.cashbox.operationCount
export const selectCashBoxOperationFilter = (state) => state.cashbox.operationFilter

// transfer

export const selectCashBoxTransfer = (state) => state.cashbox.transfer
export const selectCashBoxTransferCount = (state) => state.cashbox.transferCount
export const selectCashBoxTransferFilter = (state) => state.cashbox.transferFilter

// Services
// cashBox

export const getAllCashboxAsync = _ => dispatch => {
    return new Promise((resolve, reject) => {
        cashboxService.getAllCashbox()
            .then(res => {
                dispatch(cashboxSlice.actions.setCashbox(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getCashBoxAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getCashbox(id)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    })
}

export const registerCashbox = (data) => {
    return new Promise((resolve, reject) => {
        cashboxService.registerCashbox(data)
            .then((res) => {
                EventBus.dispatch(CASHBOX_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const updateCashbox = (data, id) => {
    return new Promise((resolve, reject) => {
        cashboxService.updateCashbox(data, id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_UPDATE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const deleteCashbox = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.deleteCashbox(id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_DELETE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

// Account
export const cashboxAccountBindingRegister = (params) => {
    return new Promise((resolve, reject) => {
        cashboxService.cashboxBindingRegister(params)
            .then(res => {
                EventBus.dispatch(CASHBOX_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const cashboxAccountBindingUnregister = (params) => {
    return new Promise((resolve, reject) => {
        cashboxService.cashboxBindingUnregister(params)
            .then(res => {
                EventBus.dispatch(CASHBOX_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const cashboxAccountBindingEnable = (params) => {
    return new Promise((resolve, reject) => {
        cashboxService.cashboxBindingEnable(params)
            .then(res => {
                EventBus.dispatch(CASHBOX_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const cashboxAccountBindingDisable = (params) => {
    return new Promise((resolve, reject) => {
        cashboxService.cashboxBindingDisable(params)
            .then(res => {
                EventBus.dispatch(CASHBOX_BINDING_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getCashboxAccountAsync = _ => (dispatch) => {
    return new Promise((resolve, reject) => {
        cashboxService.getAccountCashbox()
            .then((res) => {
                dispatch(cashboxSlice.actions.setAccountCashbox(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getCashboxAccountBinding = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getAccountCashboxBinding(id)
            .then((res) => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

// Expense
export const getAllExpenseAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        cashboxService.getAllExpense(params)
            .then((res) => {
                EventBus.dispatch(CASHBOX_GET_EXPENSE_SUCCESS)
                dispatch(cashboxSlice.actions.setExpense(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getExpenseCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        cashboxService.getExpenseCount(others)
            .then((res) => {
                dispatch(cashboxSlice.actions.setExpenseCount(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getExpenseAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getExpense(id)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    })
}

export const changeFilterExpense = ({...params}) => (dispatch) => {
    dispatch(cashboxSlice.actions.setFilterExpense({...params}))
}

export const registerExpense = (data) => {
    return new Promise((resolve, reject) => {
        cashboxService.registerExpense(data)
            .then((res) => {
                EventBus.dispatch(CASHBOX_EXPENSE_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const updateExpense = (data, id) => {
    return new Promise((resolve, reject) => {
        cashboxService.updateExpense(data, id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_EXPENSE_UPDATE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}
export const deleteExpense = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.deleteExpense(id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_EXPENSE_DELETE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

// cash in

export const getAllCashInAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        cashboxService.getAllCashIn(params)
            .then((res) => {
                EventBus.dispatch(CASHBOX_GET_CASH_IN_SUCCESS)
                dispatch(cashboxSlice.actions.setCashIn(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getCashInCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        cashboxService.getAllCashInCount(others)
            .then((res) => {
                dispatch(cashboxSlice.actions.setCashInCount(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const changeFilterCashIn = ({...params}) => (dispatch) => {
    dispatch(cashboxSlice.actions.setFilterCashIn({...params}))
}

export const getCashInAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getCashIn(id)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    })
}

export const registerCashIn = (data) => {
    return new Promise((resolve, reject) => {
        cashboxService.registerCashIn(data)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_IN_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const updateCashIn = (data, id) => {
    return new Promise((resolve, reject) => {
        cashboxService.updateCashIn(data, id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_IN_UPDATE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}
export const deleteCashIn = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.deleteCashIn(id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_IN_DELETE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

// cash out

export const getAllCashOutAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        cashboxService.getAllCashOut(params)
            .then((res) => {
                EventBus.dispatch(CASHBOX_GET_CASH_OUT_SUCCESS)
                dispatch(cashboxSlice.actions.setCashOut(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const getCashOutCountAsync = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        cashboxService.getAllCashOutCount(others)
            .then((res) => {
                dispatch(cashboxSlice.actions.setCashOutCount(res.data))
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const changeFilterCashOut = ({...params}) => (dispatch) => {
    dispatch(cashboxSlice.actions.setFilterCashOut({...params}))
}

export const getCashOutAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getCashOut(id)
            .then(res => resolve(res.data))
            .catch(e => reject(e))
    })
}

export const registerCashOut = (data) => {
    return new Promise((resolve, reject) => {
        cashboxService.registerCashOut(data)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_OUT_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const updateCashOut = (data, id) => {
    return new Promise((resolve, reject) => {
        cashboxService.updateCashOut(data, id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_OUT_UPDATE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}
export const deleteCashOut = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.deleteCashOut(id)
            .then((res) => {
                EventBus.dispatch(CASHBOX_CASH_OUT_DELETE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

// operation

export const getAllOperationAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        cashboxService.getAllOperation(params)
            .then(res => {
                resolve(res.data)
                dispatch(cashboxSlice.actions.setOperation(res.data))
            })
            .catch(e => {
                console.log(e)
            })
    })
}

export const getAllOperationCount = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        cashboxService.getAllOperationCount(others)
            .then(res => {
                resolve(res.data)
                dispatch(cashboxSlice.actions.setOperationCount(res.data))
            })
            .catch(e => console.log(e))
    })
}

export const changeFilterOperation = ({...params}) => (dispatch) => {
    dispatch(cashboxSlice.actions.setFilterOperation({...params}))
}

export const getOperationAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getOperation(id)
            .then(res => resolve(res.data))
            .catch(e => console.log(e))
    })
}

// transfer

export const getAllTransferAsync = ({page, limit, ...filters}) => (dispatch) => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        cashboxService.getAllTransfer(params)
            .then(res => {
                dispatch(cashboxSlice.actions.setTransfer(res.data))
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const getAllTransferCount = (params) => (dispatch) => {
    let {limit, page, ...others} = params

    return new Promise((resolve, reject) => {
        cashboxService.getAllTransferCount(params)
            .then(res => {
                dispatch(cashboxSlice.actions.setTransferCount(res.data))
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const changeFilterTransfer = ({...params}) => (dispatch) => {
    dispatch(cashboxSlice.actions.setFilterTransfer({...params}))
}

export const getTransferAsync = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.getTransfer(id)
            .then(res => resolve(res.data))
            .catch(e => console.log(e))
    })
}

export const registerTransfer = (data) => {
    return new Promise((resolve, reject) => {
        cashboxService.registerTransfer(data)
            .then(res => {
                EventBus.dispatch(CASHBOX_TRANSFER_REGISTER_SUCCESS)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const updateTransfer = (data,id) => {
    return new Promise((resolve, reject) => {
        cashboxService.updateTransfer(data, id)
            .then(res => {
                EventBus.dispatch(CASHBOX_TRANSFER_UPDATE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

export const deleteTransfer = (id) => {
    return new Promise((resolve, reject) => {
        cashboxService.deleteTransfer(id)
            .then(res => {
                EventBus.dispatch(CASHBOX_TRANSFER_DELETE_SUCCESS)
                resolve(res.data)
            })
            .catch(e => console.log(e))
    })
}

// settings
export const updateCashBoxDefaultSetting = (payload) => {
    return new Promise((resolve, reject) => {
        settingsService.updateCashBoxSettings(payload)
            .then((res) => resolve(res.data))
            .catch(() => reject())
    })
}
export const {setCurrency} = cashboxSlice.actions
export default cashboxSlice.reducer
