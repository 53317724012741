export const ADD_CONTRACTOR_LABEL_REQUESTED = 'ADD_CONTRACTOR_LABEL_REQUESTED';
export const ADD_CONTRACTOR_LABEL_SUCCESS = 'ADD_CONTRACTOR_LABEL_SUCCESS';
export const ADD_CONTRACTOR_LABEL_FAILED = 'ADD_CONTRACTOR_LABEL_FAILED';

export const EDIT_CONTRACTOR_LABEL_REQUESTED = 'EDIT_CONTRACTOR_LABEL_REQUESTED';
export const EDIT_CONTRACTOR_LABEL_SUCCESS = 'EDIT_CONTRACTOR_LABEL_SUCCESS';
export const EDIT_CONTRACTOR_LABEL_FAILED = 'EDIT_CONTRACTOR_LABEL_FAILED';

export const DELETE_CONTRACTOR_LABEL_REQUESTED = 'DELETE_CONTRACTOR_LABEL_REQUESTED';
export const DELETE_CONTRACTOR_LABEL_SUCCESS = 'DELETE_CONTRACTOR_LABEL_SUCCESS';
export const DELETE_CONTRACTOR_LABEL_FAILED = 'DELETE_CONTRACTOR_LABEL_FAILED';

export const BIND_CONTRACTOR_LABEL_REQUESTED = 'BIND_CONTRACTOR_LABEL_REQUESTED';
export const BIND_CONTRACTOR_LABEL_SUCCESS = 'BIND_CONTRACTOR_LABEL_SUCCESS';
export const BIND_CONTRACTOR_LABEL_FAILED = 'BIND_CONTRACTOR_LABEL_FAILED';
export const MASS_BIND_CONTRACTOR_LABEL_SUCCESS = 'MASS_BIND_CONTRACTOR_LABEL_SUCCESS';

export const REMOVE_CONTRACTOR_LABEL_REQUESTED = 'REMOVE_CONTRACTOR_LABEL_REQUESTED';
export const REMOVE_CONTRACTOR_LABEL_SUCCESS = 'REMOVE_CONTRACTOR_LABEL_SUCCESS';
export const REMOVE_CONTRACTOR_LABEL_FAILED = 'REMOVE_CONTRACTOR_LABEL_FAILED';
