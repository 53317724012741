import React, {useEffect, useState} from 'react';
import {currencyConverterConvert} from "../../../../store/reducers/currency/currencyReducer";
import {FloatingLabel, InputGroup} from "react-bootstrap";
import Cleave from "cleave.js/react";
import SelectCurrency from "../../../item/components/SelectCurrency";
const InputGroupWithCurrency = ({
                                    defaultPrice,
                                    defaultCurrencyId,
                                    constantDefaultPrice,
                                    defaultBulkPrice,
                                    onChangeBulkPrice,
                                    allowBulkPrice,
                                    onChangePrice,
                                    onChangeCurrency,
                                    onKeyPress,
                                    inputProps,
                                    inputGroupProps,
                                    autoFocus,
                                    placeholder,
                                    ...props
                                }) => {

    const [price, setPrice] = useState('');
    const [rememberPrice, setRememberPrice] = useState('');
    const [rememberCurrencyId, setRememberCurrencyId] = useState('');
    const [constantPrice, setConstantPrice] = useState('');

    useEffect(() => {
        if (defaultPrice !== price)
            setPrice(defaultPrice);
    }, [defaultPrice])

    useEffect(() => {
        if (constantPrice !== constantDefaultPrice) {
            setConstantPrice(constantDefaultPrice)
        }
        if (constantPrice !== constantDefaultPrice || rememberCurrencyId === defaultCurrencyId) {
            setRememberPrice(constantDefaultPrice)
            setRememberCurrencyId(defaultCurrencyId)
        }
    },[defaultCurrencyId, constantDefaultPrice])

    const onChangeCurrencyHandler = (oldCurrency, newCurrency) => {
        onChangeCurrency(newCurrency);
        if (oldCurrency && oldCurrency.id !== newCurrency.id) {
            currencyConverterConvert({
                currency_id_from: +oldCurrency.id,
                value_from: +price,
                currency_id_to: +newCurrency.id
            })
                .then(({currency_to: {value}}) => {
                    if (rememberCurrencyId === newCurrency?.id) {
                        setPrice(rememberPrice?.toString())
                        onChangePrice(rememberPrice?.toString());
                    } else {
                        setPrice(value?.toString())
                        onChangePrice(value?.toString());
                    }
                })
                .catch(() => {
                })
        }
    };

    return (
        <InputGroup {...inputGroupProps}>
            <FloatingLabel label={placeholder}>
                <Cleave value={price}
                        onChange={(e) => {
                            setPrice(e.target.rawValue);
                            onChangePrice(e.target.rawValue);
                        }}
                        className={'border-right-0'}
                        onKeyPress={(e) => {
                            onKeyPress && onKeyPress(e)
                        }}
                        {...inputProps}
                        onFocus={(e) => e.target.select()}
                        autoFocus={autoFocus}
                        onBlur={() => {
                            setRememberPrice(price)
                            setRememberCurrencyId(defaultCurrencyId)
                        }}
                />
            </FloatingLabel>
            <SelectCurrency left={props.left} right={props.right} defaultCurrencyId={defaultCurrencyId}
                            onChange={onChangeCurrencyHandler} selectProps={props}
            />
        </InputGroup>
    );
};

export default InputGroupWithCurrency;
