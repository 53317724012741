import {useState} from "react";
import {useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {Form} from "react-bootstrap";
import RevisionForm from "./RevisionForm";
import {
    revisionAddItemAsync,
    revisionChangeEmployeeAsync,
    revisionUpdateNoteAsync
} from "../../../../store/reducers/warehouse/revisionReducer";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {selectDefaultSetting} from "../../../../store/reducers/settings/settingsReducer";
import toast from "react-hot-toast";


const EditRevisionForm = ({revision}) => {
    const [loading, setLoading] = useState({
        addOrEditRevisionAndContinue: false,
        addOrEditRevisionAndClose: false
    })
    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang)
    const t = Translate

    const settings = useSelector(selectDefaultSetting)

    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }

    const form = useForm({
        defaultValues: {
            employee_id: revision?.employee?.id,
            date: dayjs(revision?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: revision?.note,
            number: revision?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
        }
    })


    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            marks: [],
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
        })
    }


    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        if ((buttonType === "addOrEditRevisionAndContinue" || buttonType === 'addOrEditRevisionAndClose') || (event === "addOrEditRevisionAndContinue" || event === "addOrEditRevisionClose")) {
            if (buttonType === "addOrEditRevisionAndContinue" || event === "addOrEditRevisionAndContinue") {
                setLoading({...loading, addOrEditRevisionAndContinue: true})
            }else {
                setLoading({...loading, addOrEditRevisionAndClose: true})
            }
            await revisionAddItemAsync({
                revisionId: revision?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error?.response?.data?.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    if (buttonType === "addOrEditRevisionAndClose" || event === "addOrEditRevisionClose") {
                        setLoading({...loading, addOrEditRevisionAndClose: false})
                        onHide()
                    }else {
                        setLoading({...loading, addOrEditRevisionAndContinue: false})
                    }
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || revision?.employee) {
            if (formData?.employee_id !== revision?.employee?.id) {
                revisionChangeEmployeeAsync({revisionId: revision?.id, employeeId: formData?.employee_id})
                    .then(() => {
                    })
                    .catch(() => {
                    })
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== revision?.note) {
            revisionUpdateNoteAsync({revisionId: revision?.id, note: formData?.note})
                .then(({data}) => {
                })
                .catch(() => {
                })
        }
    }


    return (
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <RevisionForm
                        revision={revision}
                        loading={loading}
                        addItem={form.handleSubmit(onSubmit)}
                        show={show}
                        onShow={onShow}
                        onHide={onHide}
                    />
                </Form>
            </FormProvider>
        </>
    )
}

export default EditRevisionForm;
