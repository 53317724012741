import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import itemWrapper from "../../../enum/item/itemWrapper";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Card, Dropdown, Modal} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import PrintItemBarcode from "./PrintItemBarcode";

const PrintItemDropdownItem = ({item, price}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const currencies = useSelector(selectCurrency);
    const [show, setShow] = useState(false)

    const defaultCurrency = itemWrapper.getCurrency(currencies, price?.common_price?.currency?.global_currency_id);

    const handlerShow = () => {
        setShow(true)
    }

    const handlerClose = () => {
        setShow(false)
    }

    return (
        <Fragment>
            <Dropdown.Item  className="border-bottom align-center" onClick={handlerShow}>
                <span>{t(lang, "items.print.barcode.modal.title")}</span>
            </Dropdown.Item>

            <Modal className={'davr-modal'} show={show} onHide={handlerClose} size="xl">
                <Modal.Header>
                    <Modal.Title>
                        {t(lang, "items.print.barcode.modal.title")}
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                  <Card className={'p-1'}>
                      <PrintItemBarcode defaultPrice={price?.common_price?.amount} itemName={item.name} defaultBarcode={item.barcodes[0]}
                                        defaultCurrency={defaultCurrency} sku={item.sku}/>
                  </Card>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default PrintItemDropdownItem;
