import React, {Fragment, useEffect, useState} from 'react';
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {selectCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import {selectCurrency} from "../../../../store/reducers/currency/currencyReducer";
import {Translate} from "../../../../lang/lang";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {
    saleApproveAsync,
    saleChangeExactDiscountsAsync,
    saleChangePercentDiscountAsync, salePaymentRegisterAsync, salePaymentUpdateAsync
} from "../../../../store/reducers/warehouse/saleReducer";
import toast from "react-hot-toast";
import {Card, Col, Row, Table, Dropdown} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {Link, useNavigate} from "react-router-dom";
import IconButton from "../../../IconButton";
import IconEditPencil from "../../../../assets/icon/IconEditPencil";
import PrintSaleDropdown from "./PrintSaleDropdown";
import DownloadSaleDropdown from "./DownloadSaleDropdown";
import classNames from "classnames";

import {
    calcDiscount, discountCheckReturnLabel,
    isApprovedCheckedWarehouseOperation
} from "../../../../enum/warehouse-operation/warehouseOperation";
import {
    PaymentTypeBankTransferCode,
    PaymentTypeBankTransferText,
    PaymentTypeCashCode,
    PaymentTypeCashText,
    PaymentTypeClickCode,
    PaymentTypeClickText,
    PaymentTypeHumoCode,
    PaymentTypeHumoText, PaymentTypePaymeCode,
    PaymentTypePaymeText,
    PaymentTypeUzCardCode,
    PaymentTypeUzCardText, PaymentTypeVisaCode, PaymentTypeVisaText
} from "../../../../enum/ContractorWrapper";
import numeral from "numeral";
import dayjs from "dayjs";
import WarehouseOperationImageModal from "../WarehouseOperationImageModal";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import WarehouseOperationFooterCurrencyTotalSum from "../WarehouseOperationFooterCurrencyTotalSum";
import {useAppContext} from "../../../../pheonix/providers/AppProvider";
import {faFileExcel, faFilePdf} from "@fortawesome/free-solid-svg-icons";


const CustomDropdown = ({ title, children }) => {
    return (
        <Dropdown drop="down-centered">
            <Dropdown.Toggle as={"span"} className={"btn-reveal dropdown-caret-none transition-none"}>
                {title}
            </Dropdown.Toggle>
            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    );
};

const Sale = ({sale}) => {

    const [activeTab, setActiveTab] = useState("all")
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const activeOrganization = useSelector(selectActiveOrganization);
    const [dataItems, setDataItems] = useState(sale?.items?.filter(f => !f.is_deleted));
    const warehouses = useSelector(selectWarehouses);
    const items = useSelector(selectItems);
    const navigate = useNavigate();
    useEffect(() => {
        if (sale?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])

    useEffect(() => {
        if (activeTab === "all") {
            setDataItems(sale?.items?.filter(f => !f?.is_deleted))
        } else {
            setDataItems(sale?.items?.filter(f => f?.is_deleted))
        }
    },[activeTab])

    const onSubmitPay = async (pay) => {
        const areLengthsEqual = pay?.exactDiscounts?.length !== sale?.exact_discounts?.length;

        const areEqual = pay?.exactDiscounts?.every((p, i) =>
            p?.amount !== sale?.exact_discounts[i]?.amount &&
            p?.currency_id === sale?.exact_discounts[i]?.currency?.id
        );



        if (pay?.percentDiscount !== sale?.percent_discount) {
            await saleChangePercentDiscountAsync({saleId: sale?.id, percentDiscount: pay?.percentDiscount})
                .then(() => {})
                .catch(() => {})
        }
        if (areLengthsEqual || areEqual) {
            await saleChangeExactDiscountsAsync({saleId: sale?.id, exactDiscounts: pay?.exactDiscounts})
                .then(() => {})
                .catch(() => {})
        }

        if (sale?.payment) {
            await salePaymentUpdateAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!sale?.payment && sale?.is_approved) {
            await salePaymentRegisterAsync({saleId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!sale?.is_approved) {
            await saleApproveAsync({saleId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    const {
        config: {theme},
    } = useAppContext();

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }

    useEffect(() => {
        window.addEventListener('scroll',() => scrollEvent('warehouse-sale-register'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-sale-register'))
    }, []);

    return (
        <Fragment>
            <Row id={'warehouse-sale-register'} className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.dashboard.items.sale_title")} : № {sale?.number}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <CustomDropdown title={
                        <IconButton
                            variant="falcon-secondary"
                            className="btn-white-davr d-flex gap-2 align-items-center"
                            icon={faFilePdf}
                            iconClassName={"fs-7 text-primary-davr align-middle"}
                        >
                            {t(lang,"edi.contract_formation.view.download_pdf")}
                        </IconButton>
                    }>
                        <DownloadSaleDropdown saleId={sale?.id} saleNumber={sale?.number}/>
                    </CustomDropdown>
                    <CustomDropdown title={
                        <IconButton
                            variant="falcon-secondary"
                            className="btn-white-davr d-flex gap-2 align-items-center"
                            icon={faFileExcel}
                            iconClassName={"fs-7 text-primary-davr align-middle"}
                        >
                            {t(lang,"warehouse.operation.item.common.print")}
                        </IconButton>
                    }>
                        <PrintSaleDropdown saleId={sale?.id} />
                    </CustomDropdown>
                    <IconButton
                        variant="falcon-secondary"
                        as={Link}
                        to={`/operation/sales/update/${sale?.id}`}
                        className="btn-white-davr d-flex gap-2 align-items-center"
                    >
                        <IconEditPencil />
                        {t(lang,"warehouse.operation.item.common.edit")}
                    </IconButton>
                </Col>
            </Row>

            <Card className={'p-1'}>
                <Card.Footer as={Row} className={'border-0 py-1'}>
                    <Col xs={12} md={6} lg={4} className={'px-1'}>
                        <div className={classNames({
                            'bg-card': theme !== 'dark',
                            'border': theme === 'dark',
                        },'p-2 rounded-2')}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, 'admin.organization.view.active_sessions.session.created_at')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{sale?.date}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, 'warehouse.operation.item.common.sale_status')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{t(lang, isApprovedCheckedWarehouseOperation(sale?.is_approved))}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, 'partners.common.sales.client')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{sale?.contractor?.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, 'Сотрудник')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{sale?.employee?.name}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, 'edi.contractor_payment.datatable.note')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-block h-100'}>{sale?.note}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4} className={'px-1'}>
                        <div className={classNames({
                            'bg-card': theme !== 'dark',
                            'border': theme === 'dark',
                        },'p-2 rounded-2')}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeCashText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeCashCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeCashCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeUzCardText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeUzCardCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeUzCardCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeHumoText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeHumoCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeHumoCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeBankTransferText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeBankTransferCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeBankTransferCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeClickText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeClickCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeClickCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={12} lg={4} className={'px-1'}>
                        <div className={classNames({
                            'bg-card': theme !== 'dark',
                            'border': theme === 'dark',
                        },'p-2 rounded-2')}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypePaymeText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypePaymeCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypePaymeCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, PaymentTypeVisaText)}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{numeral['formats'][numberFormat].format(sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeVisaCode)?.amount || 0)}</span>
                                        <span>{sale?.payment?.cash_box_states?.find(i => i.payment_type === PaymentTypeVisaCode)?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text text-truncate d-block w-100'}>{t(lang, "warehouse.items.history.operation.discount")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text w-100 text-truncate d-flex gap-2 h-100'}>
                                        <span>{sale?.exact_discounts.length ? numeral['formats'][numberFormat].format(sale?.exact_discounts[0]?.amount) : 0}</span>
                                        <span>{sale?.exact_discounts[0]?.currency?.name}</span>
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>

            <h4 className={'text-center my-2'}>{t(lang, "warehouse.operation.item.common.list.register")}</h4>

            <Card className={'p-1'}>
                <Card.Body className={'pb-1 pt-0 px-0'}>
                    <div className={'border border-1 rounded-3 overflow-hidden'}>
                        <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
                            <thead className={classNames({
                                'bg-card': theme !== 'dark',
                            },'text-dark border-bottom second-text text-center')}>
                            <tr>
                                <th className="fw-semi-bold align-middle">№</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "Скидка")}</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "Цена со скидка")}</th>
                                <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                                {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                                    <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                                }
                                {warehouses?.length > 1 &&
                                    <th className="text-end">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                                }
                                {dataItems?.some(d => d?.is_deleted) &&
                                    <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {groupByItemIdAndPrice(dataItems)?.map((item, rowIndex) => {
                                return (
                                    <React.Fragment key={rowIndex}>
                                        {item?.sub?.map((subItem, subRowIndex) => {
                                            return (
                                                <tr key={subItem.id}>
                                                    <td key={rowIndex}
                                                        className={subRowIndex === 0 ? "text-start" : "d-none"}
                                                        rowSpan={item?.sub?.length}
                                                        style={{width: "50px"}}
                                                    >
                                                        {groupByItemIdAndPrice(dataItems)?.length - rowIndex}
                                                    </td>
                                                    <td
                                                        className={subRowIndex === 0 ? "text-start" : "d-none"}
                                                        rowSpan={item?.sub?.length}>
                                                        {subItem?.warehouse_item?.name}
                                                    </td>
                                                    <th className="text-end">
                                                        {subItem?.quantity}
                                                    </th>
                                                    <th className="text-end text-nowrap">
                                                        {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                        <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </th>
                                                    <th className="text-end text-nowrap">
                                                        {numeral.formats[numberFormat].format(subItem?.discount?.value || 0)}
                                                        <span className="text-info"> {discountCheckReturnLabel(subItem?.discount?.type) || subItem?.price?.currency?.name}</span>
                                                    </th>
                                                    <td className="text-end text-nowrap">
                                                        {numeral.formats[numberFormat].format(calcDiscount({
                                                            type: subItem?.discount?.type || 0,
                                                            discountAmount: subItem?.discount?.value || 0,
                                                            priceAmount: subItem?.price?.amount,
                                                            quantity: subItem?.quantity,
                                                        }))}
                                                        <span className="text-info ms-1">{subItem?.price?.currency?.name}</span>
                                                    </td>
                                                    <th className="text-end text-nowrap">
                                                        {numeral.formats[numberFormat].format(subItem?.net_price?.amount)}
                                                        <span className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </th>
                                                    {groupByItemIdAndPrice(dataItems)[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                                        <td className="text-center">
                                                            {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                            <span> => </span>
                                                            {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                        </td>
                                                    }
                                                    {warehouses?.length > 1 &&
                                                        <th className="text-end">
                                                            {subItem?.warehouse_item?.warehouse?.name}
                                                        </th>
                                                    }
                                                    {subItem?.is_deleted &&
                                                        <>
                                                            <th className="text-nowrap text-center text-danger" style={{width: "150px"}}>
                                                                {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                                            </th>
                                                        </>
                                                    }
                                                </tr>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                            </tbody>
                        </Table>
                        <WarehouseOperationFooterCurrencyTotalSum
                            netPrice={sale?.net_price}
                            pay={sale?.payment}
                        />
                    </div>
                </Card.Body>
            </Card>

        </Fragment>
    );
};

export default Sale;
