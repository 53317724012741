import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    selectSaleCount,
    selectSaleFilterOptions,
    selectSaleItems,
    changeFilterOptionsAsync
} from "../../../../store/reducers/warehouse/saleReducer";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {
    isApprovedCheckedWarehouseOperation,
    ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId, ItemsTotalSumDebtsGroupPaymentTypeCurrencyId,
    ItemsTotalSumGroupCurrencyId, OperationTotalSum
} from "../../../../enum/warehouse-operation/warehouseOperation";
import dayjs from "dayjs";
import {Card, Dropdown, NavDropdown, OverlayTrigger, Popover} from "react-bootstrap";
import Badge from "../../../common/Badge";
import numeral from "numeral";
import AdvanceTableWrapper from "../../../table/AdvanceTableWrapper";
import WarehouseTabs from "../../WarehouseTabs";
import AdvanceTable from "../../../table/AdvanceTable";
import IconCurrencyInfo from "../../../../assets/icon/IconCurrencyInfo";
import RevealDropdown, {RevealDropdownTrigger} from "../../../common/RevealDropdown";
import {Link} from "react-router-dom";
import SaleDelete from "./SaleDelete";
import AdvanceTableCustomPagination from "../../../table/AdvanceTableCustomPagination";
import SaleDatatableHeader from "./SaleDatatableHeader";
import DownloadSaleDropdown from "./DownloadSaleDropdown";
import PrintSaleDropdown from "./PrintSaleDropdown";
import classNames from "classnames";

const SaleDatatable = ({predefinedFilters}) => {

    const saleItems = useSelector(selectSaleItems)
    const numberFormat = useSelector(selectNumberFormat);
    const filters = useSelector(selectSaleFilterOptions)
    const dispatch = useDispatch()
    const count = useSelector(selectSaleCount)
    const lang = useSelector(selectLang)
    const t = Translate

    const totalSumArray = ItemsTotalSumGroupCurrencyId(saleItems)
    const totalSumCashBoxPaymentType = ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId(saleItems)
    const totalSumDebtsPaymentType = ItemsTotalSumDebtsGroupPaymentTypeCurrencyId(saleItems)

    const debtTypeCurrency = totalSumDebtsPaymentType.map(x => {
        return {
            id: x.currency_id,
            name: x.name,
            totalSum: x.totalSum
        }
    })

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{dayjs(original?.date).format("YYYY-MM-DD")}</span>
                        {/*<span>{dayjs(original?.date).format("HH:mm:ss")}</span>*/}
                    </div>
                )
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "warehouse.operation.item.common.client"),
            cellProps: {
                className: 'px-2'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div
                        title={original?.contractor?.name}
                        className="text-truncate"
                        style={{maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >
                        {original?.contractor?.name}
                    </div>
                )
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Продавец"),
            cellProps: {className: 'px-2'},
            Cell: ({row: {original}}) => {
                return (
                    <div
                        title={original?.account?.name || original?.account?.username}
                        className="text-truncate"
                        style={{maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >
                        <span>{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'employee',
            Header: t(lang, "Сотрудник"),
            cellProps: {className: 'px-2'},
            Cell: ({row: {original}}) => {
                return (
                    <div title={original?.employee?.name}
                         className="text-truncate"
                         style={{maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >
                        <span>{original?.employee?.name}</span>
                    </div>
                );
            },
        },
        {
            Header: t(lang, "warehouse.operation.item.common.debt_state"),
            headerProps: {
                className: "text-end px-2"
            },
            cellProps: {
                className: "text-end px-2"
            },

            Cell: ({row: {original}}) => {
                const debtTypeCurrency = []

                // debt calc
                {
                    original?.net_price?.forEach(price => {
                        if (price?.currency?.id) {
                            const foundDebtState = original?.payment?.debt_states?.find(debtState => debtState.currency.id === price?.currency?.id);
                            const totalSum = foundDebtState ? price?.amount - foundDebtState.amount : price?.amount;

                            debtTypeCurrency.push({
                                id: price?.currency?.id,
                                name: price?.currency?.name,
                                totalSum: totalSum
                            });
                        }
                    });

                    original?.payment?.debt_states?.forEach(debt => {
                        if (debt.currency.id) {
                            const foundSeparationCurrency = original?.net_price?.find(price => price?.currency?.id === debt.currency.id);
                            const totalSum = foundSeparationCurrency ? debt.amount - foundSeparationCurrency.totalSum : debt.amount;

                            if (!foundSeparationCurrency) {
                                debtTypeCurrency.push({
                                    id: debt.currency.id,
                                    name: debt.currency.name,
                                    totalSum: totalSum
                                });
                            }
                        }
                    });
                }


                if (debtTypeCurrency?.length) {
                    return (
                        debtTypeCurrency?.map((x, index) => {
                            if (x.totalSum) {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        <span
                                            className={'d-flex text-end mb-1 gap-2 justify-content-end align-items-end'}>
                                            <strong
                                                className={'fs-8'}>{numeral.formats[numberFormat].format(x?.totalSum)}</strong>
                                            <small>{x?.name}</small>
                                        </span>
                                    </div>
                                )
                            }
                        })
                    )
                }
                return ''
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.total_sum"),
            headerProps: {
                className: "text-end px-2"
            },
            cellProps: {
                className: "text-end px-2"
            },
            Cell: ({row: {original}}) => {
                const operationTotalSum = OperationTotalSum(original?.items?.filter(f => !f?.is_deleted))


                return operationTotalSum?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <span className={'text-end mb-1 d-flex gap-2 justify-content-end align-items-center'}>
                                <strong className={'fs-8'}>{numeral.formats[numberFormat].format(item?.amount)}</strong>
                                <small>{item?.currency?.name}</small>
                                 <IconCurrencyInfo/>
                            </span>
                        </div>
                    )
                })
            }
        },
        {
            Header: "Статус",
            id: "status",
            filterable: false,
            headerProps: {className: 'text-end pe-2'},
            cellProps: {className: 'text-end ps-2', width: '35px'},
            Cell: ({row}) => {
                return <>
                    <Badge variant={"phoenix"}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                           bg={row?.original?.is_approved ? 'success-davr' : 'warning-davr'}
                    >
                        {t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}
                    </Badge>
                </>
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'up'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item as={Link} to={`/operation/sales/update/${original?.id}`}>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>
                            <SaleDelete id={original?.id}/>
                            <Dropdown.Item as={Link}
                                           to={`/operation/sales/view/${original?.id}`}>
                                <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                            </Dropdown.Item>
                            <NavDropdown title={
                                <span className={"dropdown-item"}>{t(lang, "warehouse.operation.item.common.download")}</span>
                            } id="nested-dropdown" drop={"end"}>
                                <DownloadSaleDropdown className={'mx-1'} saleId={original?.id}
                                                      saleNumber={original?.number}/>
                            </NavDropdown>
                            <NavDropdown title={
                                <span
                                    className={"dropdown-item"}>{t(lang, "warehouse.operation.item.common.print")}</span>
                            } id={"nested-dropdown"} drop={"end"}
                            >
                                <PrintSaleDropdown saleId={original?.id}/>
                            </NavDropdown>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]

    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={saleItems}
                pagination
                perPage={1000}
                selection
                sortable
            >
                <SaleDatatableHeader predefinedFilters={predefinedFilters} table/>
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <WarehouseTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            // isLoading={loading}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>
        </Fragment>
    );
};

export default SaleDatatable;
