import React, {useMemo, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectWarehouses} from "../../../store/reducers/warehouse/warehouseReducer";
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import {Card, Dropdown} from "react-bootstrap";
import WarehousesDataTableHeader from "./WarehousesDataTableHeader";
import AdvanceTable from "../../../components/table/AdvanceTable";
import WarehousesTabs from "./WarehousesTabs";
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import WarehousesAddAndEditModal from "./WarehousesAddAndEditModal";

const WarehousesDataTable = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses)

    const [warehouseItem, setWarehouseItem] = useState({});
    const [show, setShow] = useState(false);
    const [filters, setFilters] = useState({
        filter: false,
        name: ''
    })

    const columns = useMemo(() => [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${row.index + 1}`;
            }
        },
        {
            accessor: 'name',
            Header: t(lang, "warehouse.data.table.warehouse.name"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.name}</strong>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.data.table.created.date"),
            Cell: ({row: {original}}) => {
                return (
                    <span>{original?.created_at}</span>
                )
            }
        },
        {
            accessor: 'updated_at',
            Header: t(lang, "warehouse.data.table.updated.date"),
            Cell: ({row: {original}}) => {
                return (
                    <span>{original?.updated_at}</span>
                )
            }
        },
        {
            accessor: 'none',
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                onClick={() => {
                                    setWarehouseItem(original)
                                    setShow(true)
                                }}
                                >
                                <span>{t(lang, "edi.common.edit_button")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                )
            },
        },
    ], [])

    const data = useMemo(() => {
        if (!filters.filter) return warehouses;
        return warehouses.filter(warehouse => warehouse?.name?.toUpperCase().includes(filters.name.toUpperCase()))
    }, [filters, warehouses])

    const handleClose = () => {
        setShow(false);
        setWarehouseItem({})
    }

    return (
        <AdvanceTableWrapper columns={columns}
                             data={data}
                             pagination
                             selection
                             width={'50px'}
                             perPage={1000}
        >
            <WarehousesDataTableHeader warehouses={warehouses} setFilters={setFilters} filters={filters} setShow={setShow}/>
            <Card className={'p-0 rounded-4'}>
                <Card.Body className={'p-0'}>
                    <WarehousesTabs/>
                    <AdvanceTable
                        table
                        headerClassName="text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            className: 'fs--1 mb-0'
                        }}
                    />
                </Card.Body>
            </Card>

            <WarehousesAddAndEditModal show={show} handleClose={handleClose} warehouseItem={warehouseItem} />
        </AdvanceTableWrapper>
    );
};

export default WarehousesDataTable;
