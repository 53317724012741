import axios from "axios";

class ReturnShipmentService {
    registerReturnShipment(payload) {
        return axios.post('/return-shipment/customer/register', payload)
    }

    printReturnShipment(id) {
        return axios.get(`/pdf/return-shipment/${id}`, {
            responseType: "blob"
        })
    }

    getReturnShipments(params) {
        return axios.get('/return-shipment/get', {
            params: params
        })
    }

    getCountReturnShipments(params) {
        return axios.get('/return-shipment/count', {
            params: params
        })
    }

    getReturnShipment(id) {
        return axios.get(`/return-shipment/get/${id}`)
    }

    deleteReturnShipment({id, comment}) {
        return axios.post(`return-shipment/customer/delete/${id}`, {
            comment: comment
        })
    }

    returnShipmentCustomerApprove({id, comment}) {
        return axios.post(`/return-shipment/customer/approve/${id}`, {
            comment: comment
        })
    }

    returnShipmentExecutorApprove({id, comment}) {
        return axios.post(`/return-shipment/executor/approve/${id}`, {
            comment: comment
        })
    }


    uploadReturnShipmentToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadReturnShipmentsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadReturnShipmentsWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const returnShipmentService = new ReturnShipmentService();

export default returnShipmentService
