import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectCertificates} from "../../store/reducers/eimzo/eimzoReducer";
import {selectDateDayJSFormat} from "../../store/reducers/main/main";
import {CertificatePfx} from "../../plugins/eimzo";
import ReactSelect from 'react-select'
import classNames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const formatOptionLabel = format => {
    return certificateInto => {
        const validFrom = dayjs(certificateInto.validFromDate)
        const validEndDate = dayjs(certificateInto.validEndDate)

        return (
            <div className={`d-flex flex-column`}>
                <div className=" notification-body fs--1">
                    {certificateInto.innNumber && <p className={"d-flex mb-0"}><b className={"me-2"}>ИНН:</b>{certificateInto.innNumber}</p>}
                    {certificateInto.pinfl && <p className={"d-flex mb-0"}><b className={"me-2"}>ПИНФЛ:</b>{certificateInto.pinfl}</p>}
                    <p className={"d-flex mb-0 text-uppercase"}><b className={"me-2"}>Ф.И.О:</b>{certificateInto.issuedPerson}</p>
                    {certificateInto.companyName && <p className={"d-flex mb-0"}><b className={"me-2"}>
                        Организация:</b><span className="text-uppercase">{certificateInto.companyName}</span>
                    </p>}
                </div>
                <span className="notification-times fs--1">
                    <p className={classNames('mb-0', {
                        'text-danger': certificateInto.isExpired
                    })}>От {validFrom.format(format)} До {validEndDate.format(format)} </p>
                </span>
            </div>
        )
    };
}

const SelectCertificate = ({onChange, auth, ...props}) => {
    const certificates = useSelector(selectCertificates);
    const [options, setOptions] = useState([]);
    const dateDayJSFormat = useSelector(selectDateDayJSFormat)
    const today = new Date();

    useEffect(() => {
        setOptions(certificates.map(certificate => {
            console.log(certificate)
            const pfxCertificate = new CertificatePfx(certificate);
            return {
                innNumber: pfxCertificate.innNumber,
                pinfl: pfxCertificate.pinfl,
                companyName: pfxCertificate.companyName,
                issuedPerson: pfxCertificate.issuedPerson,
                validEndDate: pfxCertificate.validEndDate,
                validFromDate: pfxCertificate.validFromDate,
                value: `${certificate.disk}${certificate.alias}`,
                isDisabled: pfxCertificate.validEndDate.getTime() <= today.getTime(),
                isExpired: pfxCertificate.validEndDate.getTime() <= today.getTime()
            };
        }))
    }, [certificates]);

    const onOptionChange = option => {
        const certificate = certificates.find(certificate => `${certificate.disk}${certificate.alias}` === option?.value) || null;
        onChange(certificate);
    }

    return (
        <div className={auth ? "" : "select-container"}>
            <CustomFloatingLabelSelect className={auth ? "" : "select w-100"} options={options} onChange={onOptionChange} {...props} formatOptionLabel={formatOptionLabel(dateDayJSFormat)}/>
        </div>
    );
};

SelectCertificate.propTypes = {
    onChange: PropTypes.func,
    isInvalid: PropTypes.bool
};

SelectCertificate.defaultProps = {
    onChange: () => {
    },
    isInvalid: false
}

export default SelectCertificate;
