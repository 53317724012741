import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Card, Collapse, Row, Form, Col, Button} from "react-bootstrap";
import WarehouseOperationSelectStatus from "../WarehouseOperationSelectStatus";
import ContractorSelect from "../../../contractor/ContractorSelect";
import DatePickerComponent from "../../../common/DatePickerComponent";
import WarehouseOperationWarehouseSelect from "../WarehouseOperationWarehouseSelect";
import {addDays} from "date-fns";

const DatatableHeaderFilter = ({name, contractor, predefinedFilters, isOpenFilterCollapse}) => {

    const dateFormat = useSelector(selectDateFormat);
    const {control} = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div>
                    <Card className={'p-1'}>
                        <Row className="g-3">
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field}) => (
                                        <WarehouseOperationSelectStatus
                                            placeholder={t(lang, "warehouse.operation.item.common.sale_status")}
                                            defaultValue={field.value}
                                            onChange={data => field.onChange(data.value)}
                                        />
                                    )}
                                />
                            </Form.Group>

                            {
                                contractor && <Form.Group as={Col} md={4} xl={3}>
                                    <Controller
                                        name="contractor_id"
                                        control={control}
                                        render={({field}) => {
                                            return (
                                                <ContractorSelect
                                                    placeholder={t(lang, "warehouse.operation.item.common.client")}
                                                    defaultValue={field?.value}
                                                    isClearable={true}
                                                    isDisabled={predefinedFilters?.hasOwnProperty('contractor_id')}
                                                    onChange={(option) => {
                                                        field.onChange(option ? option?.id : null)
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                </Form.Group>
                            }
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="date_time_start"
                                    control={control}
                                    render={({field}) => (
                                        <DatePickerComponent
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date_start")}
                                            dateFormat={`${dateFormat} HH:mm`}
                                            field={field}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                            isClearable

                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="date_time_end"
                                    control={control}
                                    render={({field}) => (
                                        <DatePickerComponent
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date_end")}
                                            dateFormat={`${dateFormat} HH:mm`}
                                            field={field}
                                            maxDate={addDays(new Date(), 5)}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                            isClearable

                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller name={'warehouse_id'} render={({field}) => (
                                    <WarehouseOperationWarehouseSelect
                                        placeholder={t(lang, "warehouse.navbar.routes.warehouse")}
                                        onChange={field.onChange}
                                        isClearable
                                    />
                                )} />
                            </Form.Group>
                            <Form.Group as={Col} md={9}>
                                <Button size={'sm'}  form={'sale'} type="submit" className={'float-end btn-primary-davr'}>
                                    {t(lang, 'warehouse.operation.item.common.show')}
                                </Button>
                            </Form.Group>
                        </Row>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default DatatableHeaderFilter;
