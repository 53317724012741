import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import {selectLang} from "../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import {addEmployeeAsync} from "../../store/reducers/employee/employeeReducer";
import toast from "react-hot-toast";
import IconButton from "../IconButton";
import {Button, Form, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faSpinner} from "@fortawesome/free-solid-svg-icons";
import EmployeeForm from "./EmployeeForm";

const AddEmployeeModal = ({noName}) => {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const methods = useForm({
        defaultValues: {
            name: "",
        },
    });

    const handleShow = () => setShow(true);
    const handleClose = () => {
        methods.reset();
        setShow(false);
    };

    const onSubmit = formData => {
        setLoading(true);

        addEmployeeAsync({name: formData.name})
            .then(() => {
                toast.success(t(lang, 'employee.add_employee_modal.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'employee.add_employee_modal.toast.error')))
            .finally(() => setLoading(false))
    };

    return (
        <Fragment>
            <IconButton className={'bg-transparent'} variant={'phoenix-secondary'} icon={faPlus} onClick={handleShow}>
                {!noName && t(lang, 'main.common.add')}
            </IconButton>
            <Modal show={show} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header closeButton={true}>
                            <Modal.Title>{t(lang, 'employee.add_employee_modal.title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeeForm/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="phoenix-secondary"
                                className={'btn-davr'}
                                onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                            <Button className={'btn-primary-davr'} variant={'primary'} type={'submit'} disabled={loading}>
                                {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                                {t(lang, 'main.common.add')}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddEmployeeModal;
