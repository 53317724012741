import React, {Fragment} from 'react';
import SimpleBar from "simplebar-react";
import {Spinner, Table} from "react-bootstrap";
import './style.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen, faInbox} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
const AdvanceTable = ({
                                             getTableProps,
                                             headers,
                                             page,
                                             prepareRow,
                                             headerClassName,
                                             isLoading,
                                             rowClassName,
                                             tableProps,
                                             table
                                         }) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <SimpleBar>
                {isLoading && <div className="text-center">
                    <Spinner className="mx-auto" variant="primary" animation="border" role="switch"/>
                </div>}
                {!isLoading && <Table {...getTableProps(tableProps)}>
                    <thead className={headerClassName}>
                    <tr className={'border-top fs-9 second-text px-3'}>
                        {headers?.map((column, index) => {
                            const {key, ...headerProps} = column.getHeaderProps(
                                column.getSortByToggleProps(column.headerProps)
                            );
                            return (
                                <th key={index} {...headerProps}>
                                    {column.render('Header')}
                                    {column.canSort ? (
                                        column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <span className="sort desc"/>
                                            ) : (
                                                <span className="sort asc"/>
                                            )
                                        ) : (
                                            <span className="sort"/>
                                        )
                                    ) : (
                                        ''
                                    )}
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {page?.map((row, i) => {
                        prepareRow(row);
                        const {key, ...rowProps} = row.getRowProps();
                        return (
                            <tr style={{padding: '1rem 0'}} key={i} className={`${rowClassName}`} {...rowProps}>
                                {row.cells.map((cell, index) => {
                                    const {key: cellKey, ...cellProps} = cell.getCellProps(cell.column.cellProps);
                                    return (
                                        <td key={index} {...cellProps} style={{padding: "0"}}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    {headers.some(i => i.totalInfoClassName || i.totalInfo) && (
                        <tr>
                            {headers?.map((col, index) => (
                                <td key={index} style={{padding: "10px 0"}} className={col.totalInfoClassName}>
                                    {col.totalInfo}
                                </td>
                            ))}
                        </tr>
                    )}
                    </tbody>
                </Table>}
            </SimpleBar>
          {/*  {!!!page.length &&
                <div className={'mt-2 d-flex w-100 m-auto align-items-center justify-content-center gap-2'}>
                    <FontAwesomeIcon icon={faInbox} size={'xl'} className={'text-600'}/>
                    <h2 className={'fw-bold text-600 text-uppercase'}>{t(lang, 'data.table.no.data')}</h2>
                </div>}*/}
        </Fragment>
    );
};

export default AdvanceTable;
