import React from 'react';
import {Spinner} from "react-bootstrap";

const SpinnerComponent = () => {
    return (
        <div className={'content'}>
            <Spinner variant={'primary'} className={'position-absolute'} style={{top: '50%', left: '50%'}} animation="border" role="status" />
        </div>
    );
};

export default SpinnerComponent;
