import axios from "axios";


const itemServiceHostName = "item";

class ItemService {

    addItem(payload) {
        return axios.post(`/${itemServiceHostName}/item/create`, payload);
    }

    editItem(id, payload) {
        return axios.post(`/${itemServiceHostName}/item/update/${id}`, payload)
    }

    deleteItem(id) {
        return axios.post(`/${itemServiceHostName}/item/delete/${id}`);
    }

    validateItemCatalogs() {
        return axios.post(`/${itemServiceHostName}/item/validate-items-tasnif-info`)
    }

    validateItemCatalog(id) {
        return axios.post(`/${itemServiceHostName}/item/validate-item-tasnif-info/${id}`)
    }

    getCatalog(code) {
        return axios.get(`/${itemServiceHostName}/productGuide/catalog/get/${code}`)
    }

    getCatalogs(params) {
        return axios.get(`/${itemServiceHostName}/productGuide/catalog/get`, {
            params: params
        })
    }

    getProductGuide(barcode) {
        return axios.get(`${itemServiceHostName}/productGuide/get/${barcode}`);
    }


    // draft item routes
    getDraftItems(params) {
        return axios.get(`/${itemServiceHostName}/draftItem/get`, {
            params: params
        })
    }

    getCountDraftItems(params) {
        return axios.get(`/${itemServiceHostName}/draftItem/count`, {
            params: params
        })
    }

    getDraftItem(id) {
        return axios.get(`/${itemServiceHostName}/draftItem/get/${id}`)
    }

    deleteDraftItem(id) {
        return axios.post(`/${itemServiceHostName}/draftItem/delete/${id}`)
    }

    bindDraftItem(payload) {
        return axios.post(`/${itemServiceHostName}/draftItem/bind`, payload);
    }

    approveDraftItem(payload) {
        return axios.post(`/${itemServiceHostName}/draftItem/approve`, payload);
    }


    // organization settings
    getOrganization() {
        return axios.get(`/${itemServiceHostName}/organization/get`)
    }

    updateOrganization(payload) {
        return axios.post(`/${itemServiceHostName}/organization/update`, payload)
    }

    getCategories(params) {
        return axios.get(`/${itemServiceHostName}/category/get`, {
            params: params
        })
    }

    getCountCategories(params) {
        return axios.get(`/${itemServiceHostName}/category/count`, {
            params: params
        })
    }

    getCategory(id) {
        return axios.get(`/${itemServiceHostName}/category/get/${id}`)
    }

    addCategory(payload) {
        return axios.post(`/${itemServiceHostName}/category/add`, payload)
    }

    editCategory(id, payload) {
        return axios.post(`/${itemServiceHostName}/category/update/${id}`, payload)
    }

    deleteCategory(id) {
        return axios.post(`/${itemServiceHostName}/category/delete/${id}`)
    }

    exportItems(uri, params) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    exportBulkItems(uri, payload) {
        return axios.post(uri, payload, {
            responseType: "arraybuffer",
        })
    }
}

const itemService = new ItemService();
export default itemService;
