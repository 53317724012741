import React, {useState} from 'react';
import {Translate} from "../../../../lang/lang";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";
import {refundDownloadPdfAsync} from "../../../../store/reducers/warehouse/refundReducer";
import printJS from "print-js";
import IconButton from "../../../IconButton";
import {Spinner} from "react-bootstrap";
import IconPrinter from "../../../../assets/icon/IconPrinter";

const RefundPrint = ({refundId, ...props}) => {

    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const onPrint = () => {
        setLoading(true)
        refundDownloadPdfAsync({refundId})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <IconButton {...props} icon="print" disabled={loading} size="sm"
                    className="btn-white-davr d-flex gap-2 align-items-center" transform="shrink-3"
                    variant="phoenix-secondary" onClick={onPrint}>
            {loading ? (
                <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
            ) : <IconPrinter/>}
            <span className="d-none d-sm-inline-block">{t(lang, "warehouse.operation.item.common.print")}</span>
        </IconButton>
    );
};

export default RefundPrint;
