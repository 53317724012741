import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {getOrganizationUsersAsync, removeUserAsync, selectAccount} from "../../../store/reducers/auth/authReducer";
import toast from "react-hot-toast";
import {Button, Dropdown, Modal} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";

const DeleteUser = ({username}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()
    const account = useSelector(selectAccount)
    const [isShow, setIsShow] = useState(false)

    const onDeleteUser = async () => {
        await removeUserAsync({username})
        dispatch(getOrganizationUsersAsync())
        toast.success(t(lang, "edi.common.user_organization_delete"))
        setIsShow(false)
    }

    const handlerClose = () => {
        setIsShow(false)
    }

    const handlerOpen = () => {
        setIsShow(true)
    }

    return (
        <Fragment>
            <Dropdown.Item onClick={handlerOpen}>
                {t(lang, "roaming.invoice.delete")}
            </Dropdown.Item>
            <Modal show={isShow} className={"davr-modal"} onHide={handlerClose}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "edi.common.user_organization_delete_title")}</Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => handlerClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, "edi.common.user_organization_delete_ask")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="phoenix-secondary"
                            onClick={handlerClose}>{t(lang, "edi.common.select_yes_or_no.option.no")}</Button>
                    <Button variant="danger"
                            onClick={onDeleteUser}>{t(lang, "edi.common.select_yes_or_no.option.yes")}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default DeleteUser;
