import React, {Fragment, useState} from 'react';
import {Translate} from "../../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../../store/reducers/main/main";
import {Controller, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {currencyConverterConvert, selectNationalCurrency} from "../../../../../store/reducers/currency/currencyReducer";
import toast from "react-hot-toast";
import {updateWarehouseItemPurchasePriceAsync} from "../../../../../store/reducers/warehouse/warehouseReducer";
import {Button, FloatingLabel, Form, InputGroup, Modal} from "react-bootstrap";
import Cleave from "cleave.js/react";
import IconButton from "../../../../IconButton";
import SelectWarehouse from "../../../../common/SelectWarehouse";
import SelectCurrency from "../../SelectCurrency";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faSpinner} from "@fortawesome/free-solid-svg-icons";
import CleaveWithCommaMark from "../../../../common/CleaveWithCommaMark";
import Badge from "../../../../common/Badge";

const ItemPurchasePricesInput = ({purchasePricesArrayName, placeholder}) => {
    const {fields} = useFieldArray({name: purchasePricesArrayName});
    const nationalCurrency = useSelector(selectNationalCurrency);
    const {setValue} = useFormContext();

    const onPurchasePriceUpdated = (updatePurchaseResponse) => {
        const newItemPurchasePrices = updatePurchaseResponse.warehouse_items.map(warehouseItem => {
            const purchasePrice = {
                itemId: updatePurchaseResponse.id,
                warehouseId: warehouseItem.id,
                warehouseName: warehouseItem.name,
                purchasePrice: {
                    price: 0,
                    currency: {
                        id: nationalCurrency?.id || null,
                        name: nationalCurrency?.name || null,
                    },
                },
            };

            if (warehouseItem.purchase_price) {
                purchasePrice.purchasePrice.price = warehouseItem.purchase_price.price.amount;
                purchasePrice.purchasePrice.currency.id = warehouseItem.purchase_price.price.currency.id;
                purchasePrice.purchasePrice.currency.name = warehouseItem.purchase_price.price.currency.name;
            }

            return purchasePrice;
        });

        setValue(purchasePricesArrayName, newItemPurchasePrices);
    }

    if (fields.length === 0) {
        return <p className={'mb-1'}><Badge bg={'danger'}>Не найдено</Badge></p>
    }

    return (
        <Fragment>
            {fields.map((priceField) => {
                return (
                    <Fragment key={priceField.id}>
                        <ItemPurchasePriceInput placeholder={placeholder} purchasePrice={priceField} onPricesUpdated={onPurchasePriceUpdated}/>
                    </Fragment>
                );
            })}
        </Fragment>
    );
};

const ItemPurchasePriceInput = ({purchasePrice, onPricesUpdated, placeholder}) => {

    const [show, setShow] = useState();
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {getValues, setValue, control, handleSubmit, reset} = useForm({
        defaultValues: {
            purchasePriceItemId: null,
            purchasePriceAmount: null,
            purchasePriceCurrencyId: null,
            purchasePriceWarehouseId: null,
        }
    });

    const handleShow = () => {
        initDefaultValues();
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        reset();
    };

    const initDefaultValues = () => {
        setValue('purchasePriceItemId', purchasePrice.itemId);
        setValue('purchasePriceAmount', purchasePrice.purchasePrice.price);
        setValue('purchasePriceCurrencyId', purchasePrice.purchasePrice.currency.id);
        setValue('purchasePriceWarehouseId', purchasePrice.warehouseId);
    }

    const onChangeCurrencyHandler = async (oldCurrency, newCurrency) => {
        try {
            if (oldCurrency && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: +getValues('purchasePriceAmount'),
                    currency_id_to: newCurrency.id
                });
                setValue('purchasePriceAmount', value ? +value.toFixed(2) : '');
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = formData => {
        const payload = {
            id: formData.purchasePriceItemId,
            warehouse_id: formData.purchasePriceWarehouseId,
            amount: Number(formData.purchasePriceAmount),
            currency_id: formData.purchasePriceCurrencyId,
        };

        setLoading(true);
        updateWarehouseItemPurchasePriceAsync(payload)
            .then(updateResponse => {
                onPricesUpdated(updateResponse);

                toast.success(t(lang, 'items.common.toast.success'));
                handleClose();
            })
            .catch(() => toast.error(t(lang, 'items.common.toast.error')))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <InputGroup className={'mb-2'}>
                <FloatingLabel label={<span className={'d-flex gap-1'}>
                    {placeholder}
                    ({purchasePrice?.warehouseName})
                </span>}>
                    <Cleave
                        placeholder={placeholder}
                        disabled={true}
                        className={'form-control border-right-0'}
                        value={purchasePrice.purchasePrice.price}
                        options={{numeral: true, delimiter: ' ', numeralDecimalScale: 3}}
                    />
                </FloatingLabel>

                <InputGroup.Text>{purchasePrice.purchasePrice.currency.name}</InputGroup.Text>

                <IconButton className={'bg-300'} iconClassName={'text-dark'} variant={'warning'} icon={faPen} onClick={handleShow}/>
            </InputGroup>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton={true}>
                        <Modal.Title>{t(lang, 'items.common.edit_purchase_price_modal.title')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'py-1'}>
                        <Form.Group className={'my-3'}>
                            <SelectWarehouse
                                placeholder={t(lang, 'items.common.warehouse')}
                                selectProps={{isDisabled: true}}
                                defaultWarehouseId={purchasePrice.warehouseId}/>
                        </Form.Group>

                        <Form.Group className={'my-3'}>
                            <Form.Label>{t(lang, 'warehouse.operation.item.common.purchase_price')}</Form.Label>
                            <InputGroup>
                                <FloatingLabel label={t(lang, 'warehouse.operation.item.common.purchase_price')}>
                                    <Controller
                                        control={control}
                                        name={'purchasePriceAmount'}
                                        render={({field}) => (
                                            <CleaveWithCommaMark
                                                placeholder={t(lang, 'warehouse.operation.item.common.purchase_price')}
                                                value={field.value}
                                                onChange={field.onChange}
                                                ref={field.ref}/>
                                        )}
                                    />
                                </FloatingLabel>
                                <Controller
                                    control={control}
                                    name={'purchasePriceCurrencyId'}
                                    render={({field}) => (
                                        <SelectCurrency
                                            defaultCurrencyId={field.value}
                                            onChange={async (oldCurrency, newCurrency) => {
                                                await onChangeCurrencyHandler(oldCurrency, newCurrency);
                                                field.onChange(newCurrency ? newCurrency.id : null);
                                            }}
                                        />
                                    )}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={'secondary'} onClick={handleClose}>{t(lang, 'items.common.cancel')}</Button>
                        <Button variant={'primary'} type={'submit'} disabled={loading}>
                            {loading && <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>}
                            {t(lang, 'items.common.save')}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Fragment>
    );
};

export default React.memo(ItemPurchasePricesInput);
