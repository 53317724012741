import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import {faChevronDown, faPlus} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../IconButton";
import {Dropdown} from "react-bootstrap";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {addWarehouseRoutes} from "../../enum/item/warehouseWarpper";
import IconFilter from "../../assets/icon/IconFilter";
import IconPlus from "../../assets/icon/IconPlus";
import IconStroke from "../../assets/icon/IconStroke";
import IconCaretDown from "../../assets/icon/IconCaretDown";

const WarehouseAddButton = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Dropdown>
            <Dropdown.Toggle
                bsPrefix="toggle"
                className="btn-davr px-3 py-0 bg-primary-davr text-white"
                variant="phoenix-secondary"
                size="sm"
            >
                <div className={'d-flex gap-2 align-items-center justify-content-between'}>
                    <Fragment>
                        <IconPlus/>
                        <span className="dropdown-line d-none d-sm-inline-block fw-middle ms-1 text-white fs-8 text-nowrap fs-md-9 me-2">
                            {t(lang, "items.common.add_new")}
                        </span>
                    </Fragment>
                    <FontAwesomeIcon className={'text-primary-davr text-white ms-2'} icon={faChevronDown}/>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                {addWarehouseRoutes.map((item, index) => (
                    <Dropdown.Item as={Link} className={'cursor-pointer'} to={item.to} key={index}>
                        {t(lang, item.name)}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default WarehouseAddButton;
