import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import {selectDefaultSetting} from "../../../../store/reducers/settings/settingsReducer";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import {
    writeOffAddItemAsync,
    writeOffChangeEmployeeAsync,
    writeOffUpdateNoteAsync
} from "../../../../store/reducers/warehouse/writeOffReducer";
import {Form} from "react-bootstrap";
import WriteOffForm from "./WriteOffForm";

const EditWriteOffForm = ({writeOff}) => {

    const warehouses = useSelector(selectWarehouses)
    const [loading, setLoading] = useState({
        addOrEditWriteOffAndContinue: false,
        addOrEditWriteOffAndClose: false
    })
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)

    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }

    const form = useForm({
        defaultValues: {
            employee_id: writeOff?.employee?.id,
            date: dayjs(writeOff?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: writeOff?.note,
            number: writeOff?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
        }
    })


    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
        })
    }


    const onSubmit = async (formData, event) => {
        const buttonType = event.nativeEvent.submitter.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        if ((buttonType === "addOrEditWriteOffAndContinue" || buttonType === 'addOrEditWriteOffAndClose') || (event === "addOrEditWriteOffAndContinue" || event === "addOrEditWriteOffClose")) {
            if (buttonType === "addOrEditWriteOffAndContinue" || event === "addOrEditWriteOffAndContinue") {
                setLoading(prevState => {
                    return {...prevState, addOrEditWriteOffAndContinue: true}
                })
            }else {
                setLoading(prevState => {
                    return {...prevState, addOrEditWriteOffAndClose: true}
                })
            }
            await writeOffAddItemAsync({
                writeOffId: writeOff?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error?.response?.data?.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    if (buttonType === "addOrEditWriteOffAndContinue" || event === "addOrEditWriteOffAndContinue") {
                        setLoading(prevState => {
                            return {...prevState, addOrEditWriteOffAndContinue: false}
                        })
                    }else {
                        setLoading(prevState => {
                            return {...prevState, addOrEditWriteOffAndClose: false}
                        })
                        onHide()
                    }
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || writeOff?.employee) {
            if (formData?.employee_id !== writeOff?.employee?.id) {
                writeOffChangeEmployeeAsync({writeOffId: writeOff?.id, employeeId: formData?.employee_id})
                    .then(() => {
                    })
                    .catch(() => {
                    })
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== writeOff?.note) {
            writeOffUpdateNoteAsync({writeOffId: writeOff?.id, note: formData?.note})
                .then(({data}) => {
                })
                .catch(() => {
                })
        }
    }

    return (
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <WriteOffForm
                        writeOff={writeOff}
                        loading={loading}
                        addItem={form.handleSubmit(onSubmit)}
                        show={show}
                        onShow={onShow}
                        onHide={onHide}
                    />
                </Form>
            </FormProvider>
        </>
    );
};

export default EditWriteOffForm;