import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {barcodeTypes} from "../../../enum/item/itemWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const SelectItemBarcodeType = ({defaultTypeValue, onChange, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const ean8Type = barcodeTypes.find(type => type.value === 'EAN8');
    console.log(props)
    return (
        <CustomFloatingLabelSelect
            {...props}
            options={barcodeTypes}
            isSearchable={false}
            hideSelectedOptions
            getOptionLabel={option => t(lang, option.label)}
            getOptionValue={option => option.value}
            placeholder={t(lang, "items.common.select")}
            onChange={onChange}
            value={barcodeTypes.find(m => m.value === defaultTypeValue) || ean8Type}
        />
    );
};

export default SelectItemBarcodeType;
