import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import itemWrapper from "../../../enum/item/itemWrapper";
import {Col, Form, Row} from "react-bootstrap";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import ReloadItems from "../../../components/item/components/ReloadItems";
import ValidateItemCatalogs from "../../../components/item/components/ValidateItemCatalogs";
import UploadItemsToExcel from "../../../components/item/components/UploadItemsToExcel";
import UploadItemsToScales from "../../../components/item/components/UploadItemsToScales";
import WarehouseAddButton from "../../../components/warehouse/WarehouseAddButton";
import EditBulkItemsModal from "../../../components/item/components/EditBulkItemsModal";
import BulkItemRevision from "../../../components/item/components/BulkItemRevision";
import SelectBulkCatalogButton from "../../../components/item/components/SelectBulkCatalogButton";
import BulkDeleteItems from "../../../components/item/components/BulkDeleteItems";
import UploadBulkItemsToExcel from "../../../components/item/components/UploadBulkItemsToExcel";
import BulkEditItemLegalType from "../../../components/item/components/BulkEditItemLegalType";
import DataTableFilter from "../../../components/item/components/DataTableFilter";
import {FormProvider} from "react-hook-form";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconButton from "../../../components/IconButton";
import MassCommonButtonDropdown from "../../../components/common/MassCommonButtonDropdown";

const ItemDataTableHeader = ({
                                 selectedRowIds,
                                 items,
                                 remainder,
                                 setRemainder,
                                 setSelectedBranch,
                                 selectedBranch,
                                 setSelectedWarehouse,
                                 setSort,
                                 setSortByDesc,
                                 onSelectedItemsChanged,
                             }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const navigate = useNavigate()
    const [selectedItems, setSelectedItems] = useState([]);
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

    const totalItemsCommonPricesAmount = useMemo(() => itemWrapper.calculateItemsCommonPriceTotal(items), [items]);
    const totalItemsBulkPricesAmount = useMemo(() => itemWrapper.calculateItemsBulkPriceTotal(items), [items]);
    const totalItemsPurchasePricesAmount = useMemo(() => itemWrapper.calculateItemsPurchasePricesTotal(items), [items]);
    const totalItemsWarehousesState = useMemo(() => itemWrapper.calculateItemsWarehouseStates(items), [items]);
    const totalWarehousesState = useMemo(() => totalItemsWarehousesState.reduce((acc, itemWarehouse) => acc + itemWarehouse.itemWarehouseState, 0), [totalItemsWarehousesState]);

    useEffect(() => {
        const datatableSelectedItems = Object.keys(selectedRowIds).reduce((result, selectedIndex) => {
            result.push(items[selectedIndex]);
            return result;
        }, []);

        setSelectedItems(datatableSelectedItems);
    }, [selectedRowIds])

    useEffect(() => onSelectedItemsChanged(selectedItems), [selectedItems])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-items-header'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-items-header'));
    }, []);

    return (
        <Fragment>
            <Row id={'warehouse-items-header'} className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem',  zIndex: '999'}}>
                <Col xs={12} lg={2} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.datatable.header.title")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    {
                        selectedItems.length === 0 && <Fragment>
                            {/*<ReloadItems />*/}
                            <ValidateItemCatalogs />
                            <UploadItemsToExcel />
                            <UploadItemsToScales />
                            <IconButton
                                onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                                className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                            >
                                <div className={'d-flex align-items-center'}>
                                    <IconFilter />
                                    <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                                </div>
                                <div className={'d-flex justify-content-between gap-2'}>
                                    <IconStroke />
                                    <IconCaretDown />
                                </div>
                            </IconButton>
                            <WarehouseAddButton />
                        </Fragment>
                    }
                    {
                        selectedItems.length > 0 && <Fragment>
                            <BulkDeleteItems items={selectedItems}/>
                            <UploadBulkItemsToExcel selectedItems={selectedItems}/>
                            {/*<MassCommonButtonDropdown selectedItems={selectedItems}/>*/}
                        </Fragment>
                    }
                </Col>
                <Col sm={12}>
                    <DataTableFilter
                        setSelectedWarehouse={setSelectedWarehouse}
                        remainder={remainder}
                        setRemainder={setRemainder}
                        setSelectedBranch={setSelectedBranch}
                        selectedBranch={selectedBranch}
                        setSort={setSort}
                        setSortByDesc={setSortByDesc}
                        isOpenFilterCollapse={isOpenFilterCollapse}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default ItemDataTableHeader;
