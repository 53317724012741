import React, {cloneElement, Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import dayjs from "dayjs";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {registerContractorPaymentAsync} from "../../../store/reducers/contractor/contractorPaymentReducer";
import IconButton from "../../../components/IconButton";
import IconPlus from "../../../assets/icon/IconPlus";
import Flex from "../../../components/common/Flex";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import ContractorPaymentForm from "./ContractorPaymentForm";

const AddContractorPayment = ({contractor, children}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOnSubmit = (formData) => {
        const payload = {
            contractor_id: formData.contractor.id,
            date: dayjs(formData.date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss"),
            notes: formData.notes,
            cash_box_states: formData.cash_box_states.map((act) => {
                return {
                    type: act.type,
                    amount: typeof (act.currency.amount) === 'string' ? +act.currency.amount.replace(/ /g, '') : +act.currency.amount,
                    currency_id: act.currency.currency_id
                }
            }),
            debt_states: formData.debt_states.map((debt) => {
                return {
                    amount: typeof (debt.amount) === 'string' ? +debt.amount.replace(/ /g, '') : +debt.amount,
                    currency_id: debt.currency_id
                }
            }),
            cashbox_id: formData.cashbox_id,
        }

        setLoading(true)
        dispatch(registerContractorPaymentAsync(payload))
            .then(res => {
                setShow(false)
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false));
    }
    return (
        <Fragment>
            {!!children ? cloneElement(children, {...children.props, onClick: () => setShow(true)}) : (
                <IconButton
                    variant="phoenix-primary"
                    className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    size="sm"
                    icon=''
                    onClick={() => setShow(true)}
                    transform="shrink-3"
                >
                    <IconPlus/>
                    <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
                </IconButton>
            )}

            <Modal show={show} onHide={() => {
                setShow(false)
            }} size={"xl"} className={"davr-modal"}>
                <Modal.Header>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => setShow(false)} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            {t(lang, "edi.contractor_payment_form_title")}
                        </Flex>
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => {
                        setShow(false)
                    }}/>
                </Modal.Header>
                <Modal.Body>
                    <ContractorPaymentForm onClose={() => {
                        setShow(false)
                    }} loading={loading} onSubmit={handleOnSubmit} contractor={contractor}/>
                </Modal.Body>
            </Modal>
        </Fragment>)
}
export default AddContractorPayment
