import React, {cloneElement, Fragment, useState} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {
    saleDownloadContractorLastPaymentPdfAsync,
    saleDownloadPdfAsync, saleDownloadReceipt58PdfAsync, saleDownloadReceipt80PdfAsync,
    saleDownloadSkuPdfAsync
} from "../../../../store/reducers/warehouse/saleReducer";
import {downloadFile} from "../../../../helpers/excel-uploader";
import {Dropdown, NavDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faSpinner} from "@fortawesome/free-solid-svg-icons";
import IconFileDownload from "../../../../assets/icon/IconFileDownload";
import IconCloudDownload from "../../../../assets/icon/IconCloudDownload";

const DownloadSaleDropdown = ({saleId, saleNumber, children, ...props}) => {
    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const printDefaultPdf = id => {
        setLoading(true);

        saleDownloadPdfAsync({saleId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_excel.sale_name", {number: saleNumber})}.pdf`,
                });
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    const printSkuPdf = id => {
        setLoading(true);

        saleDownloadSkuPdfAsync({saleId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_excel.sale_name", {number: saleNumber})}.pdf`,
                });
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    const printContractorLastPaymentPdf = id => {
        setLoading(true);

        saleDownloadContractorLastPaymentPdfAsync({saleId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_excel.sale_name", {number: saleNumber})}.pdf`,
                });
            })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    const printDefaultReceipt80Pdf = id => {
        setLoading(true);

        saleDownloadReceipt80PdfAsync({saleId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_excel.sale_name", {number: saleNumber})}.pdf`,
                });
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const printDefaultReceipt58Pdf = id => {
        setLoading(true);

        saleDownloadReceipt58PdfAsync({saleId: id})
            .then(pdfContent => {
                downloadFile({
                    content: pdfContent,
                    contentType: 'application/pdf',
                    fileName: `${t(lang, "warehouse.item.download_excel.sale_name", {number: saleNumber})}.pdf`,
                });
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <Dropdown.Header className={'text-black'}>
                {t(lang, 'warehouse.operation.item.common.downloading_invoice')}
            </Dropdown.Header>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.download_invoice')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printSkuPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.download_sku_invoice')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading}
                           onClick={() => printContractorLastPaymentPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.download_contractor_last_payment_invoice')}
            </Dropdown.Item>
            <Dropdown.Header className="text-black">
                {t(lang, 'warehouse.operation.item.common.downloading_receipt')}
            </Dropdown.Header>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultReceipt80Pdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.download_receipt_80')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultReceipt58Pdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.download_receipt_58')}
            </Dropdown.Item>
        </Fragment>
    );
};

export default DownloadSaleDropdown;
