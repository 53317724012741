
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_REQUESTED";
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS";
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_FAILED";

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_FAILED"

export const WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_REQUESTED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_REQUESTED"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS = "WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS"
export const WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_FAILED = "WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_FAILED"
