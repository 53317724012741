import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {loadSaleDefaultsSettings} from "../store/reducers/warehouse/saleReducer";
import EventBus from "../eventbus/EventBus";
import {loadPurchaseDefaultsSettings} from "../store/reducers/warehouse/purchaseReducer";
import {loadRefundDefaultsSettings} from "../store/reducers/warehouse/refundReducer";
import {loadReturnPurchaseDefaultsSettings} from "../store/reducers/warehouse/returnPurchase";
import {loadRevisionDefaultsSettings} from "../store/reducers/warehouse/revisionReducer";
import {loadWriteOffDefaultsSettings} from "../store/reducers/warehouse/writeOffReducer";
import {loadTransferDefaultsSettings} from "../store/reducers/warehouse/transferReducer";
import {loadEmployeesAsync} from "../store/reducers/employee/employeeReducer";
import {ADD_EMPLOYEE_SUCCEED, EDIT_EMPLOYEE_SUCCEED} from "../eventbus/employee/employeeEvent";
import {Outlet} from "react-router-dom";
import {loadCategoriesAsync, updateAllCategories} from "../store/reducers/category/categoryReducer";
import {selectCategories} from "../store/reducers/item/itemReducer";

const OperationLayout = () => {
    const dispatch = useDispatch()
    const categories = useSelector(selectCategories)

    const loadCategories = () => {
        loadCategoriesAsync()
            .then((categories) => dispatch(updateAllCategories(categories)))
            .catch((error) => dispatch(updateAllCategories([])))
    }

    useEffect(() => {
        if (!categories.length) loadCategories()
        dispatch(loadSaleDefaultsSettings())
        dispatch(loadPurchaseDefaultsSettings())
        dispatch(loadRefundDefaultsSettings())
        dispatch(loadReturnPurchaseDefaultsSettings())
        dispatch(loadRevisionDefaultsSettings())
        dispatch(loadWriteOffDefaultsSettings())
        dispatch(loadTransferDefaultsSettings())
        dispatch(loadEmployeesAsync())
        let onAddEmployeeHandler;
        onAddEmployeeHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, () => {
            dispatch(loadEmployeesAsync())
        });

        const onEditEmployeeHandler = EventBus.on(EDIT_EMPLOYEE_SUCCEED, () => {
            dispatch(loadEmployeesAsync())
        })


        return () => {
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onAddEmployeeHandler)
            EventBus.remove(EDIT_EMPLOYEE_SUCCEED, onEditEmployeeHandler)
        }
    },[])

    return (
       <Outlet />
    );
};

export default OperationLayout;
