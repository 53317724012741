import React, {Fragment, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Col, FloatingLabel, Form, InputGroup} from "react-bootstrap";
import WarehouseOperationItemSelect from "../../../../components/warehouse/operation/WarehouseOperationItemSelect";
import classNames from "classnames";
import PackageTypeItem from "../../../../components/warehouse/operation/PackageTypeItem";

const RevisionsFormModal = ({categoryItems, onChangeItem}) => {

    const lang = useSelector(selectLang)
    const t = Translate
    const items = useSelector(selectItems)
    const ref = useRef()
    const {errors, setValue, trigger, register} = useFormContext()
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const itemId = useWatch({name: 'item_id', exact: true})

    return (
        <Fragment>
            <Form.Group as={Col} xs={12}>
                <Controller
                    name="item_id"
                    rules={{
                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                    }}
                    render={({field}) => {
                        return (
                            <WarehouseOperationItemSelect
                                // onFocus={onFocusItem()}
                                placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                warehouseId={warehouseId}
                                options={categoryItems || items}
                                defaultValue={field?.value}
                                right={true}
                                allowAdd={true}
                                allowEdit={true}
                                isClearable
                                onChange={option => {
                                    field?.onChange(option ? option?.item?.id : null)
                                    onChangeItem(option)
                                }}
                                className={classNames({
                                    'is-invalid': errors?.item_id
                                })}
                            />
                        )
                    }}
                />
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors?.item_id?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
                <InputGroup>
                    <FloatingLabel label={t(lang, "warehouse.operation.item.common.quantity")}>
                        <Form.Control
                            type="text"
                            id="quantity"
                            {...register("quantity", {
                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                pattern: {
                                    value: /^[0-9]*[\.,]?[0-9]*$/,
                                    message: "100 | 1.2 | 1,5"
                                }
                            })}
                            placeholder={t(lang, "warehouse.operation.item.common.quantity")}
                            className={classNames("form-control w-100", {
                                "border-right-0": ref?.current?.getPackageMeasurement()
                            })}
                        />
                    </FloatingLabel>
                    <Controller
                        name="package_measurements"
                        render={({field}) => {
                            return (
                                <PackageTypeItem
                                    ref={ref}
                                    className={'w-100'}
                                    itemId={itemId}
                                    defaultValue={field?.value}
                                    onChange={option => {
                                        field?.onChange(option)
                                    }}
                                />
                            )
                        }}
                    />
                </InputGroup>
                <Form.Control.Feedback type="invalid" className="d-block">
                    {errors?.quantity?.message}
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12}>
                <FloatingLabel label={t(lang, "warehouse.dashboard.items.transfer.view.note")}>
                    <Form.Control
                        placeholder={t(lang, "warehouse.dashboard.items.transfer.view.note")}
                        as="textarea"
                        rows={10}
                        name="note"
                        {...register("note")}
                    />
                </FloatingLabel>
            </Form.Group>
        </Fragment>
    );
};

export default RevisionsFormModal;