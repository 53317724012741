import React, {useEffect, useState} from 'react';
import {Col, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import {Translate} from "../../../lang/lang";
import {
    registerWarehouseAsync,
    updateOneWarehouseAsync
} from "../../../store/reducers/warehouse/warehouseReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import Flex from "../../../components/common/Flex";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import FalconCloseButton from "../../../components/common/FalconCloseButton";
import {useForm} from "react-hook-form";
import IconButton from "../../../components/IconButton";

const WarehousesAddAndEditModal = ({show, handleClose, warehouseItem: {id, name}}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);

    const {formState: {errors}, register, handleSubmit, reset} = useForm({
        defaultValues: {
            name: name
        }
    });

    useEffect(() => {
        reset({name})
    }, [name]);


    const onRegisterAndEditWarehouse = (formData) => {
        setLoading(true)
        if (id) {
            dispatch(updateOneWarehouseAsync({id, name: formData}))
        } else {
            dispatch(registerWarehouseAsync({name: formData}))
        }
        setLoading(false)
        handleClose()
        reset()
    }

    const onHide = () => {
        handleClose()
        reset()
    }

    return (
        <Modal show={show} onHide={onHide} className={'davr-modal'}>
            <Form onSubmit={handleSubmit(onRegisterAndEditWarehouse)}>
                <Modal.Header>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => onHide()} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            {t(lang, !id ? 'warehouse.operation.item.common.new_warehouse' : 'warehouse.operation.item.common.edit_warehouse')}
                        </Flex>
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel label={t(lang, "warehouse.modal.input.name")}>
                        <Form.Control
                            type="text"
                            name={'name'}
                            placeholder={t(lang, "warehouse.modal.input.name")}
                            isInvalid={errors?.name}
                            {...register('name', {
                                required: t(lang, "items.common.validations.is_required")
                            })}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.name?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton
                        size={"sm"}
                        icon={''}
                        type="submit"
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2 mb-2'}
                    >
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="btn-primary-davr"
                        />}
                        {t(lang, "edi.common.button.save")}
                    </IconButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default WarehousesAddAndEditModal;