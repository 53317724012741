import React, {useState} from 'react';
import printJS from "print-js";
import {useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {transferDownloadPdfAsync} from "../../../store/reducers/warehouse/transferReducer";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import toast from "react-hot-toast";
import IconButton from "../../../components/IconButton";
import {faFileExcel, faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



const TransferPrint = ({transferId, ...props}) => {
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate



    const onPrint = () => {
        setLoading(true)
        transferDownloadPdfAsync({transferId})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }




    return (
        <>
            <IconButton {...props} icon={''} disabled={loading}
                        size="sm"
                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                        iconClassName={"text-primary-davr fs-8"} variant='phoenix-warning'
                        transform="shrink-3"
                        onClick={onPrint}>
                {loading ? (
                    <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                ) : <FontAwesomeIcon className="me-1 fs-7 text-primary-davr" icon={faPrint}/>}
                <span className="d-none d-sm-inline-block">{t(lang, "warehouse.operation.item.common.print")}</span>

            </IconButton>
        </>
    );
};

export default TransferPrint;