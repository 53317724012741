import React from 'react';

const IconTheme = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17 9C17 11.3591 15.9729 13.4935 14.336 14.9458C12.9238 16.2297 11.0542 17 9 17C4.58676 17 1 13.4132 1 9C1 4.58676 4.58676 1 9 1C11.0542 1 12.9238 1.77031 14.336 3.05416C15.9729 4.50652 17 6.64092 17 9Z"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M9 12.5657C8.98447 13.9045 8.0295 14.2291 7.13665 13.8558C5.29658 13.085 4 11.2025 4 9.00348C4 6.80451 5.29658 4.92199 7.13665 4.14302C8.0295 3.76976 8.98447 4.10245 9 5.43319V12.5657Z"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconTheme;
