import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {selectAllContractor, selectPaginationOptions} from "../../../store/reducers/contractor/contractorReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Translate} from "../../../lang/lang";
import {updatePagination} from "../../../store/reducers/item/itemReducer";
import {transliterate} from "transliteration";
import naturalCompare from "natural-compare";
import {Card, Dropdown, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import ItemDataTableHeader from "../contractor-contact/ItemDataTableHeader";
import ContractorTabs from "../contractor/ContractorTabs";
import AdvanceTable from "../../../components/table/AdvanceTable";
import AdvanceTableCustomPagination2 from "../../../components/table/AdvanceTableCustomPagination2";
import EditContractor from "../../../components/contractor/EditContractor";
import DeleteContractorModal from "../contractor/DeleteContractorModal";

const ContractorContactDataTable = () => {
    const contractors = useSelector(selectAllContractor);
    const currency = useSelector(selectCurrency)
    const {page, limit} = useSelector(selectPaginationOptions, shallowEqual);
    const lang = useSelector(selectLang);


    const t = Translate;
    const dispatch = useDispatch();

    const [sortByDesc, setSortByDesc] = useState({
        label: t(lang, 'По возрастанию'),
        value: false
    });

    const [editingContractor, setEditingContractor] = useState(null);
    const [deletingContractorId, setDeletingContractorId] = useState(null);
    const [filters, setFilters] = useState({
        name: '',
        phone: '',
        email: '',
        filter: false
    })

    useEffect(() => {
        dispatch(updatePagination({limit: limit, page: 1}));
    }, [filters])

    const data = useMemo(() => {
        let filtered_contractors = [];

        // filter by name
        for (let i = 0; i < contractors.length; i++) {
            const contractor = contractors[i];

            if (filters.name && filters.filter) {
                let found = false;
                const searchName = filters.name.trim().toLowerCase();
                const contractorNameLower = contractor.name.trim().toLowerCase();

                const transliteratedQuery = transliterate(searchName);
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');

                const transliteratedItemName = transliterate(contractorNameLower);

                if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                    found = true;

                // search by contact
                if (contractor.contacts.some((contact) => contact.value.toLowerCase().includes(searchName))) {
                    found = true;
                }

                if (!found)
                    continue;
            }
            filtered_contractors.push(contractor);
        }


        if (filters.phone && filters.filter) {
            filtered_contractors = filtered_contractors.filter((contractor) => {
                return contractor?.contacts?.filter(i => i.type === 1).some((contact) => {
                    return contact.value.includes(filters.phone.replaceAll(' ', ''))
                })
            })
        }


        if (filters.email && filters.filter) {
            filtered_contractors = filtered_contractors.filter((contractor) => {
                return contractor?.contacts?.filter(i => i.type === 3).some((contact) => {
                    return contact.value.includes(filters.email)
                })
            })
        }


        if (sortByDesc?.value && filters.filter)
            return filtered_contractors.sort((a, b) => naturalCompare(b.name, a.name));
        return filtered_contractors.sort((a, b) => naturalCompare(a.name, b.name));

    }, [contractors, filters.filter, page, limit])


    const onClickEdit = (contractor) => {
        let con = JSON.parse(JSON.stringify(contractor));
        con.contacts = con.contacts.map(contact => {
            if (contact.type === 1) {
                let phone = contact.value;
                if (phone.length === 12 && phone.slice(0, 3) === '998') {
                    phone = phone.slice(3, 12)
                }
                return {
                    type: contact.type,
                    value: phone,
                    description: contact.description
                }
            } else {
                return contact
            }
        })
        setEditingContractor(con)
    }

    const columns = useMemo(() => {
        return [
            {
                Header: "#",
                id: "row",
                maxWidth: 50,
                filterable: false,
                Cell: ({row}) => {
                    return `${row.index + 1}`;
                }
            },
            {
                accessor: 'info.name',
                Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
                headerProps: {className: 'pe-7'},
                Cell: ({row: {original}}) => {
                    return (
                        <strong>{original.name}</strong>
                    );
                }
            },
            {
                accessor: 'type',
                Header: 'Тип',
                headerProps: {className: 'pe-7'},
                Cell: ({row: {original}}) => (
                    <Flex className={'align-items-center gap-15 px-2'}>
                        {original.contacts?.length > 0 && original.contacts?.filter(i => i.type === 1).map((item, index) => {
                            return <span className={'fw-bold second-text '}
                                         key={index}>+{item.value.replace(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5')}</span>
                        })}
                    </Flex>
                )
            },
            {
                accessor: 'email',
                Header: t(lang, "edi.contract_formation.info.email"),
                headerProps: {className: 'pe-7'},
                Cell: ({row: {original}}) => (
                    <Flex className={'align-items-center gap-15 p-2'}>
                        {original.contacts?.length > 0 && original.contacts?.filter(i => i.type === 3).map((item, index) => {
                            return <span className={'fw-bold second-text '}
                                         key={index}>{item.value.replace(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/, '$1 $2 $3 $4 $5')}</span>
                        })}
                    </Flex>
                )
            },
            {
                accessor: 'action',
                Header: "",
                disableSortBy: true,
                cellProps: {
                    width: '80px',
                    className: 'text-end'
                },
                Cell: ({row}) => {
                    return (
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                            <RevealDropdown>
                                <Dropdown.Item
                                    onClick={() => {
                                        onClickEdit(row.original)
                                    }}
                                    >
                                    <span>{t(lang, "edi.common.edit_button")}</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) => {
                                        setDeletingContractorId(row.original.id);
                                    }}
                                    >
                                    <span>{t(lang, "edi.common.delete_button")}</span>
                                </Dropdown.Item>
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                    );
                }
            },
        ].filter(i => i)
    }, [contractors, filters.filter, page, limit])

    const onLimitChange = (limit) => dispatch(updatePagination({limit: limit, page: page}));
    const onPageChange = (page) => dispatch(updatePagination({limit: limit, page: page}));

    if (!currency.length) return <Spinner className={'position-absolute top-50'} style={{left: '50%'}}
                                          animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
    </Spinner>

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={data}
                selection
                width={'50px'}
                pagination
                perPage={limit}
                currentPage={page - 1}
            >
                <ItemDataTableHeader
                    setSortByDesc={setSortByDesc}
                    sortByDesc={sortByDesc}
                    setFilters={setFilters}
                    filters={filters}
                    data={data}
                    table
                />
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <ContractorTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination2 rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                               onPageChange={onPageChange}
                                               onLimitChange={onLimitChange}
                                               table
                />
            </AdvanceTableWrapper>
            <EditContractor contact={true} show={!!editingContractor} contractor={editingContractor}
                            onClose={() => {
                                setEditingContractor(null)
                            }}/>

            <DeleteContractorModal id={deletingContractorId} show={!!deletingContractorId} onClose={() => {
                setDeletingContractorId(null)
            }}/>

        </Fragment>
    );
};

export default ContractorContactDataTable;
