import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {
    saleAddItemAsync,
    saleChangeEmployeeAsync, saleUpdateNoteAsync,
    selectSaleDefaultsSettings
} from "../../../../../store/reducers/warehouse/saleReducer";
import {selectWarehouses} from "../../../../../store/reducers/warehouse/warehouseReducer";
import {selectCurrency} from "../../../../../store/reducers/currency/currencyReducer";
import {selectLang} from "../../../../../store/reducers/main/main";
import {Translate} from "../../../../../lang/lang";
import {selectDefaultSetting} from "../../../../../store/reducers/settings/settingsReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {UZS} from "../../../../../enum/item/itemWrapper";
import {withoutDiscount} from "../../../../../enum/warehouse-operation/warehouseOperation";
import toast from "react-hot-toast";
import {Form} from "react-bootstrap";
import SaleForm from "./SaleForm";

const EditSaleForm = ({sale}) => {
    const saleDefaultsSettings = useSelector(selectSaleDefaultsSettings)
    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const lang = useSelector(selectLang)
    const t = Translate
    const settings = useSelector(selectDefaultSetting)

    const form = useForm({
        defaultValues: {
            contractor_id: sale?.contractor?.id,
            employee_id: sale?.employee?.id,
            date: dayjs(sale?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: sale?.note,
            number: sale?.number,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
            commonPrice: {
                amount: null,
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
            commonDiscount: {
                amount: '0',
                type: withoutDiscount,
            },
        }
    })

    const resetForm = (formData) => {
        form.reset({
            contractor_id: formData?.contractor_id,
            employee_id: formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
            commonDiscount: {
                amount: '0',
                type: formData?.commonDiscount?.type || withoutDiscount,
            },
        })
    }

    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: quantity,
            marks: formData?.marks || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
            discount: {
                value: +formData?.commonDiscount?.amount,
                type: formData?.commonDiscount?.type,
            },
        }



        if ((buttonType === "addOrEditSale" || buttonType === "addOrEditSaleMore") || (event === "addOrEditSale" || event === "addOrEditSaleMore")) {
            /*setLoading(true)
            if (sale?.contractor?.id !== formData?.contractor_id) {
                await saleChangeContractorAsync({saleId: sale.id, contractorId: formData?.contractor_id})
                    .then(({data}) => {
                    })
                    .catch(() => {})
                    .finally(() => {
                        setLoading(false)
                    })
            }*/

            if (buttonType === "addOrEditSale" || event === "addOrEditSale") {
                setLoading(true)
            } else {
                setLoadingMore(true)
            }


            await saleAddItemAsync({
                saleId: sale?.id,
                operation: item
            })
                .then(({data}) => {
                    updateNote(formData)
                    // updateNumber(formData)
                    resetForm(formData)
                    updateEmployee(formData)
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch((error) => {
                    if (error.response.data.includes("warehouse")) {
                        toast.error(t(lang, "Склад не выбран"))
                    } else {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    }
                })
                .finally(() => {
                    setLoading(false)
                    setLoadingMore(false)
                })
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || sale?.employee) {
            if (formData?.employee_id !== sale?.employee?.id) {
                saleChangeEmployeeAsync({saleId: sale?.id, employeeId: formData?.employee_id})
                    .then(() => {})
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== sale?.note) {
            saleUpdateNoteAsync({saleId: sale?.id, note: formData?.note})
                .then(({data}) => {})
                .catch(() => {})
        }
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <SaleForm loadingMore={loadingMore} sale={sale} loading={loading} addItem={form.handleSubmit(onSubmit)} isEditing={true}/>
            </Form>
        </FormProvider>
    );
};

export default EditSaleForm;
