import React, {Fragment, memo, useEffect, useState} from "react";
import {Card, Carousel, Col, Container, Row, Spinner} from 'react-bootstrap';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../store/reducers/main/main";
import {Translate} from "../lang/lang";

import {authStatus, loginAsync, selectIsAuthorized, whiteLabelLogin} from "../store/reducers/auth/authReducer";
import davrLogo from '../assets/img/DavrBusinessWhite.svg'
import IconAppStore from "../assets/icon/IconAppStore";
import IconGooglePlay from "../assets/icon/IconGooglePlay";
import toast from "react-hot-toast";

const AuthLayout = ({logo = true, className, children}) => {

    const lang = useSelector(selectLang)
    const t = Translate
    const status = useSelector(authStatus);
    const dispatch = useDispatch()
    const isAuthorized = useSelector(selectIsAuthorized);
    const navigate = useNavigate()
    const location = useLocation();

    const [loading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(location.search);

    const allParams = [];
    queryParams.forEach((value) => {
        allParams.push(value);
    });

    useEffect(() => {
        if (!isAuthorized) {
            setLoading(true)

            whiteLabelLogin(allParams)
                .then((res) => {
                    dispatch(loginAsync(res.account.username, res.otp_password));
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
                .finally(() => {
                    setLoading(false)
                })
        }


    }, []);

    useEffect(() => {
        if (isAuthorized) navigate('/')
    }, [isAuthorized, navigate]);

    return (
        <Fragment>
            {loading && <div style={{
                position: 'absolute',
                height: '100vh',
                inset: 0,
                background: 'rgb(131 127 127 / 33%)',
                zIndex: 2
            }}>
                <div className={'position-absolute top-50 start-50 translate-middle'}>
                    <div className={'w-100 text-center'}>
                        <Spinner animation="border" role="switch"/>
                    </div>
                    <h4 className={'text-center'}>{t(lang, 'login.wait')}</h4>
                </div>
            </div>}
            {/*<Container fluid className={'py-3 vh-100'}>
                <Row className={'h-100'}>
                    <Col lg={4} xs={3} className={'p-1 h-100 offset-lg-2 d-none d-md-block'}>
                        <div className={"d-none gap-2 d-lg-grid h-100"}
                             style={{gridTemplateRows: "repeat(9, 1fr)", gridTemplateColumns: "repeat(1, 1fr)"}}>
                            <Card className={'rounded-5 overflow-hidden authBackground'} style={{gridRow: "1 / span 6"}}>
                                <Carousel
                                    className='theme-slider position-relative'
                                    nextIcon={
                                        <FontAwesomeIcon icon={faAngleRight}/>
                                    }
                                    prevIcon={
                                        <FontAwesomeIcon icon={faAngleLeft}/>
                                    }
                                >
                                    <Carousel.Item className={'px-5 py-10 mt-4'}>
                                        <div className={'w-xl-75 w-100'}>
                                            <img src={davrLogo} alt="logo" className={'mb-4'}/>
                                            <h2 className={'fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7'}
                                                style={{lineHeight: "32px", color: "#fff"}}>
                                                Управление документами
                                            </h2>
                                            <p className={'fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8'}
                                               style={{color: "#fff"}}>Подписывайте,
                                                отправляйте и храните юридически
                                                значимые документы в безопасном хранилище.</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className={'px-5 p-10 mt-4'}>
                                        <div className={'w-xl-75 w-100'}>
                                            <img src={davrLogo} alt="logo" className={'mb-4'}/>
                                            <h2 className={'fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7'}
                                                style={{lineHeight: "32px", color: "#fff"}}>
                                                Эквайринг и платежные решения
                                            </h2>
                                            <p className={'fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8'}
                                               style={{color: "#fff"}}>Принимайте
                                                платежи без лишних усилий от банковских
                                                карт до онлайн транзакций с надежным эквайрингом нашего приложения.</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className={'px-5 p-10 mt-4'}>
                                        <div className={'w-xl-75 w-100'}>
                                            <img src={davrLogo} alt="logo" className={'mb-4'}/>
                                            <h2 className={'fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7'}
                                                style={{lineHeight: "32px", color: "#fff"}}>
                                                Мобильный банк для юридических лиц
                                            </h2>
                                            <p className={'fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8'}
                                               style={{color: "#fff"}}>Удобное
                                                управление финансами вашего предприятия:
                                                открытие счетов, переводы, обмен документами - все в одном месте.</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className={'px-5 p-10 mt-4'}>
                                        <div className={'w-xl-75 w-100'}>
                                            <img src={davrLogo} alt="logo" className={'mb-4'}/>
                                            <h2 className={'fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7'}
                                                style={{lineHeight: "32px", color: "#fff"}}>
                                                Мобильный банк для юридических лиц
                                            </h2>
                                            <p className={'fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8'}
                                               style={{color: "#fff"}}>Удобное
                                                управление финансами вашего предприятия:
                                                открытие
                                                счетов, переводы, обмен документами - все в одном месте.</p>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item className={'px-5 p-10 mt-4'}>
                                        <div className={'w-xl-75 w-100'}>
                                            <img src={davrLogo} alt="logo" className={'mb-4'}/>
                                            <h2 className={'fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7'}
                                                style={{lineHeight: "32px", color: "#fff"}}>
                                                Универсальное финансовое решение
                                            </h2>
                                            <p className={'fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8'} style={{color: "#fff"}}>
                                                Обеспечьте своему бизнесу все необходимые банковские услуги, управляйте
                                                документами и проводите платежи через наше мобильное приложение.
                                            </p>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </Card>
                            <Card className={"rounded-5 overflow-hidden authMobileBackground p-xxl-6 p-xl-4 p-lg-3 p-md-3"}
                                  style={{gridRow: "7 / span 3"}}>
                                <div className={"w-100 h-100 position-relative"}>
                                    <h2 className={"w-60 fs-md-10 fs-lg-9 fs-xl-8 fs-xxl-7"}
                                        style={{color: "#fff"}}>Установите
                                        приложение DAVR BUSINESS</h2>
                                    <p className={"w-60 fs-md-11 fs-lg-10 fs-xl-9 fs-xxl-8"}
                                       style={{color: "#fff"}}>Перейдите по ссылке
                                        и скачайте приложение на свое устройство</p>
                                    <div className={'d-flex gap-2 w-100 position-absolute bottom-0'}>
                                        <a href={"https://www.apple.com/app-store/"}
                                           target={"_blank"}
                                           style={{border: "1px solid"}}
                                           className={'text-primary-davr d-flex justify-content-center align-items-center gap-2 w-50 btn-davr bg-white border-white text-decoration-none h-mobile-app'}
                                        >
                                            <IconAppStore/>
                                            App Store
                                        </a>

                                        <a href={"https://play.google.com/store/games?hl=ru&gl=US&pli=1"}
                                           target={"_blank"}
                                           style={{border: "1px solid"}}
                                           className={'text-primary-davr d-flex justify-content-center align-items-center gap-2 w-50 btn-davr bg-white border-white text-decoration-none h-mobile-app'}
                                        >
                                            <IconGooglePlay/>
                                            Google Play
                                        </a>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col lg={4} md={6} className={'h-100 p-1'}>
                        <Outlet/>
                    </Col>
                </Row>
            </Container>*/}
        </Fragment>
    );
};

export default memo(AuthLayout);
