import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {
    CURRENCY_INITIALIZATION_STATE_COMPLETE,
    selectCurrencyInitializationState
} from "../../../store/reducers/currency/currencyReducer";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../store/reducers/warehouse/warehouseReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../store/reducers/item/itemReducer";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import WarehouseNo from "../../../components/warehouse/operation/WarehouseNo";
import ItemNo from "../../../components/warehouse/operation/ItemNo";
import AddTransferForm from "./AddTransferForm";

const AddTransfer = () => {
    const currencyInitializationState = useSelector(selectCurrencyInitializationState)
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)


    if (currencyInitializationState !== CURRENCY_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>

    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>


    if (warehouses?.length < 2)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    return (
        <>
            <AddTransferForm/>
        </>
    );
};

export default AddTransfer;