import axios from "axios";

class WarehouseService {

    getAllWarehouses({params}) {
        return axios.get(`/warehouse/warehouse/get`, params)
    }

    getOneWarehouse({id}) {
        return axios.get(`/warehouse/warehouse/get/${id}`)
    }

    registerWarehouse({name}) {
        return axios.post(`/warehouse/warehouse/register`, name)
    }

    updateOneWarehouse({id, name}) {
        return axios.post(`/warehouse/warehouse/update/${id}`, name)
    }

    updatePurchasePrice(payload) {
        return axios.post('/warehouse/WarehouseItem/update-purchase-price', payload)
    }

    updateWarehouseItemPurchasePrice(payload) {
        return axios.post('/warehouse/warehouseItem/update-purchase-price', payload)
    }
}

const warehouseService = new WarehouseService()

export default warehouseService;