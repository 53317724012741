import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/IconButton";
import {Card, Form, Modal, Row, Spinner} from "react-bootstrap";
import Flex from "../../../../components/common/Flex";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import FalconCloseButton from "../../../../components/common/FalconCloseButton";
import RevisionsFormModal from "./RevisionsFormModal";

const RevisionModal = ({
                           categoryItems, onChangeItem, show, onShow, onHide, loading
                       }) => {

    const lang = useSelector(selectLang)
    const t = Translate


    return (
        <Fragment>
            <IconButton icon={faPlus}
                        variant={'phoenix-primary'}
                        onClick={onShow}
                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                        iconClassName={'text-white'}
            />
            <Modal show={show} onHide={onHide} className={'davr-modal'}>
                <Modal.Header>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => onHide()} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            <div>
                                <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.view.item.title")}</h5>
                                <span className={'second-text fs-9'}>{t(lang, 'items.common.add_new.title')}</span>
                            </div>
                        </Flex>
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={onHide}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={'p-2'}>
                        <Row>
                            <Form id={'revision-add'}>
                                <RevisionsFormModal
                                    categoryItems={categoryItems}
                                    onChangeItem={onChangeItem}
                                />
                            </Form>
                        </Row>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton
                        size={"sm"}
                        icon={''}
                        variant="phoenix-secondary"
                        name="addOrEditRevisionAndContinue"
                        type={'submit'}
                        form={'revision-add'}
                        className={'btn-white-davr d-flex gap-2 mb-2'}
                    >
                        {loading.addOrEditRevisionAndContinue && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="text-primary-davr"
                        />}
                        {t(lang, "edi.common.button.save_and_continue")}
                    </IconButton>
                    <IconButton
                        size={"sm"}
                        icon={''}
                        name={'addOrEditRevisionAndClose'}
                        variant="phoenix-primary"
                        type={'submit'}
                        form={'revision-add'}
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2 mb-2'}
                    >
                        {loading.addOrEditRevisionAndClose && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="second-text"
                        />}
                        {t(lang, "edi.common.button.save_and_close")}
                    </IconButton>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default RevisionModal;