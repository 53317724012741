import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import warehouseOperationService from "../../services/warehouse/warehouseOperationService";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_FAILED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_REQUESTED,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS,
} from "../../../eventbus/warehouse/returnPurchaseEvent";


const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);


const returnPurchaseReducer = createSlice({
    name: "returnPurchase",
    initialState: {
        returnPurchases: [],
        returnPurchaseCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            date_time_start: null,
            date_time_end: null,
        },
        returnPurchaseDefaultsSettings: null,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateReturnPurchases: (state, action) => {
            state.returnPurchases = action.payload
        },
        updateReturnPurchaseCount: (state, action) => {
            state.returnPurchaseCount = action.payload
        },
        setReturnPurchaseDefaultsSettings: (state, action) => {
            state.returnPurchaseDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("ReturnPurchaseSettings", action.payload)
        },
        loadReturnPurchaseDefaultsSettings: (state, action) => {
            const returnPurchaseDefaults = JSON.parse(localStorage.getItem("ReturnPurchaseSettings"))
            state.returnPurchaseDefaultsSettings = returnPurchaseDefaults
        }
    },
})


export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(returnPurchaseReducer.actions.updateFilterOptions({...params}))
}

export const getReturnPurchasesAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        warehouseOperationService.getReturnPurchases(params)
            .then((response) => {
                dispatch(returnPurchaseReducer.actions.updateReturnPurchases(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getReturnPurchasesCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getReturnPurchasesCount(filters)
            .then((response) => {
                dispatch(returnPurchaseReducer.actions.updateReturnPurchaseCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const returnPurchaseRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_SUCCESS, response.data.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_CLIENT_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseAddItemAsync = ({returnPurchaseId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addReturnPurchaseItem({returnPurchaseId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getReturnPurchaseItemAsync = ({returnPurchaseId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getReturnPurchase({returnPurchaseId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const returnPurchaseDeleteItemAsync = ({returnPurchaseId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteReturnPurchaseItem({returnPurchaseId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseUpdateItemAsync = ({returnPurchaseId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updateReturnPurchaseItem({returnPurchaseId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseApproveAsync = ({returnPurchaseId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approveReturnPurchase({returnPurchaseId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseDeleteAsync = ({returnPurchaseId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseDelete({returnPurchaseId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseUpdateNoteAsync = ({returnPurchaseId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseUpdateNote({returnPurchaseId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NOTE_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseUpdateDateAsync = ({returnPurchaseId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseUpdateDate({returnPurchaseId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DATE_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseUpdateNumberAsync = ({returnPurchaseId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseUpdateNumber({returnPurchaseId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseChangeContractorAsync = ({returnPurchaseId, contractorId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseChangeContractor({returnPurchaseId, contractorId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_SUCCESS, returnPurchaseId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_CHANGE_CONTRACTOR_FAILED)
                reject(error)
            })
    })
}

export const returnPurchaseChangeEmployeeAsync = ({returnPurchaseId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseChangeEmployee({returnPurchaseId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const returnPurchasePaymentRegisterAsync = ({returnPurchaseId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchasePaymentRegister({returnPurchaseId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, returnPurchaseId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_FAILED)
                reject(error)
            })
    })
}

export const returnPurchasePaymentUpdateAsync = ({returnPurchaseId, payment}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchasePaymentUpdate({returnPurchaseId, payment})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, returnPurchaseId)
                resolve(response.data)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_FAILED)
                reject(error)
            })
    })
}

export const deleteReturnPurchasePaymentAsync = (returnPurchaseId) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_REQUESTED);

    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteReturnPurchasePayment(returnPurchaseId)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_SUCCESS, returnPurchaseId);
                resolve(response.data);
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_PAYMENT_FAILED);
                reject(error);
            })
    })
}

export const returnPurchaseDownloadPdfAsync = ({returnPurchaseId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.returnPurchaseDownloadPdf({returnPurchaseId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}




export const selectReturnPurchaseFilterOptions = state => state.returnPurchase.filterOptions
export const selectReturnPurchaseCount = state => state.returnPurchase.returnPurchaseCount
export const selectReturnPurchases = state => state.returnPurchase.returnPurchases

export const selectReturnPurchaseDefaultsSettings = state => state.returnPurchase.returnPurchaseDefaultsSettings


export const {setReturnPurchaseDefaultsSettings, loadReturnPurchaseDefaultsSettings} = returnPurchaseReducer.actions


export default returnPurchaseReducer.reducer;
