import {UilFileAlt} from "@iconscout/react-unicons";


export const routes = [
    {
        label: "edi.navbar.routes.documents",
        labelDisabled: true,
        icon: UilFileAlt,
    },
    {
        label: "warehouse.navbar.routes.warehouse",
        labelDisabled: true,
        icon: UilFileAlt,
        to: '/'
    }
]
