import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useSelector} from "react-redux";
import {selectDateDayJSFormat, selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Link, useNavigate, useParams} from "react-router-dom";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {getTransferItemAsync, transferApproveAsync} from "../../../store/reducers/warehouse/transferReducer";
import EventBus from "../../../eventbus/EventBus";
import {WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS} from "../../../eventbus/warehouse/transferEvent";
import IconButton from "../../../components/IconButton";
import {faLayerGroup, faPencil} from "@fortawesome/free-solid-svg-icons";
import UploadTransferToExcel from "./UploadTransferToExcel";
import IconPlus from "../../../assets/icon/IconPlus";
import TransferPrint from "./TransferPrint";
import TransferDownloadPdf from "./TransferDownloadPdf";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {
    findCurrencyReturnRate,
    isApprovedCheckedWarehouseOperation,
    OperationTotalSum
} from "../../../enum/warehouse-operation/warehouseOperation";
import {UZS} from "../../../enum/currency/currencyWrapper";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import numeral from "numeral";
import TransferViewDataTable from "./TransferViewDataTable"
import {scrollEvent} from "../../../helpers/scrollEvent";

const ViewTransfer = () => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const navigate = useNavigate()

    const activeOrganization = useSelector(selectActiveOrganization)
    const [transfer, setTransfer] = useState(null)
    const {id} = useParams()
    const dateFormat = useSelector(selectDateDayJSFormat)
    const currencies = useSelector(selectCurrency)
    const numberFormat = useSelector(selectNumberFormat)


    const loadTransfer = () => {
        getTransferItemAsync({transferId: id})
            .then(response => setTransfer(response.data))
            .catch(error => setTransfer(null))
    }

    useEffect(() => {
        loadTransfer()
    }, [activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, () => {
            loadTransfer()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-transfer-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-transfer-data-table-header'))
    }, []);



    const onApprovedHandler = async () => {
        await transferApproveAsync({transferId: transfer?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }


    const totalSumCurrency =  OperationTotalSum(transfer?.items?.filter(f => !f?.is_deleted))?.reduce((acc, cur) => acc + (cur?.amount * findCurrencyReturnRate(currencies, cur?.currency?.id)), 0) || 0


    if (!transfer) return <> loading... </>

    return (
        <Fragment>
            <Row id={'warehouse-transfer-data-table-header'}
                 className="flex-between-center align-items-center my-3 position-sticky z-index-1"
                 style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.operation.item.common.transfer")} № {transfer?.number}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'items.common.warehouse')}</span>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {!transfer?.is_approved && transfer?.items?.filter(x => !x.is_deleted).length > 0 &&
                        <IconButton
                            variant='phoenix-warning'
                            icon={faLayerGroup}
                            className={'btn-davr bg-white text-dark d-flex gap-2'}
                            iconClassName={"text-primary-davr fs-7"}
                            size="md"
                            onClick={() => onApprovedHandler()}
                        >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>
                    }
                    <IconButton size='sm'
                                as={Link}
                                to={`../transfers/update/${transfer?.id}`}
                                variant='phoenix-warning'
                                icon={faPencil}
                                className={'btn-davr bg-white text-dark d-flex gap-2'}
                                iconClassName={"text-primary-davr fs-7  "}
                    >
                        {t(lang, 'roaming.common.edit')}
                    </IconButton>
                    <UploadTransferToExcel className="me-2 d-inline-block" size="sm" id={transfer?.id}
                                           number={transfer?.number}/>
                    <TransferPrint
                        transferId={transfer?.id}
                    />
                    <TransferDownloadPdf
                        transferId={transfer?.id}
                        number={transfer?.number}
                    />
                    <IconButton
                        size={'sm'}
                        icon={''}
                        as={Link}
                        to={`../transfers/register`}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    >
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "warehouse.operation.item.common.new_transfer")}
                        </span>
                    </IconButton>
                </Col>
            </Row>

            <Card className={'mt-2'}>
                <Card.Footer as={Row} className={'border-0 justify-content-between fs-8 pb-3'}>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, "edi.common.created.time")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{dayjs(transfer?.date, 'YYYY-MM-DD').format(dateFormat)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "warehouse.dashboard.items.transfer.view.status_transfer")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>
                                        {t(lang, isApprovedCheckedWarehouseOperation(transfer?.is_approved))}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "warehouse.dashboard.items.transfer.view.executor")}:</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{transfer?.account?.name || transfer?.account?.username}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className={'px-1'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "warehouse.operation.item.common.total_sum")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{numeral?.formats[numberFormat].format(totalSumCurrency || 0)} {UZS}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "warehouse.dashboard.items.transfer.view.note")}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'main-text'}>{transfer?.note || "Нет информации"}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span
                                        className={'second-text'}>{t(lang, "warehouse.dashboard.items.transfer.view.total_amount")}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'main-text'}>{transfer?.items?.filter(f => !f?.is_deleted)?.reduce((a, b) => a + (b?.quantity || 0), 0)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Card.Footer>
            </Card>
            <Card className={'mt-2 p-2'}>
                <Card.Body className={'p-0 border border-1 rounded-2'}>
                    <TransferViewDataTable
                        dataItems={transfer?.items?.filter(f => !f.is_deleted)}
                    />
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default ViewTransfer;