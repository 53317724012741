import {createHashRouter} from 'react-router-dom'
import App from "./App";
import MainLayout from "./layout/MainLayout";
import SettingLayout from "./layout/SettingLayout";
import InfoOrganization from "./pages/settings/InfoOrganization";
import Systems from "./pages/settings/Systems";
import Sample from "./pages/settings/Sample";
import Role from "./pages/settings/Role";
import Billing from "./pages/user/Billing";
import Currency from "./pages/user/Currency";
import Notification from "./pages/user/Notification";
import AuthLayout from "./layout/AuthLayout";
import SignOut from "./pages/authentication/sign-out/SignOut";
import Register from "./pages/authentication/sign-up/Register";
import ForgetPassword from "./pages/authentication/forget-password/ForgetPassword";
import React from "react";
import Error404 from "./pages/error/Error404";
import Authentication from "./pages/authentication/Authentication";
import LoginWithCertificate from "./components/form/auth/login/LoginWithCertificate";
import LoginWithUsername from "./components/form/auth/login/LoginWithUsername";
import RegistrationWithoutInn from "./pages/authentication/sign-up/RegistrationWithoutInn";
import WarehouseLayout from "./layout/WarehouseLayout";
import ItemLayout from "./layout/ItemLayout";
import ItemDataTable from "./pages/warehouse/item/ItemDataTable";
import Packages from "./components/user/billing/Packages";
import AddItem from "./pages/warehouse/item/AddItem";
import EditItem from "./pages/warehouse/item/EditItem";
import ImportItemsFromExcel from "./pages/warehouse/item/ImportItemsFromExcel";
import OperationLayout from "./layout/OperationLayout";
import Sales from "./pages/warehouse/operation/sale/Sales";
import WarehouseDashboard from "./pages/dashboard/WarehouseDashboard";
import Contractor from "./pages/warehouse/contractor/Contractor";
import ContractorContact from "./pages/warehouse/contractor-contact/ContractorContact";
import ContractorPayments from "./pages/warehouse/contractor-payments/ContractorPayments";
import Warehouses from "./pages/warehouse/warehouses/Warehouses";
import Transfer from "./pages/warehouse/transfer/Transfer";
import AddSale from "./pages/warehouse/operation/sale/AddSale";
import ViewTransfer from "./pages/warehouse/transfer/ViewTransfer";
import EditSale from "./pages/warehouse/operation/sale/EditSale";
import ViewSale from "./pages/warehouse/operation/sale/ViewSale";
import AddTransfer from "./pages/warehouse/transfer/AddTransfer";
import EditTransfer from "./pages/warehouse/transfer/EditTransfer";
import Revisions from "./pages/warehouse/operation/revisions/Revisions";
import Purchases from "./pages/warehouse/operation/purchase/Purchases";
import AddPurchase from "./pages/warehouse/operation/purchase/AddPurchase";
import ViewPurchase from "./pages/warehouse/operation/purchase/ViewPurchase";
import AddRevisions from "./pages/warehouse/operation/revisions/AddRevisions";
import EditPurchase from "./pages/warehouse/operation/purchase/EditPurchase";
import ViewRevisions from "./pages/warehouse/operation/revisions/ViewRevisions";
import EditRevisions from "./pages/warehouse/operation/revisions/EditRevisions";
import Refunds from "./pages/warehouse/operation/refund/Refunds";
import AddRefund from "./pages/warehouse/operation/refund/AddRefund";
import EditRefund from "./pages/warehouse/operation/refund/EditRefund";
import ViewRefund from "./pages/warehouse/operation/refund/ViewRefund";
import ReturnPurchases from "./pages/warehouse/operation/return-purchase/ReturnPurchases";
import AddReturnPurchase from "./pages/warehouse/operation/return-purchase/AddReturnPurchase";
import EditReturnPurchase from "./pages/warehouse/operation/return-purchase/EditReturnPurchase";
import ViewReturnPurchase from "./pages/warehouse/operation/return-purchase/ViewReturnPurchase";
import WriteOffs from "./pages/warehouse/operation/write-offs/WriteOffs";
import ViewWriteOffs from "./pages/warehouse/operation/write-offs/ViewWriteOffs";
import AddWriteOffs from "./pages/warehouse/operation/write-offs/AddWriteOffs";
import EditWriteOffs from "./pages/warehouse/operation/write-offs/EditWriteOffs";
import OrganizationChooser from "./components/organization/OrganizationChooser";
import ChooseOrganization from "./components/form/auth/chooseOrganization/ChooseOrganization";

const routes = [
    {
        element: <App/>,
        children: [
            {
                path: "/authentication/",
                element: <AuthLayout/>,
                children: [
                    {
                        path: '/authentication/',
                        element: <Authentication/>
                    },
                    {
                        path: 'login',
                        element: <LoginWithUsername/>
                    },
                    {
                        path: 'select-organization',
                        element: <ChooseOrganization/>
                    },
                    {
                        path: 'login/with-certificate',
                        element: <LoginWithCertificate/>
                    },
                    {
                        path: 'logout',
                        element: <SignOut/>
                    },
                    {
                        path: 'register/without-inn',
                        element: <RegistrationWithoutInn/>
                    },
                    {
                        path: 'register',
                        element: <Register/>
                    },
                    {
                        path: 'forgot-password',
                        element: <ForgetPassword/>
                    }
                ]
            },
            {
                path: "/",
                element: (
                    <MainLayout/>
                ),
                children: [
                    {
                        path: "*",
                        element: <Error404/>
                    },
                    {
                        path: "/",
                        element: (
                            <WarehouseLayout/>
                        ),
                        children: [
                            {
                                index: true,
                                element: <WarehouseDashboard/>
                            },
                            {
                                path: "contractor",
                                element: <Contractor/>,
                            },
                            {
                                path: "contractor-payments",
                                element: <ContractorPayments/>,
                            },
                            {
                                path: "contractor-contact",
                                element: <ContractorContact/>,
                            },
                            {
                                path: "warehouses",
                                element: <Warehouses/>,
                            },

                            {
                                path: "items/",
                                element: (<ItemLayout/>),
                                children: [
                                    {
                                        index: true,
                                        element: <ItemDataTable/>
                                    },
                                    {
                                        path: "add",
                                        element: <AddItem/>
                                    },
                                    {
                                        path: "edit/:id",
                                        element: <EditItem/>
                                    },
                                    {
                                        path: "registry-add",
                                        element: <ImportItemsFromExcel/>
                                    },
                                ]
                            },
                            {
                                path: "operation/",
                                element: (<OperationLayout/>),
                                children: [
                                    {
                                        path: "sales",
                                        element: <Sales/>
                                    },
                                    {
                                        path: "sales/register",
                                        element: <AddSale/>
                                    },
                                    {
                                        path: "sales/update/:id",
                                        element: <EditSale/>
                                    },
                                    {
                                        path: "sales/view/:id",
                                        element: <ViewSale/>
                                    },
                                    // PURCHASE
                                    {
                                        path: "purchases",
                                        element: <Purchases/>
                                    },
                                    {
                                        path: "purchases/register",
                                        element: <AddPurchase/>
                                    },
                                    {
                                        path: "purchases/update/:id",
                                        element: <EditPurchase/>
                                    },
                                    {
                                        path: "purchases/view/:id",
                                        element: <ViewPurchase/>
                                    },
                                    // REVISION
                                    {
                                        path: "revisions",
                                        element: <Revisions/>
                                    },
                                    {
                                        path: "revisions/register",
                                        element: <AddRevisions/>
                                    },
                                    {
                                        path: "revisions/view/:id",
                                        element: <ViewRevisions/>
                                    },
                                    {
                                        path: "revisions/update/:id",
                                        element: <EditRevisions/>
                                    },
                                    // WRITE-OFF
                                    {
                                        path: "write-offs",
                                        element: <WriteOffs/>
                                    },
                                    {
                                        path: "write-offs/view/:id",
                                        element: <ViewWriteOffs/>
                                    },
                                    {
                                        path: "write-offs/register",
                                        element: <AddWriteOffs/>
                                    },
                                    {
                                        path: "write-offs/update/:id",
                                        element: <EditWriteOffs/>
                                    },
                                    // REFUNDS
                                    {
                                        path: "refunds",
                                        element: <Refunds/>
                                    },
                                    {
                                        path: "refunds/register",
                                        element: <AddRefund/>
                                    },
                                    {
                                        path: "refunds/update/:id",
                                        element: <EditRefund/>
                                    },
                                    {
                                        path: "refunds/view/:id",
                                        element: <ViewRefund/>
                                    },
                                    //  RETURN-PURCHASE
                                    {
                                        path: "return-purchases",
                                        element: <ReturnPurchases/>
                                    },
                                    {
                                        path: "return-purchases/register",
                                        element: <AddReturnPurchase/>
                                    },
                                    {
                                        path: "return-purchases/update/:id",
                                        element: <EditReturnPurchase/>
                                    },
                                    {
                                        path: "return-purchases/view/:id",
                                        element: <ViewReturnPurchase/>
                                    },
                                    // TRANSFERS
                                    {
                                        path: "transfers",
                                        element: <Transfer/>,
                                    },
                                    {
                                        path: "transfers/view/:id",
                                        element: <ViewTransfer/>
                                    },
                                    {
                                        path: "transfers/register",
                                        element: <AddTransfer/>
                                    },
                                    {
                                        path: "transfers/update/:id",
                                        element: <EditTransfer/>
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        path: "/settings/",
                        element: <SettingLayout/>,
                        children: [
                            {
                                path: 'billing',
                                element: <Billing/>
                            },
                            {
                                path: 'packages',
                                element: <Packages/>
                            },
                            {
                                path: 'currency',
                                element: <Currency/>
                            },
                            {
                                path: 'notification',
                                element: <Notification/>
                            },
                            {
                                path: 'info-organization',
                                element: <InfoOrganization/>
                            },
                            {
                                path: 'systems',
                                element: <Systems/>
                            },
                            {
                                path: 'sample',
                                element: <Sample/>
                            },
                            {
                                path: 'role',
                                element: <Role/>
                            },
                        ]
                    },
                ]
            },
        ]
    }
]

// export const router = createBrowserRouter(routes)
export const router = createHashRouter(routes)

export default routes
