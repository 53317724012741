import {createSlice} from "@reduxjs/toolkit";
import settingsService from "../../services/settings/settingsService";
import EventBus from "../../../eventbus/EventBus";
import {
    UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED,
    UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED,
    UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED
} from "../../../enum/settings/settingsEvents";

const initialState= {
    settings: null,
    defaultSettings: null
}
const settingsReducer = createSlice({
    name: "settings",
    initialState,
    reducers:{
        updateSettings: (state, action) => {
            state.settings = action.payload;
        },
        updateDefaultSettings: (state, action) => {
            state.defaultSettings = action.payload
        }
    }
})

export const selectInvoiceRegistrySettings = state => state.settings.settings?.invoice_registry;
export const selectDefaultSetting = state => state.settings.defaultSettings;


export const loadSettings = () => (dispatch) => {
    settingsService.getSettings()
        .then((response) => {
            dispatch(settingsReducer.actions.updateSettings(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}

export const updateLangSettingsAsync = ({payload}) => dispatch => {

    return new Promise((resolve, reject) => {
        settingsService.updateLangSettings({payload})
            .then((response) => {
                resolve(response)
                dispatch(settingsReducer.actions.updateSettings(response.data))
            })
            .catch((error) => {
                console.log(error)
                // reject(error)
            })
    })
}

export const updateInvoiceRegistrySettingsAsync = (payload) => {
    EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        settingsService.updateInvoiceRegistrySettings(payload)
            .then(settings => {
                EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_SUCCEED, settings);
                resolve();
            })
            .catch(error => {
                EventBus.dispatch(UPDATE_INVOICE_REGISTRY_SETTINGS_FAILED, error);
                reject();
            })
    })
}

export const loadDefaultSettings = () => (dispatch) => {
    settingsService.getDefaultSettings()
        .then((response) => {
            dispatch(settingsReducer.actions.updateDefaultSettings(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}
export default settingsReducer.reducer
