import React from 'react';

const IconCurrencyInfo = ({width = 16, height = 16, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.75 7.25C8.75 6.83579 8.41421 6.5 8 6.5C7.58579 6.5 7.25 6.83579 7.25 7.25H8.75ZM7.25 11C7.25 11.4142 7.58579 11.75 8 11.75C8.41421 11.75 8.75 11.4142 8.75 11H7.25ZM8.03735 5H8.78735C8.78735 4.58579 8.45157 4.25 8.03735 4.25V5ZM8.03735 5.075L8.03883 5.825C8.45246 5.82419 8.78735 5.48864 8.78735 5.075L8.03735 5.075ZM7.96265 5.07515H7.21265C7.21265 5.27431 7.29187 5.4653 7.43284 5.606C7.57381 5.74669 7.76495 5.82554 7.96412 5.82515L7.96265 5.07515ZM7.96265 5V4.25C7.54843 4.25 7.21265 4.58579 7.21265 5H7.96265ZM8 14C4.68629 14 2 11.3137 2 8H0.5C0.5 12.1421 3.85786 15.5 8 15.5V14ZM2 8C2 4.68629 4.68629 2 8 2V0.5C3.85786 0.5 0.5 3.85786 0.5 8H2ZM8 2C11.3137 2 14 4.68629 14 8H15.5C15.5 3.85786 12.1421 0.5 8 0.5V2ZM14 8C14 11.3137 11.3137 14 8 14V15.5C12.1421 15.5 15.5 12.1421 15.5 8H14ZM7.25 7.25V11H8.75V7.25H7.25ZM7.28735 5V5.075H8.78735V5H7.28735ZM8.03588 4.325L7.96117 4.32515L7.96412 5.82515L8.03883 5.825L8.03588 4.325ZM8.71265 5.07515V5H7.21265V5.07515H8.71265ZM7.96265 5.75H8.03735V4.25H7.96265V5.75Z"
                fill="#9BA2B1"/>
        </svg>

    );
};

export default IconCurrencyInfo;
