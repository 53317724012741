import React, {Fragment, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {loadItemAsync} from "../../../store/reducers/item/itemReducer";
import IconButton from "../../IconButton";
import {Modal} from "react-bootstrap";
import WarehouseOperationEditItemForm from "./WarehouseOperationEditItemForm";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../common/FalconCloseButton";

const WarehouseOperationEditItem = ({id, onItemEdited, allowEdit}) => {

    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate

    const [item, setItem] = useState(null);
    const [price, setPrice] = useState(null);

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
        loadItemAsync(id)
            .then(({item, price}) => {
                setItem(item);
                setPrice(price);
            })
            .catch(() => {
                setItem(null);
                setPrice(null);
            })
    }


    return (
        <Fragment>

            <IconButton
                onClick={handleShow}
                variant="phoenix-secondary"
                disabled={!id}
                icon={faEdit}
                style={{zIndex: 0}}
                className="position-relative bg-transparent"
            />
            <Modal show={show} onHide={handleClose} fullscreen className={'davr-modal'}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "Редактировать")}</Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    {item && price &&
                        <WarehouseOperationEditItemForm item={item}
                                                        price={price}
                                                        setShow={setShow}
                                                        allowEdit={allowEdit}
                                                        onItemEdited={onItemEdited}
                        />
                    }
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default WarehouseOperationEditItem;
