import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useParams} from "react-router-dom";
import {getReturnPurchaseItemAsync} from "../../../../store/reducers/warehouse/returnPurchase";
import {loadContractorAsync, updateCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS,
    WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS
} from "../../../../eventbus/warehouse/returnPurchaseEvent";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import ReturnPurchase from "../../../../components/warehouse/operation/return-purchase/ReturnPurchase";

const ViewReturnPurchase = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const [returnPurchase, setReturnPurchase] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()

    const loadReturnPurchase = () => {
        getReturnPurchaseItemAsync({returnPurchaseId: id})
            .then(response => {
                setReturnPurchase(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setReturnPurchase(null))
    }

    useEffect(() => {
        loadReturnPurchase()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onRegisterPaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, () => {
            loadReturnPurchase()
        })

        const onUpdatePaymentHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, () => {
            loadReturnPurchase()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_REGISTER_PAYMENT_SUCCESS, onRegisterPaymentHandler)
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_UPDATE_PAYMENT_SUCCESS, onUpdatePaymentHandler)
        }
    },[])

    if (!returnPurchase) return <SpinnerComponent />

    return (
        <ReturnPurchase returnPurchase={returnPurchase}/>
    );
};

export default ViewReturnPurchase;
