import React, {cloneElement, Fragment, useState} from 'react';
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {
    saleDownloadContractorLastPaymentPdfAsync,
    saleDownloadPdfAsync, saleDownloadReceipt58PdfAsync, saleDownloadReceipt80PdfAsync,
    saleDownloadSkuPdfAsync
} from "../../../../store/reducers/warehouse/saleReducer";
import printJS from "print-js";
import {Dropdown, NavDropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import IconPrinter from "../../../../assets/icon/IconPrinter";

const PrintSaleDropdown = ({saleId, children, ...props}) => {

    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const printDefaultPdf = id => {
        setLoading(true);

        saleDownloadPdfAsync({saleId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const printSkuPdf = id => {
        setLoading(true);

        saleDownloadSkuPdfAsync({saleId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const printContractorLastPaymentPdf = id => {
        setLoading(true);

        saleDownloadContractorLastPaymentPdfAsync({saleId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const printDefaultReceipt80Pdf = id => {
        setLoading(true);

        saleDownloadReceipt80PdfAsync({saleId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const printDefaultReceipt58Pdf = id => {
        setLoading(true);

        saleDownloadReceipt58PdfAsync({saleId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }


    return (
        <Fragment>
            <Dropdown.Header
                className="text-black">{t(lang, 'warehouse.operation.item.common.printing_invoice')}</Dropdown.Header>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.print_invoice')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printSkuPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.print_sku_invoice')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printContractorLastPaymentPdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.print_contractor_last_payment_invoice')}
            </Dropdown.Item>
            <Dropdown.Header
                className="text-black">{t(lang, 'warehouse.operation.item.common.printing_receipt')}</Dropdown.Header>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultReceipt80Pdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.print_receipt_80')}
            </Dropdown.Item>
            <Dropdown.Item className="ps-4" disabled={loading} onClick={() => printDefaultReceipt58Pdf(saleId)}>
                {t(lang, 'warehouse.operation.item.common.print_receipt_58')}
            </Dropdown.Item>
        </Fragment>
    );
};

export default PrintSaleDropdown;
