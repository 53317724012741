import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import styled, { createGlobalStyle } from 'styled-components';
import { selectDateFormat } from '../../store/reducers/main/main';
import { useAppContext } from "../../pheonix/providers/AppProvider";
import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div`
    position: relative;
`;

const StyledDatePicker = styled(DatePicker)`
    width: 100%;

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__input-container input {
        width: 100%;
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 4px;
        background: ${({ theme }) => (theme === 'dark' ? '#333' : '#fff')};
        color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
    }
`;

const Label = styled.label.withConfig({
    shouldForwardProp: (prop) => prop !== 'isActive'
})`
    position: absolute;
    top: ${props => (props.isActive ? '12px' : '50%')};
    left: 10px;
    font-size: ${props => (props.isActive ? '12px' : '14px')};
    color: ${props => (props.isActive ? '#aaa' : (props.theme === 'dark' ? 'rgb(159, 166, 188)' : '#000'))};
    padding: 0 5px;
    transition: 0.2s;
    pointer-events: none;
    transform: translateY(-50%);
    z-index: 1;
`;

const GlobalStyle = createGlobalStyle`
    .react-datepicker {
        background: ${({theme}) => (theme === 'dark' ? '#333' : '#fff')};
        border: ${({theme}) => (theme === 'dark' ? '1px solid #555' : '1px solid #ccc')};
        color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};
    }

    .react-datepicker__header {
        background: ${({theme}) => (theme === 'dark' ? '#444' : '#f0f0f0')};
        border-bottom: ${({theme}) => (theme === 'dark' ? '1px solid #555' : '1px solid #ccc')};
    }

    .react-datepicker-time__header,
    .react-datepicker__current-month,
    .react-datepicker__navigation {
        color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};
    }

    .react-datepicker__navigation {
        border-color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};

        &::before {
            border-color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};
        }
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day:hover,
    .react-datepicker__time-list-item--selected,
    .react-datepicker__time-list-item--selected:hover,
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        background-color: #007bff;
        color: #fff;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-top: none;
        border-bottom-color: ${({theme}) => (theme === 'dark' ? '#444' : '#f0f0f0')};
    }

    .react-datepicker__time-container {
        background: ${({theme}) => (theme === 'dark' ? '#333' : '#fff')};
        border-left: ${({theme}) => (theme === 'dark' ? '1px solid #555' : '1px solid #ccc')};
    }

    .react-datepicker__time-list-item {
        background: ${({theme}) => (theme === 'dark' ? '#333' : '#fff')};
        color: ${({theme}) => (theme === 'dark' ? '#fff' : '#000')};
    }

    .react-datepicker__time-list-item:hover {
        background-color: #007bff;
        color: #fff;
    }

    .react-datepicker__time-list-item--selected {
        background-color: #007bff;
        color: #fff;
    }

    .react-datepicker__time-list-item--selected:hover {
        background-color: #0056b3;
        color: #fff;
    }
`;

const DatePickerComponent = ({ field, placeholderText, ...props }) => {
    const { config: { theme } } = useAppContext();
    const dateFormat = useSelector(selectDateFormat);
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef(null);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const handleChange = (date) => {
        field.onChange(date);
    }

    return (
        <Container className={props?.containerClass || ''}>
            <GlobalStyle theme={theme} />
            <StyledDatePicker
                className="form-control"
                selected={field.value}
                onChange={(date) => handleChange(date)}
                dateFormat={dateFormat}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={inputRef}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                theme={theme}
                {...props}
            />
            <Label theme={theme} isActive={isFocused || field.value}>{placeholderText}</Label>
        </Container>
    );
};

export default DatePickerComponent;
