import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectWarehouses} from "../../../store/reducers/warehouse/warehouseReducer";
import {Translate} from "../../../lang/lang";
import Badge from "../../common/Badge";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const WarehouseOperationWarehouseSelect = ({options, defaultValue, onChange, disabled, onFocus, autoFocus, onBlur, placeholder, ...props}) => {
    const [value, setValue] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang);
    const t = Translate;

    const ref = useRef(null)
    useEffect(() => {
        if (defaultValue !== null && !options) {
            const val = warehouses.find(ware => ware?.id === defaultValue)
            setValue(val)
        } else if(defaultValue && options) {
            const val = options?.find(ware => ware?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    },[options, defaultValue])

    const onChangeWarehouse = option => {
        setValue(option)
        onChange(option)
    }

    const getOptionValue = option => option?.id

    const getOptionLabel = option => option?.name
    const formatOptionLabel = option => {
        return(
            <div className="d-flex justify-content-between">
                {option?.name}
                <Badge bg={option?.state >= 0 ? "success-davr" : "danger-davr"}>
                    {option?.state}
                </Badge>
            </div>
        )
    }

    useEffect(() => {
        onFocus && ref?.current?.focus()
    }, [onFocus])

    return (
        <CustomFloatingLabelSelect
            ref={ref}
            options={options || warehouses || []}
            onBlur={() => onBlur && onBlur(value)}
            value={value}
            autoFocus={autoFocus}
            onChange={onChangeWarehouse}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            formatOptionLabel={formatOptionLabel}
            label={placeholder}
            hideSelectedOptions={true}
            noOptionsMessage={() => t(lang, "no options")}
            isDisabled={disabled}
            menuPortalTarget={document.body}
            {...props}
        />
    );
};

export default WarehouseOperationWarehouseSelect;
