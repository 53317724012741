import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import {Image} from "react-bootstrap";

const ImageSlider = ({ product: { images, isNew } }) => {

    let slider1;
    let slider2;
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoPlay: true,
        autoplaySpeed: 2000,
    }

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    return (
        <div className="position-relative h-sm-75 overflow-hidden">
            {images.length === 0 && (
                <Image fluid
                       className="fit-cover w-sm-100 h-sm-100 rounded"
                       style={{
                           maxWidth: "808px",
                           maxHeight: "385px"
                       }}
                       src={require("../../../assets/img/imagePlaceholder.png")}
                       alt="imagePlaceholder.png"
                />
            )}
            {images.length === 1 && (
                <Image
                    fluid
                    className="w-sm-100 h-sm-100 rounded"
                    src={`${process.env.REACT_APP_HOST}/cdn/${images[0].path}`}
                    alt={`${images[0].name}`}
                    style={{
                        maxWidth: "808px",
                        maxHeight: "385px",
                        backgroundPosition: "center",
                        objectFit: "contain"
                    }}
                />
            )}
            {images.length > 1 && (
                <>
                    <div className="product-slider" style={{
                        maxWidth: "808px",
                        maxHeight: "385px"
                    }}>
                        <Slider
                            {...settings}
                            asNavFor={nav2}
                            ref={slider => (slider1 = slider)}
                            className="slick-slider-arrow-inner h-100 full-height-slider"
                        >
                            {images.map(img => (
                                <div key={img.id} className="d-flex justify-content-center align-items-center m-auto">
                                    <Image
                                        fluid
                                        rounded
                                        className="w-sm-100 h-sm-100"
                                        src={`${process.env.REACT_APP_HOST}/cdn/${img.path}`}
                                        alt={img.name}
                                        key={img.id}
                                        style={{
                                            maxWidth: "808px",
                                            maxHeight: "385px",
                                            backgroundPosition: "center",
                                            objectFit: "contain",
                                        }}
                                    />
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <Slider
                        slidesToShow={images.length > 5 ? 4 : images.length}
                        asNavFor={nav1}
                        ref={slider => (slider2 = slider)}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        centerMode={true}
                        arrows={false}
                        className="slick-slider-arrow-inner mt-1 mr-n1 mb-n1"
                    >
                        {images.map(img => (
                            <div className="px-1 outline-none d-flex justify-content-center align-items-center m-auto"
                                 key={img.id}>
                                <Image
                                    fluid
                                    rounded
                                    className="cursor-pointer w-sm-100 h-sm-50"
                                    src={`${process.env.REACT_APP_HOST}/cdn/${img.path}`}
                                    alt={img.name}
                                    style={{
                                        maxHeight: "105px",
                                        backgroundPosition: "center",
                                        objectFit: "contain"
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                </>
            )}
        </div>
    );
};

export default ImageSlider;
