import React, {useRef} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {
    CatalogCodeAndPackageOk,
    CatalogCodeDeleted,
    CatalogCodeInvalidPackage,
    CatalogCodeMoved, ServerError
} from "../../../enum/item/itemWrapper";
import dayjs from "dayjs";
import Badge from "../../common/Badge";
import {OverlayTrigger, Popover} from "react-bootstrap";

const CatalogStatusBadge = ({catalogCode, tasnif_info, badgeProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const ref = useRef(null);

    const getBadgeVariant = (tasnif_info) => {
        if (tasnif_info === null)
            return "primary";

        switch (tasnif_info.status) {
            case CatalogCodeAndPackageOk:
                return "success";
            case CatalogCodeMoved:
            case CatalogCodeInvalidPackage:
                return "warning";
            case CatalogCodeDeleted:
                return "danger";
            case ServerError:
                return "secondary";
            default:
                return "secondary";
        }
    };
    const getBadgeText = (tasnif_info) => {
        if (tasnif_info === null)
            return t(lang, "items.common.catalog.unknown");

        switch (tasnif_info.status) {
            case CatalogCodeAndPackageOk:
                return t(lang, "items.common.catalog.is_active");
            case CatalogCodeMoved:
                return t(lang, "items.common.catalog.moved_to", {code: tasnif_info.moved});
            case CatalogCodeInvalidPackage:
                return t(lang, "items.common.catalog.package_edited");
            case CatalogCodeDeleted:
                return t(lang, "items.common.catalog.deleted");
            case ServerError:
                return t(lang, "items.common.catalog.server_error");
            default:
                return t(lang, "items.common.catalog.unknown");
        }
    };

    const getPopoverBodyText = (tasnif_info) => {
        if (tasnif_info === null)
            return t(lang, "items.common.catalog.popover_text");
        return t(lang, "items.common.catalog.last_sync_time", {time: dayjs(tasnif_info.check_date).format("YYYY-MM-DD HH:mm:ss")})
    };
    const badgeVariant = getBadgeVariant(tasnif_info);
    const badgeText = getBadgeText(tasnif_info);
    const popoverBodyText = getPopoverBodyText(tasnif_info);

    return (
        <div className={'d-flex gap-2'}>
            <OverlayTrigger trigger={['hover', 'focus']}
                            placement="right"
                            overlay={
                                    <Popover id="popover-basic" className='ms-0'>
                                        <Popover.Header>
                                            <Badge bg={badgeVariant}>
                                                <span className="fs-8 fw-bold">{t(lang, 'items.common.catalog')}</span>
                                            </Badge>
                                        </Popover.Header>
                                        <Popover.Body>
                                            <h5>
                                                <span className='me-2'>{t(lang, 'items.common.catalog.status')}:</span>
                                                <Badge bg={badgeVariant}>{badgeText}</Badge>
                                            </h5>
                                            {popoverBodyText}
                                        </Popover.Body>
                                    </Popover>
                            }
                            target={ref.current}
            >
                {/*<Badge bg={badgeVariant} ref={ref} {...badgeProps}>*/}
                    <span className={''}>{catalogCode}</span>
                {/*</Badge>*/}
            </OverlayTrigger>
        </div>
    );
};

export default CatalogStatusBadge;
