import React, {cloneElement, Fragment, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {selectItemsDictionary} from "../../../../store/reducers/item/itemReducer";
import itemDictionaryWrapper from "../../../../enum/item/itemDictionaryWrapper";
import {Button, Modal} from "react-bootstrap";
import Badge from "../../../common/Badge";

const CheckSaleItemStateAvailableForSaleWrapper = ({isCheckEnabled, itemId, itemWarehouseId, quantityAmount, children}) => {

    const [show, setShow] = useState(false);
    const itemsDictionary = useSelector(selectItemsDictionary);
    const lang = useSelector(selectLang);
    const t = Translate;

    const {props, ...element} = children;

    const isSaleBlocked = useMemo(() => {
        if (isCheckEnabled) {
            const operationItem = itemsDictionary[itemId];
            const itemWarehouse = operationItem?.warehouse_states?.warehouse_items?.find(wi => wi.id === itemWarehouseId);
            if (itemWarehouse?.state < quantityAmount) {
                return true;
            }
        }

        return false;
    }, [isCheckEnabled, itemId, itemWarehouseId, quantityAmount])

    const itemWarehouseState = useMemo(() => {
        return itemDictionaryWrapper.getWarehouseState(itemsDictionary, itemId, itemWarehouseId);
    }, [itemsDictionary, itemId, itemWarehouseId])

    const handleShow = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setShow(true);
    };
    const handleClose = () => setShow(false);

    return (
        <Fragment>
            {!!isSaleBlocked ? cloneElement(element, {...props, onClick: handleShow}) : children}
            <Modal show={show} onHide={handleClose} centered={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>{t(lang, 'warehouse.item.check_sale_item_available_modal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <span>{t(lang, 'warehouse.item.check_sale_item_available_modal.description')}:</span><br/>
                        <span className={'mx-1 text-dark fw-semi-bold'}>{itemDictionaryWrapper.getName(itemsDictionary, itemId)}:</span>
                        <Badge bg={itemWarehouseState >= 0 ? 'primary-davr' : 'danger-davr'} className={'mx-1 fs-0'}>{itemWarehouseState}</Badge>
                    </div>
                </Modal.Body>
                <Modal.Footer className={'d-flex flex-center'}>
                    <Button autoFocus={true} onClick={handleClose}>{t(lang, 'warehouse.operation.item.common.button.back')}</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CheckSaleItemStateAvailableForSaleWrapper;
