import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {excelFileUploader} from "../../../helpers/excel-uploader";
import IconButton from "../../../components/IconButton";
import {getExcelContractor} from "../../../store/reducers/contractor/contractorReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import IconCloudUpload from "../../../assets/icon/IconCloudUpload";

const UploadContractorsListToExcel = ({data}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);

    const ids = data.map(item => item.id)
    const getExcel = () => {
        setLoading(true)
        getExcelContractor(ids)
            .then((res) => {
                excelFileUploader({
                    content: res,
                    fileName: t(lang, "crm.bind.contractor.account.contractors")
                })
            })
            .catch(() => {
            })
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <IconButton
                variant='phoenix-warning'
                size="sm"
                as={'div'}
                className={'btn-davr bg-white text-dark d-flex gap-2'}
                iconClassName={"text-primary-davr fs-8"}
                icon={''}
                onClick={getExcel}
            >
                {loading
                    ? <FontAwesomeIcon className="me-1 text-primary" pulse={true} icon={faSpinner}/>
                    : <IconCloudUpload/>
                }
                <span className="d-none d-sm-inline-block">
                        {t(lang, 'edi.order.datatable.header.navigation.dropdown.export_to_excel')}
                    </span>
            </IconButton>
        </Fragment>
    );
};

export default UploadContractorsListToExcel;
