import React, {useEffect} from 'react';
import EventBus from "../../eventbus/EventBus";
import toast from "react-hot-toast";
import {Translate} from "../../lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED,
    REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED,
    UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS
} from "../../eventbus/notificationEvents";
import {
    loadNotificationsAsync,
    selectNotificationsData
} from "../../store/reducers/telegram-notification/telegramNotification";
import NotificationUpdate from "../../components/user/notification/NotificationUpdate";
import NotificationDelete from "../../components/user/notification/NotificationDelete";
import AdvanceTableWrapper from "../../components/table/AdvanceTableWrapper";
import AdvanceTable from "../../components/table/AdvanceTable";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import {Card, Dropdown} from "react-bootstrap";

const Notification = () => {
    const lang = useSelector(selectLang)
    const t = Translate
    const dispatch = useDispatch()

    const data = useSelector(selectNotificationsData)

    useEffect(() => {
        const onRegisterHandlerSuccess = EventBus.on(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS, () => {
            toast.success('Success')
            dispatch(loadNotificationsAsync())
        })

        const onRegisterHandlerFailed = EventBus.on(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED, () => {
            toast.error("Error")
        })

        const onUpdateHandlerSuccess = EventBus.on(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS, () => {
            toast.success('Success')
            dispatch(loadNotificationsAsync())
        })
        const onUpdateHandlerFailed = EventBus.on(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED, () => {
            toast.error('Error')
        })

        return () => {
            EventBus.remove(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_SUCCESS, onRegisterHandlerSuccess)
            EventBus.remove(REGISTER_NOTIFICATION_ADD_PHONE_NUMBER_FAILED, onRegisterHandlerFailed)
            EventBus.remove(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_SUCCESS, onUpdateHandlerSuccess)
            EventBus.remove(UPDATE_NOTIFICATION_EVENTS_PHONE_NUMBER_FAILED, onUpdateHandlerFailed)
        }
    }, []);

    useEffect(() => {
        dispatch(loadNotificationsAsync())
    }, []);

    const columns = [
        {
            accessor: 'phoneNumber',
            Header: t(lang, "notification.table.phone_number"),
            cellProps: {
                className: 'ps-3'
            },
            headerProps: {className: 'ps-3'},
            Cell: ({row: {original}}) => {
                return (
                    <span className="fw-medium">{original.phone_number}</span>
                );
            }
        },
        {
            accessor: 'action',
            Header: "",
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <NotificationUpdate notification={original}/>
                            <NotificationDelete notification={original}/>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                );
            }
        },
    ]


    return (
        <AdvanceTableWrapper
            pagination
            perPage={1000}
            columns={columns}
            data={data}
            selection
        >
            <Card className={'p-0 rounded-4'}>
                <Card.Body className={'p-0'}>
                    <AdvanceTable
                        table
                        rowClassName="align-middle white-space-nowrap"
                        headerClassName="text-900 text-nowrap align-middle"
                        tableProps={{
                            size: 'sm',
                            className: 'fs-8 mb-0 overflow-hidden',
                        }}
                    />
                </Card.Body>
            </Card>

        </AdvanceTableWrapper>
    );
};

export default Notification;
