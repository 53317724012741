import React, {Fragment} from 'react';
import {ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import Button from "./Button";
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight, faGear} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {settingsServices} from "../../enum/settings/settingsServices";
import Flex from "./Flex";
import RevealDropdown from "./RevealDropdown";

const CommonDropdownButton = () => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Fragment>

            <RevealDropdown
                icon={
                    <Button
                        className="btn-davr px-3 py-0 bg-primary-davr text-white"
                        variant="phoenix-secondary"
                    >
                        <div className={'d-flex gap-2 align-items-center justify-content-between '}>
                            <Fragment>
                                <FontAwesomeIcon icon={faGear} className={'text-white'}/>
                                <span className="dropdown-line d-none d-sm-inline-block fw-middle ms-1 text-white fs-8 text-nowrap fs-md-9 me-2">{t(lang, "main.top.nav.profile_dropdown.dropdown_item.settings")}</span>
                            </Fragment>
                            <FontAwesomeIcon className={'text-primary-davr text-white ms-2'} icon={faChevronDown}/>
                        </div>
                    </Button>
                }
            >
                {settingsServices.map((item, index) => <Dropdown.Item as={Link} to={item?.query ? `${item.to}?${item.query}=${true}` : item.to} key={index}>
                    <Flex justifyContent={"between"} alignItems={"center"}>
                        {t(lang, item.title)}
                        <FontAwesomeIcon className={'text-primary-davr'} icon={faChevronRight}/>
                    </Flex>
                </Dropdown.Item>)
                }
            </RevealDropdown>
        </Fragment>


    );
};

export default CommonDropdownButton;
