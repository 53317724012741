import React, {Fragment} from 'react';
import {Translate} from "../../../../lang/lang";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {Controller, useFormContext} from "react-hook-form";
import {Button, Card, Col, Collapse, Form, Row} from "react-bootstrap";
import WarehouseOperationSelectStatus from "../../../../components/warehouse/operation/WarehouseOperationSelectStatus";
import DatePickerComponent from "../../../../components/common/DatePickerComponent";
import {addDays} from "date-fns";
import WarehouseOperationWarehouseSelect
    from "../../../../components/warehouse/operation/WarehouseOperationWarehouseSelect";
import Flex from "../../../../components/common/Flex";

const WriteOffsDataTableFilter = ({
                                      isOpenFilterCollapse
                                  }) => {

    const t = Translate
    const lang = useSelector(selectLang)
    const dateFormat = useSelector(selectDateFormat);

    const {control} = useFormContext()

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div className={'mt-2'}>
                    <Card className={'p-2'}>

                        <Row className='mb-3 g-3'>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field}) => (
                                        <WarehouseOperationSelectStatus
                                            placeholder={t(lang, "warehouse.operation.item.common.sale_status")}
                                            defaultValue={field.value}
                                            onChange={data => field.onChange(data.value)}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="date_time_start"
                                    control={control}
                                    render={({field}) => (
                                        <DatePickerComponent
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                            dateFormat={`${dateFormat} HH:mm`}
                                            selected={field?.value}
                                            onChange={field.onChange}
                                            showTimeSelect
                                            field={field}
                                            timeFormat="p"
                                            timeIntervals={60}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller
                                    name="date_time_end"
                                    control={control}
                                    render={({field}) => (
                                        <DatePickerComponent
                                            className='form-control'
                                            placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                            dateFormat={`${dateFormat} HH:mm`}
                                            field={field}
                                            selected={field?.value}
                                            onChange={field.onChange}
                                            maxDate={addDays(new Date(), 5)}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={60}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={4} xl={3}>
                                <Controller name={'warehouse_id'} render={({field}) => (
                                    <WarehouseOperationWarehouseSelect
                                        onChange={field.onChange}
                                        isClearable
                                        placeholder={t(lang, "warehouse.navbar.routes.warehouse")}
                                    />
                                )}/>
                            </Form.Group>
                            <Col sm={12} md={12}>
                                <Flex className={'gap-2 align-items-center justify-content-end h-100'}>
                                    <Button className={'btn-davr bg-primary-davr text-white'} variant="primary"
                                           form={'write-offs'} type={'submit'}>{t(lang, "roaming.invoices.table_header.show")}</Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default WriteOffsDataTableFilter;