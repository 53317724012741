import React, {Fragment, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import {registerNotification} from "../../../store/reducers/telegram-notification/telegramNotification";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import NotificationForm from "./NotificationForm";
import NotificationEvents from "./NotificationEvents";
import IconButton from "../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../common/FalconCloseButton";

const NotificationRegister = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const t = Translate
    const lang = useSelector(selectLang)
    const handleShow = () => setShow(true);
    const handleClose = () => {
        methods.reset()
        setShow(false)
    };

    const methods = useForm({
        defaultValues: {
            phoneNumber: "",
            eventCodes: []
        }
    })

    const onSubmit = (formData) => {
        setLoading(true)
        const phoneNumber = formData.phoneNumber
        const eventCodes = formData.eventCodes.map(eventCode => eventCode.value)

        registerNotification({phoneNumber: phoneNumber, eventCodes: eventCodes})
            .then(() => handleClose())
            .catch(e => new Error(e))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-end">
                <IconButton
                    icon={faPlus}
                    onClick={handleShow}
                    size={"sm"}
                    iconAlign={"left"}
                    variant="phoenix-secondary"
                    iconClassName="fs-7 text-primary-davr align-middle"
                    className={'btn-davr bg-white text-dark gap-2'}
                    transform="shrink-3"
                >
                    {t(lang, "notification.table.register")}
                </IconButton>
            </div>

            <Modal show={show} className={"davr-modal"}  onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "notification.table.register.modal_title")}</Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form onSubmit={methods.handleSubmit(onSubmit)} id={"notification-register"}>
                            <NotificationForm/>
                            <NotificationEvents/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="btn-davr px-3 py-0 bg-primary-davr" disabled={loading} type={"submit"} form={"notification-register"}>
                        {loading && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                        />}
                        {t(lang, "notification.add_button")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default NotificationRegister;
