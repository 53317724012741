import React, {Fragment} from 'react';
import {Button, Card, Col, Collapse, FloatingLabel, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {selectFilterOptionsContractorPayments} from "../../../store/reducers/contractor/contractorPaymentReducer";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {ContractorPaymentSources} from "../../../enum/ContractorPaymentSources";
import {Translate} from "../../../lang/lang";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {changeFilterOptionsAsync} from "../../../store/reducers/contractor/contractorPaymentReducer";
import SelectContractor from "../contractor/SelectContractor";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";
import DatePickerComponent from "../../../components/common/DatePickerComponent";
import Flex from "../../../components/common/Flex";

const ContractorPaymentDataTableFilter = ({isOpenFilterCollapse}) => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const paymentFilters = useSelector(selectFilterOptionsContractorPayments);
    const methods = useForm({
        defaultValues: {
            contractor: paymentFilters.contractor_id || null,
            date_start: paymentFilters.date_start ? dayjs(paymentFilters.date_start).toDate() : null,
            date_end: paymentFilters.date_end ? dayjs(paymentFilters.date_end).toDate() : null,
            source: ContractorPaymentSources.find(source => source.value === paymentFilters?.payment_source) || null
        },
    });

    const onSubmitFilter = ({date_end, date_start, contractor, source, ...filters}) => {
        date_start = date_start && dayjs(date_start).format("YYYY-MM-DD");
        date_end = date_end && dayjs(date_end).format("YYYY-MM-DD");
        const contractor_id = contractor?.id
        const payment_source = source?.value
        dispatch(changeFilterOptionsAsync({
            page: 1,
            date_start,
            date_end,
            contractor_id,
            payment_source,
            ...filters
        }))
    }

    const resetFilter = () => {
        dispatch(changeFilterOptionsAsync({
            contractor_id: undefined,
            date_start: null,
            date_end: null,
            payment_source: undefined
        }))
        methods.reset()
    }

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div className={'mt-2'}>
                    <Card className={'p-2'}>
                        <Form onSubmit={methods.handleSubmit(onSubmitFilter)}>
                            <Row className={'mb-3 g-3'}>
                                <Col sm={12} md={3}>
                                    <Controller
                                        control={methods.control}
                                        name="contractor"
                                        render={({field}) => (
                                            <SelectContractor
                                                label={"Контрагент"}
                                                placeholder={t(lang, "edi.common.button.select")}
                                                onChange={field.onChange}
                                                defaultValue={field?.value}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col sm={12} md={3}>
                                    <Controller name={'source'}
                                                className={''}
                                                control={methods.control}
                                                render={({field}) => {
                                                    return (
                                                        <CustomFloatingLabelSelect
                                                            label={'Тип'}
                                                            options={ContractorPaymentSources}
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            getOptionValue={option => option.value}
                                                            getOptionLabel={option => t(lang, option.label)}
                                                            placeholder={t(lang, "edi.common.button.select")}
                                                            isClearable
                                                            hideSelectedOptions/>
                                                    )
                                                }}
                                    />
                                </Col>
                                <Col sm={12} md={3}>
                                    <Controller
                                        control={methods.control}
                                        name="date_start"
                                        render={({field}) => (
                                            <DatePickerComponent
                                                isClearable
                                                className='form-control'
                                                placeholderText={"Дата оплата (начало)"}
                                                field={field}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col sm={12} md={3}>
                                    <Controller
                                        control={methods.control}
                                        name="date_end"
                                        render={({field}) => (
                                            <DatePickerComponent
                                                isClearable
                                                className='form-control'
                                                placeholderText={"Дата оплата (конец)"}
                                                field={field}
                                            />
                                        )}
                                    />
                                </Col>
                                <Col sm={12} md={12}>
                                    <Flex className={'gap-2 align-items-center justify-content-end h-100'}>
                                        <Button className={'btn-davr bg-white-davr text-dark'} variant="phoenix-primary"
                                                type='button'
                                                onClick={() => resetFilter()}>{t(lang, "items.common.clear_imported_file")}</Button>
                                        <Button className={'btn-davr bg-primary-davr text-white'} variant="primary"
                                                type={"submit"}
                                        >{t(lang, "roaming.invoices.table_header.show")}</Button>
                                    </Flex>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
}


export default ContractorPaymentDataTableFilter;