import React, {Fragment, useEffect, useState} from 'react';
import {selectSaleFilterOptions, changeFilterOptionsAsync} from "../../../../store/reducers/warehouse/saleReducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import {Col, Form, Row} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {useNavigate, Link} from "react-router-dom";
import UploadSalesToExcel from "./UploadSalesToExcel";
import IconButton from "../../../IconButton";
import IconPlus from "../../../../assets/icon/IconPlus";
import IconFilter from "../../../../assets/icon/IconFilter";
import IconStroke from "../../../../assets/icon/IconStroke";
import IconCaretDown from "../../../../assets/icon/IconCaretDown";
import DatatableHeaderFilter from "./DatatableHeaderFilter";

const SaleDatatableHeader = ({selectedRowIds, predefinedFilters}) => {
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);
    const filter = useSelector(selectSaleFilterOptions)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate

    const form = useForm({
        defaultValues: {
            date_time_start: filter?.date_time_start && dayjs(filter?.date_time_start, "YYYY-MM-DD HH:mm").toDate(),
            date_time_end: filter?.date_time_end && dayjs(filter?.date_time_end, "YYYY-MM-DD HH:mm").toDate(),
            contractor_id: null,
            status: null,
            warehouse_id: null
        }
    })

    useEffect( () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            Object.keys(params).forEach(key => {
                form.setValue(key, params[key]);
            });
        }
    }, [predefinedFilters])

    const onSubmit = (formData) => {
        dispatch(changeFilterOptionsAsync({
            ...filter,
            date_time_start: formData?.date_time_start && dayjs(formData?.date_time_start).format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: formData?.date_time_end && dayjs(formData?.date_time_end).format("YYYY-MM-DD HH:mm:ss"),
            is_approved: formData?.status,
            contractor_id: formData?.contractor_id,
            warehouse_id: formData?.warehouse_id?.id,
        }))
    }

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-sale-header'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-sale-header'))
    }, []);

    return (
        <Fragment>
            <Row id={'warehouse-sale-header'} className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem',  zIndex: '999'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.operation.item.common.sale.list")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <UploadSalesToExcel />
                    <IconButton
                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                        variant={'phoenix-secondary'}
                        size="sm"
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                        transform="shrink-3"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter/>
                            <span className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">
                                {t(lang, "warehouse.operation.item.common.filter")}
                            </span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke/>
                            <IconCaretDown/>
                        </div>
                    </IconButton>
                    <IconButton
                        size="sm"
                        variant="falcon-primary"
                        className="btn-davr px-3 py-0 bg-primary-davr"
                        as={Link}
                        to={'/operation/sales/register'}
                    >
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">
                            {t(lang, "warehouse.operation.item.common.new_sales")}
                        </span>
                    </IconButton>
                </Col>
                <Col xs={12}>
                    <FormProvider {...form}>
                        <Form id={'sale'} onSubmit={form.handleSubmit(onSubmit)}>
                            <DatatableHeaderFilter predefinedFilters={predefinedFilters} name={t(lang, "warehouse.operation.item.common.list_sales")} contractor isOpenFilterCollapse={isOpenFilterCollapse}/>
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Fragment>
    );
};

export default SaleDatatableHeader;
