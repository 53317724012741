import React from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {currencyConverterConvert} from "../../../store/reducers/currency/currencyReducer";
import {InputGroup} from "react-bootstrap";
import CleaveWithCommaMark from "../../common/CleaveWithCommaMark";
import SelectCurrency from "./SelectCurrency";

const InputGroupWithCurrency = ({
                                    priceFieldName,
                                    currencyFieldName,
                                    placeholder
                                }) => {
    const form = useFormContext();
    const onChangeCurrencyHandler = async (oldCurrency, newCurrency) => {
        try {
            if (oldCurrency && oldCurrency.id !== newCurrency.id) {
                const {currency_to: {value}} = await currencyConverterConvert({
                    currency_id_from: oldCurrency.id,
                    value_from: +form.getValues(priceFieldName),
                    currency_id_to: newCurrency.id
                });
                form.setValue(priceFieldName, value ? +value.toFixed(4) : '');
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <InputGroup>
            <Controller name={`${priceFieldName}`}
                        render={({field}) => (
                                <CleaveWithCommaMark value={field.value}
                                                     onChange={field.onChange}
                                                     ref={field.ref}
                                                     placeholder={placeholder}
                                                     className="w-75"
                                />
                        )}
            />
            <Controller name={`${currencyFieldName}`}
                        render={({field}) => (
                            <SelectCurrency defaultCurrencyId={field.value}
                                            onChange={async (oldCurrency, newCurrency) => {
                                                await onChangeCurrencyHandler(oldCurrency, newCurrency);
                                                field.onChange(newCurrency ? newCurrency.id : null);
                                            }}
                            />
                        )}
            />
        </InputGroup>
    );
};

export default InputGroupWithCurrency;
