import React, {Fragment, useEffect} from 'react';
import {Translate} from "../../../../lang/lang";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterOptionsAsync,
    getReturnPurchasesAsync,
    getReturnPurchasesCountAsync,
    returnPurchaseDownloadPdfAsync,
    selectReturnPurchaseCount,
    selectReturnPurchaseFilterOptions,
    selectReturnPurchases
} from "../../../../store/reducers/warehouse/returnPurchase";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {
    isApprovedCheckedWarehouseOperation,
    ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId,
    ItemsTotalSumDebtsGroupPaymentTypeCurrencyId,
    ItemsTotalSumGroupCurrencyId,
    OperationTotalSumInfo
} from "../../../../enum/warehouse-operation/warehouseOperation";
import printJS from "print-js";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {Card, Dropdown} from "react-bootstrap";
import numeral from "numeral";
import Badge from "../../../common/Badge";
import RevealDropdown, {RevealDropdownTrigger} from "../../../common/RevealDropdown";
import ReturnPurchaseDelete from "./ReturnPurchaseDelete";
import EventBus from "../../../../eventbus/EventBus";
import {WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS} from "../../../../eventbus/warehouse/returnPurchaseEvent";
import AdvanceTableWrapper from "../../../table/AdvanceTableWrapper";
import WarehouseTabs from "../../WarehouseTabs";
import AdvanceTable from "../../../table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../table/AdvanceTableCustomPagination";
import ReturnPurchaseDatatableHeader from "./ReturnPurchaseDatatableHeader";

const ReturnPurchaseDatatable = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const numberFormat = useSelector(selectNumberFormat)
    const returnPurchases = useSelector(selectReturnPurchases)
    const dispatch = useDispatch()
    const filters = useSelector(selectReturnPurchaseFilterOptions)
    const count = useSelector(selectReturnPurchaseCount)
    const lang = useSelector(selectLang)
    const t = Translate

    const totalSumArray = ItemsTotalSumGroupCurrencyId(returnPurchases)
    const totalSumCashBoxPaymentType = ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId(returnPurchases)
    const totalSumDebtsPaymentType = ItemsTotalSumDebtsGroupPaymentTypeCurrencyId(returnPurchases)

    const debtTypeCurrency = []

    const downloadPdfReturnPurchase = ({id, number}) => {
        returnPurchaseDownloadPdfAsync({returnPurchaseId: id})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.return_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPrint = ({id}) => {
        returnPurchaseDownloadPdfAsync({returnPurchaseId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }

    // debt calc
    {
        totalSumArray?.forEach(itemPrices => {
            if (itemPrices.currency_id) {
                const foundDebtState = totalSumDebtsPaymentType?.find(x => x.currency_id === itemPrices.currency_id);
                const totalSum = foundDebtState ? itemPrices.totalSum - foundDebtState.totalSum : itemPrices.totalSum;

                debtTypeCurrency.push({
                    id: itemPrices.currency_id,
                    name: itemPrices.name,
                    totalSum: totalSum
                });
            }
        });

        totalSumDebtsPaymentType?.forEach(itemPrices => {
            if (itemPrices.currency_id) {
                const foundSeparationCurrency = totalSumArray.find(separationCurrency => separationCurrency.currency_id === itemPrices.currency_id);
                const totalSum = foundSeparationCurrency ? itemPrices.totalSum - foundSeparationCurrency.totalSum : itemPrices.totalSum;

                if (!foundSeparationCurrency) {
                    debtTypeCurrency.push({
                        id: itemPrices.currency_id,
                        name: itemPrices.name,
                        totalSum: totalSum
                    });
                }
            }
        });
    }

    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.date_return_purchase"),
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{dayjs(original?.date).format("YYYY-MM-DD")}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "warehouse.operation.item.common.executor"),
            cellProps: {
                className: 'px-2'
            },
            Cell: ({row: {original}}) => {
                return (
                    <div
                        title={original?.contractor?.name}
                        className="text-truncate"
                        style={{
                            maxWidth: '150px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {original?.contractor?.name}
                    </div>
                )
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Продавец"),
            cellProps: {className: 'px-2'},
            Cell: ({row: {original}}) => {
                return (
                    <div
                        title={original?.account?.name || original?.account?.username}
                        className="text-truncate"
                        style={{maxWidth: '100px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    >
                        <span>{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },

        {
            Header: t(lang, "warehouse.operation.item.common.total_sum"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfo(original?.items?.filter(f => !f?.is_deleted))


                return separationCurrencyCalc?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <span className={'d-flex text-end mb-1 gap-2 justify-content-end align-items-end'}>
                                <strong className={'fs-8'}>
                                    {numeral.formats[numberFormat].format(item?.totalSum)}
                                </strong>
                                <small>{item?.name}</small>
                            </span>
                        </div>
                    )
                })
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.debt_state"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                const debtTypeCurrency = []

                // debt calc
                {
                    original?.net_price?.forEach(price => {
                        if (price?.currency?.id) {
                            const foundDebtState = original?.payment?.debt_states?.find(debtState => debtState.currency.id === price?.currency?.id);
                            const totalSum = foundDebtState ? price?.amount - foundDebtState.amount : price?.amount;

                            debtTypeCurrency.push({
                                id: price?.currency?.id,
                                name: price?.currency?.name,
                                totalSum: totalSum
                            });
                        }
                    });

                    original?.payment?.debt_states?.forEach(debt => {
                        if (debt.currency.id) {
                            const foundSeparationCurrency = original?.net_price?.find(price => price?.currency?.id === debt.currency.id);
                            const totalSum = foundSeparationCurrency ? debt.amount - foundSeparationCurrency.totalSum : debt.amount;

                            if (!foundSeparationCurrency) {
                                debtTypeCurrency.push({
                                    id: debt.currency.id,
                                    name: debt.currency.name,
                                    totalSum: totalSum
                                });
                            }
                        }
                    });
                }


                if (debtTypeCurrency?.length) {
                    return (
                        debtTypeCurrency?.map((x, index) => {
                            if (x.totalSum) {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        <span
                                            className={'d-flex text-end mb-1 gap-2 justify-content-end align-items-end'}>
                                            <strong className={'fs-8'}>
                                                {numeral.formats[numberFormat].format(x?.totalSum)}
                                            </strong>
                                            <small>{x?.name}</small>
                                        </span>
                                    </div>
                                )
                            }
                        })
                    )
                }
                return ''
            }
        },
        {
            Header: "Статус",
            id: "status",
            filterable: false,
            headerProps: {className: 'text-end pe-2'},
            cellProps: {className: 'text-end ps-2', width: '35px'},
            Cell: ({row}) => {
                return <>
                    <Badge variant={"phoenix"}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                           bg={row?.original?.is_approved ? 'success-davr' : 'warning-davr'}
                    >
                        {t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}
                    </Badge>
                </>
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                    <RevealDropdown>
                        <Dropdown.Item as={Link} to={`/operation/return-purchases/update/${original?.id}`}>
                            <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                        </Dropdown.Item>
                        <ReturnPurchaseDelete id={original?.id}/>
                        <Dropdown.Item as={Link} to={`/operation/return-purchases/view/${original?.id}`}>
                            <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => downloadPdfReturnPurchase({id: original?.id, number: original?.number})}>
                            <span>{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => onPrint({id: original?.id})}>
                            <span>{t(lang, "warehouse.operation.item.common.print")}</span>
                        </Dropdown.Item>
                    </RevealDropdown>
                </RevealDropdownTrigger>
            )
        }
    ]

    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))

    const loadReturnPurchases = () => {
        dispatch(getReturnPurchasesAsync({...filters}))
        dispatch(getReturnPurchasesCountAsync({...filters}))
    }

    useEffect(() => {
        loadReturnPurchases()

        const onReturnPurchaseDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS, () => {
            loadReturnPurchases()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_RETURN_PURCHASE_DELETE_SUCCESS, onReturnPurchaseDeletedHandler)
        }
    }, [activeOrganization, filters])

    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={returnPurchases}
                pagination
                sortable
                perPage={1000}
                selection
            >
                <ReturnPurchaseDatatableHeader/>
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <WarehouseTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                            // isLoading={loading}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

        </Fragment>
    );
};

export default ReturnPurchaseDatatable;
