import React, {useEffect, useState} from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {selectWarehouses} from "../../store/reducers/warehouse/warehouseReducer";
import CustomFloatingLabelSelect from "../floating-custom-select/CustomFloatingLabelSelect";

const SelectWarehouse = ({options, onChange, placeholder, id, defaultWarehouseId, ...props}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const warehouses = useSelector(selectWarehouses);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(warehouses.find(o => o.id === defaultWarehouseId))
    }, [defaultWarehouseId]);

    const handleChange = (option) => {
        onChange(option)
    }

    return (
            <CustomFloatingLabelSelect
                {...props}
                label={placeholder}
                options={warehouses}
                hideSelectedOptions
                value={value}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={handleChange}
                noOptionsMessage={() => t(lang, "roaming.invoices.datatable.filter.branches_not_found")}
            />
    );
};

export default SelectWarehouse;
