import React from 'react';
import {Translate} from "../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Button, Card, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import IconChevronLeftDuo from "../../assets/icon/IconChevronLeftDuo";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconChevronRightDuo from "../../assets/icon/IconChevronRightDuo";

const AdvanceTableCustomPagination2 = ({
                                           rowsPerPageOptions,
                                           setPageSize,
                                           pageSize,
                                           canPreviousPage,
                                           canNextPage,
                                           previousPage,
                                           nextPage,
                                           pageCount,
                                           pageIndex,
                                           gotoPage,
                                           onLimitChange,
                                           onPageChange,
                                       }) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const customPaginationArray = [];
    const range = 2
    for (let i = pageIndex - range; i <= pageCount && i <= pageIndex + range; i++) {
        if (i >= 0 && i < pageCount)
            customPaginationArray.push(i)
    }

    return (
        <Row className="d-flex flex-sm-row mt-3 justify-content-between">
            <div className="w-25 d-flex align-items-center flex-column flex-sm-row">
                <Card className={'p-1 w-50'}>
                    <FloatingLabel label={t(lang, "edi.advance_table_custom_pagination.limit")}>
                        <Form.Select
                            size="sm"
                            onChange={e => {
                                const pageSize = +e.target.value;
                                setPageSize(pageSize);
                                onLimitChange(pageSize);
                            }}
                            selected
                            className="w-100"
                            defaultValue={pageSize}
                        >
                            {rowsPerPageOptions.map(option => (
                                <option key={option}
                                        value={option}>{option}</option>
                            ))}
                        </Form.Select>
                    </FloatingLabel>
                </Card>

                {/*<p className="mx-2 mb-0">{t(lang, "edi.advance_table_custom_pagination.limit")}</p>*/}
            </div>
            <div className="w-auto">
                <Card className={'d-flex p-1 gap-2 flex-nowrap w-auto'}>
                    <div className={'d-flex align-items-center'}>
                        <Button
                            size="sm"
                            variant="phoenix-secondary"
                            onClick={() => {
                                gotoPage(0);
                                onPageChange(1);
                            }}
                            disabled={!canPreviousPage}
                            className="bg-white border-0"
                        >
                            <IconChevronLeftDuo/>
                        </Button>


                        <Button
                            size="sm"
                            onClick={() => {
                                previousPage();
                                onPageChange(pageIndex);
                            }}
                            disabled={!canPreviousPage}
                            variant="phoenix-secondary"
                            className="bg-white border-0"
                            style={{padding: "13px 23px"}}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} size={"xs"}/>
                        </Button>


                        <ul className="pagination mb-0 d-flex gap-1 align-items-center">
                            {customPaginationArray.map(page => {
                                return <li key={page}>
                                    <Button
                                        variant={page === pageIndex ? "" : "phoenix-secondary"}
                                        className={classNames('page', 'border-0', {
                                            'bg-card': page === pageIndex,
                                            'bg-white': page !== pageIndex,
                                        })}
                                        onClick={() => {
                                            gotoPage(page);
                                            onPageChange(page + 1)
                                        }}
                                    >
                                        {page + 1}
                                    </Button>
                                </li>
                            })}
                        </ul>

                        <Button
                            size="sm"
                            variant="phoenix-secondary"
                            className="bg-white border-0"
                            onClick={() => {
                                nextPage();
                                onPageChange(pageIndex + 2);
                            }}
                            disabled={!canNextPage}
                            style={{padding: "13px 23px"}}
                        >
                            <FontAwesomeIcon icon={faChevronRight} size={"xs"}/>
                        </Button>

                        <Button
                            size="sm"
                            variant="phoenix-secondary"
                            className="bg-white border-0"
                            onClick={() => {
                                gotoPage(pageCount - 1);
                                onPageChange(pageCount);
                            }}
                            disabled={!canNextPage}
                        >
                            <IconChevronRightDuo/>
                        </Button>
                    </div>
                </Card>
            </div>
        </Row>
);
};

export default AdvanceTableCustomPagination2;
