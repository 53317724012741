import classNames from 'classnames';
import React, {Fragment, useState} from 'react';
import {Button, Form, Row, Col, InputGroup, Card, FloatingLabel} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {Translate} from "../../lang/lang";
import {selectLang} from "../../store/reducers/main/main";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconChevronRightDuo from "../../assets/icon/IconChevronRightDuo";
import IconChevronLeftDuo from "../../assets/icon/IconChevronLeftDuo";

export const AdvanceTableCustomPagination = ({count, limit, page, onPageChange, onLimitChange}) => {
    const [query, setQuery] = useState("");

    let maxPage = Math.ceil(count / limit);
    if(count === 0)
        maxPage = Math.ceil((count + 1)/limit);
    const lang = useSelector(selectLang);
    const t = Translate;
    const pages = [];

    const range = 2
    for (let i = page - range; i <= maxPage && i <= page + range; i++) {
        if (i >= 1 && i <= maxPage)
            pages.push(i)
    }
    const pageLimitOptions = [
        {text: "10", value: 10},
        {text: "25", value: 25},
        {text: "50", value: 50},
        {text: "75", value: 75},
        {text: "100", value: 100},
    ]

    const onGoToPageClicked = () => {
        if (query && +query > 0 && +query <= maxPage)
            onPageChange(+query)
    }

    return (
        <Fragment>
            {!!count && <Row className="d-flex flex-sm-row mt-3 justify-content-between">
                <Col cols={"auto"} className="d-none justify-content-center align-items-center justify-content-xxl-end my-3 my-md-0 flex-column flex-sm-row">
                    <InputGroup as={Form.Group} size="sm" className="w-75">
                        <p className="mx-2 mb-0 ">{t(lang, "edi.advance_table_custom_pagination.to")}</p>
                        <Form.Control
                            type="number"
                            name="customPagination"
                            className="input-spin-none"
                            onChange={event => setQuery(event.target.value)}
                        />
                        next
                    </InputGroup>
                </Col>
                <div className="w-25 d-flex align-items-center flex-column flex-sm-row">
                    <Card className={'p-1 w-50'}>
                        <FloatingLabel label={t(lang, "edi.advance_table_custom_pagination.limit")}>
                            <Form.Select
                                size="sm"
                                onChange={e => onLimitChange(+e.target.value)}
                                //selected={true}
                                className="w-100"
                                defaultValue={limit}
                            >
                                {pageLimitOptions.map(option => (
                                    <option key={option.value}
                                            value={option.value}>{option.text}</option>
                                ))}
                            </Form.Select>
                        </FloatingLabel>
                    </Card>

                    {/*<p className="mx-2 mb-0">{t(lang, "edi.advance_table_custom_pagination.limit")}</p>*/}
                </div>
                <div className="w-auto">
                    <Card className={'d-flex p-1 gap-2 flex-nowrap w-auto'}>
                        <div className={'d-flex align-items-center'}>
                            <Button
                                size="sm"
                                variant="phoenix-secondary"
                                onClick={() => onPageChange(page = 1)}
                                disabled={page === 1}
                                className="bg-white border-0"
                            >
                                <IconChevronLeftDuo/>
                            </Button>

                            <Button
                                size="sm"
                                onClick={() => onPageChange(page - 1)}
                                disabled={page === 1}
                                variant="phoenix-secondary"
                                className="bg-white border-0"
                                style={{padding: "13px 23px"}}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} size={"xs"}/>
                            </Button>
                            <ul className="pagination mb-0 d-flex gap-1 align-items-center">
                                {pages.map(p => {
                                    return <li key={p}>
                                        <Button
                                            // style={{padding: "12px 20px"}}
                                            variant={p === page ? "" : "phoenix-secondary"}
                                            className={classNames('page', 'border-0', {
                                                'bg-card': p === page,
                                                'bg-white': p !== page,
                                            })}
                                            onClick={() => {
                                                if (p !== page)
                                                    onPageChange(p)
                                            }}
                                        >
                                            {p}
                                        </Button>
                                    </li>
                                })}
                            </ul>
                            <Button
                                size="sm"
                                variant="phoenix-secondary"
                                className="bg-white border-0"
                                onClick={() => onPageChange(page + 1)}
                                disabled={page === maxPage}
                                // style={{padding: "13px 23px"}}
                            >
                                <FontAwesomeIcon icon={faChevronRight} size={"xs"}/>
                            </Button>

                            <Button
                                size="sm"
                                variant="phoenix-secondary"
                                className="bg-white border-0"
                                onClick={() => onPageChange(page = maxPage)}
                                disabled={page === maxPage}
                            >
                                <IconChevronRightDuo/>
                            </Button>
                        </div>
                    </Card>
                </div>
            </Row>}
        </Fragment>
    );
};

export default AdvanceTableCustomPagination;
