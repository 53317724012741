import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    getRefundItemAsync,
    refundAddItemAsync, refundChangeEmployeeAsync,
    refundRegisterAsync, refundUpdateNoteAsync,
    selectRefundDefaultsSettings
} from "../../../../../store/reducers/warehouse/refundReducer";
import {selectLang} from "../../../../../store/reducers/main/main";
import {selectCurrency} from "../../../../../store/reducers/currency/currencyReducer";
import {selectWarehouses} from "../../../../../store/reducers/warehouse/warehouseReducer";
import {selectDefaultSetting} from "../../../../../store/reducers/settings/settingsReducer";
import {Translate} from "../../../../../lang/lang";
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import EventBus from "../../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS
} from "../../../../../eventbus/warehouse/refundEvent";
import {Form} from "react-bootstrap";
import RefundForm from "./RefundForm";
import {UZS} from "../../../../../enum/item/itemWrapper";

const AddRefundForm = () => {

    const [response, setResponse] = useState(null)
    const refundDefaultsSettings = useSelector(selectRefundDefaultsSettings)
    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [refund, setRefund] = useState(null)
    const warehouses = useSelector(selectWarehouses)
    const currencies = useSelector(selectCurrency)
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)

    const form = useForm({
        defaultValues: {
            contractor_id: null,
            employee_id: refundDefaultsSettings?.defaultEmployeeId || null,
            cashbox_id: settings?.cash_box?.default_id || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        }
    })

    const resetForm = (formData) => {
        form.reset({
            contractor_id: formData?.contractor?.id || formData?.contractor_id,
            employee_id: formData?.employee?.id || formData?.employee_id,
            cashbox_id: settings?.cash_box?.default_id || formData?.cashbox?.id || formData?.cashbox_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
            commonPrice: {
                amount: '0',
                currency_id: currencies?.find(c => c?.code === UZS)?.id,
            },
        })
    }

    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name
        const quantity = +formData?.quantity * +formData?.package_measurements?.quantity

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: quantity,
            marks: formData?.marks || [],
            price: {
                amount: +formData?.commonPrice?.amount,
                currency_id: formData?.commonPrice?.currency_id,
            },
        }


        if ((buttonType === "addOrEditRefund" || buttonType === "addOrEditRefundMore") || (event === "addOrEditRefund" || event === "addOrEditRefundMore")) {

           if (buttonType === "addOrEditRefund" || event === "addOrEditRefund") {
               setLoading(true)
           } else {
               setLoadingMore(true)
           }

            if (!refund) {
                await refundRegisterAsync({
                    contractor_id: formData?.contractor_id,
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                    cashbox_id: formData?.cashbox_id
                })
                    .then(({data}) => {
                        setResponse(data)
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error.response.data?.error?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                        setLoadingMore(false)
                    })
            }
            /*if (refund && response?.contractor?.id !== formData?.contractor_id) {
                await refundChangeContractorAsync({refundId: refund.id, contractorId: formData?.contractor_id})
                    .then(({data}) => {
                        setResponse(data)
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }*/
            if (refund) {
                await refundAddItemAsync({
                    refundId: refund?.id,
                    operation: item
                })
                    .then(() => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse") || error?.response?.data?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        setLoading(false)
                        setLoadingMore(false)
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || refund?.employee) {
            if (formData?.employee_id !== refund?.employee?.id) {
                refundChangeEmployeeAsync({refundId: refund?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setRefund(data, data.items.reverse())
                    })
                    .catch(() => {})
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== refund?.note) {
            refundUpdateNoteAsync({refundId: refund?.id, note: formData?.note})
                .then(({data}) => {
                    setRefund(data, data.items.reverse())
                })
                .catch(() => {})
        }
    }

    useEffect(() => {
        const onAddClientHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`/operation/refunds/view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, (id) => {
            loadRefund(id)
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, (id) => {
            loadRefund(id)
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_CLIENT_SUCCESS, onAddClientHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    const loadRefund = async (id) => {
        await getRefundItemAsync({refundId: id})
            .then((response) => {
                setRefund(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setRefund(null)
            })
    }

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <RefundForm refund={refund} loadingMore={loadingMore} loading={loading} addItem={form.handleSubmit(onSubmit)} isEditing={false}/>
            </Form>
        </FormProvider>
    );
};

export default AddRefundForm;
