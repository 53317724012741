import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterOptionsAsync,
    getTransfersAsync,
    getTransfersCountAsync,
    selectTransferCount,
    selectTransferFilterOptions,
    selectTransfers,
    transferDownloadPdfAsync
} from "../../../store/reducers/warehouse/transferReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import printJS from "print-js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faEye, faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {Card, Dropdown} from "react-bootstrap";
import {isApprovedCheckedWarehouseOperation} from "../../../enum/warehouse-operation/warehouseOperation";
import dayjs from "dayjs";
import {WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS} from "../../../eventbus/warehouse/transferEvent";
import EventBus from "../../../eventbus/EventBus";
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import WarehousesTabs from "../warehouses/WarehousesTabs";
import AdvanceTable from "../../../components/table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../components/table/AdvanceTableCustomPagination";
import TransferDataTableHeader from "./TransferDataTableHeader"
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import {selectOrganizationTemplates, uploadTransferToExcelAsync} from "../../../store/reducers/excel/excelReducer";
import {downloadFile} from "../../../helpers/excel-uploader";
import {TYPE_TRANSFER} from "../../../enum/excel/ExcelTemplateTypes";
import Badge from "../../../components/common/Badge";

const TransferDataTable = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const transfers = useSelector(selectTransfers)
    const dispatch = useDispatch()
    const filters = useSelector(selectTransferFilterOptions)
    const count = useSelector(selectTransferCount)
    const lang = useSelector(selectLang)
    const t = Translate

    const [transferTypeTemplates, setTransferTypeTemplates] = useState([]);

    const organizationTemplates = useSelector(selectOrganizationTemplates);

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_TRANSFER) || [];
        setTransferTypeTemplates(templates);
    }, [organizationTemplates])

    const downloadPdfTransfer = ({id, number}) => {
        transferDownloadPdfAsync({transferId: id})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.transfer_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onPrint = ({id}) => {
        transferDownloadPdfAsync({transferId: id})
            .then(response => {
                const blob = new Blob([response], {type: "application/pdf"});
                const blobUrl = window.URL.createObjectURL(blob);
                printJS(blobUrl);
            })
            .catch(error => {
                console.log(error)
            })
    }

    const uploadTransfer = (uri, id, number) => {
        uploadTransferToExcelAsync({uri, id})
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, 'warehouse.item.download_excel.transfer_name', {number}),
                });
            })
            .catch((error) => console.log("error", error))
    }


    const columns = [
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),

            Cell: ({row: {original}}) => {
                return (
                    dayjs(original?.date).format("YYYY-MM-DD HH:mm:ss")
                )
            }
        },
        {
            id: 'warehouse_from.id',
            Header: t(lang, "warehouse.operation.item.common.warehouse_from"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.items[0]?.warehouse_item_from?.warehouse?.name}
                    </>
                )
            }
        },
        {
            id: 'warehouse_to.id',
            Header: t(lang, "warehouse.operation.item.common.warehouse_to"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original?.items[0]?.warehouse_item_to?.warehouse?.name}
                    </>
                )
            }
        },
        {
            id: 'name',
            Header: t(lang, "warehouse.operation.item.common.sale_status"),
            Cell: ({row: {original}}) => {
                return (
                    <>
                        <Badge
                            variant={'phoenix'}
                            className={'px-3 py-1 fs-9 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}
                            bg={original?.is_approved ? 'success' : 'warning'}>
                            {t(lang, isApprovedCheckedWarehouseOperation(original?.is_approved))}
                        </Badge>
                    </>
                )
            }
        },
        // {
        //     accessor: 'account.username',
        //     Header: t(lang, "Пользователь"),
        //     headerProps: {
        //         className: "text-center"
        //     },
        //     cellProps: {
        //         className: "text-center"
        //     },
        //     Cell: ({row: {original}}) => {
        //         return (
        //             <div className="d-flex justify-content-center">
        //                 <span
        //                     className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
        //             </div>
        //         )
        //     }
        // },
        // {
        //     accessor: 'note',
        //     Header: t(lang, "Информация"),
        //     headerProps: {
        //         className: "text-start"
        //     },
        //     cellProps: {
        //         className: "text-end"
        //     },
        //     Cell: ({row: {original}}) => {
        //         return (
        //             <div style={{maxWidth: "250px", minWidth: "250px"}}>
        //                 <span className="d-flex text-wrap">{original?.note}</span>
        //             </div>
        //         )
        //     }
        // },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                        <RevealDropdown>
                            <Dropdown.Item
                                as={Link} to={`update/${original?.id}`}
                                className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faEdit}/>
                                <span>{t(lang, "edi.common.edit_button")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => downloadPdfTransfer({id: original?.id, number: original?.number})}
                                className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faFilePdf}/>
                                <span>{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onPrint({id: original?.id})} className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faPrint}/>
                                <span>{t(lang, "warehouse.operation.item.common.print")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                as={Link}
                                to={`view/${original?.id}`}
                                className="text-primary">
                                <FontAwesomeIcon className="me-1" icon={faEye}/>
                                <span>{t(lang, "warehouse.dashboard.items.transfer.view")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => {
                                    const [defaultTemplate] = transferTypeTemplates;
                                    uploadTransfer(defaultTemplate.uri, original?.id, original?.number);
                                }}
                                className="text-primary">
                                <FontAwesomeIcon className={'me-1'} icon={faFileExcel}/>
                                <span>{t(lang, "warehouse.dashboard.items.transfer.download_excel")}</span>
                            </Dropdown.Item>
                        </RevealDropdown>
                    </RevealDropdownTrigger>
                </div>
            )
        }
    ]


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))


    const loadTransfers = () => {
        dispatch(getTransfersAsync({...filters}))
        dispatch(getTransfersCountAsync({...filters}))
    }


    useEffect(() => {
        loadTransfers()

        const onTransferDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS, () => {
            loadTransfers()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_TRANSFER_DELETE_SUCCESS, onTransferDeletedHandler)
        }
    }, [activeOrganization, filters])


    return (
        <AdvanceTableWrapper
            columns={columns}
            data={transfers}
            pagination
            selection
            perPage={1000}
            width={'50px'}
        >
            <TransferDataTableHeader table/>
            <Card className={'p-0 rounded-4'}>
                <Card.Body className={'p-0'}>
                    <WarehousesTabs/>
                    <AdvanceTable
                        table
                        headerClassName="text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            className: 'fs--1 mb-0'
                        }}
                    />
                </Card.Body>
            </Card>
            <AdvanceTableCustomPagination
                count={count}
                limit={filters.limit}
                page={filters.page}
                onLimitChange={onLimitChange}
                onPageChange={onPageChange}
            />
        </AdvanceTableWrapper>
    );
};

export default TransferDataTable;