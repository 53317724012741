import React, {useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {excelImportColumnOptions} from "../../../enum/item/itemExcelWrapper";
import {utils, writeFile} from "xlsx";
import {Card, Col, Row, Table, Form, Button} from "react-bootstrap";
import SelectWarehouse from "../../common/SelectWarehouse";
import IconButton from "../../IconButton";
import {faDownload, faFileImport} from "@fortawesome/free-solid-svg-icons";
import Badge from "../../common/Badge";
import classNames from "classnames";
import ImportItemsFromExcelColumnSelectors from "./ImportItemsFromExcelColumnSelectors";
import SkipRowsInputGroup from "./SkipRowsInputGroup";


const columnsFieldName = "columns";
const itemsFieldName = "items";
const skipRowsFieldName = "skipRows";
const isEditingFieldName = "isEditing";

const ImportItemsFromExcelForm = ({ failed }) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const selectedColumns = useWatch({ name: columnsFieldName });
    const items = useWatch({ name: itemsFieldName });
    const skipRows = useWatch({ name: skipRowsFieldName });
    const isEditing = useWatch({ name: isEditingFieldName });
    const [limit, setLimit] = useState(50);

    const validateColumns = () => {
        let noErrorsFound = true;
        for (let i = 0; i < excelImportColumnOptions.length; i++) {
            let found = false;
            const field = excelImportColumnOptions[i];

            if (field.isRequired) {
                for (let j = 0; j < selectedColumns.length; j++) {
                    if (field.key === selectedColumns[j]) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    noErrorsFound = false;
                    form.setError(columnsFieldName, {
                        type: 'required',
                        message: t(lang, 'items.common.import_from_excel.column_selector.required_option', { column: t(lang, field.label) })
                    })
                }
            }
        }

        if (noErrorsFound)
            form.clearErrors(columnsFieldName);
    }

    useEffect(() => {
        validateColumns();
    }, [selectedColumns])

    const downloadToExcel = (selectedColumns, items) => {
        let headers = selectedColumns.map(c => t(lang, excelImportColumnOptions.find(x => x.key === c)?.label));
        const workbook = utils.book_new();
        const worksheet = utils.aoa_to_sheet([headers, ...items]);
        utils.book_append_sheet(workbook, worksheet, t(lang, 'items.table.title'));
        writeFile(workbook, `${t(lang, 'items.common.import_from_excel.failed')}.xlsx`);
    }

    if (items.length > 0) {
        return (
            <Row>
                <Col xs={12} className="mt-3">
                    <Card>
                        <Card.Header>
                            <Card.Title className="d-flex justify-content-between flex-wrap">
                                <div>
                                    <span className="me-2">{t(lang, 'items.table.title')}</span>
                                    <Badge bg={'warning'}>{items.length - skipRows}</Badge>
                                </div>
                                <div>
                                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                                        <label className="switch">
                                            <Form.Check type="switch" id={"isEditing"} className="d-flex align-items-center">
                                                <Form.Check.Input {...form.register("isEditing")} type="checkbox"/>
                                                <span className="slider"></span>
                                            </Form.Check>
                                        </label>
                                        <Form.Check.Label
                                            htmlFor={"isEditing"}
                                            className="mb-0 switch-label">{t(lang, 'items.common.edit')}</Form.Check.Label>
                                    </Form.Group>

                                    <Form.Group className="switch-container mb-2 gap-2 align-items-center">
                                        <label className="switch">
                                            <Form.Check type="switch" id={"skipNameUniqeValidation"} className="d-flex align-items-center">
                                                <Form.Check.Input {...form.register("skipNameUniqeValidation")} type="checkbox"/>
                                                <span className="slider"></span>
                                            </Form.Check>
                                        </label>
                                        <Form.Check.Label
                                            htmlFor={"skipNameUniqeValidation"}
                                            className="mb-0 switch-label">{t(lang, 'items.common.skipNameUniqueValidation')}</Form.Check.Label>
                                    </Form.Group>
                                </div>
                            </Card.Title>
                            <Row className="gy-2 gy-lg-0">
                                <Col xs="12" sm="6" md="auto">
                                    <Form.Group>
                                        <Form.Label>{t(lang, 'items.common.import_from_excel.skip_rows')}</Form.Label>
                                        <Controller name="skipRows"
                                                    render={({ field }) => (
                                                        <SkipRowsInputGroup onChangeSkipCount={field.onChange}
                                                                            defaultSkipCount={field.value}
                                                                            maxSkipCount={items.length}
                                                        />
                                                    )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className="d-grid d-lg-block text-lg-end">
                                    {failed.length > 0 &&
                                        <IconButton icon={faDownload}
                                                    variant="falcon-success"
                                                    onClick={() => downloadToExcel(selectedColumns, items)}
                                        >
                                            {t(lang, 'items.common.import_from_excel.download')}
                                        </IconButton>
                                    }

                                    {!isEditing &&
                                        <IconButton icon={faFileImport}
                                                    variant="falcon-primary"
                                                    type="submit"
                                                    className="mt-2 mt-lg-0 ms-lg-2"
                                        >
                                            {t(lang, 'items.common.import')}
                                        </IconButton>
                                    }
                                    {isEditing &&
                                        <IconButton icon={faFileImport}
                                                    variant="falcon-warning"
                                                    type="submit"
                                                    className="mt-2 mt-lg-0 ms-lg-2"
                                        >
                                            {t(lang, 'items.common.edit')}
                                        </IconButton>
                                    }
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} sm={6} lg={4} xl={3} className="mb-3">
                                    <Form.Group>
                                        <Form.Label>{t(lang, 'items.common.warehouse')}</Form.Label>
                                        <Controller name={`globalWarehouseId`}
                                                    render={({ field }) => (
                                                        <SelectWarehouse defaultWarehouseId={field.value}
                                                                         onChange={(option) => field.onChange(option ? option.id : null)}
                                                                         selectProps={{
                                                                             isSearchable: false,
                                                                         }}
                                                        />
                                                    )}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12}>
                                    {form.formState.errors?.columns && <span
                                        className="text-danger d-block">{form.formState.errors.columns.message}</span>}
                                    <Table bordered responsive>
                                        <thead>
                                        <tr>
                                            <th className="text-center px-3 m-0">№</th>
                                            <ImportItemsFromExcelColumnSelectors isEditing={isEditing} items={items} />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.slice(0, limit).map((item, itemIndex) => (
                                            <tr className={classNames('fs--1', { 'bg-400 text-dark': (itemIndex + 1) <= skipRows })} key={itemIndex}>
                                                <th className="text-center px-3 m-0">{itemIndex + 1}</th>
                                                {item.map((product, productIndex) => (
                                                    <th key={productIndex}>{product}</th>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>

                                    {items.length > limit && (
                                        <div className="w-100 text-center my-3">
                                            <Button onClick={() => setLimit(limit + 50)}>{t(lang, 'items.common.import_from_excel.show_more')}</Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }
};

export default ImportItemsFromExcelForm;
