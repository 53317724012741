import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    getWriteOffItemAsync,
    selectWriteOffDefaultsSettings,
    writeOffAddItemAsync,
    writeOffChangeEmployeeAsync,
    writeOffRegisterAsync,
    writeOffUpdateNoteAsync
} from "../../../../store/reducers/warehouse/writeOffReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {useNavigate} from "react-router-dom";
import {Translate} from "../../../../lang/lang";
import {selectDefaultSetting} from "../../../../store/reducers/settings/settingsReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS
} from "../../../../eventbus/warehouse/writeOffEvent";
import {Form} from "react-bootstrap";
import WriteOffForm from "./WriteOffForm";

const AddWriteOffForm = () => {

    const writeOffDefaultsSettings = useSelector(selectWriteOffDefaultsSettings)
    const [writeOff, setWriteOff] = useState(null)
    const [loading, setLoading] = useState({
        addOrEditWriteOffAndContinue: false,
        addOrEditWriteOffAndClose: false
    })
    const warehouses = useSelector(selectWarehouses)
    const navigate = useNavigate()
    const lang = useSelector(selectLang)
    const t = Translate;
    const settings = useSelector(selectDefaultSetting)


    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }

    const form = useForm({
        defaultValues: {
            employee_id: writeOffDefaultsSettings?.defaultEmployeeId || null,
            date: dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            note: '',
            number: null,
            item_id: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            quantity: null,
            marks: [],
        }
    })

    const resetForm = (formData) => {
        form.reset({
            employee_id: formData?.employee?.id || formData?.employee_id,
            date: formData?.date && dayjs(formData?.date, "YYYY-MM-DD HH:mm").toDate(),
            note: formData?.note,
            number: formData?.number,
            item_id: null,
            quantity: null,
            warehouse_id: settings?.warehouse?.default_id || null,
            warehouse: warehouses,
            package_measurements: null,
            marks: [],
        })
    }


    const onSubmit = async (formData, event) => {
        const buttonType = event?.nativeEvent?.submitter?.name

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
            marks: formData?.marks || [],
        }

        if ((buttonType === "addOrEditWriteOffAndContinue" || buttonType === 'addOrEditWriteOffAndClose') || (event === "addOrEditWriteOffAndContinue" || event === "addOrEditWriteOffClose")) {
            if (buttonType === "addOrEditWriteOffAndContinue" || event === "addOrEditWriteOffAndContinue") {
                setLoading( prevState => {
                    return {...prevState, addOrEditWriteOffAndContinue: true}
                })
            }else {
                setLoading( prevState => {
                    return {...prevState, addOrEditWriteOffAndClose: true}
                })
            }
            if (!writeOff) {
                await writeOffRegisterAsync({
                    date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
                    note: formData?.note,
                    number: formData?.number?.trim() ? formData?.number : null,
                    items: [item],
                    employee_id: formData?.employee_id,
                })
                    .then(({data}) => {
                        resetForm(data)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.error?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        if (buttonType === "addOrEditWriteOffAndClose" || event === "addOrEditWriteOffClose") {
                            setLoading(prevState => {
                                return {...prevState, addOrEditWriteOffAndClose: false}
                            })
                            onHide()
                        }else {
                            setLoading(prevState => {
                                return {...prevState, addOrEditWriteOffAndContinue: false}
                            })
                        }
                    })
            }
            if (writeOff) {
                await writeOffAddItemAsync({
                    writeOffId: writeOff?.id,
                    operation: item
                })
                    .then(() => {
                        updateNote(formData)
                        // updateNumber(formData)
                        resetForm(formData)
                        updateEmployee(formData)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch((error) => {
                        if (error?.response?.data?.includes("warehouse")) {
                            toast.error(t(lang, "Склад не выбран"))
                        } else {
                            toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                        }
                    })
                    .finally(() => {
                        if (buttonType === "addOrEditWriteOffAndClose" || event === "addOrEditWriteOffClose") {
                            setLoading(prevState => {
                                return {...prevState, addOrEditWriteOffAndClose: false}
                            })
                            onHide()
                        }else {
                            setLoading(prevState => {
                                return {...prevState, addOrEditWriteOffAndContinue: false}
                            })
                        }
                    })
            }
        }
    }

    const updateEmployee = (formData) => {
        if (formData?.employee_id || writeOff?.employee) {
            if (formData?.employee_id !== writeOff?.employee?.id) {
                writeOffChangeEmployeeAsync({writeOffId: writeOff?.id, employeeId: formData?.employee_id})
                    .then(({data}) => {
                        setWriteOff(data, data.items.reverse())
                    })
                    .catch(() => {
                    })
            }
        }
    }

    const updateNote = (formData) => {
        if (formData?.note !== writeOff?.note) {
            writeOffUpdateNoteAsync({writeOffId: writeOff?.id, note: formData?.note})
                .then(({data}) => {
                    setWriteOff(data, data.items.reverse())
                })
                .catch(() => {
                })
        }
    }

    useEffect(() => {
        const onRegisterHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`../write-offs/view/${id}`)
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, (id) => {
            loadWriteOff(id).then()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS, onRegisterHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        form.setValue('warehouse_id', settings?.warehouse?.default_id || null)
    }, [settings])

    const loadWriteOff = async (id) => {
        await getWriteOffItemAsync({writeOffId: id})
            .then((response) => {
                setWriteOff(response.data, response.data.items.reverse())
            })
            .catch(_ => setWriteOff(null))
    }

    return (
        <>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <WriteOffForm
                        writeOff={writeOff}
                        addItem={form.handleSubmit(onSubmit)}
                        loading={loading}
                        show={show}
                        onShow={onShow}
                        onHide={onHide}
                    />
                </Form>
            </FormProvider>
        </>
    );
};

export default AddWriteOffForm;