import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../../store/reducers/warehouse/warehouseReducer";
import {Controller, useFormContext} from "react-hook-form";
import {selectDateFormat, selectLang} from "../../../../../store/reducers/main/main";
import {selectItems} from "../../../../../store/reducers/item/itemReducer";
import {Translate} from "../../../../../lang/lang";
import {Col, Row, Form, InputGroup, FloatingLabel, Card} from "react-bootstrap";
import DatePickerComponent from "../../../../common/DatePickerComponent";
import classNames from "classnames";
import WarehouseOperationItemSelect from "../../../../warehouse/operation/WarehouseOperationItemSelect";
import WarehouseOperationWarehouseSelect from "../../../../warehouse/operation/WarehouseOperationWarehouseSelect";
import ReactSelect from "react-select";

const AddRevisionModalForm = ({disabled}) => {

    const warehouses = useSelector(selectWarehouses)
    const dateFormat = useSelector(selectDateFormat)
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate

    // state
    const {errors} = form.formState

    // watch
    const warehouse = form.watch('warehouse')

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }


    return (
        <Card className={'p-1'}>
            <Row>
                <Form.Group as={Col} xs={12} lg={6} className="d-flex flex-column">
                    <Controller
                        name={`date`}
                        rules={{
                            required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                        }}
                        render={({field}) => {
                            return (
                                    <DatePickerComponent
                                        field={field}
                                        placeholderText={t(lang, "Дата")}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                                        wrapperClassName={classNames({
                                            'is-invalid': errors?.date
                                        })}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.date,
                                        })}
                                    />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors?.date?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} className="d-flex flex-column">
                    <FloatingLabel label={t(lang, "Номер")}>
                        <Form.Control
                            type="text"
                            {...form.register("number")}
                            placeholder={t(lang, "Номер")}
                            className={classNames("form-control w-100")}
                        />
                    </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col} xs={12} className="my-1 d-flex flex-column">
                    <Controller
                        name="item_id"
                        rules={{
                            required: t(lang, "warehouse.operation.item.common.validation.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <WarehouseOperationItemSelect
                                    placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                    options={items}
                                    allowImage={true}
                                    defaultValue={field?.value}
                                    isDisabled={disabled}
                                    isClearable
                                    onChange={option => {
                                        field?.onChange(option ? option?.item?.id : null)
                                        onChangeItem(option)
                                    }}
                                    className={classNames({
                                        'is-invalid': errors?.item_id
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors?.item_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} className="d-flex flex-column">
                    <Controller
                        name="warehouse_id"
                        rules={{
                            required: t(lang, "warehouse.operation.item.common.validation.is_required")
                        }}
                        render={({field}) => {
                            return (
                                <WarehouseOperationWarehouseSelect
                                    placeholder={t(lang, "warehouse.operation.item.common.warehouse_name")}
                                    options={warehouse}
                                    defaultValue={field?.value}
                                    onChange={option => {
                                        field?.onChange(option ? option?.id : null)
                                    }}
                                    className={classNames({
                                        'is-invalid': errors?.warehouse_id
                                    })}
                                />
                            )
                        }}
                    />
                    <Form.Control.Feedback className={'d-block'} type="invalid">
                        {errors?.warehouse_id?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={12} className="mt-1 d-flex flex-column">
                    <FloatingLabel label={t(lang, "warehouse.operation.item.common.quantity")}>
                        <Form.Control
                            type="text"
                            id="quantity"
                            {...form.register("quantity", {
                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                pattern: {
                                    value: /^[0-9]*[\.,]?[0-9]*$/,
                                    message: "100 | 1.2 | 1,5"
                                }
                            })}
                            placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                            // isInvalid={errors?.quantity}
                            className={classNames("form-control w-100")}
                        />
                    </FloatingLabel>
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors?.quantity?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
        </Card>
    );
};

export default AddRevisionModalForm;
