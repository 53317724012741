import axios from "axios";

const url = '/cashbox'
class CashboxService {
    // CashBox
    getAllCashbox() {
        return axios.get(`${url}/cashbox/get`)
    }

    getCashbox(id) {
        return axios.get(`${url}/cashbox/get/${id}`)
    }

    registerCashbox(data) {
        return axios.post(`${url}/cashbox/register`, data)
    }

    updateCashbox(data, id) {
        return axios.post(`${url}/cashbox/update/${id}`, data)
    }

    deleteCashbox(id) {
        return axios.post(`${url}/cashbox/delete/${id}`)
    }

    // Account
    cashboxBindingRegister(params) {
        return axios.post(`${url}/account/binding/register`,params)
    }
    cashboxBindingUnregister(params) {
        return axios.post(`${url}/account/binding/unregister`,params)
    }
    cashboxBindingEnable(params) {
        return axios.post(`${url}/account/binding/enable`,params)
    }
    cashboxBindingDisable(params) {
        return axios.post(`${url}/account/binding/disable`,params)
    }

    getAccountCashbox() {
        return axios.get(`${url}/account/get`)
    }

    getAccountCashboxBinding(id) {
        return axios.get(`${url}/account/binding/get/${id}`)
    }

    // expense
    getAllExpense(params) {
        return axios.get(`${url}/expense/get`, {
            params:params
        })
    }

    getExpenseCount(params) {
        return axios.get(`${url}/expense/get/count`, {
            params:params
        })
    }

    getExpense(id) {
        return axios.get(`${url}/expense/get/${id}`)
    }

    registerExpense(data) {
        return axios.post(`${url}/expense/register`, data)
    }

    updateExpense(data, id) {
        return axios.post(`${url}/expense/update/${id}`, data)
    }

    deleteExpense(id) {
        return axios.post(`${url}/expense/delete/${id}`)
    }

    // cash-in
    getAllCashIn(params) {
        return axios.get(`${url}/cash-in/get`, {
            params:params
        })
    }

    getAllCashInCount(params) {
        return axios.get(`${url}/cash-in/get/count`, {
            params:params
        })
    }

    getCashIn(id) {
        return axios.get(`${url}/cash-in/get/${id}`)
    }

    registerCashIn(data) {
        return axios.post(`${url}/cash-in/register`, data)
    }

    updateCashIn(data, id) {
        return axios.post(`${url}/cash-in/update/${id}`, data)
    }

    deleteCashIn(id) {
        return axios.post(`${url}/cash-in/delete/${id}`)
    }

    // cash-out

    getAllCashOut(params) {
        return axios.get(`${url}/cash-out/get`, {
            params: params
        })
    }

    getAllCashOutCount(params) {
        return axios.get(`${url}/cash-out/get/count`, {
            params: params
        })
    }

    getCashOut(id) {
        return axios.get(`${url}/cash-out/get/${id}`)
    }

    registerCashOut(data) {
        return axios.post(`${url}/cash-out/register`, data)
    }

    updateCashOut(data, id) {
        return axios.post(`${url}/cash-out/update/${id}`, data)
    }

    deleteCashOut(id) {
        return axios.post(`${url}/cash-out/delete/${id}`)
    }


    getAllOperation(params) {
        return axios.get(`${url}/operation/get`, {
            params: params
        })
    }

    getAllOperationCount(params) {
        return axios.get(`${url}/operation/get/count`, {
            params: params
        })
    }

    getOperation(id) {
        return axios.get(`${url}/operation/get/${id}`)
    }

    // transfer

    getAllTransfer(params) {
        return axios.get(`${url}/transfer/get`, {
            params: params
        })
    }

    getAllTransferCount(params) {
        return axios.get(`${url}/transfer/get/count`, {
            params: params
        })
    }

    getTransfer(id) {
        return axios.get(`${url}/transfer/get/${id}`)
    }

    registerTransfer(data) {
        return axios.post(`${url}/transfer/register`, data)
    }

    updateTransfer(data,id) {
        return axios.post(`${url}/transfer/update/${id}`, data)
    }

    deleteTransfer(id) {
        return axios.post(`${url}/transfer/delete/${id}`)
    }
}

const cashierService = new CashboxService()

export default cashierService
