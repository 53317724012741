import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {LangOptions, Translate} from "../../lang/lang";
import {updateLangSettingsAsync} from "../../store/reducers/settings/settingsReducer";
import {useAppContext} from "../../pheonix/providers/AppProvider";
import {Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import Flex from "../../components/common/Flex";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconEImzo from "../../assets/icon/IconEImzo";
import IconEImzoSecondary from "../../assets/icon/IconEImzoSecondary";
import IconBranches from "../../assets/icon/IconBranches";
import IconTariff from "../../assets/icon/IconTariff";
import RevealDropdown, {RevealDropdownTrigger} from "../../components/common/RevealDropdown";
import IconSetting from "../../assets/icon/IconSetting";
import IconRussian from "../../assets/icon/IconRussian";
import IconUzbekistan from "../../assets/icon/IconUzbekistan";
import IconTheme from "../../assets/icon/IconTheme";
import IconLock from "../../assets/icon/IconLock";

const Authentication = () => {

    const lang = useSelector(selectLang);
    const t = Translate;
    const dispatch = useDispatch()

    const [step, setStep] = useState(1)

    const onChangeLang = async lang => {
        const payload = {
            lang: LangOptions.map(l => {
                return {
                    code: l,
                    is_active: l === lang,
                }
            })
        }

        dispatch(updateLangSettingsAsync({payload}))
    }

    const {
        config: {theme, isRTL},
        toggleTheme
    } = useAppContext();

    const openMaps = () => {
        window.open(`https://davrbank.uz/${lang}/offices-atms`, '_blank')
    }

    return (
        <Fragment className={"h-100 "}>
            <div className={"d-grid gap-2 w-100 h-100 "}
                 style={{gridTemplateRows: "repeat(9, 1fr)", gridTemplateColumns: "repeat(1, 1fr)"}}>
                <Card className={'rounded-5 overflow-hidden p-3 grid-row-auth-form'}>
                    <Flex className={"h-100 scrollbar flex-column justify-content-between"}>
                        <div>
                            <Flex className={"w-100 gap-1"}>
                                <div className={'d-flex w-90 rounded-3 mb-2 p-1'} style={{background: "#fff"}}>
                                    <Button onClick={() => setStep(1)}
                                            className={'w-50 text-secondary rounded-3 d-flex text-decoration-none align-items-center justify-content-center'}
                                            size={"sm"}
                                            variant={step === 1 ? 'phoenix-secondary' : 'link'}>
                                        <p className={`m-0 ${step === 1 && "text-dark"}`}>Вход в систему</p>
                                    </Button>
                                    <Button onClick={() => setStep(2)}
                                            className={'w-50 text-secondary rounded-3 d-flex text-decoration-none align-items-center justify-content-center'}
                                            size={"sm"}
                                            variant={step === 2 ? 'phoenix-secondary' : 'link'}>
                                        <p className={`m-0 ${step === 2 && "text-dark"}`}>Регистрация</p>
                                    </Button>
                                </div>
                                <div className={""}>
                                    <RevealDropdownTrigger drop={'start'}
                                                           className={"bg-white border border-1 rounded-3"}
                                                           btnRevealClass="btn-reveal-sm">
                                        <RevealDropdown icon={<IconSetting width={22} height={22}/>}>
                                            <Dropdown.Item
                                                onClick={() => onChangeLang(lang === "ru" ? "uz" : "ru")}
                                                style={{width: 250}}>
                                                <Flex
                                                    className={'justify-content-between align-items-center cursor-pointer'}>
                                                    <Flex alignItems={"center"}>
                                                        {lang === "ru" ? <IconRussian/> : <IconUzbekistan/>} <Flex
                                                        className={"flex-column justify-content-center ms-2"}>
                                                        <p className={'fs-9 fw-bold m-0'}>Язык</p>
                                                    </Flex>
                                                    </Flex>
                                                    <Flex alignItems={"center"}
                                                          className={`gap-2 second-text`}
                                                    >
                                                        <p className={'fs-9  m-0'}>{t(lang, "hippo.user.settings.lang")}</p>
                                                        <FontAwesomeIcon icon={faChevronRight}
                                                                         className={'me-2 '}/>
                                                    </Flex>
                                                </Flex>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => toggleTheme()}>
                                                <Flex
                                                    className={'justify-content-between align-items-center cursor-pointer'}>
                                                    <Flex alignItems={"center"}>
                                                        <IconTheme/>
                                                        <Flex
                                                            className={"flex-column justify-content-center ms-2"}>
                                                            <p className={'fs-9 fw-bold m-0'}>Тема</p>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex alignItems={"center"}
                                                          className={`gap-2 second-text`}
                                                          style={theme === "daek" ? {color: "#fff"} : {}}>
                                                        <p className={'fs-9 m-0'}>{theme === 'dark' ? "Темное" : "Светлое"}</p>
                                                        <FontAwesomeIcon icon={faChevronRight}
                                                                         className={'me-2'}/>
                                                    </Flex>
                                                </Flex>
                                            </Dropdown.Item>
                                        </RevealDropdown>
                                    </RevealDropdownTrigger>
                                </div>
                            </Flex>
                            <h3 className={"text-center text-dark"}>Выберите тип</h3>
                            {step === 1 ? <SignIn theme={theme}/> : <Register theme={theme}/>}
                        </div>
                        <Flex>
                            <div className={"w-50 pe-xl-1"}>
                                <Card
                                    onClick={openMaps}
                                    className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                                    <Flex className={'justify-content-between align-items-center cursor-pointer'}>
                                        <Flex>
                                            <IconBranches/>
                                            <Flex className={"flex-column justify-content-center ms-2"}>
                                                <h2 className={'fs-8 '}>Филиалы</h2>
                                            </Flex>
                                        </Flex>
                                        <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                                    </Flex>
                                </Card>
                            </div>
                            <div className={"w-50 ps-xl-1"}>
                                <Card
                                    // as={Link} to={'/authentication/login'}
                                    className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                                    <Flex className={'justify-content-between align-items-center cursor-pointer'}>
                                        <Flex>
                                            <IconTariff/>
                                            <Flex className={"flex-column justify-content-center ms-2"}>
                                                <h2 className={'fs-8 '}>Тарифы</h2>
                                            </Flex>
                                        </Flex>
                                        <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                                    </Flex>
                                </Card>
                            </div>
                        </Flex>
                    </Flex>
                </Card>
                <Card className={"rounded-5 overflow-hidden grid-row-auth-eimzo authEImzoBackground"}>
                    <div className={"w-100 h-100 position-relative"}></div>
                </Card>
            </div>
        </Fragment>
    );
};

const SignIn = ({theme}) => {
    return (
        <Fragment>
            <Card as={Link} to={'/authentication/login/with-certificate'}
                  className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                <Flex className={'justify-content-between align-items-center'}>
                    <Flex>
                        <IconEImzo/>
                        <Flex className={"flex-column justify-content-center ms-2"}>
                            <h2 className={'fs-8 '}>Через E-IMZO</h2>
                            <p
                                className={`fs-9 m-0 second-text`}

                            >Полный доступ</p>
                        </Flex>
                    </Flex>
                    <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                </Flex>
            </Card>
            <Card as={Link} to={'/authentication/login'}
                  className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                <Flex className={'justify-content-between align-items-center'}>
                    <Flex>
                        <IconLock/>
                        <Flex className={"flex-column justify-content-center ms-2"}>
                            <h2 className={'fs-8 '}>По поролю</h2>
                            <p
                                className={`fs-9 m-0 second-text`}

                            >Ограниченный доступ</p>
                        </Flex>
                    </Flex>
                    <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                </Flex>
            </Card>
        </Fragment>
    )
}

const Register = ({theme}) => {
    return (
        <Fragment>
            <Card as={Link} to={'/authentication/register'}
                  className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                <Flex className={'justify-content-between align-items-center'}>
                    <Flex>
                        <IconEImzo/>
                        <Flex className={"flex-column justify-content-center ms-2"}>
                            <h2 className={'fs-8 '}>Через E-IMZO</h2>
                            <p
                                className={`fs-9 m-0 second-text`}

                            >Полный доступ</p>
                        </Flex>
                    </Flex>
                    <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                </Flex>
            </Card>
            <Card as={Link} to={'/authentication/register/without-inn'}
                  className={"rounded-3 p-2 mt-2 text-decoration-none"}>
                <Flex className={'justify-content-between align-items-center'}>
                    <Flex>
                        <IconEImzoSecondary/>
                        <Flex className={"flex-column justify-content-center ms-2"}>
                            <h2 className={'fs-8 '}>Без E-IMZO</h2>
                            <p
                                className={`fs-9 m-0 second-text`}

                            >Ограниченный доступ</p>
                        </Flex>
                    </Flex>
                    <FontAwesomeIcon icon={faChevronRight} className={'me-2 text-secondary'}/>
                </Flex>
            </Card>
        </Fragment>
    )
}

export default Authentication;
