import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {FormProvider, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {AddUserAsync, getRegistrationVerifyConfirmationTicketAsync} from "../../../store/reducers/auth/authReducer";
import {Button, Form, Modal, Row} from "react-bootstrap";
import IconButton from "../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddUserStepOne from "./AddUserStepOne";
import AddUserStepTwo from "./AddUserStepTwo";
import FalconCloseButton from "../../common/FalconCloseButton";

const AddUser = () => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const [isShow, setIsShow] = useState(false)
    const [step, setStep] = useState(1);
    const [ticket, setTicket] = useState(null);
    const [invalidConfirmCode, setInvalidConfirmCode] = useState('');

    const methods = useForm({
        defaultValues: {
            inn: '',
            name: '',
            username: '',
            password: '',
            confirmCode: '',
        },
    });

    const handleShow = () => setIsShow(true);
    const handleClose = () => {
        methods.reset();
        setIsShow(false);
        setStep(1);
    };

    const onSubmit = formData => {
        const username = formData.username.split(' ').join('')

        switch (step) {
            case 1:
                getRegistrationVerifyConfirmationTicketAsync(username)
                    .then(ticket => {
                        setTicket(ticket);
                        setStep(step + 1);
                    })
                break;

            case 2:
                AddUserAsync({
                    username: username,
                    password: formData.password,
                    name: formData.name,
                    confirmCode: formData.confirmCode,
                    confirmTicket: ticket,
                })
                    .then((res) => {
                        methods.reset();
                        handleClose();
                        toast.success(t(lang, "edi.common.users_organization_success"))
                    })
                    .catch(({invalidConfirmationCodeUserAdd}) => {
                        if (invalidConfirmationCodeUserAdd) {
                            setInvalidConfirmCode(formData.confirmCode);
                            toast.error(t(lang, "authentication.forgot-password.confirmation_code.is_invalid"))
                        } else {
                            toast.error(t(lang, "edi.common.user_organization_error"))
                        }
                    })
                break;
        }
    };

    return (
        <Fragment>
            <IconButton
                variant="phoenix-secondary"
                size="sm"
                icon={faPlus}
                transform="shrink-3"
                iconClassName="fs-7 text-primary-davr align-middle"
                className={'btn-davr bg-white text-dark gap-2'}
                onClick={() => handleShow()}
            >
                        <span
                            className="d-none d-sm-inline-block ms-1">{t(lang, "edi.branch.datatable.header.row.navigation.create")}</span>
            </IconButton>
            <Modal show={isShow} className={"davr-modal"} onHide={handleClose}>
                <FormProvider {...methods}>
                    <Form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Modal.Header className={''}>
                            <Modal.Title>{t(lang, "edi.common.user_organization_new")}</Modal.Title>
                            <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'}
                                               onClick={() => handleClose()}/>
                        </Modal.Header>
                        <Modal.Body className={''}>
                            {step === 1 && <AddUserStepOne close={setIsShow}/>}
                            {step === 2 && <AddUserStepTwo invalidConfirmCode={invalidConfirmCode}/>}
                        </Modal.Body>
                        <Modal.Footer className={''}>
                            {step === 1 && <Button variant="primary" type="submit"
                                                   className="btn-davr px-3 py-0 bg-primary-davr">
                                {t(lang, "authentication.forgot-password.send_confirmation_code")}
                            </Button>}
                            {step === 2 && <Button variant="primary" type="submit"
                                                   className="btn-davr px-3 py-0 bg-primary-davr">
                                {t(lang, "edi.common.add_button")}
                            </Button>}
                        </Modal.Footer>
                    </Form>
                </FormProvider>
            </Modal>
        </Fragment>
    );
};

export default AddUser;
