export const Name = "name";
export const Description = "description";
export const Sku = "sku";
export const Code = "code";
export const Barcode = "barcodes";
export const State = "state";
export const Measurement = "measurement";
export const Category = "category";
export const CommonPrice = "commonPrice";
export const BulkPrice = "bulkPrice";
export const PurchasePrice = "purchasePrice";
export const TaxCatalogCode = "taxCatalogCode";
export const TaxCatalogName = "taxCatalogName";
export const TaxPackageCode = "taxPackageCode";
export const TaxMeasurement = "taxMeasurement";
export const TaxBenefit = "taxBenefit";
export const TaxRate = "taxRate";
export const PackageMeasurementName = "packageMeasurementName";
export const PackageMeasurementQuantity = "packageMeasurementQuantity";
export const ID = 'id';

export const excelImportColumnOptions = [
    { key: Name, label: 'items.common.import_from_excel.column_selector.options.name', isRequired: false },
    { key: Description, label: 'items.common.import_from_excel.column_selector.options.description', isRequired: false },
    { key: Sku, label: 'items.common.import_from_excel.column_selector.options.sku', isRequired: false },
    { key: Code, label: 'items.common.import_from_excel.column_selector.options.code', isRequired: false },
    { key: Barcode, label: 'items.common.import_from_excel.column_selector.options.barcode', isRequired: false },
    { key: State, label: 'items.common.import_from_excel.column_selector.options.state', isRequired: false },
    { key: Measurement, label: 'items.common.import_from_excel.column_selector.options.measurement', isRequired: false },
    { key: Category, label: 'items.common.import_from_excel.column_selector.options.category', isRequired: false },
    { key: CommonPrice, label: 'items.common.import_from_excel.column_selector.options.commonPrice', isRequired: false },
    { key: BulkPrice, label: 'items.common.import_from_excel.column_selector.options.bulkPrice', isRequired: false },
    { key: PurchasePrice, label: 'items.common.import_from_excel.column_selector.options.purchasePrice', isRequired: false },
    { key: TaxCatalogCode, label: 'items.common.import_from_excel.column_selector.options.tax.catalog.code', isRequired: false },
    { key: TaxCatalogName, label: 'items.common.import_from_excel.column_selector.options.tax.catalog.name', isRequired: false },
    { key: TaxRate, label: 'items.common.import_from_excel.column_selector.options.tax.rate', isRequired: false },
    { key: PackageMeasurementName, label: 'items.common.import_from_excel.column_selector.options.package_measurement_name', isRequired: false },
    { key: PackageMeasurementQuantity, label: 'items.common.import_from_excel.column_selector.options.package_measurement_quantity', isRequired: false },
    { key: ID, label: 'items.common.import_from_excel.column_selector.options.id', isRequired: false },
];
