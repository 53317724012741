import React, {
    createContext,
    useContext,
    useState
} from 'react';

export const MainLayoutContext = createContext(
    {
        contentClass: "",
        setContentClass:"",
        footerClass: "",
        setFooterClass: "",
        loading: false,
        setLoading: () => {}
    }
);

const MainLayoutProvider = ({ children }) => {
    const [contentClass, setContentClass] = useState('');
    const [footerClass, setFooterClass] = useState('');
    const [loading, setLoading] = useState(false);
    return (
        <MainLayoutContext.Provider
            value={{ contentClass, setContentClass, footerClass, setFooterClass, loading, setLoading }}
        >
            {children}
        </MainLayoutContext.Provider>
    );
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
