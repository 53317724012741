import React from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {useFormContext} from "react-hook-form";
import {Translate} from "../../lang/lang";
import {Row, Form, Col, FloatingLabel} from "react-bootstrap";
import classNames from "classnames";

const EmployeeForm = () => {
    const {register, formState: {errors}} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Row>
            <Form.Group as={Col} xs={12}>
                <FloatingLabel label={t(lang, 'employee.employee_form.field.name.title')}>
                    <Form.Control
                        placeholder={t(lang, 'employee.employee_form.field.name.title')}
                        className={classNames({'is-invalid': errors?.name})}
                        {...register('name', {required: t(lang, 'main.validation.is_required')})}
                    />
                </FloatingLabel>
                <Form.Control.Feedback type={'invalid'}>
                    {errors?.name?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Row>
    );
};

export default EmployeeForm;
