import axios from 'axios'

class ReturnInvoiceService {
    returnInvoiceRegister(payload) {
        return axios.post(`/return-invoice/executor/register`, payload)
    }

    printReturnInvoice(id) {
        return axios.get(`/pdf/return-invoice/${id}`, {
            responseType: "blob"
        })
    }

    getReturnInvoices(params) {
        return axios.get('/return-invoice/get', {
            params: params
        })
    }

    getCount(params) {
        return axios.get('/return-invoice/count', {
            params: params,
        });
    }

    deleteReturnInvoice({id, comment}) {
        return axios.post(`/return-invoice/executor/delete/${id}`, {
            comment: comment,
        })
    }

    getReturnInvoice(id) {
        return axios.get(`/return-invoice/get/${id}`)
    }

    getSignTicket(id) {
        return axios.get(`/return-invoice/sign-ticket/${id}`)
    }


    uploadReturnInvoiceToExcel({uri, id}) {
        return axios.get(`${uri}/${id}`,{
            responseType: "arraybuffer",
        })
    }

    uploadReturnInvoicesToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }

    uploadReturnInvoicesWithItemsToExcel({uri, params}) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer",
        })
    }
}

const returnInvoiceService = new ReturnInvoiceService();
export default  returnInvoiceService;
