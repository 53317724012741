import React, {Fragment, useEffect, useState} from 'react';
import ContractorTabs from "../contractor/ContractorTabs";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    changeFilterOptionsAsync,
    loadContractorPaymentAsync,
    loadContractorPaymentCountAsync,
    selectContractorPayments,
    selectCountContractorPayments,
    selectFilterOptionsContractorPayments,
    selectLoading
} from "../../../store/reducers/contractor/contractorPaymentReducer";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import AdvanceTableWrapper from "../../../components/table/AdvanceTableWrapper";
import ContractorPaymentDataTableHeader from "./ContractorPaymentDataTableHeader";
import Badge from "../../../components/common/Badge";
import {
    ContractorSourceReturn,
    ContractorSourceSale,
    FindContractorPaymentSource
} from "../../../enum/ContractorPaymentSources";
import numeral from "numeral";
import {Card, Dropdown} from "react-bootstrap";
import AdvanceTable from "../../../components/table/AdvanceTable";
import AdvanceTableCustomPagination from "../../../components/table/AdvanceTableCustomPagination";
import RevealDropdown, {RevealDropdownTrigger} from "../../../components/common/RevealDropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import DeleteContractorPaymentModal from "./DeleteContractorPaymentModal";
import EditContractorPayment from "./EditContractorPayment";


const ContractorPaymentDataTable = () => {

    const navigate = useNavigate();
    const contractorPayments = useSelector(selectContractorPayments);
    const count = useSelector(selectCountContractorPayments)
    const lang = useSelector(selectLang);
    const filters = useSelector(selectFilterOptionsContractorPayments);
    const t = Translate;
    const dispatch = useDispatch();
    const numberFormat = useSelector(selectNumberFormat);
    const [editingContractorPayment, setEditingContractorPayment] = useState(null);
    const [deletingContractorPaymentId, setDeletingContractorPaymentId] = useState(null);
    const isLoading = useSelector(selectLoading)

    const separationCurrenciesAndTotalSum = (states) => {
        let groupByCurrencyId = {}

        states.forEach(state => {
            if (state?.currency?.id in groupByCurrencyId) {
                groupByCurrencyId[state?.currency?.id].totalSum += state?.amount
            } else {
                if (state?.currency?.id) {
                    groupByCurrencyId[state?.currency?.id] = {
                        id: state?.currency?.id,
                        name: state?.currency?.name,
                        totalSum: state?.amount
                    }
                }
            }
        })
        return Object.values(groupByCurrencyId)
    }

    const columns = [
        {
            Header: "#",
            id: "row",
            maxWidth: 50,
            filterable: false,
            Cell: ({row}) => {
                return `${((filters.page - 1) * filters.limit) + row.index + 1}`;
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "edi.contractor.datatable.header.row.contractor"),
            headerProps: {className: 'pe-7'},
            Cell: ({row: {original}}) => {
                return (
                    <strong>{original.contractor.name}</strong>
                )
            }
        },
        {
            accessor: 'date',
            Header: t(lang, "crm.contractor_payment.datatable.header.date"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'cash_box_states',
            Header: t(lang, "edi.contractor_payment.datatable.payment_amount"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {separationCurrenciesAndTotalSum(original.cash_box_states).map((payment, index) => {
                            return (
                                <div className={'fs-0 d-block text-end mb-1 flex-fill me-1'} key={index}>
                                    <strong className={'fs-7'}>
                                        {numeral.formats[numberFormat].format(payment.totalSum)}
                                    </strong>
                                    &nbsp;
                                    <small className={'fw-bold second-text'}>{payment.name}</small>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'debt_states',
            Header: t(lang, "warehouse.operation.item.common.credit"),
            headerProps: {
                className: 'text-end'
            },
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => {
                return (
                    <>
                        {original.debt_states.map((payment, index) => {
                            return (
                                <div
                                    className={'fs-0 px-2 py-1 text-uppercase text-decoration-none fw-medium border-0 rounded-4'}
                                    key={index}>
                                    <strong className={'fs-7'}>
                                        {numeral.formats[numberFormat].format(payment.amount)}
                                    </strong>
                                    &nbsp;
                                    <small className="fw-bold second-text">{payment.currency.name}</small>
                                </div>
                            )
                        })}
                    </>
                )
            }
        },
        {
            accessor: 'notes',
            Header: t(lang, "edi.contractor_payment.datatable.note"),
            headerProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            cellProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            }
        },
        {
            accessor: 'account',
            Header: 'Пользователь',
            headerProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            cellProps: {
                style: {
                    maxWidth: '100px'
                },
                className: "text-wrap"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div>
                        <span>{original.account.name || original.account.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'source',
            id: "source",
            headerProps: {
                className: 'text-center'
            },
            cellProps: {
                className: 'text-center'
            },
            Header: t(lang, "crm.data.table.source"),
            Cell: ({row: {original}}) => {
                if (original.payment_source === ContractorSourceSale) {
                    return (
                        <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                               variant={'phoenix'}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                    )
                } else if (original.payment_source === ContractorSourceReturn) {
                    return (
                        <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                               variant={'phoenix'}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                    )
                } else {
                    return (
                        <Badge bg={FindContractorPaymentSource(original.payment_source)?.variant}
                               variant={'phoenix'}
                           className={'px-3 py-1 fs-9 text-uppercase w-100 text-decoration-none fw-medium border-0 rounded-4'}
                        >{t(lang, FindContractorPaymentSource(original.payment_source)?.label)}</Badge>
                    )
                }
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "roaming.contracts.datatable.header.row.created_at"),
            headerProps: {className: 'pe-2'},
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                width: '80px',
                className: 'text-end'
            },
            Cell: ({row}) => {
                return (
                    <>
                        <RevealDropdownTrigger drop={'start'} btnRevealClass="btn-reveal-sm">
                            <RevealDropdown>
                                <Dropdown.Item
                                    onClick={() => {
                                        setEditingContractorPayment(row.original)
                                    }}
                                    >
                                    <span>{t(lang, "edi.common.edit_button")}</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={(event) => {
                                        setDeletingContractorPaymentId(row.original.id);
                                    }}
                                    >
                                    <span>{t(lang, "edi.common.delete_button")}</span>
                                </Dropdown.Item>
                            </RevealDropdown>
                        </RevealDropdownTrigger>
                    </>
                );
            }
        }
    ].filter(i => i);

    const onPageChange = async (page) => {
        dispatch(changeFilterOptionsAsync({...filters, page: page}))
    }
    const onLimitChange = async (limit) => {
        dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))
    }
    const activeOrganization = useSelector(selectActiveOrganization)

    useEffect(() => {
        dispatch(loadContractorPaymentAsync({...filters}))
        dispatch(loadContractorPaymentCountAsync({...filters}))
    }, [filters, activeOrganization])


    return (
        <Fragment>
            <AdvanceTableWrapper
                columns={columns}
                data={contractorPayments}
                selection
                pagination
                perPage={1000}
            >
                <ContractorPaymentDataTableHeader/>
                <Card className={'p-0 rounded-4'}>
                    <Card.Body className={'p-0'}>
                        <ContractorTabs/>
                        <AdvanceTable
                            table
                            headerClassName="text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            isLoading={isLoading}
                            tableProps={{
                                size: 'sm',
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    </Card.Body>
                </Card>
                <AdvanceTableCustomPagination
                    count={count}
                    limit={filters.limit}
                    page={filters.page}
                    onLimitChange={onLimitChange}
                    onPageChange={onPageChange}
                />
            </AdvanceTableWrapper>

            <DeleteContractorPaymentModal id={deletingContractorPaymentId} show={!!deletingContractorPaymentId}
                                          onClose={() => {
                                              setDeletingContractorPaymentId(null)
                                          }}/>


            <EditContractorPayment payment={editingContractorPayment} show={!!editingContractorPayment} onClose={() => {
                setEditingContractorPayment(null)
            }}/>

        </Fragment>
    );
};

export default ContractorPaymentDataTable;
