import React from 'react';
import {useSelector} from "react-redux";
import {selectLang, selectSystemMoneyFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    EDI_SERVICE_CODE,
    POS_SERVICE_CODE,
    selectBillingPackages,
    selectOrganizationActivePackages,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../../store/reducers/billing/billingReducer";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import PackageActivationModal from "./PackageActivationModal";
import numeral from "numeral";
import IconBasket from "../../../assets/icon/IconBasket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfinity} from "@fortawesome/free-solid-svg-icons";

const PricingCard = ({pricing}) => {
    const numberFormat = useSelector(selectSystemMoneyFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const activePackages = useSelector(selectOrganizationActivePackages);
    const packages = useSelector(selectBillingPackages)

    let hasNonSoonExpiringPackages = {
        [EDI_SERVICE_CODE]: false,
        [POS_SERVICE_CODE]: false,
        [WAREHOUSE_MANAGEMENT_SERVICE_CODE]: false
    }
    console.log(activePackages)
    for (let i = 0; i < packages.length; i++) {
        if (activePackages && activePackages.length > 0) {
            for (let j = 0; j < activePackages.length; j++) {
                let item = activePackages[j]
                if (item.package.service.code === packages[i].service.code) {
                    const expireDate = new Date(item.expires_at)
                    const threeDaysBeforeExpire = expireDate.setDate(expireDate.getDate() - 3)
                    const today = new Date().getTime()

                    hasNonSoonExpiringPackages[item.package.service.code] = threeDaysBeforeExpire > today
                }
            }
        }
    }

    console.log(hasNonSoonExpiringPackages, 'hasNonSoonExpiringPackages')

    let durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period'
    if (pricing.duration_months === 0 && pricing.duration_days > 0) {
        durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period_no_months'
    } else if (pricing.duration_months > 0 && pricing.duration_days === 0) {
        durationTranslationKey = 'main.billing.all_packages_card.pricing_card.package_validity_period_no_days'
    }

    const packageItems = []
    for (let i = 0; i < pricing.items.length; i++)
        packageItems.push(pricing.items[i])

    for (let j = 0; j < pricing.on_activation_bonus_packages.length; j++)
        for (let k = 0; k < pricing.on_activation_bonus_packages[j].items.length; k++)
            packageItems.push(pricing.on_activation_bonus_packages[j].items[k])


    return (
        <div className="w-100">
            <Card className="shadow card-bg-img rounded-4 h-100">
                <Card.Header className="border-bottom pt-2">
                    <div className="text-center">
                        <h5 className="fw-medium">{pricing.name}</h5>
                        <h4>
                                <span
                                    className="mx-1 fs-6">{numeral['formats'][numberFormat].format(pricing.price)}</span>
                            <span
                                className="mx-1 fs-6 text-uppercase">{t(lang, 'main.navbar.nav_item.billing.balance.currency.sum')}</span>
                            {pricing.brv_rate > 0 &&
                                <span className="mx-1 fs-6">({t(lang, 'main.billing.brv_value', {
                                    brvRate: pricing.brv_rate
                                })})</span>}
                        </h4>
                        <small>
                                <span className="ms-1">{t(lang, durationTranslationKey, {
                                    month: pricing.duration_months,
                                    day: pricing.duration_days,
                                })}</span>
                        </small>
                    </div>
                </Card.Header>
                <Card.Body className="overflow-hidden">
                    {packageItems.length > 0 &&
                        <ListGroup>
                            {
                                packageItems.map(item => (
                                    <ListGroup>
                                        <Row className={'justify-content-between'}>
                                            <Col cols={'auto'}>
                                                <p className={'second-text fs-9 d-flex gap-2'}>
                                                    {t(lang, "main.billing.all_packages_card.pricing_card.limit")}:
                                                    {item.is_unlimited &&
                                                        <FontAwesomeIcon className="text-black" icon={faInfinity}/>}
                                                    {!item.is_unlimited && <span>{item.limit}</span>}
                                                </p>
                                                <h5 className={'fs-8 fw-medium'}>
                                                    {t(lang, `main.billing.package.item.name.${item.name}`)}
                                                </h5>
                                            </Col>
                                            <Col cols={'auto'} className={'d-flex justify-content-end'}>
                                                <p className={'second-text fs-9 d-flex gap-2'}>
                                                    {t(lang, 'main.billing.all_packages_card.pricing_card.over_limit')}
                                                </p>
                                                <div>
                                                    {!item.is_unlimited && item.is_over_limit_allowed &&
                                                        <span>{t(lang, 'main.billing.all_packages_card.pricing_card.over_limit_price', {value: item.over_limit_price})}</span>}

                                                </div>
                                            </Col>
                                        </Row>

                                    </ListGroup>))
                            }
                        </ListGroup>
                    }
                </Card.Body>
                <Card.Footer className="border-top py-4">
                    <PackageActivationModal serviceCode={pricing.service} packageId={pricing.id}
                                            hasSoonExpiringPackages={hasNonSoonExpiringPackages}>
                        <Button
                            variant="phoenix-secondary"
                            style={{borderColor: "#58B3D3", border: "1px solid"}}
                            className="btn-davr px-5 py-0 bg-white d-flex gap-2 text-primary-davr" size="sm"
                        >
                            <IconBasket/>
                            <span>{t(lang, 'main.common.activate')}</span>
                        </Button>
                    </PackageActivationModal>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default PricingCard;
