import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {
    loadAllContractorAsync,
    registerContractorAsync,
    setDefaultContractor
} from "../../store/reducers/contractor/contractorReducer";
import toast from "react-hot-toast";
import IconButton from "../IconButton";
import {Modal} from "react-bootstrap";
import FalconCloseButton from "../common/FalconCloseButton";
import IconPlus from "../../assets/icon/IconPlus";
import IconArrowLeft from "../../assets/icon/IconArrowLeft";
import Flex from "../common/Flex";
import ContractorForm from "../../pages/warehouse/contractor/ContractorForm";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const AddContractor = ({sizeAndNameClose, disabled, changeNewContractorValue, contact, selectBtn}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleOnSubmit = (formData) => {
        let phones = formData?.phones?.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value) {
                let ph_value = phone.value.replace(/ /g, '')
                ph_value = '998' + ph_value;
                return {
                    type: 1,
                    value: ph_value
                }
            }
        });
        let emails = formData?.emails?.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        sizeAndNameClose && changeNewContractorValue({name: formData.name, inn: formData.inn})

        const startingDebts = formData?.starting_debt_states?.map(startingDebt => {
            return {
                amount: Number(startingDebt.amount),
                currency_id: startingDebt.currency_id,
            }
        });

        setLoading(true)

        dispatch(registerContractorAsync({
            name: formData.name,
            inn: formData.inn,
            is_default: formData.is_default,
            organization_code: formData.organization_code || null,
            phone: formData.phone,
            type: formData.type.map(t => +t),
            contacts: contacts,
            starting_debts: startingDebts,
        })).then(res => {
            if (formData.is_default) {
                dispatch(setDefaultContractor(res.id)).then(res => {
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            }
            dispatch(loadAllContractorAsync());
            toast.success(t(lang, "edi.common.toast.success"));
            setShow(false)
        }).catch(err => {
            toast.error(t(lang, "edi.common.toast.error"));
        }).finally(() => {
            setLoading(false)
        });
    }
    return (<>
        {selectBtn ?
            <IconButton
                disabled={disabled}
                className={'bg-transparent'}
                variant={'phoenix-secondary'}
                icon={faPlus}
                onClick={() => setShow(true)}
            /> :
            <IconButton
            disabled={disabled}
            size={!sizeAndNameClose && "sm"}
            icon={''}
            onClick={() => setShow(true)}
            variant="phoenix-primary"
            className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
        >
            <IconPlus/>
            {!sizeAndNameClose &&
                <span className="d-none d-sm-inline-block ms-1">
                    {t(lang, "edi.common.add_button")}
                </span>
            }
        </IconButton>}
        <Modal show={show} onHide={() => {
            setShow(false)
        }} size={!contact ? "xl" : "lg"} className={'davr-modal'}>
            <Modal.Header>
                <Modal.Title>
                    <Flex className={'gap-2'}>
                        <button onClick={() => setShow(false)} className={'main-text btn-back'}
                                style={{width: 48, height: 48, borderRadius: 16}}>
                            <IconArrowLeft/>
                        </button>
                        {t(lang, "edi.contractor.add_contractor_title")}
                    </Flex>
                </Modal.Title>
                <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => {
                    setShow(false)
                }}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorForm contact={contact} onClose={() => setShow(false)} loading={loading}
                                onSubmit={handleOnSubmit} isEditing={false}/>
            </Modal.Body>
        </Modal>
    </>)
}

export default AddContractor;
