import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Table} from "react-bootstrap";
import dayjs from "dayjs";

const RevisionsViewDataTable = ({dataItems}) => {

    const warehouses = useSelector(selectWarehouses)
    const lang = useSelector(selectLang)
    const t = Translate

    const groupByItemIdAndPrice = (filterItems) => {
        let groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;


            if (itemID in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[itemID].sub.push(item)
            } else {
                if (itemID) {
                    groupByItemIdPriceIdCurrencyId[itemID] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(dataItems)

    return (
        <Table bordered responsive className="table-view scrollbar border-900 mb-0 fs-9 fw-semi-bold">
            <thead style={{position: "sticky", top: '-1px'}}
                   className="text-dark border-bottom bg-card second-text text-center">
            <tr>
                <th className="fw-semi-bold align-middle">№</th>
                <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.name")}</th>
                <th className="text-end fw-semi-bold">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                {dataItems?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                    <th className="fw-semi-bold align-middle">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                }
                {warehouses?.length > 1 &&
                    <th className="text-end fw-semi-bold">{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                }
                {dataItems?.some(d => d?.is_deleted) &&
                    <th className="text-end text-danger">{t(lang, "Удаленное время")}</th>
                }
            </tr>
            </thead>
            <tbody>

            {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                return (
                    <Fragment key={rowIndex}>
                        {item?.sub?.map((subItem, subRowIndex) => {
                            return (
                                <tr key={subItem.id}>
                                    <td key={rowIndex}
                                        className={subRowIndex === 0 ? "fw-semi-bold text-center" : "d-none"}
                                        rowSpan={item?.sub?.length}
                                        style={{width: "50px"}}
                                    >
                                        {groupByItemPriceCurrencyId?.length - rowIndex}
                                    </td>
                                    <td
                                        className={subRowIndex === 0 ? "fw-semi-bold text-center" : "d-none"}
                                        rowSpan={item?.sub?.length}>
                                        {subItem?.warehouse_item?.name}
                                    </td>
                                    <th className="text-end fw-semi-bold">
                                        {subItem?.quantity}
                                    </th>
                                    {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(x => x?.warehouse_item.warehouse_item_use) &&
                                        <td className="fw-semi-bold align-middle">
                                            {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                            <span> => </span>
                                            {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                        </td>
                                    }
                                    {warehouses?.length > 1 &&
                                        <th className="text-end fw-semi-bold">
                                            {subItem?.warehouse_item?.warehouse?.name}
                                        </th>
                                    }
                                    {subItem?.is_deleted &&
                                        <>
                                            <th className="fw-semi-bold align-middle text-danger"
                                                style={{width: "150px"}}>
                                                {dayjs(subItem?.deleted_at).format("YYYY-MM-DD HH:mm:ss")}
                                            </th>
                                        </>
                                    }
                                </tr>
                            )
                        })}
                    </Fragment>
                )
            })}
            </tbody>
        </Table>
    );
};

export default RevisionsViewDataTable;