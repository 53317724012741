export const warehouseRoutes = [
    {
        name: "warehouse.dashboard.items.title",
        to: "/items"
    },
    {
        name: "warehouse.operation.item.common.list_sales",
        to: "/operation/sales"
    },
    {
        name: "warehouse.operation.item.common.list_coming",
        to: "/operation/purchases"
    },
    {
        name: "warehouse.operation.item.common.list_refund",
        to: "/operation/refunds"
    },
    {
        name: "warehouse.operation.item.common.list_return_purchase",
        to: "/operation/return-purchases"
    },
    {
        name: "warehouse.operation.item.common.list_revision",
        to: "/operation/revisions"
    },
    {
        name: "warehouse.operation.item.common.list_write_off",
        to: "/operation/write-offs"
    },
    // {
    //     name: "items.common.draft",
    //     to: "/warehouse/items/drafts"
    // },
    // {
    //     name: "items.common.categories",
    //     to: "/warehouse/items/categories"
    // },
    // {
    //     name: "items.common.settings",
    //     to: "/warehouse/items/settings"
    // },
    // {
    //     name: "warehouse.common.item.title.warehouses",
    //     to: "/warehouse/warehouses"
    // },
    // {
    //     name: "warehouse.common.item.title.histories",
    //     to: "/warehouse/items/history"
    // },
    // {
    //     name: "warehouse.navbar.routes.partners",
    //     to: "/warehouse/partners"
    // },
]


export const addWarehouseRoutes = [
    {
        name: "items.common.import_from_excel",
        to: "/items/registry-add"
    },
    // {
    //     name: "items.common.bulk_add",
    //     to: "/warehouse/items/bulk-add"
    // },
    {
        name: "items.common.add_new",
        to: "/items/add"
    },
]
