import React, {Fragment, useState} from 'react';
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import IconButton from "../../IconButton";
import {Modal} from "react-bootstrap";
import WarehouseOperationAddItemForm from "./WarehouseOperationAddItemForm";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import FalconCloseButton from "../../common/FalconCloseButton";

const WarehouseOperationAddItem = ({onItemCreated, allowAdd}) => {

    const [show, setShow] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate


    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    return (
        <Fragment>
            <IconButton
                onClick={handleShow}
                icon={faPlus}
                style={{zIndex: 0}}
                className={'position-relative bg-transparent'}
                variant={'phoenix-secondary'}
                transform="shrink-3"
            />
            <Modal show={show} onHide={handleClose} fullscreen className={'davr-modal'}>
                <Modal.Header>
                    <Modal.Title>{t(lang, "Добавить товар")}</Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => handleClose()}/>
                </Modal.Header>
                <Modal.Body>
                    <WarehouseOperationAddItemForm allowAdd={allowAdd} setShow={setShow} onItemCreated={onItemCreated} />
                </Modal.Body>
            </Modal>

        </Fragment>
    );
};

export default WarehouseOperationAddItem;
