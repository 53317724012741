import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getPurchasesAsync,
    getPurchasesCountAsync,
    selectPurchaseFilterOptions
} from "../../../../store/reducers/warehouse/purchaseReducer";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import EventBus from "../../../../eventbus/EventBus";
import {WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS} from "../../../../eventbus/warehouse/purchaseEvent";
import PurchaseDatatable from "../../../../components/warehouse/operation/purchase/PurchaseDatatable";

const Purchases = () => {

    const activeOrganization = useSelector(selectActiveOrganization);
    const filters = useSelector(selectPurchaseFilterOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        loadPurchases();

        const onPurchaseDeletedHandler = EventBus.on(WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS, () => {
            loadPurchases();
        });

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_PURCHASE_DELETE_SUCCESS, onPurchaseDeletedHandler)
        };
    }, [activeOrganization, filters])

    const loadPurchases = () => {
        dispatch(getPurchasesAsync({...filters}));
        dispatch(getPurchasesCountAsync({...filters}));
    }

    return (
        <PurchaseDatatable />
    );
};

export default Purchases;
