import {createSlice} from "@reduxjs/toolkit";
import contractorService from "../../services/contractor/contractorService";
import EventBus from "../../../eventbus/EventBus";
import {
    ADD_CONTRACTOR_REQUESTED,
    ADD_CONTRACTOR_SUCCESS,
    ADD_CONTRACTOR_FAILED,
    EDIT_CONTRACTOR_SUCCESS,
    EDIT_CONTRACTOR_FAILED,
    EDIT_CONTRACTOR_REQUESTED,
    DELETE_CONTRACTOR_SUCCESS,
    DELETE_CONTRACTOR_FAILED,
    DELETE_CONTRACTOR_REQUESTED,
    SET_DEFAULT_CONTRACTOR_REQUESTED,
    SET_DEFAULT_CONTRACTOR_SUCCESS,
    SET_DEFAULT_CONTRACTOR_FAILED,
    UN_SET_DEFAULT_CONTRACTOR_REQUESTED,
    UN_SET_DEFAULT_CONTRACTOR_SUCCESS,
    UN_SET_DEFAULT_CONTRACTOR_FAILED,
    CHANGE_CONTRACTOR_STARTING_DEBTS_REQUESTED,
    CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED,
    CHANGE_CONTRACTOR_STARTING_DEBTS_FAILED
} from "../../../eventbus/contractor/contractorEvents";
import ContractorService from "../../services/contractor/contractorService";

export const contractorSlice = createSlice({
    name: 'contractors',
    initialState: {
        filterOptions: {type: null, name: '', label: null, debt: null},
        count: 0,
        cardContractor: null,
        allContractors: [],
        pagination: {page: 1, limit: 10},
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = {...state.filterOptions, ...action.payload}
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        updateAllContractors: (state, action) => {
            state.allContractors = action.payload || []
        },
        updateCardContractor: (state, action) => {
            state.cardContractor = action.payload || []
        },
        updateContractor: (state, action) => {
            const {id, contacts, debts, inn, labels, name, organization_code, organization_id, type} = action.payload
            const editingItemIndex = state.allContractors.findIndex((contractor) => contractor.id === id);

            if (editingItemIndex > -1) {
                state.allContractors[editingItemIndex].contacts = contacts;
                state.allContractors[editingItemIndex].debts = debts;
                state.allContractors[editingItemIndex].inn = inn;
                state.allContractors[editingItemIndex].labels = labels;
                state.allContractors[editingItemIndex].name = name;
                state.allContractors[editingItemIndex].organization_code = organization_code;
                state.allContractors[editingItemIndex].organization_id = organization_id;
                state.allContractors[editingItemIndex].type = type;
            } else {
                state.allContractors.push(action.payload)
            }
        },
        updateContractorDebt: (state, action) => {
            const {contractorId, debts} = action.payload;

            const contractor = state.allContractors.find(c => c.id === contractorId);
            if (!!contractor) {
                contractor.debts = debts
            }
        },
        deleteContractor: (state, action) => {
            let contractor_id = action.payload;
            state.allContractors = state.allContractors.filter(contractor => contractor.id !== contractor_id)
        }
    }
})

export const updatePagination = (pagination) => (dispatch) => dispatch(contractorSlice.actions.setPagination(pagination));

export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(contractorSlice.actions.updateFilterOptions({...params}))
}
export const updateCardContractor = (contractor) => dispatch => {
    dispatch(contractorSlice.actions.updateCardContractor(contractor))
}

export const loadAllContractorAsync = () => (dispatch) => {
    contractorService.getContractors()
        .then((response) => {
            dispatch(contractorSlice.actions.updateAllContractors(response.data))
        })
        .catch((error) => {
            console.log(error)
        })
}

export const loadContractorAsync = id => {
    return new Promise((resolve, reject) => {
        contractorService.getContractor(id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                reject(e);
            });
    })
}
export const registerContractorAsync = (payload) => () => {
    EventBus.dispatch(ADD_CONTRACTOR_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorService.addContractor(payload)
            .then(response => {
                EventBus.dispatch(ADD_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(ADD_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}
export const editContractorAsync = (id, payload) => () => {
    EventBus.dispatch(EDIT_CONTRACTOR_REQUESTED, payload);

    return new Promise((resolve, reject) => {
        contractorService.editContractor(id, payload)
            .then(response => {
                EventBus.dispatch(EDIT_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(EDIT_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}
export const deleteContractorAsync = ({id}) => {
    EventBus.dispatch(DELETE_CONTRACTOR_REQUESTED, {id});

    return new Promise((resolve, reject) => {
        contractorService.deleteContractor({id})
            .then((response) => {
                EventBus.dispatch(DELETE_CONTRACTOR_SUCCESS, id);
                resolve(id);
            })
            .catch((error) => {
                EventBus.dispatch(DELETE_CONTRACTOR_FAILED, error.message);
                reject(error)
            })
    });
};
export const changeContractorStartingDebtsAsync = ({id, payload}) => {
    EventBus.dispatch(CHANGE_CONTRACTOR_STARTING_DEBTS_REQUESTED, id);

    return new Promise((resolve, reject) => {
        contractorService.updateContractorStartingDebts(id, payload)
            .then(response => {
                EventBus.dispatch(CHANGE_CONTRACTOR_STARTING_DEBTS_SUCCEED, response.data);
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(CHANGE_CONTRACTOR_STARTING_DEBTS_FAILED, error);
                reject(error);
            })
    })
};

export const setDefaultContractor = (id) => () => {
    EventBus.dispatch(SET_DEFAULT_CONTRACTOR_REQUESTED);

    return new Promise((resolve, reject) => {
        contractorService.setDefaultContractor(id)
            .then(response => {
                EventBus.dispatch(SET_DEFAULT_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(SET_DEFAULT_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}

export const unSetDefaultContractor = () => () => {
    EventBus.dispatch(UN_SET_DEFAULT_CONTRACTOR_REQUESTED);

    return new Promise((resolve, reject) => {
        contractorService.unSetDefaultContractor()
            .then(response => {
                EventBus.dispatch(UN_SET_DEFAULT_CONTRACTOR_SUCCESS, response.data)
                resolve(response.data);
            })
            .catch(error => {
                EventBus.dispatch(UN_SET_DEFAULT_CONTRACTOR_FAILED, error)
                reject(error);
            })
    });
}


export const getExcelContractor = (ids) => {
    return new Promise((resolve, reject) => {
        ContractorService.getExcelContractor(ids)
            .then(res => {
                resolve(res.data)
            })
            .catch(e => reject(e))
    })
}

export const updateContractor = (contractor) => (dispatch) => {
    dispatch(contractorSlice.actions.updateContractor(contractor));
};
export const updateContractorDebt = ({contractorId, debts}) => (dispatch) => {
    dispatch(contractorSlice.actions.updateContractorDebt({contractorId, debts}))
};
export const deleteContractor = (contractor_id) => (dispatch) => {
    dispatch(contractorSlice.actions.deleteContractor(contractor_id));
};

export const selectAllContractor = (state) => state.contractors.allContractors;
export const selectCardContractor = (state) => state.contractors.cardContractor;
export const selectContractorFilterOptions = (state) => state.contractors.filterOptions;
export const selectPaginationOptions = state => state.item.pagination;

export default contractorSlice.reducer;
