import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getWriteOffItemAsync, writeOffApproveAsync} from "../../../../store/reducers/warehouse/writeOffReducer";
import EventBus from "../../../../eventbus/EventBus";
import {WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS} from "../../../../eventbus/warehouse/writeOffEvent";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import {Card, Col, Row} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import IconButton from "../../../../components/IconButton";
import {faLayerGroup, faPencil} from "@fortawesome/free-solid-svg-icons";
import IconPlus from "../../../../assets/icon/IconPlus";
import UploadWriteOffToExcel from "./UploadWriteOffToExcel";
import WriteOffPrint from "./WriteOffPrint";
import WriteOffDownloadPdf from "./WriteOffDownloadPdf";
import toast from "react-hot-toast";
import WriteOffsViewDataTable from "./WriteOffsViewDataTable";

const ViewWriteOffs = () => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const navigate = useNavigate();

    const activeOrganization = useSelector(selectActiveOrganization)
    const [writeOff, setWriteOff] = useState(null)
    const {id} = useParams()


    const loadWriteOff = () => {
        getWriteOffItemAsync({writeOffId: id})
            .then(response => setWriteOff(response.data, response?.data?.items?.reverse()))
            .catch(_ => setWriteOff(null))
    }

    useEffect(() => {
        loadWriteOff()
    }, [activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, () => {
            loadWriteOff()
        })

        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-write-offs-view-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-write-offs-view-data-table-header'))
    }, []);

    // approve
    const onApprovedHandler = async () => {
        await writeOffApproveAsync({writeOffId: writeOff?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }

    if (!writeOff) return <> loading... </>

    return (
        <Fragment>
            <Row id={'warehouse-revisions-view-data-table-header'}
                 className="flex-between-center align-items-center my-3 position-sticky z-index-1"
                 style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.dashboard.items.title.write_off")} № {writeOff?.number}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'items.common.warehouse')}</span>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    {writeOff?.items.length > 0 && !writeOff?.is_approved &&
                        <IconButton
                            variant='phoenix-warning'
                            icon={faLayerGroup}
                            className={'btn-davr bg-white text-dark d-flex gap-2'}
                            iconClassName={"text-primary-davr fs-7"}
                            size="sm"
                            onClick={() => onApprovedHandler()}
                        >{t(lang, "warehouse.operation.item.common.status.approve")}</IconButton>

                    }
                    <IconButton size='sm'
                                as={Link}
                                to={`../write-offs/update/${writeOff?.id}`}
                                variant='phoenix-warning'
                                icon={faPencil}
                                className={'btn-davr bg-white text-dark d-flex gap-2'}
                                iconClassName={"text-primary-davr fs-7  "}
                    >
                        {t(lang, 'roaming.common.edit')}
                    </IconButton>
                    <UploadWriteOffToExcel className="me-2 d-inline-block" size="sm" id={writeOff?.id}
                                           number={writeOff?.number}/>
                    <WriteOffPrint
                        writeOffId={writeOff?.id}
                    />
                    <WriteOffDownloadPdf
                        writeOffId={writeOff?.id}
                        number={writeOff?.number}
                    />
                    <IconButton
                        size={'sm'}
                        icon={''}
                        as={Link}
                        to={`../write-offs/register`}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    >
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "warehouse.operation.item.common.new_transfer")}
                        </span>
                    </IconButton>
                </Col>
            </Row>
            <Card className={'mt-2 p-2'}>
                <Card.Body className={'p-0 border border-1 rounded-2'}>
                    <WriteOffsViewDataTable
                        dataItems={writeOff?.items?.filter(f => !f.is_deleted)}
                    />
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default ViewWriteOffs;