import React, {Fragment, useState} from 'react';
import IconButton from "../../../IconButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Button, Card, Form, Modal, Spinner} from "react-bootstrap";
import SaleFormModal from "../sale/form/SaleFormModal";
import CheckSaleItemStateAvailableForSaleWrapper from "../sale/CheckSaleItemStateAvailableForSaleWrapper";
import PurchaseFormModal from "./form/PurchaseFormModal";
import FalconCloseButton from "../../../common/FalconCloseButton";
import Flex from "../../../common/Flex";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";

const PurchaseModal = ({
                           categoryItems,
                           loadingMore,
                           loading,
                           commonPrice,
                           onChangeItem,
                           setItem
                       }) => {

    const lang = useSelector(selectLang)
    const t = Translate

    const [show, setShow] = useState(false)
    const onShow = () => {
        setShow(true)
    }

    const onHide = () => {
        setShow(false)
    }


    return (
        <Fragment>

            <IconButton icon={faPlus}
                        variant={'phoenix-primary'}
                        onClick={onShow}
                        className={'bg-primary-davr py-2 px-2 rounded-3'}
                        iconClassName={'text-white'}
            />

            <Modal show={show} onHide={onHide} className={'davr-modal'}>
                <Modal.Header className={''}>
                    <Modal.Title>
                        <Flex className={'gap-2'}>
                            <button onClick={() => onHide()} className={'main-text btn-back'}
                                    style={{width: 48, height: 48, borderRadius: 16}}>
                                <IconArrowLeft/>
                            </button>
                            <div>
                                <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.view.item.title")}</h5>
                                <span className={'second-text fs-9'}>{t(lang, 'items.common.add_new.title')}</span>
                            </div>
                        </Flex>
                    </Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'}  onClick={onHide} />
                </Modal.Header>
                <Modal.Body>
                    <Card className={'p-1'}>
                        <Form id={'purchase-add'}>
                            <PurchaseFormModal
                                categoryItems={categoryItems}
                                onChangeItem={onChangeItem}
                                setItem={setItem}
                                commonPrice={commonPrice}
                            />
                        </Form>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        form={'purchase-add'}
                        type="submit"
                        disabled={loadingMore}
                        className={'btn-white-davr'}
                        variant="phoenix-secondary"
                        name="addOrEditPurchaseMore"
                        size={"lg"}
                    >
                        {loadingMore &&
                            <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                        }
                        {t(lang, "warehouse.operation.item.common.button.add.more")}
                    </Button>

                    <Button
                        form={'purchase-add'}
                        type="submit"
                        onClick={onHide}
                        disabled={loading}
                        variant="phoenix-secondary"
                        className={'btn-primary-davr'}
                        name="addOrEditPurchase"
                        size={"lg"}
                    >
                        {
                            loading &&
                            <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                        }
                        {t(lang, "warehouse.operation.item.common.button.add.and.hide.modal")}
                    </Button>

                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default PurchaseModal;
