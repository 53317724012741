import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Translate} from "../../../../../lang/lang";
import {selectDateFormat, selectLang, selectNumberFormat} from "../../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectItems} from "../../../../../store/reducers/item/itemReducer";
import {selectWarehouses} from "../../../../../store/reducers/warehouse/warehouseReducer";
import {selectAllContractor} from "../../../../../store/reducers/contractor/contractorReducer";
import {
    deleteReturnPurchasePaymentAsync,
    returnPurchaseApproveAsync,
    returnPurchaseChangeContractorAsync,
    returnPurchaseDeleteItemAsync,
    returnPurchasePaymentRegisterAsync,
    returnPurchasePaymentUpdateAsync,
    returnPurchaseUpdateDateAsync,
    returnPurchaseUpdateItemAsync,
    returnPurchaseUpdateNumberAsync,
    selectReturnPurchaseDefaultsSettings
} from "../../../../../store/reducers/warehouse/returnPurchase";
import {selectCurrency} from "../../../../../store/reducers/currency/currencyReducer";
import {useNavigate} from "react-router-dom";
import EventBus from "../../../../../eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../../../eventbus/itemEvents";
import {ADD_CONTRACTOR_SUCCESS} from "../../../../../eventbus/contractor/contractorEvents";
import {ADD_EMPLOYEE_SUCCEED} from "../../../../../eventbus/employee/employeeEvent";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {scrollEvent} from "../../../../../helpers/scrollEvent";
import {Card, Col, FloatingLabel, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import IconArrowLeft from "../../../../../assets/icon/IconArrowLeft";
import IconButton from "../../../../IconButton";
import IconPlus from "../../../../../assets/icon/IconPlus";
import DatePickerComponent from "../../../../common/DatePickerComponent";
import classNames from "classnames";
import ContractorSelect from "../../../../contractor/ContractorSelect";
import WarehouseOperationWarehouseSelect from "../../WarehouseOperationWarehouseSelect";
import WarehouseOperationItemSelect from "../../WarehouseOperationItemSelect";
import numeral from "numeral";
import Badge from "../../../../common/Badge";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import WarehouseOperationFooterCurrencyTotalSum from "../../WarehouseOperationFooterCurrencyTotalSum";
import WOExactDiscountPayment from "../../purchase/WOExactDiscountPayment";
import ReturnPurchaseModal from "../ReturnPurchaseModal";
import {useMainLayoutContext} from "../../../../../pheonix/providers/MainLayoutProvider";

const colIndexItem = 0
const colIndexQuantity = 2
const colIndexPrice = 3
const colIndexTotal = 4
const colIndexWarehouse = 6


const colCoordinateZero = 0
const rowCoordinateOne = 1
const subRowoordinateTwo = 2


const ReturnPurchaseForm = ({returnPurchase, loading, loadingMore, addItem, isEditing}) => {

    const returnPurchaseDefaultsSettings = useSelector(selectReturnPurchaseDefaultsSettings)
    const numberFormat = useSelector(selectNumberFormat)
    const contractors = useSelector(selectAllContractor)
    const currencies = useSelector(selectCurrency)
    const dateFormat = useSelector(selectDateFormat)
    const warehouses = useSelector(selectWarehouses)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate

    // state
    const [categoryItems, setCategoryItems] = useState(null)
    const [isHover, setIsHover] = useState([])
    const [filter, setFilter] = useState('')
    const [loadingContractor, setLoadingContractor] = useState(false)
    const {errors} = form?.formState

    // watch
    const packageMeasurements = useWatch({name: 'package_measurements', exact: true})
    const contractorId = useWatch({name: 'contractor_id', exact: true})
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const numberWatch = useWatch({name: 'number', exact: true})
    const commonPrice = form.watch('commonPrice')
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})

    const totalSum = +quantity * (+packageMeasurements?.quantity || 0) * +commonPrice?.amount
    const totalSumCurrencyName = currencies?.find(x => x.id === commonPrice?.currency_id)?.name
    const contractor = contractors.find(f => f?.id === contractorId)

    useEffect(() => {
        if (!contractor) {
            form?.setValue("contractor_id", returnPurchaseDefaultsSettings?.defaultClientId)
        }

        if (!warehouseId) {
            form?.setValue("warehouse_id", returnPurchaseDefaultsSettings?.defaultWarehouseId)
        }
        const onContractorAddedHandler = EventBus.on(ADD_CONTRACTOR_SUCCESS, (res) => {
            form?.setValue("contractor_id", res?.id)
        })
        const onEmployeeAddedHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, (res) => {
            form?.setValue("employee_id", res?.data?.id)
        })
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
            EventBus.remove(ADD_CONTRACTOR_SUCCESS, onContractorAddedHandler)
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeAddedHandler)
        }
    }, [returnPurchaseDefaultsSettings])

    useEffect(() => {
        if (warehouses?.length === 1 && warehouse) {
            form?.setValue("warehouse_id", warehouse[0]?.id)
        }
    }, [warehouse])

    useEffect(() => {
        form?.setValue("employee_id", returnPurchaseDefaultsSettings?.defaultEmployeeId)
    }, [returnPurchaseDefaultsSettings?.defaultEmployeeId]);

    const onScan = (value) => {
        if (value.length > 14) {
            const quantityGet = form?.getValues('quantity')

            form?.setValue('marks', [value])
            form?.setValue('quantity', +quantityGet + 1)

            addItem("addOrEditReturnPurchase")
        }
    }

    const onChangeCategory = (option) => {
        setCategoryItems(items.filter(i => i?.item?.category?.id === option?.id))
    }

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }


    // focus start
    const onFocusContractor = () => {
        if (!contractorId && !quantity && !itemId) {
            return true
        }
    }

    const onFocusItem = () => {
        if (contractorId && !itemId) {
            return true
        }
    }

    const onFocusWarehouse = () => {
        if (itemId && !warehouseId) {
            return true
        }
    }

    const onFocusQuantity = () => {
        if (warehouseId && itemId && contractorId && !quantity) {
            document.getElementById('quantity')?.focus()
            return true
        }
    }

    useEffect(() => {
        onFocusQuantity()
    }, [warehouseId, itemId])
    // focus end

// payment start
    const onSubmitPay = async (pay) => {
        if (returnPurchase?.payment) {
            await returnPurchasePaymentUpdateAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                })
                .catch(() => {

                })
        }

        if (!returnPurchase?.payment && returnPurchase?.is_approved) {
            await returnPurchasePaymentRegisterAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                })
                .catch(() => {

                })

        }

        if (!returnPurchase?.is_approved) {
            await returnPurchaseApproveAsync({returnPurchaseId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                })
                .catch(() => {

                })
        }
    }
    // payment end

    const data = useMemo(() => {
        return returnPurchase?.items?.filter(i => {
            const searchLower = filter.trim().toLowerCase();

            // search by barcode
            {
                if (searchLower.length >= 8 && searchLower.length <= 13 && !isNaN(searchLower)) {
                    const searchBarcode = items.filter(it => it.item?.barcodes?.some((b) => b?.includes(searchLower)))

                    if (searchBarcode.some(bar => bar?.item?.name.trim().toLowerCase() === i?.warehouse_item?.name.trim().toLowerCase()))
                        return true;
                }
            }

            // search by name
            {
                const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                const itemLowerName = i.warehouse_item?.name.toLowerCase();
                if (filterNameParts?.length === filterNameParts?.filter(fnp => itemLowerName?.indexOf(fnp) > -1)?.length)
                    return true;
            }
        })
    }, [returnPurchase, filter])

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(data?.filter(f => !f?.is_deleted))


    // hover mouser over
    const handleMouseOver = ({col, row, subRow}) => {
        setIsHover([col, row, subRow])
    }

    const updateItem = async ({itemOne, quantity, price, totalSum, warehouseId, item}) => {
        const operationItem = {
            operation_item_id: item?.id,
            item_id: item?.warehouse_item?.id,
            warehouse_id: item?.warehouse_item?.warehouse?.id,
            quantity: item?.quantity,
            price: {
                amount: item?.price?.amount,
                currency_id: item?.price?.currency?.id,
            },
        }


        if (itemOne) {
            operationItem.item_id = itemOne?.item?.id
            operationItem.price.amount = itemOne?.price?.common_price.amount
            operationItem.price.currency_id = itemOne?.price?.common_price?.currency?.global_currency_id
        }
        if (quantity) {
            operationItem.quantity = +quantity
        }
        if (price) {
            operationItem.price.amount = +price
        }
        if (totalSum) {
            operationItem.quantity = +(+totalSum / item?.price?.amount).toFixed(2)
        }
        if (warehouseId) {
            operationItem.warehouse_id = warehouseId
        }

        await returnPurchaseUpdateItemAsync({
            returnPurchaseId: returnPurchase?.id,
            operation: operationItem
        })
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setIsHover([])

            })
    }

    // delete item
    const onDeleteItem = async ({id, itemId}) => {
        await returnPurchaseDeleteItemAsync({returnPurchaseId: id, operationId: itemId})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }

    const onDateChanged = async (e, date) => {
        if (e === "Save") {
            await returnPurchaseUpdateDateAsync({
                returnPurchaseId: returnPurchase?.id,
                date: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : dayjs(returnPurchase?.date).format("YYYY-MM-DD HH:mm:ss")
            })
                .then(() => {
                    form.setValue('date', date ? dayjs(date, "YYYY-MM-DD HH:mm").toDate() : dayjs(returnPurchase?.date, "YYYY-MM-DD HH:mm").toDate())
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
        if (e === "Cancel") {
            form.setValue('date', dayjs(returnPurchase?.date, "YYYY-MM-DD HH:mm").toDate())
        }
    }

    const onNumberChanged = (e, number) => {
        if (e === "Save") {
            if (number?.trim() && number !== returnPurchase?.number) {
                returnPurchaseUpdateNumberAsync({returnPurchaseId: returnPurchase?.id, number: number})
                    .then(() => {
                        form.setValue('number', number ? number : returnPurchase?.number)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch(() => {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    })
            }
        }
        if (e === "Cancel") {
            form.setValue('number', returnPurchase?.number)
        }
    }

    const deleteReturnPurchasePayment = (returnPurchaseId) => {
        deleteReturnPurchasePaymentAsync(returnPurchaseId)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    }

    const onContractorChanged = async (e, value, setValue, defaultValue) => {
        if (e === 'Save') {
            setLoadingContractor(true)
            await returnPurchaseChangeContractorAsync({returnPurchaseId: returnPurchase.id, contractorId: value?.id})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    form.setValue('contractor_id', value?.id)
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
                .finally(() => {
                    setLoadingContractor(false)
                })
        } else {
            setValue(contractors.find(i => i.id === defaultValue))
        }
    }
    const {loading: contextLoading} = useMainLayoutContext();

    const childRef = useRef();
    const handleSubmit = () => {
        if (childRef.current) {
            childRef.current();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-return-purchase-register'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-return-purchase-register'))
    }, []);

    return (
        <Fragment>
            <Row id={'warehouse-return-purchase-register'}
                 className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.operation.item.common.list_return_purchase")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <IconButton
                        size="sm"
                        variant="falcon-primary"
                        className="btn-primary-davr"
                        onClick={handleSubmit}
                        disabled={!returnPurchase?.items?.length}
                    >
                        {contextLoading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">
                            {t(lang, "items.common.save")}
                        </span>
                    </IconButton>
                </Col>
            </Row>

            <Card className={'p-1'}>
                <Row>
                    <Form.Group as={Col} xs={12} lg={4}>
                        <Controller
                            name={'date'}
                            rules={{
                                required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                            }}
                            render={({field}) => (
                                <InputGroup className={'select'}>
                                    <DatePickerComponent
                                        field={field}
                                        placeholderText={<>
                                            {t(lang, "Дата")}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                                        wrapperClassName={classNames({
                                            'is-invalid': errors?.date
                                        })}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.date,
                                        })}
                                    />
                                    {dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== returnPurchase?.date && returnPurchase &&
                                        <Fragment>
                                            <IconButton
                                                variant={"phoenix-secondary"}
                                                title={"Save"}
                                                size="md"
                                                onClick={(e) => onDateChanged("Save", field?.value)}
                                                icon={"check"}
                                            />
                                            <IconButton
                                                variant="phoenix-secondary"
                                                title={"Cancel"}
                                                size="md"
                                                onClick={(e) => onDateChanged("Cancel")}
                                                icon="cancel"
                                            />
                                        </Fragment>
                                    }
                                </InputGroup>
                            )}/>
                        <Form.Control.Feedback type="invalid">
                            {errors?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} lg={4}>
                        <Controller
                            name="contractor_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <ContractorSelect
                                        right={true}
                                        onFocus={onFocusContractor()}
                                        placeholder={<>
                                            <span>{t(lang, "warehouse.operation.item.common.executor")}</span>
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        defaultValue={field?.value}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        isClearable={true}
                                        allowAdd={true}
                                        allowEdit={true}
                                        className={classNames({
                                            'is-invalid': errors?.contractor_id,
                                        })}
                                        isEditing={groupByItemPriceCurrencyId.length > 0 && true || isEditing}
                                        changeContractor={onContractorChanged}
                                        loading={loadingContractor}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.contractor_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} xs={12} lg={4}>
                        <Controller
                            name="warehouse_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        placeholder={<>
                                            {t(lang, 'warehouse.navbar.routes.warehouse')}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        defaultValue={field?.value}
                                        options={warehouse}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.warehouse_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card>

            <Card className={'p-1 mt-2'}>
                <Table
                    responsive
                    className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold"
                    style={{maxHeight: "560px"}}
                >
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th className="fw-medium  align-middle text-center">№</th>
                        <th className="fw-medium  align-middle text-center" style={{width: "300px"}}>
                            {t(lang, "warehouse.operation.item.common.name")}
                        </th>
                        {/* <th className="fw-medium  align-middle text-center">
                            {t(lang, "warehouse.operation.item.common.image")}
                        </th>*/}
                        <th className="fw-medium  align-middle text-center">
                            {t(lang, "warehouse.operation.item.common.quantity")}
                        </th>
                        <th className="fw-medium  align-middle text-center" style={{width: "300px"}}>
                            {t(lang, "warehouse.operation.item.common.selling_price")}
                        </th>
                        <th className="fw-medium  align-middle text-center">
                            {t(lang, "warehouse.operation.item.common.total")}
                        </th>
                        {returnPurchase?.items?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                            <th className="fw-medium  align-middle text-center">
                                {t(lang, "warehouse.operation.item.common.remainder_change")}
                            </th>
                        }
                        {warehouses?.length > 1 &&
                            <th className="fw-medium  align-middle text-center" style={{width: "230px"}}>
                                {t(lang, "warehouse.operation.item.common.warehouse_name")}
                            </th>
                        }
                        <th>
                            <ReturnPurchaseModal
                                categoryItems={categoryItems}
                                onChangeItem={onChangeItem}
                                commonPrice={commonPrice}
                                loading={loading}
                                loadingMore={loadingMore}
                                warehouseId={warehouseId}
                                itemId={itemId}
                                quantity={quantity}
                                addItem={addItem}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                        return (
                            <Fragment key={rowIndex}>
                                {item?.sub?.map((subItem, subRowIndex) => {
                                    return (
                                        <tr key={subItem.id} className="fw-bold" style={{height: "62px"}}>
                                            <td key={rowIndex}
                                                className={subRowIndex === 0 ? "text-start ps-3" : "d-none"}
                                                rowSpan={item?.sub?.length}
                                                style={{width: "50px"}}
                                            >
                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                            </td>
                                            <td className={subRowIndex === 0 ? "text-start" : "d-none"}
                                                rowSpan={item?.sub?.length}
                                                style={{minWidth: "300px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexItem,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}
                                            >
                                                {isHover[colCoordinateZero] === colIndexItem && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <WarehouseOperationItemSelect
                                                        placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                                        options={items}
                                                        defaultValue={subItem?.warehouse_item?.id}
                                                        menuPortalTarget={document.body}
                                                        onChange={option => {
                                                            updateItem({
                                                                itemOne: option,
                                                                item: subItem
                                                            })
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        {subItem?.warehouse_item?.name}
                                                    </>
                                                }
                                            </td>
                                            <td className="text-start"
                                                style={{maxWidth: "100px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexQuantity,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexQuantity && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <FloatingLabel
                                                        label={t(lang, "warehouse.operation.item.common.quantity")}>
                                                        <Form.Control
                                                            placeholder={t(lang, "Quantity")}
                                                            defaultValue={subItem?.quantity}
                                                            onFocus={(e) => e.target.select()}
                                                            autoFocus
                                                            onBlur={(e) => {
                                                                updateItem({
                                                                    quantity: e.target.value,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    :
                                                    <>
                                                        {subItem?.quantity}
                                                    </>
                                                }
                                            </td>
                                            <td className="text-start text-nowrap"
                                                style={{width: "200px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexPrice,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexPrice && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <FloatingLabel
                                                        label={t(lang, "warehouse.operation.item.common.selling_price")}>
                                                        <Form.Control
                                                            placeholder={t(lang, "Price")}
                                                            defaultValue={subItem?.price?.amount}
                                                            onFocus={(e) => e.target.select()}
                                                            autoFocus
                                                            onBlur={(e) => {
                                                                updateItem({
                                                                    price: e.target.value,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    :
                                                    <>
                                                        {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                        <span
                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </>
                                                }
                                            </td>
                                            <td className="text-start text-nowrap"
                                                style={{width: "300px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexTotal,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexTotal && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <FloatingLabel
                                                        label={t(lang, "warehouse.operation.item.common.total")}>
                                                        <Form.Control
                                                            placeholder={t(lang, "total sum")}
                                                            defaultValue={subItem?.quantity * subItem?.price?.amount}
                                                            onFocus={(e) => e.target.select()}
                                                            autoFocus
                                                            onBlur={(e) => {
                                                                updateItem({
                                                                    totalSum: +e.target.value,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    :
                                                    <>
                                                        {numeral.formats[numberFormat].format(subItem?.quantity * subItem?.price?.amount)}
                                                        <span
                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                    </>
                                                }
                                            </td>
                                            {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(s => s?.warehouse_item?.warehouse_item_use) &&
                                                <td className="text-center text-nowrap">
                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                    <span> => </span>
                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                </td>
                                            }
                                            {warehouses?.length > 1 &&
                                                <td className="text-start"
                                                    style={{width: "240px"}}
                                                    onDoubleClick={() => handleMouseOver({
                                                        col: colIndexWarehouse,
                                                        row: rowIndex,
                                                        subRow: subRowIndex
                                                    })}>
                                                    {isHover[colCoordinateZero] === colIndexWarehouse && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex && warehouses?.length > 1 ?
                                                        <WarehouseOperationWarehouseSelect
                                                            placeholder={t(lang, "warehouse.operation.item.common.warehouse_name")}
                                                            options={items?.find(f => f?.warehouse_states?.id === subItem?.warehouse_item?.id)?.warehouse_states?.warehouse_items}
                                                            defaultValue={subItem?.warehouse_item?.warehouse?.id}
                                                            onFocus={() => true}
                                                            autoFocus={true}
                                                            onBlur={(warehouse) => {
                                                                updateItem({
                                                                    warehouseId: warehouse?.id,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                            onChange={warehouse => {
                                                                updateItem({
                                                                    warehouseId: warehouse?.id,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                        />
                                                        :
                                                        <>
                                                            {subItem?.warehouse_item?.warehouse?.name}
                                                        </>
                                                    }
                                                </td>
                                            }

                                            <td className="text-nowrap text-center"
                                                style={{width: "40px"}}>
                                                {subItem?.is_deleted &&
                                                    <span className="text-end text-nowrap text-danger">
                                                                        {t(lang, "warehouse.operation.item.common.removed")}
                                                                    </span>
                                                }

                                                <Badge
                                                    className={'px-2 py-2 fs-8 rounded-3 cursor-pointer'}
                                                    bg="danger-davr"
                                                    variant="phoenix"
                                                    onClick={() => onDeleteItem({
                                                        id: returnPurchase?.id,
                                                        itemId: subItem?.id
                                                    })}
                                                >
                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                </Badge>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                    </tbody>
                </Table>
                {returnPurchase?.items?.length && <WarehouseOperationFooterCurrencyTotalSum
                    netPriceType={"debtHidden"}
                    netPrice={returnPurchase?.net_price}
                    pay={returnPurchase?.payout}
                />}
            </Card>


            {
                returnPurchase?.items?.length && <Card className={'p-1 mt-2'}>
                    <WOExactDiscountPayment
                        ref={childRef}
                        data={returnPurchase}
                        isEditing={isEditing}
                        onSubmitPay={onSubmitPay}
                    />
                </Card>
            }
        </Fragment>
    );
};

export default ReturnPurchaseForm;
