import React from 'react';
import ReturnPurchaseDatatable
    from "../../../../components/warehouse/operation/return-purchase/ReturnPurchaseDatatable";

const ReturnPurchases = () => {
    return (
        <ReturnPurchaseDatatable />
    );
};

export default ReturnPurchases;
