import Select from "react-select"
import React from "react";
import {useSelector} from "react-redux";
import {InputGroup} from "react-bootstrap";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";

const SelectCurrencyCode = ({label, defaultValue, onChange, ...props}) => {
    const currencies = useSelector(selectCurrency);
    const activeCurrencies = currencies.filter(cur => cur.is_active)


    return (
        <div className={'w-25'} {...props}>
            {activeCurrencies?.length <= 1 ?
                <InputGroup.Text className="cursor-pointer" id="basic-addon2">
                    {activeCurrencies[0]?.name}
                </InputGroup.Text>
                :
                <CustomFloatingLabelSelect
                    left={true}
                    height={'40px'}
                    label={label}
                    value={activeCurrencies.find(c => c.id === defaultValue) || activeCurrencies[0]}
                    options={activeCurrencies}
                    isDisabled={activeCurrencies?.length <= 1}
                    hideSelectedOptions
                    onChange={(val) => onChange(val)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                />
            }
        </div>
    )
}
export default SelectCurrencyCode