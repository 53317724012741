import React, {Fragment, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../store/reducers/auth/authReducer";
import {
    getOrganizationBillingInfoAsync, POS_SERVICE_CODE,
    selectIsPosServiceActive,
    selectIsWarehouseServiceActive,
    selectOrganizationServiceDemoUses, WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../store/reducers/billing/billingReducer";
import {getAllWarehousesAsync} from "../store/reducers/warehouse/warehouseReducer";
import EventBus from "../eventbus/EventBus";
import {ACTIVATE_DEMO_SUCCEED} from "../eventbus/billingEvents";
import TryEdiTrialAlert from "../components/notificate/TryEdiTrialAlert";
import ActivateServicePackageAlert from "../components/notificate/ActivateServicePackageAlert";
import BarcodeReader from "../components/item/components/BarcodeReader";
import {Outlet} from "react-router-dom";

const WarehouseLayout = () => {
    const activeOrganization = useSelector(selectActiveOrganization)
    const dispatch = useDispatch()
    const serviceDemoUses = useSelector(selectOrganizationServiceDemoUses);
    const isWarehouseServiceActive = useSelector(selectIsWarehouseServiceActive)
    const isPosServiceActive = useSelector(selectIsPosServiceActive)

    const loadBillingInfo = () => {
        dispatch(getOrganizationBillingInfoAsync())
    }

    useEffect(() => {
        dispatch(getAllWarehousesAsync({}))
        loadBillingInfo();

        const activateDemoPeriodHandler = EventBus.on(ACTIVATE_DEMO_SUCCEED, () => {
            dispatch(getOrganizationBillingInfoAsync());
        });

        return () => {
            EventBus.remove(ACTIVATE_DEMO_SUCCEED, activateDemoPeriodHandler);
        }
    }, [activeOrganization])

    const isAlreadyUsedWarehouseManagement = serviceDemoUses?.find(service => service?.code === WAREHOUSE_MANAGEMENT_SERVICE_CODE);
    const isAlreadyUsedPos = serviceDemoUses?.find(service => service?.code === POS_SERVICE_CODE);

    return (
        <Fragment>
            {!isAlreadyUsedWarehouseManagement && <TryEdiTrialAlert serviceCode={WAREHOUSE_MANAGEMENT_SERVICE_CODE} />}
            {!isAlreadyUsedPos && <TryEdiTrialAlert serviceCode={POS_SERVICE_CODE}/>}
            {!isWarehouseServiceActive && <ActivateServicePackageAlert serviceCode={WAREHOUSE_MANAGEMENT_SERVICE_CODE} />}
            {!isPosServiceActive && <ActivateServicePackageAlert serviceCode={POS_SERVICE_CODE}/>}

            <BarcodeReader />

            <Outlet />
        </Fragment>
    );
};

export default WarehouseLayout;
