import React, {useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {validateItemCatalogsAsync} from "../../../store/reducers/item/itemReducer";
import {
    VALIDATE_ITEM_CATALOGS_FAILED,
    VALIDATE_ITEM_CATALOGS_REQUESTED,
    VALIDATE_ITEM_CATALOGS_SUCCESS
} from "../../../eventbus/itemEvents";
import EventBus from "../../../eventbus/EventBus";
import {faRepeat} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";
import IconButton from "../../IconButton";

const ValidateItemCatalogs = () => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [loading, setLoading] = useState(false);

    const validateItemCatalogs = () => {
        setLoading(true);
        EventBus.dispatch(VALIDATE_ITEM_CATALOGS_REQUESTED);
        validateItemCatalogsAsync()
            .then(() => {
                setLoading(false);
                EventBus.dispatch(VALIDATE_ITEM_CATALOGS_SUCCESS)
            })
            .catch(() => {
                setLoading(false);
                EventBus.dispatch(VALIDATE_ITEM_CATALOGS_FAILED)
            })
    }

    return (
        <IconButton variant="falcon-info"
                    size="sm"
                    icon={faRepeat}
                    onClick={validateItemCatalogs}
                    disabled={loading}
                    className="btn-davr px-2 py-0 bg-white text-dark gap-2"
                    iconClassName={'text-primary-davr'}
        >
            {t(lang, 'items.common.catalogs.sync')}
            {loading && <Spinner className='align-middle ms-2' animation='border' role='switch' size='sm'/>}
        </IconButton>
    );
};

export default ValidateItemCatalogs;
