import React from 'react';
import RefundDatatable from "../../../../components/warehouse/operation/refund/RefundDatatable";

const Refunds = () => {
    return (
        <RefundDatatable />
    );
};

export default Refunds;
