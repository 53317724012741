import {Button, Col, FloatingLabel, Form, FormLabel, Row, Spinner} from "react-bootstrap";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import Cleave from "cleave.js/react";
import {useLocation} from "react-router";
import dayjs from "dayjs";
import {selectDateFormat, selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCardContractor} from "../../../store/reducers/contractor/contractorReducer";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {selectDefaultSetting} from "../../../store/reducers/settings/settingsReducer";
import SelectContractor from "../contractor/SelectContractor";
import IconButton from "../../../components/IconButton";
import {UZS} from "../../../enum/currency/currencyWrapper";
import CashBoxSelect from "./CashBoxSelect";
import SelectContractorPaymentType from "./SelectContractorPaymentType";
import SelectCurrencyCode from "./SelectCurrencyCode";
import DatePickerComponent from "../../../components/common/DatePickerComponent";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import CleaveWithCommaMark from "../../../components/common/CleaveWithCommaMark";

const ContractorPaymentForm = ({onClose, loading, onSubmit, payment, onEditNote, onEditContractor, onEditDate}) => {
    const lang = useSelector(selectLang);
    const t = Translate;
    const dateFormat = useSelector(selectDateFormat);
    const cardContractor = useSelector(selectCardContractor)
    const location = useLocation();
    const currencies = useSelector(selectCurrency);
    const settings = useSelector(selectDefaultSetting)

    const defaultDebtStates = {
        amount: '',
        currency_id: currencies.find(c => c?.code === UZS)?.id
    }
    const defaultCashBoxStates = {
        type: 1,
        currency: {
            amount: '',
            currency_id: currencies.find(c => c?.code === UZS)?.id
        }
    }

    const routeArr = location.pathname.split("/")
    const isContractorCard = routeArr.includes("contractor-card");

    const {formState: {errors}, handleSubmit, control, register, setValue} = useForm({
        defaultValues: {
            contractor: (payment && payment.contractor.id) || (isContractorCard && cardContractor?.id) || null,
            date: payment ? dayjs(payment?.date, "YYYY-MM-DD HH:mm").toDate() : dayjs(new Date(), "YYYY-MM-DD HH:mm").toDate(),
            notes: payment ? payment.notes : '',
            debt_states: payment ? payment.debt_states.map(ds => {
                return {
                    type: ds.payment_type,
                    currency: {
                        amount: ds.amount,
                        currency_id: ds.currency?.id
                    }
                }
            }) : [defaultDebtStates],
            cash_box_states: payment ? payment.cash_box_states.map(bs => {
                return {
                    type: bs.payment_type,
                    currency: {
                        amount: bs.amount,
                        currency_id: bs.currency?.id
                    }
                }
            }) : [defaultCashBoxStates],
            cashbox_id: payment ? payment.cash_box?.id : settings?.cash_box?.default_id || ""
        }
    });

    const {
        fields: fieldsDebtStates,
        update: updateDebtStates,
        append: appendDebtStates,
        remove: removeDebtStates,
        replace: replaceDebtStates,
    } = useFieldArray({
        control,
        name: 'debt_states'
    });
    const {
        fields: fieldsCashBoxStates,
        update: updateCashBoxStates,
        append: appendCashBoxStates,
        remove: removeCashBoxStates,
        replace: replaceCashBoxStates,
    } = useFieldArray({
        control,
        name: 'cash_box_states'
    });

    const watchCashBoxStates = useWatch({name: 'cash_box_states', control});

    useEffect(() => {
        setValue('cashbox_id', payment ? payment.cash_box?.id : settings?.cash_box?.default_id || null)
    }, [settings])

    useEffect(() => {
        let mm = watchCashBoxStates.map(item => {
            return {
                currency_id: item.currency.currency_id,
                amount: item.currency.amount,
            }
        });
        replaceDebtStates(mm)
    }, [watchCashBoxStates])

    const onChangeContractor = (contractor) => {
        setValue('contractor', contractor || null)
        if (!payment && contractor && contractor?.debts?.length > 0) {
            let dt = contractor?.debts?.filter(c => c?.amount !== 0).map(debt => {
                return {
                    amount: Math.abs(debt?.amount),
                    currency_id: debt?.currency?.id
                }
            })
            let cc = contractor?.debts?.filter(c => c?.amount !== 0).map(debt => {
                return {
                    type: 1,
                    currency: {
                        amount: Math.abs(debt?.amount),
                        currency_id: debt?.currency?.id
                    }
                }
            })

            replaceCashBoxStates(cc)
            replaceDebtStates(dt)
        }

        if (payment && payment.id && contractor) {
            onEditContractor(contractor.id)
        }
    }

    const onChangeCashBoxStatesPaymentType = (selectedPaymentType, field, index) => {
        if (selectedPaymentType) {
            updateCashBoxStates(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
            })
        }
    }
    const onChangeCashBoxStatesCurrencyCode = (currencyCode, field, index) => {
        if (currencyCode) {
            updateCashBoxStates(index, {
                type: field.type,
                currency: {
                    currency_id: currencyCode.id,
                    amount: field.currency.amount,
                }
            })
        }
    }
    const onChangeDebtStatesCurrencyId = (currencyCode, field, index) => {
        if (currencyCode) {
            updateDebtStates(index, {
                currency_id: currencyCode.id,
                amount: field.amount,
            })
        }
    }

    const addNewPaymentRowToCashBoxStates = () => {
        appendCashBoxStates(defaultCashBoxStates)
    }
    const addNewPaymentRowToDebtStateAmounts = () => {
        appendDebtStates(defaultDebtStates)
    }
    const onTrashCashBoxStates = (index) => {
        removeCashBoxStates(index)
    }
    const onTrashDebtStateAmounts = (index) => {
        removeDebtStates(index)
    }

    const handleOnBlurNote = (val) => {
        if (payment && payment.id)
            onEditNote(val.target.value)
    }

    console.log(errors)
    return (<>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={12}>
                    <Form.Group className="mb-3">
                        <Controller control={control} render={({field}) => (
                            <SelectContractor
                                label={t(lang, "edi.contractor.datatable.header.row.contractor")}
                                placeholder={t(lang, "edi.contractor.datatable.header.row.contractor")}
                                className={classNames({
                                    'is-invalid': errors?.contractor,
                                })}
                                wrapperClassName={classNames({
                                    'is-invalid': errors?.contractor,
                                })}
                                defaultValue={field?.value}
                                onChange={onChangeContractor}
                            />)}
                                    name="contractor"
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    }}
                        />
                        <Form.Control.Feedback
                            type="invalid">{errors?.contractor?.message}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group>
                        <Controller
                            control={control}
                            name={'cashbox_id'}
                            render={({field}) => (
                                <CashBoxSelect
                                    label={t(lang, "edi.contractor_payment_form_title")}
                                    defaultValue={field.value}
                                    onChange={selectedCashBox => selectedCashBox ? field.onChange(selectedCashBox.id) : field.onChange(null)}
                                />
                            )}/>
                    </Form.Group>
                </Col>
                <Col xs={6}>
                    <Form.Group className="mb-3">
                        <Controller
                            control={control}
                            name='date'
                            render={({field}) => (
                                <DatePickerComponent
                                    placeholderText={t(lang, "edi.common.order.date.placeholder")}
                                    dateFormat={`${dateFormat} HH:mm`}
                                    selected={field?.value}
                                    onChange={(date) => {
                                        field.onChange(date)
                                        if (onEditDate)
                                            onEditDate(date)
                                    }}
                                    field={field}
                                    showTimeSelect
                                    timeFormat="p"
                                    timeIntervals={60}
                                    className={'form-control'}
                                />
                            )}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6}>
                    <div className="d-flex justify-content-end">
                        <IconButton
                            size={"sm"}
                            icon={faPlus}
                            onClick={addNewPaymentRowToCashBoxStates}
                            variant="phoenix-primary"
                            className={'btn-davr bg-primary-davr-outline text-white d-flex gap-2 mb-2'}
                        >
                            {/*{t(lang, "edi.common.add_button")}*/}
                        </IconButton>
                    </div>
                    {fieldsCashBoxStates.map((item, index) => (
                        <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                            <Controller name={`cash_box_states.${index}.type`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectContractorPaymentType
                                                defaultValue={field.value}
                                                label={t(lang, 'crm.payment_type')}
                                                onChange={(paymentType) => onChangeCashBoxStatesPaymentType(paymentType, item, index)}
                                            />
                                        )}
                            />
                            <Controller
                                name={`cash_box_states.${index}.currency.amount`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                }}
                                render={({field}) => (
                                    <CleaveWithCommaMark options={{
                                        numeral: true,
                                        delimiter: ' ',
                                        numeralDecimalScale: 3,
                                    }}
                                                         class={'border-left-0'}
                                                         value={field?.value}
                                                         placeholder={t(lang, 'edi.contractor_payment_form_cash_label')}
                                                         className={classNames('form-control', {
                                                             'is-invalid': errors?.cash_box_states?.[index]?.currency.amount.message,
                                                             'is-valid': Object.keys(errors).length > 0 && !errors?.cash_box_states?.[index]?.currency.amount.message
                                                         })}
                                                         onChange={val => {
                                                             let v = val ? val?.replace(/-/g, '') : val
                                                             field.onChange(v)
                                                         }}
                                    />
                                )}
                            />
                            <Controller name={`cash_box_states.${index}.currency.currency_id`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectCurrencyCode
                                                label={t(lang, 'warehouse.operation.item.common.currency')}
                                                defaultValue={field.value}
                                                onChange={(currencyCode) => {
                                                    onChangeCashBoxStatesCurrencyCode(currencyCode, item, index)
                                                }}/>
                                        )}
                            />
                            {fieldsCashBoxStates.length !== 1 && <IconButton
                                size="sm"
                                icon={faTrash}
                                variant="phoenix"
                                className={'btn-davr bg-danger-davr-outline d-flex gap-2 mb-2'}
                                onClick={() => onTrashCashBoxStates(index)}
                            />}
                        </Form.Group>
                    ))}
                </Col>
                <Col xs={12} lg={6}>
                    <div className="d-flex justify-content-end">
                        <IconButton
                            size={"sm"}
                            icon={faPlus}
                            onClick={addNewPaymentRowToDebtStateAmounts}
                            variant="phoenix-primary"
                            className={'btn-davr bg-primary-davr-outline text-white d-flex gap-2 mb-2'}
                        >
                            {/*{t(lang, "edi.common.add_button")}*/}
                        </IconButton>
                    </div>
                    {fieldsDebtStates.map((item, index) => (
                        <Form.Group className="text-start mb-3 d-flex" key={item.id}>
                            <Controller
                                name={`debt_states.${index}.amount`}
                                control={control}
                                rules={{
                                    required: t(lang, "edi.common.forms.validations.is_required"),
                                }}
                                render={({field}) => (
                                    <CleaveWithCommaMark
                                        floatingLabelClass={'w-75'}
                                        options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 3,
                                        }}
                                        value={field.value}
                                        placeholder={t(lang,'edi.contractor_payment_form_debt_label')}
                                        className={classNames('w-75 form-control', {
                                            'is-invalid': errors?.debt_states?.[index]?.amount.message,
                                            'is-valid': Object.keys(errors).length > 0 && !errors?.debt_states?.[index]?.amount.message
                                        })}
                                        onChange={val => {
                                            let v = val ? val?.replace(/-/g, '') : val
                                            field.onChange(v)
                                        }}
                                    />
                                )}
                            />
                            <Controller name={`debt_states.${index}.currency_id`}
                                        control={control}
                                        render={({field}) => (
                                            <SelectCurrencyCode defaultValue={field.value}
                                                                label={t(lang, 'warehouse.operation.item.common.currency')}
                                                                onChange={(currencyCode) => {
                                                                    onChangeDebtStatesCurrencyId(currencyCode, item, index)
                                                                }}/>
                                        )}
                            />
                            {fieldsDebtStates.length !== 1 && <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon={faTrash}
                                className={"ms-2"}
                                iconClassName={"text-secondary"}
                                onClick={() => {
                                    onTrashDebtStateAmounts(index)
                                }}
                            />}
                        </Form.Group>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Form.Group className="text-start mb-3">
                        <FloatingLabel label={t(lang, "edi.contractor_payment_form_note_field")}>
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            placeholder={"Информация"}
                            name="notes"
                            {...register(`notes`)}
                            onBlur={handleOnBlurNote}
                        />
                        </FloatingLabel>
                    </Form.Group>
                </Col>
                <Col xs={12} className="d-flex justify-content-end">
                    <IconButton
                        type={"submit"}
                        size={"sm"}
                        icon={''}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2 mb-2'}
                    >
                        {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        {t(lang, "edi.common.button.save")}
                    </IconButton>
                </Col>
            </Row>
        </Form>
    </>)
}
export default ContractorPaymentForm
