import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconFilter from "../../../assets/icon/IconFilter";
import IconStroke from "../../../assets/icon/IconStroke";
import IconCaretDown from "../../../assets/icon/IconCaretDown";
import IconButton from "../../../components/IconButton";
import IconPlus from "../../../assets/icon/IconPlus";
import WarehousesDataTableFilter from "./WarehousesDataTableFilter";
import WarehousesAddAndEditModal from "./WarehousesAddAndEditModal";

const WarehousesDataTableHeader = ({warehouses, setShow, filters, setFilters}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    const navigate = useNavigate()

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouses-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouses-data-table-header'))
    }, []);


    return (
        <Fragment>
            <Row id={'warehouses-data-table-header'}
                 className="flex-between-center align-items-center my-3 position-sticky z-index-1"
                 style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.operation.item.common.warehouse_list")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'items.common.warehouse')}</span>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter/>
                            <span
                                className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke/>
                            <IconCaretDown/>
                        </div>
                    </IconButton>

                    <IconButton
                        size={"sm"}
                        icon={''}
                        onClick={() => setShow(true)}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    >
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "edi.common.add_button")}
                        </span>
                    </IconButton>
                </Col>
                <Col sm={12}>
                    <WarehousesDataTableFilter isOpenFilterCollapse={isOpenFilterCollapse}
                                                filters={filters}
                                                setFilters={setFilters}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};

export default WarehousesDataTableHeader;