import React, {Fragment} from 'react';
import {Alert} from "react-bootstrap";
import {useSelector} from "react-redux";
import IconAlert from "../common/IconAlert";
import StartTrialPeriodModal from "../common/StartTrialPeriodModal";

import {selectLang} from '../../store/reducers/main/main';
import {Translate} from '../../lang/lang';
import {
    EDI_SERVICE_CODE,
    POS_SERVICE_CODE,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from '../../store/reducers/billing/billingReducer';
import Badge from "../common/Badge";
import {faWarning} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import IconAlertPrimary from "../../assets/icon/IconAlertPrimary";

const TryEdiTrialAlert = ({serviceCode, className, ...rest}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <div className={classNames(className, 'd-flex mb-2 align-items-start alert-davr-primary py-1 px-2 rounded-3')} {...rest}>
                <div className={'mt-1 me-2'}>
                    <IconAlertPrimary />
                </div>
                <div className={`me-1`}>
                    <Alert.Heading className="mb-2 mb-sm-0 fs-sm-9 fs-md-7">
                        {t(lang, 'main.demo_alert.edi.title')}
                    </Alert.Heading>
                    {WAREHOUSE_MANAGEMENT_SERVICE_CODE === serviceCode &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">{t(lang, 'main.demo_alert.warehouse.description')}</p>
                    }
                    {POS_SERVICE_CODE === serviceCode &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">{t(lang, 'main.demo_alert.pos.description')}</p>
                    }
                    {EDI_SERVICE_CODE === serviceCode &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">{t(lang, 'main.demo_alert.edi.description')}</p>
                    }
                </div>
                <StartTrialPeriodModal serviceCode={serviceCode}/>
            </div>
        </Fragment>
    );
};

export default TryEdiTrialAlert;
