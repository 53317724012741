import React, {useEffect} from 'react';
import ContractorDataTable from "./ContractorDataTable";
import {useDispatch, useSelector} from "react-redux";
import {loadContractsAsync} from "../../../store/reducers/contract/contractReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import EventBus from "../../../eventbus/EventBus";
import {
    DELETE_CONTRACT_SUCCEED,
    EDIT_CONTRACT_SUCCEED,
    REGISTER_CONTRACT_SUCCEED
} from "../../../eventbus/contractEvents";
import toast from "react-hot-toast";

const Contractor = () => {

    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    const loadContracts = () => {
        dispatch(loadContractsAsync())
    };

    useEffect(() => {
        loadContracts();

        const onContactAddedHandler = EventBus.on(REGISTER_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.register_contract_succeed'));
            loadContracts();
        });

        const onContractEditedHandler = EventBus.on(EDIT_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.edit_contract_succeed'));
            loadContracts();
        });

        const onContractDeletedHandler = EventBus.on(DELETE_CONTRACT_SUCCEED, () => {
            toast.success(t(lang, 'edi.toast.edit_contract_succeed'));
            loadContracts();
        });

        return () => {
            EventBus.remove(REGISTER_CONTRACT_SUCCEED, onContactAddedHandler);
            EventBus.remove(EDIT_CONTRACT_SUCCEED, onContractEditedHandler);
            EventBus.remove(DELETE_CONTRACT_SUCCEED, onContractDeletedHandler);
        }
    }, []);

    return (<ContractorDataTable/>);
};

export default Contractor;