import React from 'react';
import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses,
    WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../store/reducers/warehouse/warehouseReducer";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import WarehouseNo from "../../../../components/warehouse/operation/WarehouseNo";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../store/reducers/item/itemReducer";
import ItemNo from "../../../../components/warehouse/operation/ItemNo";
import AddRevisionForm from "./AddRevisionForm";

const AddRevisions = () => {

    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const warehouses = useSelector(selectWarehouses)
    const items = useSelector(selectItems)


    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent/>


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )


    return (
        <>
            <AddRevisionForm/>
        </>
    )
};

export default AddRevisions;