import React from 'react';

const IconPrinter = ({width = 24, height = 24, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 16.9999C18.9319 16.9999 19.3978 16.9999 19.7654 16.8477C20.2554 16.6447 20.6447 16.2552 20.8477 15.7652C20.9999 15.3976 21 14.9321 21 14.0002V10.1969C21 9.07899 21 8.5192 20.7822 8.0918C20.5905 7.71547 20.2837 7.40973 19.9074 7.21799C19.5058 7.01337 18.9884 7.00082 18 7.00005M18 7.00005C17.9354 7 17.8689 7 17.8002 7H6.2002C6.13138 7 6.06469 7 6 7.00005M18 7.00005L6 7.00005M18 7.00005V6.19691C18 5.07899 18 4.5192 17.7822 4.0918C17.5905 3.71547 17.2837 3.40973 16.9074 3.21799C16.4796 3 15.9203 3 14.8002 3H9.2002C8.08009 3 7.51962 3 7.0918 3.21799C6.71547 3.40973 6.40973 3.71547 6.21799 4.0918C6 4.51962 6 5.08009 6 6.2002V7.00005M6 7.00005C5.01173 7.00082 4.49334 7.01339 4.0918 7.21799C3.71547 7.40973 3.40973 7.71547 3.21799 8.0918C3 8.51962 3 9.08009 3 10.2002V14.0002C3 14.9321 3 15.3976 3.15224 15.7652C3.35523 16.2552 3.74432 16.6447 4.23438 16.8477C4.60192 16.9999 5.06786 16.9999 5.99974 16.9999M10 11H14M9 15H15C15.9319 15 16.3978 15 16.7654 15.1522C17.2554 15.3552 17.6447 15.7443 17.8477 16.2344C17.9999 16.6019 17.9999 17.0681 17.9999 18C17.9999 18.9319 17.9999 19.3978 17.8477 19.7654C17.6447 20.2554 17.2554 20.6447 16.7654 20.8477C16.3978 20.9999 15.9319 21 15 21H9C8.06812 21 7.60192 20.9999 7.23438 20.8477C6.74432 20.6447 6.35523 20.2557 6.15224 19.7656C6 19.3981 6 18.9316 6 17.9997C6 17.0679 6 16.6019 6.15224 16.2344C6.35523 15.7443 6.74432 15.3552 7.23438 15.1522C7.60192 15 8.06812 15 9 15Z"
                stroke="#58B3D3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconPrinter;
