import React from 'react';
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {InputGroup} from "react-bootstrap";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";

const SelectCurrencyCode = ({defaultValue, onChange, ...props}) => {

    const currencies = useSelector(selectCurrency);
    const activeCurrencies = currencies.filter(cur => cur.is_active)
    const lang = useSelector(selectLang)
    const t = Translate
    return (
        <div  {...props}>
            {activeCurrencies?.length <= 1 ?
                <InputGroup.Text className="cursor-pointer" id="basic-addon2">
                    {activeCurrencies[0]?.name}
                </InputGroup.Text>
                :
                <CustomFloatingLabelSelect
                    label={t(lang, "main.top.nav.profile_dropdown.dropdown_item.currency")}
                    value={activeCurrencies.find(c => c.id === defaultValue) || activeCurrencies[0]}
                    options={activeCurrencies}
                    isDisabled={activeCurrencies?.length <= 1}
                    hideSelectedOptions
                    onChange={(val) => onChange(val)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    left={true}
                />
            }
        </div>
    );
};

export default SelectCurrencyCode;
