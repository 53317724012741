import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectFilteredItems, updateFilteredItems} from "../../../store/reducers/warehouse/saleReducer";
import {Translate} from "../../../lang/lang";
import {
    CheckScanBarcodeOrMarking,
    showOperationItemSelectOptionCodeInfo,
    showOperationItemSelectOptionSkuInfo,
    statusProductShowRetailPrice,
    statusProductShowWholeSalePrice
} from "../../../enum/warehouse-operation/warehouseOperation";
import EventBus from "../../../eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../eventbus/itemEvents";
import {loadItemAsync} from "../../../store/reducers/item/itemReducer";
import {transliterate} from "transliteration";
import {debounce} from "underscore";
import Badge from "../../common/Badge";
import numeral from "numeral";
import {InputGroup, OverlayTrigger, Popover} from "react-bootstrap";
import CustomFloatingLabelSelectAsync from "../../floating-custom-select/CustomFloatingLabelSelectAsync";
import WarehouseOperationAddItem from "./WarehouseOperationAddItem";
import WarehouseOperationEditItem from "./WarehouseOperationEditItem";

const WarehouseOperationItemSelect = ({
                                          options,
                                          defaultValue,
                                          defaultsSettings,
                                          warehouseId,
                                          onChange,
                                          allowAdd,
                                          allowEdit,
                                          allowImage,
                                          placeholder,
                                          ...props
                                      }) => {

    const filterOptions = {page: 0, limit: +defaultsSettings?.showItemSearchOptions || 20}
    const currencies = useSelector(selectCurrency)
    const numberFormat = useSelector(selectNumberFormat);
    const selectFilteredItem = useSelector(selectFilteredItems)
    const dispatch = useDispatch()
    const [value, setValue] = useState(null)
    const lang = useSelector(selectLang);
    const t = Translate;
    const allowRetailPrice = statusProductShowRetailPrice(defaultsSettings?.priceTypeOfInputOption || 0)
    const allowWholeSalePrice = statusProductShowWholeSalePrice(defaultsSettings?.priceTypeOfInputOption || 0)

    const showOperationItemSkuOnSearch = showOperationItemSelectOptionSkuInfo(defaultsSettings?.operationItemExtraInfo || 0);
    const showOperationItemCodeOnSearch = showOperationItemSelectOptionCodeInfo(defaultsSettings?.operationItemExtraInfo || 0);
    const showOperationItemExtraInfoOnSearch = showOperationItemSkuOnSearch || showOperationItemCodeOnSearch;

    useEffect(() => {
        if (defaultValue !== null) {
            const val = options?.find(item => item?.item?.id === defaultValue)
            setValue(val)
        } else {
            setValue(null)
        }
    }, [options, defaultValue])

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [])

    const itemWarehousePurchasePrice = useMemo(() => {
        if (value) {
            for (let i = 0; i < value?.warehouse_states?.warehouse_items?.length; i++) {
                const itemWarehouse = value?.warehouse_states?.warehouse_items[i];
                if (itemWarehouse.id === warehouseId && itemWarehouse.purchase_price) {
                    return itemWarehouse.purchase_price.price;
                }
            }
        }

        return null;
    }, [value, warehouseId])

    const onScan = async (value) => {
        try {
            const val = options?.find(item => item?.item?.barcodes.includes(CheckScanBarcodeOrMarking(value)))
            setValue(val)
            onChange(val)
        } catch (e) {
            console.log(e);
        }
    }

    const onChangeItem = option => {
        setValue(option)
        onChange(option)
    }

    const onNewItemCreated = async createdItem => {
        try {
            const rdaItem = await loadItemAsync(createdItem.id)
            onChangeItem(rdaItem);
        } catch (error) {
            console.log(error);
            onChangeItem(null);
        }
    }

    const onItemEdited = async editedItem => {
        try {
            const rdaItem = await loadItemAsync(editedItem.id)
            onChangeItem(rdaItem);
        } catch (error) {
            console.log(error);
            onChangeItem(null);
        }
    }

    const filteredItems = [];
    const loadOptions = (query, callback) => {
        for (let i = 0; i < options?.length; i++) {
            const item = options[i];

            let found = false;
            const searchTermLower = query?.trim().toLowerCase();
            const transliteratedQuery = transliterate(searchTermLower)

            // search by name
            const filterNameParts = transliteratedQuery.replaceAll('  ', ' ').split(' ');
            const itemNameLower = item.item.name.trim().toLowerCase();
            const transliteratedItemName = transliterate(itemNameLower);
            if (filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length)
                found = true;

            const findBarcode = item?.item?.barcodes?.find(x => x === CheckScanBarcodeOrMarking(transliteratedQuery))
            if (findBarcode) {
                onChangeItem(item)
                found = true
                return
            }

            const itemSkuLower = item?.item?.sku?.trim().toLowerCase();
            const transliteratedItemSku = transliterate(itemSkuLower);
            const findSku = filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemSku.indexOf(fnp) > -1).length
            if (findSku) {
                found = true
            }

            const itemCodeLower = item?.item?.code?.trim().toLowerCase();
            const transliteratedItemCode = transliterate(itemCodeLower);
            const findCode = filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemCode.indexOf(fnp) > -1).length
            if (findCode) {
                found = true
            }

            if (!found)
                continue;

            if (!filteredItems?.find(f => f?.item?.id === item?.item?.id))
                filteredItems.push(item);
        }

        filteredItems.sort((a, b) => {
            const key = query?.toLowerCase();
            const isGoodMatchA = a.item.name.toLowerCase().startsWith(key);
            const isGoodMatchB = b.item.name.toLowerCase().startsWith(key);

            if (isGoodMatchA ^ isGoodMatchB) {
                return isGoodMatchA ? -1 : 1;
            }

            return a.item.name.localeCompare(b.item.name);
        })

        dispatch(updateFilteredItems(filteredItems?.slice(filterOptions?.page, filterOptions?.limit)))
        callback(filteredItems?.length ? filteredItems?.slice(filterOptions?.page, filterOptions?.limit) : selectFilteredItem);
    };
    const debouncedLoadOptions = debounce(loadOptions, 500);

    const getOptionValue = option => option?.item?.id
    const getOptionLabel = option => option?.item?.name

    const formatOptionLabel = option => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                {showOperationItemExtraInfoOnSearch ?
                    <div className={'lh-1'}>
                        <p className={'m-0 fs-0'}>{option?.item?.name}</p>

                        {showOperationItemCodeOnSearch &&
                            <OverlayTrigger trigger={['hover', 'focus']} placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <Badge variant={'phoenix'} bg={'warning-davr'}>{t(lang, 'Код')}</Badge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <Badge variant={'phoenix'}  bg={'warning'}
                                       className={'m-0 fs--2 px-2 py-0 me-1'}>{option?.item?.code}</Badge>
                            </OverlayTrigger>}

                        {showOperationItemSkuOnSearch &&
                            <OverlayTrigger trigger={['hover', 'focus']} placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <Badge variant={'phoenix'} className={'text-black'}
                                                               bg={'secondary-davr'}>{t(lang, 'Артикул')}</Badge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <Badge variant={'phoenix'} bg={'secondary'}
                                       className={'m-0 fs--2 px-2 py-0 me-1 text-700'}>{option?.item?.sku}</Badge>
                            </OverlayTrigger>}
                    </div> : <h6 className="fs-0 mb-0">{option?.item?.name}</h6>
                }
                <div className="d-flex flex-nowrap ms-1">
                    {(allowRetailPrice || allowWholeSalePrice) &&
                        <>
                            {allowRetailPrice &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <Badge variant={'phoenix'} bg={'primary-davr'}>{t(lang, 'Цена продажи')}</Badge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <Badge variant={'phoenix'} bg="primary-davr">
                                        {numeral.formats[numberFormat].format(option?.price.common_price?.amount)}
                                        &nbsp;
                                        <span
                                            className="fw-bold">{currencies?.find(cur => cur?.id === option?.price.common_price?.currency?.global_currency_id)?.name}</span>
                                    </Badge>
                                </OverlayTrigger>
                            }
                            {allowWholeSalePrice &&
                                <OverlayTrigger trigger={['hover', 'focus']}
                                                placement={'bottom'}
                                                overlay={
                                                    <Popover id="popover-basic" className='mt-0'>
                                                        <Popover.Header>
                                                            <Badge variant={'phoenix'}
                                                                bg={'secondary-davr'}>{t(lang, 'Оптовая цена')}</Badge>
                                                        </Popover.Header>
                                                    </Popover>
                                                }
                                >
                                    <Badge variant={'phoenix'} bg="secondary-davr" className="ms-1">
                                        {numeral.formats[numberFormat].format(option?.price.bulk_price?.amount)}
                                        &nbsp;
                                        <span
                                            className="fw-bold">{currencies?.find(cur => cur?.id === option?.price.bulk_price?.currency?.global_currency_id)?.name}</span>
                                    </Badge>
                                </OverlayTrigger>
                            }
                        </>
                    }
                    {option?.warehouse_states?.warehouse_items?.length === 1 &&
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <Badge
                                                        variant={'phoenix'}
                                                        bg={'primary-davr'}>{option?.warehouse_states?.warehouse_items[0]?.name}</Badge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <Badge
                                variant={'phoenix'}
                                bg={option?.warehouse_states?.warehouse_items[0]?.state < 0 ? 'danger-davr' : 'success-davr'}
                                className="ms-1">
                                {option?.warehouse_states?.warehouse_items[0]?.state}
                            </Badge>
                        </OverlayTrigger>
                    }
                    {option?.item?.package_measurements?.length >= 1 &&
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <Badge
                                                        variant={'phoenix'}
                                                        bg={'primary-davr'}>{option?.warehouse_states?.warehouse_items[0]?.name}</Badge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <Badge
                                bg={option?.warehouse_states?.warehouse_items[0]?.state < 0 ? 'danger-davr' : 'success-davr'}
                                className="ms-1">
                                {option?.warehouse_states?.warehouse_items[0]?.state}
                                <span className="mx-2">=></span>
                                {Math.round(option?.warehouse_states?.warehouse_items[0]?.state / option?.item?.package_measurements[0]?.quantity)}
                                <span className="ms-1">{option?.item?.package_measurements[0]?.name}</span>
                            </Badge>
                        </OverlayTrigger>
                    }
                </div>
            </div>
        )
    }

    const defaultOptions = selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)?.length ?
        selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)
        :
        options?.slice(filterOptions?.page, filterOptions?.limit)

    return (
        <InputGroup className={'select flex-nowrap'}>
            <CustomFloatingLabelSelectAsync
                loadOptions={debouncedLoadOptions}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                label={placeholder}
                value={value}
                // defaultOptions={selectFilteredItem?.slice(filterOptions?.page, filterOptions?.limit)}
                defaultOptions={defaultOptions}
                onChange={onChangeItem}
                noOptionsMessage={() => t(lang, "edi.toast.find_company_no_option")}
                hideSelectedOptions
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderTopLeftRadius: props?.left ? '0px' : '8px',
                        borderBottomLeftRadius: props?.left ? '0px' : '8px',
                        borderBottomRightRadius: props?.right ? '0px' : '8px',
                        borderTopRightRadius: props?.right ? '0px' : '8px',
                    }),
                }}
                {...props}
            />
            {allowAdd && !value &&
                <WarehouseOperationAddItem allowAdd={allowAdd} onItemCreated={onNewItemCreated}/>
            }
            {allowEdit && value &&
                <WarehouseOperationEditItem allowEdit={allowEdit} id={value?.item?.id} onItemEdited={onItemEdited}/>
            }
        </InputGroup>
    );
};

export default WarehouseOperationItemSelect;
