import {createSlice} from "@reduxjs/toolkit";
import EventBus from "../../../eventbus/EventBus";
import {PRICE_UPDATE_FAILED, PRICE_UPDATE_REQUESTED, PRICE_UPDATE_SUCCESS} from "../../../eventbus/price/priceEvents";
import priceService from "../../services/price/priceService";

export const priceSlice = createSlice({
    name: 'price',
    initialState: {},
    reducers: {
        setPrice: (state, action) => {
            state.price = action.payload;
        }
    }
})

export const loadPriceAsync = (id, params) => {
    return new Promise((resolve, reject) => {
        priceService.getPrice(id, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            })
    })
}

export const updatePriceAsync = (payload) => {
    EventBus.dispatch(PRICE_UPDATE_REQUESTED, payload.item_id);
    return new Promise((resolve, reject) => {
        priceService.updatePrice(payload)
            .then((response) => {
                EventBus.dispatch(PRICE_UPDATE_SUCCESS);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(error);
                EventBus.dispatch(PRICE_UPDATE_FAILED)
                reject(error);
            })
    })
}

export const updateItemPriceContractor = (payload) => {

    return new Promise((resolve, reject) => {
        priceService.updateItemPriceContractor(payload)
            .then((res) => {
                resolve(res.data)
            })
            .catch((e) => {
                reject(e)
            })
    })

}

export const selectPrice = state => state.price.price;

export default priceSlice.actions;
