import {useSelector} from "react-redux";
import {Spinner} from "react-bootstrap";
import {useState} from "react";
import {selectLang} from "../../../../store/reducers/main/main";
import IconButton from "../../../../components/IconButton";
import {Translate} from "../../../../lang/lang";
import {writeOffDownloadPdfAsync} from "../../../../store/reducers/warehouse/writeOffReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons";


const WriteOffDownloadPdf = ({writeOffId, number, ...props}) => {
    const [loading, setLoading] = useState(false)
    const lang = useSelector(selectLang)
    const t = Translate


    const downloadPdfWriteOff = () => {
        setLoading(true)
        writeOffDownloadPdfAsync({writeOffId})
            .then((response) => {
                const link = document.createElement("a");
                document.body.appendChild(link);

                const blobFile = new Blob([response], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blobFile);

                link.href = blobUrl;
                link.download = `${t(lang, "warehouse.item.download_excel.write_off_name", {number: number})}.pdf`;
                link.click();
                link.remove();
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    return (
        <>
            <IconButton {...props} icon={''} disabled={loading} size="sm"
                        className={'btn-davr bg-white text-dark d-flex gap-2'}
                        iconClassName={"text-primary-davr fs-8"} transform="shrink-3"
                        variant='phoenix-warning'
                        onClick={downloadPdfWriteOff}>
                {loading ? (
                        <Spinner className="align-middle ms-2" size="sm" animation="border" role="switch"/>
                    ) :
                    <FontAwesomeIcon className="me-1 fs-7 text-primary-davr" icon={faFilePdf}/>
                }
                <span
                    className="d-none d-sm-inline-block">{t(lang, "warehouse.operation.item.common.download_pdf")}</span>
            </IconButton>
        </>
    );
};

export default WriteOffDownloadPdf;