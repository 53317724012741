import {forwardRef} from "react";
import Cleave from "cleave.js/react";
import classNames from "classnames";
import {FloatingLabel} from "react-bootstrap";

const commaKeyCode = 188;

const CleaveWithCommaMark = forwardRef(({value, onChange, onContextMenu, disabled, placeholder, ...props}, ref) => {
    return <FloatingLabel className={props.floatingLabelClass || 'w-50'} label={placeholder}>
        <Cleave htmlRef={ref}
                className={classNames(`form-control border-right-0 ${props.class ? props.class : ''}`, {
                    "bg-200": disabled
                })}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (e.keyCode === commaKeyCode)
                        e.target.value += "."
                }}
                options={{
                    disabled: disabled,
                    numeral: true,
                    delimiter: ' ',
                    numeralDecimalScale: 4,
                }}
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e.target.rawValue)}
        />
    </FloatingLabel>
})

export default CleaveWithCommaMark;
