import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {
    EDI_SERVICE_CODE, getOrganizationBillingInfoAsync,
    loadBillingPackagesAsync, POS_SERVICE_CODE,
    selectBillingPackages, selectOrganizationActivePackages, WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../../store/reducers/billing/billingReducer";
import {Row, Tab, Tabs} from "react-bootstrap";
import PricingCard from "./PricingCard";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";

const Packages = () => {
    const packages = useSelector(selectBillingPackages);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const activePackages = useSelector(selectOrganizationActivePackages);

    const [key, setKey] = useState(EDI_SERVICE_CODE)

    const loadBillingInfo = () => {
        dispatch(getOrganizationBillingInfoAsync())
    }

    useEffect(() => {
        dispatch(loadBillingPackagesAsync())
        if (!activePackages) {
            loadBillingInfo();
        }
    }, [activeOrganization])

    return (
            <div className="grid-cols-3 gap-4">
                {packages.map(billingPackage => (
                    <PricingCard key={billingPackage.id} pricing={billingPackage}/>
                ))}
            </div>
    );
};

export default Packages;
