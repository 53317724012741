import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import IconVerticalDropdown from "../../assets/icon/IconVerticalDropdown";

export const RevealDropdownTrigger = ({
                                          children,
                                          className,
                                          ...rest
                                      }) => {
    return (
        <div className={classNames('btn-reveal-trigger', className)} {...rest}>
            {children}
        </div>
    );
};

const RevealDropdown = ({
                            children,
                            className,
                            btnClassName,
                            dropdownMenuClassName,
                            icon = <IconVerticalDropdown />
                        }) => {
    return (
        <Dropdown className={classNames(className)} align="up" >
            <Dropdown.Toggle
                variant=""
                size="sm"
                className={classNames(
                    btnClassName,
                    'btn-reveal dropdown-caret-none transition-none'
                )}
            >
                {icon}
            </Dropdown.Toggle>
            <Dropdown.Menu
                align="end"
                className={classNames(dropdownMenuClassName, 'py-2')}
            >
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default RevealDropdown;
