import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {
    CalculatePaymentDebtAndCashBack,
    checkTypeOfReturnNumber, dropDownDiscountOptions, findCurrencyReturnRate, withDiscount
} from "../../../enum/warehouse-operation/warehouseOperation";
import {UZS} from "../../../enum/item/itemWrapper";
import {Col, DropdownButton, FloatingLabel, Form, InputGroup, Row, Dropdown} from "react-bootstrap";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import IconButton from "../../IconButton";
import {ContractorPaymentTypes} from "../../../enum/ContractorWrapper";
import SelectCurrencyCode from "./SelectCurrencyCode";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../helpers/scrollEvent";
import IconMagnetCoin from "../../../assets/icon/IconMagnetCoin";
import SelectContractorPaymentType from "../../../pages/warehouse/contractor-payments/SelectContractorPaymentType";

const WoWithDiscountPaymentForm = ({
                                       addNewRowToExactDiscounts,
                                       controlledFieldsExactDiscounts,
                                       onTrashExactDiscounts,
                                       onChangeExactDiscountsCurrencyId,
                                       totals,

                                       controlledFieldsCashBackPay,
                                       onChangeCashBackPayPaymentType,
                                       addNewRowToCashBackPay,
                                       onTrashCashBackPay,
                                       onChangeCashBackPayCurrencyId,

                                       controlledFieldsCustomerDebtPay,
                                       addNewRowToCustomerDebtPay,
                                       onTrashCustomerDebtPay,
                                       onChangeCustomerDebtPayCurrencyId,

                                       addNewPaymentRowToCashBoxStates,
                                       onChangeCashBoxStatesPaymentType,
                                       onChangeCashBoxStatesCurrencyCode,
                                       onTrashCashBoxStates,
                                       fieldsCashBoxStates,
                                   }) => {

    const numberFormat = useSelector(selectNumberFormat)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const navigate = useNavigate()

    // state
    var groupByCurrencyExactDiscounts = []
    var groupByCurrencyCashBoxStates = []
    const [total, setTotal] = useState([])
    const percentDiscount = useWatch({name: 'percentDiscount', exact: true})
    const cashBoxStates = useWatch({name: 'cash_box_states', control: form?.control});
    const cashBackPay = useWatch({name: 'cash_back_pay', control: form?.control});
    const customerDebtPay = useWatch({name: 'customer_debt_pay', control: form?.control});
    const {errors} = form.formState
    const currencies = useSelector(selectCurrency)

    for (let i = 0; i < controlledFieldsExactDiscounts?.length; i++) {
        const exactDiscount = controlledFieldsExactDiscounts[i];
        if (exactDiscount?.currency_id) {
            let found = false;
            for (let j = 0; j < groupByCurrencyExactDiscounts.length; j++) {
                if (groupByCurrencyExactDiscounts[j].currency.id === exactDiscount?.currency_id) {
                    groupByCurrencyExactDiscounts[j].amount += checkTypeOfReturnNumber(exactDiscount?.amount) || 0
                    found = true;
                    break;
                }
            }

            if (!found) {
                groupByCurrencyExactDiscounts.push({
                    currency: currencies?.find(x => x.id === exactDiscount?.currency_id),
                    amount: checkTypeOfReturnNumber(exactDiscount?.amount) || 0
                })
            }
        }
    }

    for (let i = 0; i < cashBoxStates?.length; i++) {
        const cashBoxState = cashBoxStates[i];
        if (cashBoxState?.currency?.currency_id) {
            let found = false;
            for (let j = 0; j < groupByCurrencyCashBoxStates.length; j++) {
                if (groupByCurrencyCashBoxStates[j]?.currency?.id === cashBoxState?.currency?.currency_id) {
                    groupByCurrencyCashBoxStates[j].amount += checkTypeOfReturnNumber(cashBoxState?.currency?.amount) || 0
                    found = true;
                    break;
                }
            }

            if (!found) {
                groupByCurrencyCashBoxStates.push({
                    currency: currencies?.find(x => x.id === cashBoxState?.currency?.currency_id),
                    amount: checkTypeOfReturnNumber(cashBoxState?.currency?.amount) || 0,
                    payment_type: cashBoxState?.type
                })
            }
        }
    }

    const [discount, setDiscount] = useState(0)
    const [debts, setDebts] = useState([])
    const [cashBack, setCashBack] = useState([])
    var cashBackTypeCash = []
    var cashBackTypeDebtState = []

    useEffect(() => {
        if (totals) {
            const calcPercent = totals.map(t => {
                const cfed = groupByCurrencyExactDiscounts.find(c => c.currency?.id === t.currency.id);
                const calc = checkTypeOfReturnNumber(t.amount * percentDiscount);
                const percent = checkTypeOfReturnNumber(t.amount - calc / 100);

                if (cfed) {
                    return {
                        amount: checkTypeOfReturnNumber(percent - cfed?.amount),
                        currency: currencies?.find(x => x?.id === t?.currency?.id),
                    };
                } else {
                    return {
                        amount: checkTypeOfReturnNumber(percent),
                        currency: currencies?.find(x => x?.id === t?.currency?.id),
                    };
                }
            });
            var {cash_back, debts} = CalculatePaymentDebtAndCashBack({
                cash_box_states: groupByCurrencyCashBoxStates,
                net_price: calcPercent,
                currency: currencies
            })
            calculateDebtsAndCashBack({cash_back, debts})


            setTotal(calcPercent)
            setCashBack(cash_back)
            setDebts(debts)
        }
    }, [percentDiscount, cashBoxStates, controlledFieldsExactDiscounts])

    const calculateDebtsAndCashBack = ({cash_back, debts}) => {
        var isShownCashBackFields = false
        cash_back.forEach(cashBack => {
            cashBackTypeCash.push({
                payment_type: 1,
                currency: cashBack.currency,
                amount: cashBack.amount
            })
        })

        cashBackTypeDebtState.push({
            payment_type: 1,
            currency: currencies?.find(currency => currency.is_national),
            amount: 0
        })
    }

    useEffect(() => {
        if (total) {
            const {debt_states} = calculateCashBackAndDebtState()
            form.setValue(`debt_states`, debt_states)
        }
    }, [groupByCurrencyCashBoxStates, total, cashBack])

    const calculateCashBackAndDebtState = () => {
        //sotuvni obshiy summasidan qarzlarni valyuta bo'yicha ayirib chiqamiz
        let resCashBoxStates = []
        let resDebtStates = []
        //kassaga tushadigan pullarni yig'ib chiqamiz
        groupByCurrencyCashBoxStates.forEach(cashBoxState => {
            resCashBoxStates.push({
                payment_type: cashBoxState.payment_type,
                amount: +cashBoxState.amount,
                currency: cashBoxState.currency,
            })
        })

        //qarzdorlikka ketadigan tolovlarni qoshib chiqamiz
        total?.forEach(netPrice => {
            let foundDebt = debts?.find(debt => debt.currency?.id === netPrice.currency?.id)
            if (!foundDebt || (foundDebt.amount === 0 || foundDebt.amount === '0')) {
                resDebtStates.push({
                    currency: netPrice.currency,
                    amount: +netPrice.amount
                });
            } else {
                resDebtStates.push({
                    currency: netPrice.currency,
                    amount: +netPrice.amount - +foundDebt.amount
                });
            }
        });

        //agar qaytim mavjud bolsa kassaga tushayotgan puldan qaytimlarni ayiramiz yoki mijoz hisobiga o'tkazamiz
        let isExistCashBack = cashBack.length > 0 && cashBack.every(cashBackk => cashBackk.amount > 0)
        if (isExistCashBack) {
            // kassadan berilayotgan qaytim mavjud bo'lsa
            let isExistCashBackTypeCash = cashBackTypeCash.length > 0 && cashBackTypeCash.every(c => c.amount > 0)

            if (isExistCashBackTypeCash) {
                cashBackTypeCash.forEach(cashBackTypeCashh => {
                    // agar kassaga tushayotgan pul va kassadan berilayotgan qaytim valyutasi va tolov turi birxillari bo'lsa
                    let foundInCashBox = resCashBoxStates.find(cashBox => cashBox.currency?.id === cashBackTypeCashh.currency?.id && cashBox.payment_type === cashBackTypeCashh.payment_type)
                    if (foundInCashBox) {
                        foundInCashBox.amount = +foundInCashBox.amount - +cashBackTypeCashh.amount;
                    } else {
                        resCashBoxStates.push({
                            payment_type: cashBackTypeCashh.payment_type,
                            amount: 0 - +cashBackTypeCashh.amount,
                            currency: cashBackTypeCashh.currency
                        })
                    }
                })
            }

            //agar qaytimlar orasida mijozni hisobiga o'tkazish bo'lsa
            let isExistCashBackTypeDebtState = cashBackTypeDebtState.length > 0 && cashBackTypeDebtState.every(c => c.amount > 0);

            if (isExistCashBackTypeDebtState) {
                cashBackTypeDebtState.forEach(cashBackTypeDebtStatee => {
                    //agar qarz ichidan bunday valyutalik to'lov bolsa
                    let foundDebt = resDebtStates.find(debt => debt.currency?.id === cashBackTypeDebtStatee.currency?.id)
                    if (foundDebt) {
                        foundDebt.amount = +foundDebt.amount + +cashBackTypeDebtStatee.amount
                    } else {
                        resDebtStates.push({
                            amount: +cashBackTypeDebtStatee.amount,
                            currency: cashBackTypeDebtStatee.currency
                        })
                    }
                })
            }
        }

        let notNolResCashBoxStates = resCashBoxStates.filter(cashBox => cashBox.amount !== 0)
        let notNolResDebtStates = resDebtStates.filter(d => d.amount !== 0)
        return {
            cash_box_states: notNolResCashBoxStates.map(res => {
                return {
                    type: res.payment_type,
                    amount: res.amount,
                    currency_id: res.currency?.id,
                    currency: res.currency
                }
            }), debt_states: notNolResDebtStates.map(res => {
                return {
                    amount: res.amount,
                    currency_id: res.currency?.id,
                    currency: res.currency
                }
            })
        }
    }

    const onDiscountHandler = (option) => {
        if (option.value !== discount) {
            setDiscount(option.value)
            form.setValue(`percentDiscount`, 0)
            form.setValue(`exactDiscounts`, [{
                amount: '0',
                currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id
            }])
        }
    }

    const [toPayShow, setToPayShow] = useState(false)

    const toPayHandlerShow = () => {
        setToPayShow(true)
        form.setValue(`cash_back_pay.${0}.currency.amount`, cashBack[0].amount.toFixed(2))
        form.setValue(`cash_back_pay.${0}.currency.currency_id`, cashBack[0]?.currency?.id)
    }

    useEffect(() => {
        if (percentDiscount) {
            setDiscount(withDiscount)
        }
    }, [])

    useEffect(() => {
        if (cashBack.length > 0) {
            toPayHandlerShow()
        }
        if (cashBack?.length === 0) {
            if (cashBackPay) {
                if (cashBackPay[0].currency?.amount > 0 || customerDebtPay[0].amount > 0) {
                    form.setValue(`cash_back_pay`, [{
                        type: 1,
                        currency: {
                            amount: '0',
                            currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id,
                        }
                    }])
                    form.setValue(`customer_debt_pay`, [{
                        amount: '0',
                        currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id
                    }])
                }
                setToPayShow(false)
            }

        }
    }, [cashBack])

    const setCashTotalSum = (index) => {
        const indexAmount = checkTypeOfReturnNumber(form.watch(`cash_box_states.${index}.currency.amount`))
        const indexCurrency = +form.watch(`cash_box_states.${index}.currency.currency_id`)
        const cashBoxTotalSum = groupByCurrencyCashBoxStates.reduce((acc, curr) => {
            const currencyAmount = checkTypeOfReturnNumber(curr?.amount) * curr?.currency?.rate;
            return +acc + currencyAmount;
        }, 0)
        const totalSum = total?.reduce((acc, cur) => acc + (cur?.amount * findCurrencyReturnRate(currencies, cur?.currency?.id)), 0)

        form.setValue(`cash_box_states.${index}.currency.amount`, (indexAmount + ((totalSum - cashBoxTotalSum) / findCurrencyReturnRate(currencies, indexCurrency))).toFixed(2))
    }


    return (
        <Fragment>
            <Row>
                {controlledFieldsExactDiscounts.map((item, index) => (
                    <Form.Group as={Col} xs={12} lg={3} key={item.id}>
                        <Controller
                            name={`exactDiscounts.${index}.amount`}
                            render={({field}) => (
                                <InputGroup>
                                    <FloatingLabel className={'w-75'} label={t(lang, "Скидка")}>
                                        <Cleave
                                            options={{
                                                numeral: true,
                                                delimiter: ' ',
                                                numeralDecimalScale: 3,
                                            }}
                                            value={field.value}
                                            placeholder={"Сумма"}
                                            className={classNames('form-control border-right-0', {
                                                'is-invalid': form?.formState?.errors?.exactDiscounts?.[index]?.amount.message,
                                                'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.exactDiscounts?.[index]?.amount.message
                                            })}
                                            onChange={val => {
                                                field.onChange(val?.target?.value)
                                            }}
                                        />
                                    </FloatingLabel>
                                    <Controller name={`exactDiscounts.${index}.currency_id`}
                                                control={form.control}
                                                render={({field}) => (
                                                    <SelectCurrencyCode
                                                        className={'w-25'}
                                                        defaultValue={field.value}
                                                        onChange={(currencyCode) => {
                                                            onChangeExactDiscountsCurrencyId(currencyCode, item, index)
                                                        }}/>
                                                )}
                                    />
                                </InputGroup>
                            )}
                        />
                    </Form.Group>
                ))}
                {fieldsCashBoxStates.map((item, index) => (
                    <Form.Group as={Col} xs={12} lg={3} className="text-start d-flex mb-1" key={item?.id}>
                        <Controller
                            name={`cash_box_states.${index}.currency.amount`}
                            control={form.control}
                            rules={{
                                required: t(lang, "edi.common.forms.validations.is_required"),
                            }}
                            render={({field}) => (
                                <Fragment>
                                    <FloatingLabel className={'w-75 position-relative'}
                                                   label={t(lang, ContractorPaymentTypes.find(b => b.value === form.watch(`cash_box_states.${index}.type`))?.label)}>
                                        <Cleave options={{
                                            numeral: true,
                                            delimiter: ' ',
                                            numeralDecimalScale: 3,
                                        }}
                                                value={field.value}
                                                placeholder={t(lang, ContractorPaymentTypes.find(b => b.value === form.watch(`cash_box_states.${index}.type`))?.label)}
                                                className={classNames('form-control border-right-0', {
                                                    'is-invalid': form?.formState?.errors?.cash_box_states?.[index]?.currency.amount.message,
                                                    'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.cash_box_states?.[index]?.currency.amount.message
                                                })}
                                                onChange={val => {
                                                    field.onChange(val?.target?.value)
                                                }}
                                        />
                                        <InputGroup.Text style={{top: 0, right: 0}}
                                                         className="bg-transparent border-0 cursor-pointer position-absolute"
                                                         id="basic-addon2" onClick={() => setCashTotalSum(index)}>
                                            <IconMagnetCoin/>
                                        </InputGroup.Text>
                                    </FloatingLabel>
                                </Fragment>
                            )}
                        />
                        <Controller name={`cash_box_states.${index}.currency.currency_id`}
                                    control={form.control}
                                    render={({field}) => (
                                        <SelectCurrencyCode
                                            className={'w-25'}
                                            defaultValue={field.value}
                                            onChange={(currencyCode) => {
                                                onChangeCashBoxStatesCurrencyCode(currencyCode, item, index)
                                            }}
                                        />
                                    )}
                        />
                    </Form.Group>
                ))}
                {toPayShow &&
                    <Fragment>
                        {controlledFieldsCashBackPay.map((item, index) => (
                            <Form.Group as={Col} xs={12} lg={6} className="text-start d-flex mb-1" key={item?.id}>
                                <Controller name={`cash_back_pay.${index}.type`}
                                            control={form.control}
                                            render={({field}) => (
                                                <SelectContractorPaymentType
                                                    label={t(lang, "crm.payment_type")}
                                                    className={'w-25'}
                                                    defaultValue={field.value}
                                                    onChange={(paymentType) => onChangeCashBackPayPaymentType(paymentType, item, index)}
                                                />
                                            )}
                                />
                                <Controller
                                    name={`cash_back_pay.${index}.currency.amount`}
                                    control={form.control}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    }}
                                    render={({field}) => (
                                        <FloatingLabel className={'w-50'} label={t(lang, "Дайте наличные")}>
                                            <Cleave
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 3,
                                                }}
                                                value={field.value}
                                                placeholder={"Сумма"}
                                                className={classNames('form-control border-right-0 border-left-0', {
                                                    'is-invalid': form?.formState?.errors?.cash_back_pay?.[index]?.currency?.amount.message,
                                                    'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.cash_back_pay?.currency?.[index]?.amount.message
                                                })}
                                                onChange={val => {
                                                    field.onChange(val?.target?.value)
                                                }}
                                            />
                                        </FloatingLabel>
                                    )}
                                />
                                <Controller name={`cash_back_pay.${index}.currency.currency_id`}
                                            control={form.control}
                                            render={({field}) => (
                                                <SelectCurrencyCode
                                                    className={'w-25'}
                                                    defaultValue={field.value}
                                                    onChange={(currencyCode) => {
                                                        console.log(item, 'item')
                                                        onChangeCashBackPayCurrencyId(currencyCode, item, index)
                                                    }}/>
                                            )}
                                />
                            </Form.Group>
                        ))}

                        {controlledFieldsCustomerDebtPay.map((item, index) => (
                            <Form.Group as={Col} xs={12} lg={6} className="text-start d-flex mb-1" key={item?.id}>
                                <Controller
                                    name={`customer_debt_pay.${index}.amount`}
                                    control={form.control}
                                    rules={{
                                        required: t(lang, "edi.common.forms.validations.is_required"),
                                    }}
                                    render={({field}) => (
                                        <FloatingLabel className={'w-75'}
                                                       label={t(lang, "Списание задолженности перед клиентом.")}>
                                            <Cleave
                                                options={{
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 3,
                                                }}
                                                value={field.value}
                                                placeholder={"Сумма"}
                                                className={classNames('form-control border-right-0', {
                                                    'is-invalid': form?.formState?.errors?.customer_debt_pay?.[index]?.amount.message,
                                                    'is-valid': Object.keys(form?.formState?.errors).length > 0 && !form?.formState?.errors?.customer_debt_pay?.[index]?.amount.message
                                                })}
                                                onChange={val => {
                                                    field.onChange(val?.target?.value)
                                                }}
                                            />
                                        </FloatingLabel>
                                    )}
                                />
                                <Controller name={`customer_debt_pay.${index}.currency_id`}
                                            control={form.control}
                                            render={({field}) => (
                                                <SelectCurrencyCode
                                                    className={'w-25'}
                                                    defaultValue={field.value}
                                                    onChange={(currencyCode) => {
                                                        onChangeCustomerDebtPayCurrencyId(currencyCode, item, index)
                                                    }}/>
                                            )}
                                />
                            </Form.Group>
                        ))}
                    </Fragment>
                }
            </Row>
        </Fragment>
    );
};

export default WoWithDiscountPaymentForm;
