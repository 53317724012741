import axios from "axios";


class CurrencyService {
    currencyGet(params) {
        return axios.get('/currency/currency/get', {
            params: params
        })
    }

    currencyUpdateRate({id, rate}) {
        return axios.post(`/currency/currency/update/${id}`, {
            rate
        })
    }

    currencyActivate({id}) {
        return axios.post(`/currency/currency/activate/${id}`)
    }

    currencyDeactivate({id}) {
        return axios.post(`/currency/currency/deactivate/${id}`)
    }

    currencyConverter(payload) {
        return axios.post(`/currency/converter/convert`, payload)
    }
}

const currencyService = new CurrencyService()

export default currencyService;
