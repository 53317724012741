import React from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {selectNationalCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {Card, Col, FloatingLabel, Form, Row, Spinner} from "react-bootstrap";
import FindCompanySearchable from "../../../components/organization/FindCompanySearchable";
import classNames from "classnames";
import IconButton from "../../../components/IconButton";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Cleave from "cleave.js/react";


const ContractorForm = ({onClose, loading, onSubmit, contractor, isEditing, contact = false}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const nationalCurrency = useSelector(selectNationalCurrency);

    const form = useForm({
        defaultValues: {
            name: contractor?.name,
            inn: contractor?.inn,
            type: contractor?.type?.length ? contractor?.type.map(t => String(t)) : [],
            organization_code: contractor?.organization_code,
            phones: (contractor?.contacts?.length && contractor.contacts.filter(contact => contact.type === 1).length) ? contractor.contacts.filter(contact => contact.type === 1).map(contact => ({value: contact.value.length > 9 ? contact.value.slice(3, 12) : contact.value})) : [{value: ""}],
            emails: (contractor?.contacts?.length && contractor.contacts.filter(contact => contact.type === 3).length) ? contractor.contacts.filter(contact => contact.type === 3).map(contact => ({value: contact.value})) : [{value: ""}],
            is_default: contractor?.is_default || false,
            starting_debt_states: [
                {
                    amount: '',
                    currency_id: nationalCurrency?.id,
                }
            ],
        },
    });
    const {fields: fieldsPhone, append: appendPhone, remove: removePhone} = useFieldArray({
        control: form.control,
        name: "phones"
    });
    const {fields: fieldsEmails, append: appendEmail, remove: removeEmail} = useFieldArray({
        control: form.control,
        name: "emails"
    });
    const {fields: fieldStartingDebts, append: appendStartingDebt, remove: removeStartingDebt} = useFieldArray({
        control: form.control,
        name: "starting_debt_states"
    });

    const onChangeCompany = company => {
        if (company) {
            form.setValue('inn', company.inn)
            form.setValue('name', company.name)
        } else {
            form.setValue('inn', '')
            form.setValue('name', '')
        }
    };


    const allowDeleteEmail = fieldsEmails.length > 1;
    const addNewEmail = () => {
        appendEmail({
            value: '',
            description: ''
        })
    };
    const deleteEmail = (index) => {
        removeEmail(index);
    };


    const allowDeletePhoneNumber = fieldsPhone.length > 1;
    const addNewPhoneNumber = () => {
        appendPhone({
            value: '',
            description: ''
        });
    };
    const deletePhoneNumber = (index) => {
        removePhone(index);
    };

    const allowDeleteStartingDebtState = fieldStartingDebts.length > 1;
    const addNewStartingDebt = () => {
        appendStartingDebt({
            amount: '',
            currency_id: nationalCurrency?.id,
        });
    };
    const deleteStartingDebtState = index => {
        removeStartingDebt(index)
    };

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Row className="g-3">
                    {!contact && <Col xs={12}>
                        <Card className="h-100">
                            <Card.Body>
                                <div className={"d-flex justify-content-between"}>
                                    <div className={'d-flex'}>
                                        <div className={"me-3"}>
                                            <Form.Check type="checkbox" id="typeBuyer">
                                                <Form.Check.Input type="checkbox"
                                                                  value='1'
                                                                  {...form.register('type')}
                                                />
                                                <Form.Check.Label className="fs-8 fw-bold text-dark cursor-pointer">
                                                    {t(lang, "edi.common.buyer")}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                        <div>
                                            <Form.Check type="checkbox" id="typeSeller">
                                                <Form.Check.Input type="checkbox"
                                                                  value='2'
                                                                  {...form.register('type')}
                                                />
                                                <Form.Check.Label className="fs-8 fw-bold text-dark cursor-pointer">
                                                    {t(lang, "edi.common.executor")}
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>}
                    {!contact &&
                        <Col xs={12} lg={6}>
                            <Card className="h-100">
                                <Card.Body>
                                    <Row className="g-2">
                                        <Col xs={12}>
                                            <Form.Group className="text-start">
                                                <Controller name="inn"
                                                            render={({field}) => (
                                                                <FindCompanySearchable defaultInn={field.value}
                                                                                       onChange={(company) => {
                                                                                           onChangeCompany(company);
                                                                                           field.onChange(company?.inn);
                                                                                       }}
                                                                                       inputProps={{
                                                                                           placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                                                       }}
                                                                                       ref={field.ref}
                                                                />
                                                            )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="text-start">
                                                <FloatingLabel label={t(lang, 'edi.common.name')}>
                                                    <Form.Control
                                                        placeholder={t(lang, "edi.common.name")}
                                                        name="name"
                                                        type="text"
                                                        className={'text-truncate'}
                                                        isInvalid={form.formState.errors?.name?.message}
                                                        isValid={Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.name?.message}
                                                        {...form.register("name", {
                                                            required: {
                                                                value: true,
                                                                message: t(lang, "edi.common.forms.validations.is_required")
                                                            }
                                                        })}
                                                    />
                                                </FloatingLabel>
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.name?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Group className="text-start mt-4">
                                                <FloatingLabel
                                                    label={t(lang, 'partners.common.organization_code.placeholder')}>
                                                    <Form.Control
                                                        placeholder={t(lang, "partners.common.organization_code.placeholder")}
                                                        name="organization_code"
                                                        className={'text-truncate'}
                                                        type="text"
                                                        {...form.register("organization_code")}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    <Col xs={12} lg={!contact ? 6 : 12}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="g-2">
                                    {contact && <Col xs={12}>
                                        <Form.Group className="text-start">
                                            <Controller name="inn"
                                                        render={({field}) => (
                                                            <FindCompanySearchable defaultInn={field.value}
                                                                                   onChange={(company) => {
                                                                                       onChangeCompany(company);
                                                                                       field.onChange(company?.inn);
                                                                                   }}
                                                                                   inputProps={{
                                                                                       placeholder: t(lang, "edi.toast.find_company_searchable:placeholder")
                                                                                   }}
                                                                                   ref={field.ref}
                                                            />
                                                        )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    }
                                    <Col xs={12}>
                                        {fieldsEmails.map((item, index) => (
                                            <Form.Group key={item.id}
                                                        className={classNames({'mb-2': index !== fieldsEmails.length - 1})}
                                            >
                                                {index === 0 &&
                                                    <IconButton
                                                        size={"sm"}
                                                        icon={faPlus}
                                                        onClick={addNewEmail}
                                                        variant="phoenix-primary"
                                                        className={'btn-davr bg-primary-davr-outline text-white d-flex gap-2 mb-2'}
                                                    >
                                                        {t(lang, "edi.common.add_button")}
                                                    </IconButton>
                                                }
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <FloatingLabel label={t(lang, 'edi.contract_formation.info.email')}
                                                                   className="flex-grow-1">
                                                        <Form.Control
                                                            placeholder={t(lang, "edi.contract_formation.info.email")}
                                                            type="email"
                                                            {...form.register(`emails.${index}.value`, {
                                                                pattern: {
                                                                    value: /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,3}$/,
                                                                    message: t(lang, "edi.common.forms.validations.is_required")
                                                                }
                                                            })}
                                                            className={classNames(' border-right-0 form-control', {
                                                                'is-invalid': form.formState.errors?.emails?.[index]?.value.message,
                                                                'is-valid': Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.emails?.[index]?.value.message
                                                            })}
                                                        />
                                                    </FloatingLabel>
                                                    <IconButton
                                                        style={{height: 40}}
                                                        size="sm"
                                                        disabled={!allowDeleteEmail}
                                                        variant="falcon-default"
                                                        icon={faTrash}
                                                        iconClassName={"text-danger"}
                                                        className={'border-left-0'}
                                                        onClick={() => deleteEmail(index)}
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.emails?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}
                                    </Col>
                                    <Col xs={12}>
                                        {fieldsPhone.map((item, index) => (
                                            <Form.Group key={item.id}
                                                        className={classNames({'mb-2': index !== fieldsPhone.length - 1})}
                                            >
                                                {index === 0 &&
                                                    <IconButton
                                                        size={"sm"}
                                                        icon={faPlus}
                                                        onClick={addNewPhoneNumber}
                                                        variant="phoenix-primary"
                                                        className={'btn-davr bg-primary-davr-outline text-white d-flex gap-2 mb-2'}
                                                    >
                                                        {t(lang, "edi.common.add_button")}
                                                    </IconButton>
                                                }
                                                <div className={'d-flex align-items-center'}>
                                                    <FloatingLabel
                                                        label={<>КОД <sup className={'text-primary-davr'}>*</sup></>}>
                                                        <Form.Control
                                                            name="organization_code"
                                                            className={'text-truncate'}
                                                            type="text"
                                                            value={"+998"}
                                                            disabled
                                                        />
                                                    </FloatingLabel>
                                                    <FloatingLabel
                                                        label={<>{t(lang, 'crm.common.datatable.phone.number')} {"\u00A0"}
                                                            <sup className={'text-primary-davr'}>*</sup></>}>
                                                        <Controller name={`phones.${index}.value`}
                                                                    rules={{
                                                                        validate: {
                                                                            format: value => !value || /^(\d{9})$/.test(value.replace(/ /g, '')) || t(lang, 'authentication.forgot-password.phoneNumber.validations.format'),
                                                                        }
                                                                    }}
                                                                    render={({field}) => (
                                                                        <Cleave
                                                                            style={{width: !contact ? 330 : 550}}
                                                                            placeholder={"-- --- -- --"}
                                                                            options={{
                                                                                delimiters: [' ', ' ', ' ', ' '],
                                                                                blocks: [2, 3, 2, 2],
                                                                                numericOnly: true
                                                                            }}
                                                                            value={field?.value}
                                                                            onChange={(username) => field.onChange(username)}
                                                                            className={classNames('ms-2 border-right-0 form-control', {
                                                                                'is-invalid': form.formState.errors?.phones?.[index]?.value.message,
                                                                                'is-valid': Object.keys(form.formState.errors).length > 0 && !form.formState.errors?.phones?.[index]?.value.message
                                                                            })}
                                                                        />
                                                                    )}
                                                        />
                                                    </FloatingLabel>
                                                    <IconButton size="sm"
                                                                style={{height: 40}}
                                                                disabled={!allowDeletePhoneNumber}
                                                                variant="falcon-default"
                                                                icon={faTrash}
                                                                iconClassName={"text-danger"}
                                                                className={'border-left-0'}
                                                                onClick={() => deletePhoneNumber(index)}
                                                    />
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    {form.formState.errors?.phones?.[index]?.value.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ))}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-end">
                        <IconButton
                            size={"sm"}
                            icon={''}
                            type="submit"
                            variant="phoenix-primary"
                            className={'btn-davr bg-primary-davr text-white d-flex gap-2 mb-2'}
                        >
                            {loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="btn-primary-davr"
                            />}
                            {t(lang, "edi.common.button.save")}
                        </IconButton>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    )
}

export default ContractorForm