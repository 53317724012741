import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {useParams} from "react-router-dom";
import {loadContractorAsync, updateCardContractor} from "../../../../store/reducers/contractor/contractorReducer";
import {getRefundItemAsync} from "../../../../store/reducers/warehouse/refundReducer";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS,
    WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS
} from "../../../../eventbus/warehouse/refundEvent";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import Refund from "../../../../components/warehouse/operation/refund/Refund";

const ViewRefund = () => {

    const activeOrganization = useSelector(selectActiveOrganization)
    const [refund, setRefund] = useState(null)
    const dispatch = useDispatch()
    const {id} = useParams()

    const loadRefund = () => {
        getRefundItemAsync({refundId: id})
            .then(response => {
                setRefund(response.data, response?.data?.items?.reverse())
                loadContractorAsync(response?.data?.contractor?.id)
                    .then(cr => {
                        dispatch(updateCardContractor(cr))
                    }).catch(err => console.log(err));
            })
            .catch(error => setRefund(null))
    }

    useEffect(() => {
        loadRefund()
    },[activeOrganization, id])

    useEffect(() => {
        const onApprovedItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, () => {
            loadRefund()
        })

        const onDeletedItemHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, () => {
            loadRefund()
        })

        const onRegisterPayoutHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, () => {
            loadRefund()
        })

        const onUpdatePayoutHandler = EventBus.on(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, () => {
            loadRefund()
        })


        return() => {
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_APPROVED_ITEM_SUCCESS, onApprovedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_DELETE_ITEM_SUCCESS, onDeletedItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_REGISTER_PAYOUT_SUCCESS, onRegisterPayoutHandler)
            EventBus.remove(WAREHOUSE_OPERATION_REFUND_UPDATE_PAYOUT_SUCCESS, onUpdatePayoutHandler)
        }
    },[])


    if (!refund) return <SpinnerComponent />

    return (
        <Refund refund={refund}/>
    );
};

export default ViewRefund;
