import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {
    revisionApproveAsync,
    revisionDeleteItemAsync,
    revisionUpdateDateAsync,
    revisionUpdateItemAsync,
    revisionUpdateNumberAsync,
    selectRevisionDefaultsSettings
} from "../../../../store/reducers/warehouse/revisionReducer";
import {selectDateFormat, selectLang} from "../../../../store/reducers/main/main";
import {selectWarehouses} from "../../../../store/reducers/warehouse/warehouseReducer";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {Translate} from "../../../../lang/lang";
import EventBus from "../../../../eventbus/EventBus";
import {ADD_EMPLOYEE_SUCCEED} from "../../../../eventbus/employee/employeeEvent";
import {SCAN_SUCCESS} from "../../../../eventbus/itemEvents";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {Card, Col, FloatingLabel, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import IconButton from "../../../../components/IconButton";
import DatePickerComponent from "../../../../components/common/DatePickerComponent";
import classNames from "classnames";
import Flex from "../../../../components/common/Flex";
import {faCancel, faCheck} from "@fortawesome/free-solid-svg-icons";
import EmployeeSelect from "../../../../components/employee/EmployeeSelect";
import WarehouseOperationWarehouseSelect
    from "../../../../components/warehouse/operation/WarehouseOperationWarehouseSelect";
import RevisionModal from "./RevisionModal"
import WarehouseOperationItemSelect from "../../../../components/warehouse/operation/WarehouseOperationItemSelect";
import WarehouseOperationDeleteItem from "../../../../components/warehouse/operation/WarehouseOperationDeleteItem";
import {useMainLayoutContext} from "../../../../pheonix/providers/MainLayoutProvider";


const colIndexItem = 0
const colIndexQuantity = 2
const colIndexWarehouse = 6


const colCoordinateZero = 0
const rowCoordinateOne = 1
const subRowoordinateTwo = 2

const RevisionForm = ({addItem, revision, loading, show, onShow, onHide}) => {

    const revisionDefaultsSettings = useSelector(selectRevisionDefaultsSettings)
    const dateFormat = useSelector(selectDateFormat)
    const warehouses = useSelector(selectWarehouses)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate
    const {id} = useParams()


    // state
    const [categoryItems, setCategoryItems] = useState(null)
    const [isHover, setIsHover] = useState([])
    const [filter, setFilter] = useState('')
    const {errors} = form?.formState


    // watch
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const numberWatch = useWatch({name: 'number', exact: true})
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})

    const {loading: contextLoading, setLoading: setContextLoading} = useMainLayoutContext();


    useEffect(() => {
        if (!warehouseId) {
            form?.setValue("warehouse_id", revisionDefaultsSettings?.defaultWarehouseId)
        }
        const onEmployeeAddedHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, (res) => {
            form?.setValue("employee_id", res?.data?.id)
        })
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeAddedHandler)
        }
    }, [revisionDefaultsSettings])

    useEffect(() => {
        if (warehouses?.length === 1 && warehouse) {
            form?.setValue("warehouse_id", warehouse[0]?.id)
        }
    }, [warehouse])

    useEffect(() => {
        form?.setValue("employee_id", revisionDefaultsSettings?.defaultEmployeeId)
    }, [revisionDefaultsSettings?.defaultEmployeeId]);


    const onScan = (value) => {
        if (value.length > 14) {
            const quantityGet = form?.getValues('quantity')

            form?.setValue('marks', [value])
            form?.setValue('quantity', +quantityGet + 1)

            addItem("addOrEditRevision")
        }
    }


    const onChangeCategory = (option) => {
        setCategoryItems(items.filter(i => i?.item?.category?.id === option?.id))
    }

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
    }


    // focus start

    const onFocusItem = () => {
        if (!itemId) {
            return true
        }
    }

    const onFocusWarehouse = () => {
        if (itemId && !warehouseId) {
            return true
        }
    }

    const onFocusQuantity = () => {
        if (warehouseId && itemId && !quantity) {
            document.getElementById('quantity')?.focus()
            return true
        }
    }

    useEffect(() => {
        onFocusQuantity()
    }, [warehouseId, itemId])
    // focus end


    const data = useMemo(() => {
        return revision?.items?.filter(i => {
            const searchLower = filter.trim().toLowerCase();

            // search by barcode
            {
                if (searchLower.length >= 8 && searchLower.length <= 13 && !isNaN(searchLower)) {
                    const searchBarcode = items.filter(it => it.item?.barcodes?.some((b) => b?.includes(searchLower)))

                    if (searchBarcode.some(bar => bar?.item?.name.trim().toLowerCase() === i?.warehouse_item?.name.trim().toLowerCase()))
                        return true;
                }
            }

            // search by name
            {
                const filterNameParts = searchLower.replaceAll('  ', ' ').split(' ');
                const itemLowerName = i.warehouse_item?.name.toLowerCase();
                if (filterNameParts?.length === filterNameParts?.filter(fnp => itemLowerName?.indexOf(fnp) > -1)?.length)
                    return true;
            }
        })
    }, [revision, filter])

    const groupByItemIdAndPrice = (filterItems) => {
        let groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;


            if (itemID in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[itemID].sub.push(item)
            } else {
                if (itemID) {
                    groupByItemIdPriceIdCurrencyId[itemID] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(data?.filter(f => !f?.is_deleted))


    // hover mouser over
    const handleMouseOver = ({col, row, subRow}) => {
        setIsHover([col, row, subRow])
    }


    const updateItem = async ({itemOne, quantity, warehouseId, item}) => {
        const operationItem = {
            operation_item_id: item?.id,
            item_id: item?.warehouse_item?.id,
            warehouse_id: item?.warehouse_item?.warehouse?.id,
            quantity: item?.quantity,
        }


        if (itemOne) {
            operationItem.item_id = itemOne?.item?.id
        }
        if (quantity) {
            operationItem.quantity = +quantity
        }
        if (warehouseId) {
            operationItem.warehouse_id = warehouseId
        }

        await revisionUpdateItemAsync({
            revisionId: revision?.id,
            operation: operationItem
        })
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setIsHover([])

            })
    }

    // delete item
    const onDeleteItem = async ({id, itemId}) => {
        await revisionDeleteItemAsync({revisionId: id, operationId: itemId})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }

    // change date
    const onDateChanged = async (e, date) => {
        if (e === "Save") {
            await revisionUpdateDateAsync({
                revisionId: revision?.id,
                date: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : dayjs(revision?.date).format("YYYY-MM-DD HH:mm:ss")
            })
                .then(() => {
                    form.setValue('date', date ? dayjs(date, "YYYY-MM-DD HH:mm").toDate() : dayjs(revision?.date, "YYYY-MM-DD HH:mm").toDate())
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
        if (e === "Cancel") {
            form.setValue('date', dayjs(revision?.date, "YYYY-MM-DD HH:mm").toDate())
        }
    }


    // approve
    const onApprovedHandler = async () => {
        setContextLoading(true)
        await revisionApproveAsync({revisionId: revision?.id})
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            }).finally(() => {
                setContextLoading(false)
            })
    }

    const onNumberChanged = (e, number) => {
        if (e === "Save") {
            if (number?.trim() && number !== revision?.number) {
                revisionUpdateNumberAsync({revisionId: revision?.id, number: number})
                    .then(() => {
                        form.setValue('number', number ? number : revision?.number)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch(() => {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    })
            }
        }
        if (e === "Cancel") {
            form.setValue('number', revision?.number)
        }
    }

    return (
        <Fragment>
            <Row id={'warehouse-revisions-register'}
                 className="flex-between-center align-items-center my-3 position-sticky" style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, !id ? "warehouse.operation.item.common.new_revision" : "warehouse.operation.item.common.edit_revision")}</h5>
                        {/*<span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>*/}
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <IconButton
                        size="sm"
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                        onClick={() => onApprovedHandler()}
                        disabled={!(revision?.items?.length > 0 && !revision?.is_approved)}
                    >
                        {/*<IconPlus/>*/}
                        {contextLoading &&
                            <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        <span className="d-none d-sm-inline-block ms-1 fs-8 text-white text-nowrap fs-md-9">
                            {t(lang, !revision?.is_approved ? "items.common.save" : "warehouse.dashboard.items.transfer.approved")}
                        </span>
                    </IconButton>
                </Col>
            </Row>
            <Card className={'mt-3 p-2'}>
                <Row>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name={'date'}
                            rules={{
                                required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                            }}
                            render={({field}) => (
                                <InputGroup
                                    className={!(dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== revision?.date && revision) ? 'select' : 'border-right-0'}>
                                    <DatePickerComponent
                                        containerClass={
                                            !(dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== revision?.date && revision) ? '' : 'w-75'
                                        }
                                        field={field}
                                        placeholderText={<>
                                            {t(lang, "Дата")}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        dateFormat={`${dateFormat} HH:mm`}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={60}
                                        wrapperClassName={classNames({
                                            'is-invalid': errors?.date
                                        })}
                                        className={classNames('form-control', {
                                            'is-invalid': errors?.date,
                                            'border-right-0': dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== revision?.date && revision,
                                        })}
                                    />
                                    {dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== revision?.date && revision &&
                                        <Flex className={'w-25'}>
                                            <IconButton
                                                variant={"phoenix"}
                                                title={"Save"}
                                                className={'fs-8 w-50 rounded-3 cursor-pointer border-right-0 border-left-0 btn-primary-davr'}
                                                size="md"
                                                style={{height: 39}}
                                                onClick={(e) => onDateChanged("Save", field?.value)}
                                                icon={faCheck}
                                            />
                                            <IconButton
                                                variant="phoenix-secondary"
                                                title={"Cancel"}
                                                size="md"
                                                style={{height: 39}}
                                                className={'fs-8 w-50 rounded-3 cursor-pointer btn-davr border-left-0'}
                                                onClick={(e) => onDateChanged("Cancel")}
                                                icon={faCancel}
                                            />
                                        </Flex>
                                    }
                                </InputGroup>
                            )}/>
                        <Form.Control.Feedback type="invalid">
                            {errors?.date?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name="employee_id"
                            render={({field}) => {
                                return (
                                    <EmployeeSelect
                                        right={true}
                                        placeholder={t(lang, 'Сотрудник')}
                                        defaultValue={field?.value}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        isClearable={true}
                                        allowAdd={true}
                                        allowEdit={true}
                                    />
                                )
                            }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} sm={12} md={4}>
                        <Controller
                            name="warehouse_id"
                            rules={{
                                required: t(lang, "warehouse.operation.item.common.validation.is_required")
                            }}
                            render={({field}) => {
                                return (
                                    <WarehouseOperationWarehouseSelect
                                        placeholder={<>
                                            {t(lang, 'warehouse.navbar.routes.warehouse')}
                                            <span className={'text-primary-davr'}>*</span>
                                        </>}
                                        defaultValue={field?.value}
                                        options={warehouse}
                                        onChange={(option) => {
                                            field.onChange(option ? option?.id : null)
                                        }}
                                        className={classNames({
                                            'is-invalid': errors?.warehouse_id
                                        })}
                                    />
                                )
                            }}
                        />
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {errors?.warehouse_id?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Card>
            <Card className={'mt-3 p-2'}>
                <Table responsive className="scrollbar view-table border-900 mb-0 fs-9 second-text fw-semi-bold"
                       style={{maxHeight: "560px"}}>
                    <thead className="text-dark border-bottom">
                    <tr>
                        <th className="fw-medium  align-middle text-center">№</th>
                        <th className="align-middle"
                            style={{width: "300px"}}>{t(lang, "warehouse.operation.item.common.name")}</th>
                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                        {revision?.items?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                            <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                        }
                        {warehouses?.length > 1 &&
                            <th className="text-start"
                                style={{width: "230px"}}>{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                        }
                        <th className={'col'}>
                            <RevisionModal
                                loading={loading}
                                categoryItems={categoryItems}
                                onChangeItem={onChangeItem}
                                show={show}
                                onHide={onHide}
                                onShow={onShow}
                            />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                        return (
                            <React.Fragment key={rowIndex}>
                                {item?.sub?.map((subItem, subRowIndex) => {
                                    return (
                                        <tr key={subItem.id} className="fw-bold" style={{height: "62px"}}>
                                            <td key={rowIndex}
                                                rowSpan={item?.sub?.length}
                                                style={{width: "50px"}}
                                            >
                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                            </td>
                                            <td className={subRowIndex === 0 ? "text-start " : "d-none"}
                                                rowSpan={item?.sub?.length}
                                                style={{minWidth: "300px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexItem,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}
                                            >
                                                {isHover[colCoordinateZero] === colIndexItem && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <WarehouseOperationItemSelect
                                                        placeholder={t(lang, "warehouse.operation.item.common.product_name")}
                                                        options={items}
                                                        defaultValue={subItem?.warehouse_item?.id}
                                                        menuPortalTarget={document.body}
                                                        onChange={option => {
                                                            updateItem({
                                                                itemOne: option,
                                                                item: subItem
                                                            }).then()
                                                        }}
                                                    />
                                                    :
                                                    <>
                                                        {subItem?.warehouse_item?.name}
                                                    </>
                                                }
                                            </td>
                                            <td className="text-start "
                                                style={{maxWidth: "100px"}}
                                                onDoubleClick={() => handleMouseOver({
                                                    col: colIndexQuantity,
                                                    row: rowIndex,
                                                    subRow: subRowIndex
                                                })}>
                                                {isHover[colCoordinateZero] === colIndexQuantity && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                    <FloatingLabel
                                                        label={t(lang, "warehouse.operation.item.common.quantity")}>
                                                        <Form.Control
                                                            placeholder={t(lang, "Quantity")}
                                                            defaultValue={subItem?.quantity}
                                                            onFocus={(e) => e.target.select()}
                                                            autoFocus
                                                            onBlur={(e) => {
                                                                updateItem({
                                                                    quantity: e.target.value,
                                                                    item: subItem,
                                                                }).then()
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    :
                                                    <>
                                                        {subItem?.quantity}
                                                    </>
                                                }
                                            </td>
                                            {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(s => s?.warehouse_item?.warehouse_item_use) &&
                                                <td className="text-center">
                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                    <span> => </span>
                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                </td>
                                            }
                                            {warehouses?.length > 1 &&
                                                <td className="text-start"
                                                    style={{width: "240px"}}
                                                    onDoubleClick={() => handleMouseOver({
                                                        col: colIndexWarehouse,
                                                        row: rowIndex,
                                                        subRow: subRowIndex
                                                    })}>
                                                    {isHover[colCoordinateZero] === colIndexWarehouse && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex && warehouses?.length > 1 ?
                                                        <WarehouseOperationWarehouseSelect
                                                            placeholder={t(lang, "warehouse.operation.item.common.warehouse_name")}
                                                            options={items?.find(f => f?.warehouse_states?.id === subItem?.warehouse_item?.id)?.warehouse_states?.warehouse_items}
                                                            defaultValue={subItem?.warehouse_item?.warehouse?.id}
                                                            onFocus={() => true}
                                                            autoFocus={true}
                                                            onBlur={(warehouse) => {
                                                                updateItem({
                                                                    warehouseId: warehouse?.id,
                                                                    item: subItem,
                                                                })
                                                            }}
                                                            onChange={warehouse => {
                                                                updateItem({
                                                                    warehouseId: warehouse?.id,
                                                                    item: subItem,
                                                                }).then()
                                                            }}
                                                        />
                                                        :
                                                        <>
                                                            {subItem?.warehouse_item?.warehouse?.name}
                                                        </>
                                                    }
                                                </td>
                                            }


                                            <td className="text-nowrap text-center"
                                                style={{width: "40px"}}>
                                                {subItem?.is_deleted &&
                                                    <span className="text-end text-nowrap text-danger">
                                                                        {t(lang, "warehouse.operation.item.common.removed")}
                                                                    </span>
                                                }
                                                <WarehouseOperationDeleteItem onClick={onDeleteItem}
                                                                              itemId={subItem?.id}
                                                                              id={revision?.id}/>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                </Table>
            </Card>
        </Fragment>
    );
};

export default RevisionForm;