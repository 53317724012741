import React, {Fragment, useEffect} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../../lang/lang";
import {Card, Col, FloatingLabel, Form, InputGroup, Row, Spinner} from "react-bootstrap";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import {useNavigate} from "react-router-dom";
import IconButton from "../../../IconButton";
import {ErrorMessage} from "@hookform/error-message";
import ItemLegalTypeSelector from "../ItemLegalTypeSelector";
import SelectCategory from "../../../common/SelectCategory";
import {selectAllCategories} from "../../../../store/reducers/category/categoryReducer";
import SelectMeasurement from "../../../common/SelectMeasurement";
import UploadImages from "../UploadImages";
import IconCloudUpload from "../../../../assets/icon/IconCloudUpload";
import InputGroupWithCurrency from "../InputGroupWithCurrency";
import ItemPurchasePricesInput from "./bulk-item/ItemPurchasePricesInput";
import BarcodeRow from "../BarcodeRow";
import SelectWarehouse from "../../../common/SelectWarehouse";
import Cleave from "cleave.js/react";

const ItemForm = ({isEditing, onSubmit, loading, allowAdd, allowEdit}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const categories = useSelector(selectAllCategories);
    const navigate = useNavigate()
    const form = useFormContext();

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-items-add'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-items-add'));
    }, []);

    return (
        <Fragment>
            {!allowAdd && !allowEdit && <Row id={'warehouse-items-add'}
                  className="flex-between-center align-items-center my-3 position-sticky"
                  style={{top: '4rem',  zIndex: '999'}}>
                <Col xs={12} lg={2} className={'d-flex gap-3'}>
                    <button onClick={() => navigate('/items')} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.add_new.title")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <Col cols={'auto'} className={'d-flex align-items-center justify-content-end gap-2 flex-wrap'}>
                    <IconButton
                        className="btn-davr px-3 py-0 bg-primary-davr text-white"
                        variant="falcon-default"
                        size="lg"
                        disabled={loading}
                        onClick={onSubmit}
                    >
                        {loading && <Spinner className='align-middle me-2' animation='border' role='switch'/>}
                        {t(lang, "items.common.save")}
                    </IconButton>
                </Col>
            </Row>}
            <Row>
                <Col sm={12} lg={6}>
                    <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'items.common.name.title')}</h4>
                    <Card className={'p-1'}>
                        <Row className={'d-flex gap-2'}>
                            <Form.Group as={Col} lg={12}>
                                <FloatingLabel label={<>
                                    {t(lang, 'items.common.name')}
                                    <span className="text-danger">*</span>
                                </>}>
                                    <Form.Control name={'name'}
                                                  placeholder={t(lang, 'items.common.name')}
                                                  isInvalid={form.formState.errors?.name}
                                                  {...form.register('name', {
                                                      required: t(lang, 'items.common.validations.is_required'),
                                                  })}
                                    />
                                    <ErrorMessage name='name'
                                                  errors={form.formState.errors}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group as={Col} lg={12}>
                                <Controller
                                    name={'legalType'}
                                    render={({field}) => (
                                        <ItemLegalTypeSelector
                                            placeholder={t(lang, 'warehouse.items.history.datatable.column.type')}
                                            legalType={field.value}
                                            onChange={field.onChange}/>
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} lg={12}>
                                <Controller name="categoryId"
                                            render={({field}) => (
                                                    <SelectCategory
                                                        categories={categories}
                                                        placeholder={t(lang, "items.common.import_from_excel.column_selector.options.category")}
                                                        onChange={option => field.onChange(option ? option.id : null)}
                                                        defaultCategoryId={field.value}
                                                        selectProps={{
                                                            styles: {
                                                                container: (provided) => ({
                                                                    ...provided,
                                                                })
                                                            }
                                                        }}
                                                    />
                                            )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} lg={12}>
                                <Controller
                                    name={'measurement'}
                                    render={({field}) => (
                                        <SelectMeasurement
                                            placeholder={t(lang, 'items.common.measurement')}
                                            defaultMeasurement={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </Form.Group>

                            <Form.Group as={Col} lg={12}>
                                <FloatingLabel label={<>
                                    {t(lang, 'items.common.package_measurement.name')}
                                    <span className="text-danger">*</span>
                                </>}>
                                    <Form.Control
                                        name={`packageMeasurements.0.name`}
                                        placeholder={t(lang, 'items.common.package_measurement.name')}
                                        {...form.register(`packageMeasurements.0.name`, {
                                            required: t(lang, 'items.common.validations.is_required')
                                        })}
                                    />
                                    <ErrorMessage name='name'
                                                  errors={form.formState.errors['packageMeasurements.0.name']}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group as={Col} lg={12}>
                                <FloatingLabel label={<>
                                    {t(lang, 'items.common.package_measurement.quantity')}
                                    <span className="text-danger">*</span>
                                </>}>
                                    <Form.Control
                                        name={`packageMeasurements.0.quantity`}
                                        placeholder={t(lang, 'items.common.package_measurement.quantity')}
                                        {...form.register(`packageMeasurements.0.quantity`, {
                                            required: t(lang, 'items.common.validations.is_required')
                                        })}
                                    />
                                    <ErrorMessage name='name'
                                                  errors={form.formState.errors['packageMeasurements.0.quantity']}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                    </Card>
                </Col>
                <Col sm={12} lg={6}>
                    <Row className={'flex-column gap-3'}>
                        <Col sm={12}>
                            <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'items.common.images')}</h4>
                            <Card className={'p-1'}>
                                <div className={'border border-300 rounded-3 p-4'}>
                                    <Controller name={'images'}
                                                render={({field}) => (
                                                    <UploadImages files={field.value}
                                                                  onChange={field.onChange}
                                                                  preview
                                                                  placeholder={<IconCloudUpload/>}
                                                                  className={'d-flex gap-2 align-items-center justify-content-center'}
                                                    />
                                                )}
                                    />
                                </div>
                            </Card>
                        </Col>

                        <Col sm={12}>
                            <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'warehouse.operation.item.common.selling_price')}</h4>
                            <Card className={'p-1'}>
                                <Row className={'d-flex gap-2'}>
                                    <Col sm={12}>
                                        <InputGroupWithCurrency
                                            placeholder={t(lang, 'items.common.price')}
                                            currencyFieldName={'commonPrice.currencyId'}
                                            priceFieldName={'commonPrice.price'}/>
                                    </Col>
                                    <Col sm={12}>
                                        <InputGroupWithCurrency
                                            placeholder={t(lang, 'items.common.bulk_price')}
                                            currencyFieldName={'bulkPrice.currencyId'}
                                            priceFieldName={'bulkPrice.price'}/>
                                    </Col>
                                    {!isEditing && <Col sm={12}>
                                        <InputGroupWithCurrency
                                            placeholder={t(lang, 'items.common.purchase_price')}
                                            currencyFieldName={'purchasePrice.currencyId'}
                                            priceFieldName={'purchasePrice.price'}/>
                                    </Col>}

                                    {!!isEditing && (
                                        <Form.Group>
                                            <ItemPurchasePricesInput
                                                placeholder={t(lang, 'items.common.purchase_price')}
                                                purchasePricesArrayName={'purchasePrices'}/>
                                        </Form.Group>
                                    )}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col sm={12}>
                    <h4 className="fs-8 fw-semi-bold second-text">{t(lang, 'items.common.inventory')}</h4>
                    <Card className={'p-1'}>
                        <Row>
                            <Form.Group as={Col} sm={12} lg={3}>
                                <BarcodeRow/>
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={3}>
                                <Controller
                                    name={`warehouseId`}
                                    render={({field}) => (
                                        <SelectWarehouse
                                            placeholder={t(lang, 'items.common.warehouse')}
                                            defaultWarehouseId={field.value}
                                            onChange={(option) => field.onChange(option ? option.id : null)}
                                            selectProps={{
                                                isSearchable: false
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={3}>
                                <Controller
                                    name={`state`}
                                    render={({field}) => (
                                        <FloatingLabel label={t(lang, 'items.common.state')}>
                                            <Cleave htmlRef={field.ref}
                                                    className="form-control"
                                                    placeholder={t(lang, 'items.common.state')}
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode === 188)
                                                            e.target.value += "."
                                                    }}
                                                    options={{
                                                        numeral: true,
                                                        delimiter: ' ',
                                                        numeralDecimalScale: 3,
                                                    }}
                                                    value={field.value}
                                                    onChange={e => field.onChange(e.target.rawValue)}

                                            />
                                        </FloatingLabel>
                                    )}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} lg={3}>
                                <FloatingLabel label={t(lang, 'items.common.sku')}>
                                    <Form.Control
                                        name={'sku'}
                                        placeholder={t(lang, 'items.common.sku')}
                                        {...form.register('sku')}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Row>
                    </Card>
                    {(allowAdd || allowEdit) && <Row className={"mt-2"}>
                        <Col xs={12} className={'d-flex justify-content-end'}>
                            <IconButton
                                className="btn-davr px-3 py-0 bg-primary-davr text-white"
                                variant="falcon-default"
                                size="lg"
                                disabled={loading}
                                onClick={onSubmit}
                            >
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' role='switch'/>}
                                {t(lang, "items.common.save")}
                            </IconButton>
                        </Col>
                    </Row> }
                </Col>
            </Row>
        </Fragment>
    );
};

export default ItemForm;
