import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react';
import useQuery from "../../../roaming/form/useQuery";
import {useDispatch, useSelector} from "react-redux";
import {
    loadContractorAsync,
    selectCardContractor,
    updateCardContractor
} from "../../../../store/reducers/contractor/contractorReducer";
import {selectLang, selectNumberFormat} from "../../../../store/reducers/main/main";
import {selectCurrency} from "../../../../store/reducers/currency/currencyReducer";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useFieldArray, useForm, useWatch} from "react-hook-form";
import itemWrapper, {UZS} from "../../../../enum/item/itemWrapper";
import {checkTypeOfReturnNumber} from "../../../../enum/warehouse-operation/warehouseOperation";
import {Form} from "react-bootstrap";
import WoWithoutDiscountPaymentForm from "./WOWithoutDiscountPaymentForm";
import {useMainLayoutContext} from "../../../../pheonix/providers/MainLayoutProvider";

const WoExactDiscountPayment = forwardRef(({data, onSubmitPay, isEditing, ...props}, ref) => {
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const {payout_id} = useQuery();
    const contractor = useSelector(selectCardContractor);
    const numberFormat = useSelector(selectNumberFormat);
    const currencies = useSelector(selectCurrency);
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;

    const {setLoading: setContextLoading} = useMainLayoutContext();

    useEffect(() => {
        if (payout_id) {
            setShow(true)
        }
    },[])

    const totals = data?.net_price

    useEffect(() => {
        handlerShow()
    }, []);

    const handlerShow = () => {
        setShow(true)
        loadContractorAsync(data?.contractor?.id)
            .then(cr => {
                dispatch(updateCardContractor(cr))
            }).catch(err => console.log(err));

        if (data && (data?.payout || data?.payment)) {
            let type = data?.payout ? 'payout' : 'payment'
            let dt = data?.payout?.debt_states.map(pay => {
                return {
                    amount: pay?.amount,
                    currency_id: pay?.currency?.id
                }
            })
            let cc = defaultCashBoxStates.map((item, index) =>  {
                let pay = data?.[type].cash_box_states?.find(pay => pay?.payment_type === item?.type)
                if (pay) {
                    return {
                        ...item,
                        currency: {
                            amount: pay?.amount,
                            currency_id: pay?.currency?.id
                        }
                    }
                }
                return item
            })
            /*let cc = data?.payout?.cash_box_states.map(pay => {
                return {
                    type: pay?.payment_type,
                    currency: {
                        amount: pay?.amount,
                        currency_id: pay?.currency?.id
                    }
                }
            })*/

            replaceCashBoxStates(cc)
            form.setValue('notes', data?.[type]?.notes)
        }

        /*if (data && data?.payment) {
            let dt = data?.payment?.debt_states.map(pay => {
                return {
                    amount: pay?.amount,
                    currency_id: pay?.currency?.id
                }
            })
            let cc = data?.payment?.cash_box_states.map(pay => {
                return {
                    type: pay?.payment_type,
                    currency: {
                        amount: pay?.amount,
                        currency_id: pay?.currency?.id
                    }
                }
            })

            replaceCashBoxStates(cc)
            form.setValue('notes', data?.payment?.notes)
        }*/
    }

    const defaultCustomerDebtPay = {
        amount: '0',
        currency_id: currencies?.filter(cur => cur.is_active)?.find(c => c?.code === UZS)?.id
    }
    const defaultDebtStates = {
        type: 1,
        amount: '0',
        currency_id: currencies?.filter(cur => cur.is_active).find(c => c?.code === UZS)?.id,
    }
    const defaultCashBoxStates = [
        {
            type: 1,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 2,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 3,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 4,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 5,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 6,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },
        {
            type: 7,
            currency: {
                amount: '0',
                currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
            }
        },

    ]
    const defaultCashBackPay = {
        type: 1,
        currency: {
            amount: '0',
            currency_id: itemWrapper.getDefaultCurrency(currencies)?.id,
        }
    }

    const form = useForm({
        defaultValues: {
            debt_states: [defaultDebtStates],
            cash_box_states: defaultCashBoxStates,
            notes: "",
            cash_back_pay: [defaultCashBackPay],
            customer_debt_pay: [defaultCustomerDebtPay],
        }
    })

    const {
        fields: fieldsCashBoxStates,
        update: updateCashBoxStates,
        append: appendCashBoxStates,
        remove: removeCashBoxStates,
        replace: replaceCashBoxStates,
    } = useFieldArray({
        control: form.control,
        name: 'cash_box_states'
    });

    const {
        fields: fieldsCashBackPay,
        update: updateCashBackPay,
        append: appendCashBackPay,
        remove: removeCashBackPay,
    } = useFieldArray({
        control: form.control,
        name: 'cash_back_pay'
    });

    const {
        fields: fieldsCustomerDebtPay,
        update: updateCustomerDebtPay,
        append: appendCustomerDebtPay,
        remove: removeCustomerDebtPay,
    } = useFieldArray({
        control: form.control,
        name: 'customer_debt_pay'
    });

    const watchCashBackPay = useWatch({name: 'cash_back_pay', control: form.control});
    const controlledFieldsCashBackPay = fieldsCashBackPay.map((field, index) => {
        return {
            ...field,
            ...watchCashBackPay[index]
        };
    });

    const watchCustomerDebtPay = useWatch({name: 'customer_debt_pay', control: form.control});
    const controlledFieldsCustomerDebtPay = fieldsCustomerDebtPay.map((field, index) => {
        return {
            ...field,
            ...watchCustomerDebtPay[index]
        };
    });
    const addNewRowToCashBackPay = () => {
        appendCashBackPay(defaultCashBoxStates)
    }

    const onTrashCashBackPay = (index) => {
        removeCashBackPay(index)
    }

    const onChangeCashBackPayPaymentType = (selectedPaymentType, field, index) => {

        if (selectedPaymentType) {
            updateCashBackPay(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
                rate: field.currency.rate
            })
        }
    }

    const onChangeCashBackPayCurrencyId = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field.currency.amount) * currencies.find(cur => cur.id === field?.currency?.currency_id)?.rate

        updateCashBackPay(index, {
            type: field.type,
            currency: {
                currency_id: currencyCode.id,
                amount: (convertToUZS || 0) / currencyCode?.rate,
            }
        })
    }

    const addNewRowToCustomerDebtPay = () => {
        appendCustomerDebtPay(defaultCustomerDebtPay)
    }

    const onTrashCustomerDebtPay = (index) => {
        removeCustomerDebtPay(index)
    }

    const onChangeCustomerDebtPayCurrencyId = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field?.amount) * currencies.find(cur => cur?.id === field?.currency_id)?.rate
        updateCustomerDebtPay(index, {
            currency_id: currencyCode.id,
            amount: (convertToUZS || 0) / currencyCode?.rate,
        })
    }

    const addNewPaymentRowToCashBoxStates = () => {
        appendCashBoxStates(defaultCashBoxStates)
    }
    const onTrashCashBoxStates = (index) => {
        removeCashBoxStates(index)
    }

    const onChangeCashBoxStatesPaymentType = (selectedPaymentType, field, index) => {
        if (selectedPaymentType) {
            updateCashBoxStates(index, {
                type: selectedPaymentType.value,
                currency: field.currency,
                rate: field.currency.rate
            })
        }
    }
    const onChangeCashBoxStatesCurrencyCode = (currencyCode, field, index) => {
        const convertToUZS = checkTypeOfReturnNumber(field.currency.amount) * currencies.find(cur => cur.id === field?.currency?.currency_id)?.rate

        if (currencyCode) {
            updateCashBoxStates(index, {
                type: field.type,
                currency: {
                    currency_id: currencyCode.id,
                    amount: (convertToUZS || 0) / currencyCode?.rate,
                }
            })
        }
    }

    const onSubmit = async (formData) => {
        setContextLoading(true)
        setLoading(true)
        const result = {
            cash_box_states: formData?.cash_box_states.map(cbs => {
                return {
                    amount: checkTypeOfReturnNumber(cbs?.currency?.amount),
                    currency_id: cbs?.currency?.currency_id,
                    type: cbs?.type,
                }
            }).filter(x => x.amount !== 0),
            debt_states: formData?.debt_states?.map(ds => {
                return {
                    amount: checkTypeOfReturnNumber(ds?.amount),
                    currency_id: ds?.currency_id,
                }
            }).filter(x => x.amount !== 0),
            notes: formData?.notes,
        }
        formData?.cash_back_pay.map((cbp) => {
            const resultCashBox = result.cash_box_states.findIndex(x => x?.type === cbp?.type && x?.currency_id === cbp?.currency?.currency_id)

            if (resultCashBox > -1) {
                result.cash_box_states[resultCashBox].amount = result?.cash_box_states[resultCashBox]?.amount - checkTypeOfReturnNumber(cbp?.currency?.amount)
            } else {
                result.cash_box_states.push({
                    type: cbp?.type,
                    currency_id: cbp?.currency?.currency_id,
                    amount: -checkTypeOfReturnNumber(cbp?.currency?.amount),
                })
            }
        })
        formData?.customer_debt_pay?.map((cdp) => {
            const resultDebtPay = result.debt_states.findIndex(x => x?.currency_id === cdp?.currency_id)

            if (resultDebtPay > -1) {
                result.debt_states[resultDebtPay].amount = result.debt_states[resultDebtPay]?.amount + checkTypeOfReturnNumber(cdp?.amount)
            } else {
                result.debt_states.push({
                    amount: checkTypeOfReturnNumber(cdp?.amount),
                    currency_id: cdp?.currency_id,
                })
            }

        })

        result.debt_states = result.debt_states.filter(x => x.amount !== 0)
        result.cash_box_states = result.cash_box_states.filter(x => x.amount !== 0)

        onSubmitPay({
            id: data?.id,
            payment: result.cash_box_states?.length > 0 || result.debt_states?.length > 0 ? result : null,
        })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
                setContextLoading(false)
                setLoading(false)
            })
    }

    useImperativeHandle(ref, () => form.handleSubmit(onSubmit));

    return (
        <Fragment>
            <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <WoWithoutDiscountPaymentForm

                        totals={totals}

                        controlledFieldsCashBackPay={controlledFieldsCashBackPay}
                        onChangeCashBackPayPaymentType={onChangeCashBackPayPaymentType}
                        addNewRowToCashBackPay={addNewRowToCashBackPay}
                        onTrashCashBackPay={onTrashCashBackPay}
                        onChangeCashBackPayCurrencyId={onChangeCashBackPayCurrencyId}

                        controlledFieldsCustomerDebtPay={controlledFieldsCustomerDebtPay}
                        addNewRowToCustomerDebtPay={addNewRowToCustomerDebtPay}
                        onTrashCustomerDebtPay={onTrashCustomerDebtPay}
                        onChangeCustomerDebtPayCurrencyId={onChangeCustomerDebtPayCurrencyId}


                        addNewPaymentRowToCashBoxStates={addNewPaymentRowToCashBoxStates}
                        onChangeCashBoxStatesPaymentType={onChangeCashBoxStatesPaymentType}
                        onChangeCashBoxStatesCurrencyCode={onChangeCashBoxStatesCurrencyCode}
                        onTrashCashBoxStates={onTrashCashBoxStates}
                        fieldsCashBoxStates={fieldsCashBoxStates}

                        data={data}
                        net_price={data?.net_price}
                    />
                </Form>
            </FormProvider>
        </Fragment>
    );
});

export default WoExactDiscountPayment;
