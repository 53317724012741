import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "react-bootstrap";
import ContractorForm from "../../pages/warehouse/contractor/ContractorForm";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {
    editContractorAsync,
    loadAllContractorAsync,
    setDefaultContractor,
    unSetDefaultContractor
} from "../../store/reducers/contractor/contractorReducer";
import FalconCloseButton from "../common/FalconCloseButton";
import toast from "react-hot-toast";
import Flex from "../common/Flex";
import IconArrowLeft from "../../assets/icon/IconArrowLeft";
import IconButton from "../IconButton";
import {faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";

const EditContractor = ({contractor, onClose = () => {}, show, contact = false, selectBtn = false}) => {
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const [loading, setLoading] = useState(false);
    const [selectBtnShow, setSelectBtnShow] = useState(false);
    const handleOnSubmit = (formData) => {
        let phones = formData.phones.filter(p => p.value)
        phones = phones.map(phone => {
            if (phone.value) {
                let ph_value = phone.value.replace(/ /g, '')
                ph_value = '998' + ph_value;
                return {
                    type: 1,
                    value: ph_value
                }
            }
        });
        let emails = formData.emails.filter(e => e.value)
        emails = emails.map(email => {
            if (email.value)
                return {
                    type: 3,
                    value: email.value
                }
        });
        const contacts = phones.concat(emails)

        setLoading(true)

        if (contractor.is_default !== formData.is_default)
            if (formData.is_default) {
                dispatch(setDefaultContractor(contractor.id)).then(res => {
                    toast.success(t(lang, "edi.common.toast.success"));
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            } else {
                dispatch(unSetDefaultContractor()).then(res => {
                }).catch(err => {
                    toast.error(t(lang, "edi.common.toast.error"));
                });
            }


        dispatch(editContractorAsync(contractor.id, {
            name: formData.name,
            inn: formData.inn,
            is_default: formData.is_default,
            organization_code: formData.organization_code || null,
            type: formData.type.map(t => +t),
            contacts: contacts
        })).then(res => {
            dispatch(loadAllContractorAsync());
            toast.success(t(lang, "edi.common.toast.success"));
            onClose()
            setSelectBtnShow(false)
        }).catch(err => {
            toast.error(t(lang, "edi.common.toast.error"));
        }).finally(() => {
            setLoading(false)
        });


    }
    return (<>
        {selectBtn &&
            <IconButton
                className={'bg-transparent'}
                variant={'phoenix-secondary'}
                icon={faPencil}
                onClick={() => setSelectBtnShow(true)}
            />
        }
        <Modal show={show || selectBtnShow} onHide={() => {
            onClose()
            setSelectBtnShow(false)
        }} size={!contact ? "xl" : "lg"} className={"davr-modal"}>
            <Modal.Header>
                <Modal.Title>
                    <Flex className={'gap-2'}>
                        <button onClick={() => {
                            onClose()
                            setSelectBtnShow(false)
                        }} className={'main-text btn-back'}
                                style={{width: 48, height: 48, borderRadius: 16}}>
                            <IconArrowLeft/>
                        </button>
                        {t(lang, "edi.contractor.edit_contractor_title")}
                    </Flex>
                </Modal.Title>
                <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={()=>{
                    onClose()
                    setSelectBtnShow(false)
                }}/>
            </Modal.Header>
            <Modal.Body>
                <ContractorForm contact={contact} contractor={contractor} onClose={onClose} loading={loading}
                                onSubmit={handleOnSubmit} isEditing={true}/>
            </Modal.Body>
        </Modal>
    </>)
}
export default EditContractor
