import React, {Fragment, useEffect} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {useFieldArray, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import itemWrapper from "../../../enum/item/itemWrapper";
import {getProductGuideAsync} from "../../../store/reducers/item/itemReducer";
import {CheckScanBarcodeOrMarking} from "../../../enum/warehouse-operation/warehouseOperation";
import EventBus from "../../../eventbus/EventBus";
import {SCAN_SUCCESS} from "../../../eventbus/itemEvents";
import {ErrorMessage} from "@hookform/error-message";
import {FloatingLabel, Form, InputGroup} from "react-bootstrap";
import classNames from "classnames";

const barcodeRegExp = /^[0-9]{0,13}$/;
const fieldName = "barcodes";
const BarcodeRow = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useFormContext();
    const {fields, append, replace, remove} = useFieldArray({name: `${fieldName}`});
    const addNewBarcode = () => append({barcode: itemWrapper.generateGtinBarcode(new Date().getTime().toString().slice(5, 13))});
    const deleteBarcode = (index) => remove(index);

    const onScan = async (value) => {
        const barcodes = [];
        const cuteBarcode = CheckScanBarcodeOrMarking(value)
        barcodes.push({barcode: cuteBarcode});

        try {
            replace(barcodes);
            const {product_guide, tasnif_product_guide: catalog} = await getProductGuideAsync(cuteBarcode);
            if (product_guide?.name)
                form.setValue('name', product_guide.name);

            if (catalog) {
                form.setValue('tax.catalog', catalog);
                form.setValue('tax.package', (catalog?.use_package && catalog?.package_names?.length === 1) ? catalog.package_names[0] : null);
                form.setValue('tax.measurement', null);
                form.setValue('tax.benefit', null);
            }

            form.setFocus(product_guide?.name ? 'commonPrice.price' : 'name');
        } catch (e) {
            replace(barcodes);
            form.setFocus('name');
            console.log(e);
        }
    }

    useEffect(() => {
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
        }
    }, [])

    return (
        <Fragment>
            {fields.map((field, index) => {
                const {error} = form.getFieldState(`${fieldName}.${index}`);
                return (
                    <Form.Group key={field.id} className="">
                        <InputGroup className={classNames({'is-invalid': error?.barcode})}>
                            <FloatingLabel label={<>
                                {t(lang, 'items.common.barcode')}
                                <span className="text-danger">*</span>
                            </>}>
                                <Form.Control placeholder={t(lang, 'items.common.barcode.placeholder')}
                                              name={`${fieldName}.${index}.barcode`}
                                              isInvalid={error?.barcode}
                                              {...form.register(`${fieldName}.${index}.barcode`, {
                                                  required: t(lang, 'items.common.validations.is_required'),
                                                  pattern: {
                                                      value: barcodeRegExp,
                                                      message: t(lang, "items.common.validations.invalid_barcode")
                                                  }
                                              })}
                                />
                            </FloatingLabel>
                        </InputGroup>
                        <ErrorMessage name='barcode'
                                      errors={error}
                                      as={<Form.Control.Feedback type="invalid"/>}
                        />
                    </Form.Group>
                )
            })}
        </Fragment>
    );
};

export default BarcodeRow;
