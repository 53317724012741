import {root} from "./reducers/root";
import {configureStore} from "@reduxjs/toolkit";

const store = configureStore({
    reducer: root,
    // only development environment because in a production environment this middleware is automatically false.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})
export default store
