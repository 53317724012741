import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {
    selectWarehouseInitializationState,
    selectWarehouses, WAREHOUSE_INITIALIZATION_STATE_COMPLETE
} from "../../../../store/reducers/warehouse/warehouseReducer";
import {selectActiveOrganization} from "../../../../store/reducers/auth/authReducer";
import {
    ITEM_INITIALIZATION_STATE_COMPLETE,
    selectItemInitializationState,
    selectItems
} from "../../../../store/reducers/item/itemReducer";
import {useNavigate, useParams} from "react-router-dom";
import EventBus from "../../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS, WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS
} from "../../../../eventbus/warehouse/writeOffEvent";
import {getWriteOffItemAsync} from "../../../../store/reducers/warehouse/writeOffReducer";
import SpinnerComponent from "../../../../components/common/SpinnerComponent";
import WarehouseNo from "../../../../components/warehouse/operation/WarehouseNo";
import ItemNo from "../../../../components/warehouse/operation/ItemNo";
import EditWriteOffForm from "./EditWriteOffForm"
const EditWriteOffs = () => {
    const warehouseInitializationState = useSelector(selectWarehouseInitializationState)
    const itemInitializationState = useSelector(selectItemInitializationState)
    const activeOrganization = useSelector(selectActiveOrganization)
    const warehouses = useSelector(selectWarehouses)
    const [writeOff, setWriteOff] = useState(null)
    const navigate = useNavigate()
    const items = useSelector(selectItems)
    const {id} = useParams()



    useEffect(() => {
        const onAddItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS, (id) => {
            loadWriteOff().then()
        })

        const onUpdateItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS, (id) => {
            loadWriteOff().then()
        })

        const onDeleteItemHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS, (id) => {
            loadWriteOff().then()
        })

        const onApprovedHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, (id) => {
            navigate(`../write-offs/view/${id}`)
        })

        const onNoteHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS, (id) => {
            loadWriteOff().then()
        })

        const onChangedDateHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS, (id) => {
            loadWriteOff().then()
        })
        const onChangeEmployeeHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS, (response) => {
            setWriteOff(response.data, response.data.items.reverse())
        })

        const onChangedNumberHandler = EventBus.on(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, (id) => {
            loadWriteOff().then()
        })


        return () => {
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS, onAddItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS, onUpdateItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS, onDeleteItemHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, onApprovedHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS, onNoteHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS, onChangedDateHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS, onChangeEmployeeHandler)
            EventBus.remove(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, onChangedNumberHandler)
        }
    }, [])

    useEffect(() => {
        loadWriteOff().then()
    },[activeOrganization, id])



    const loadWriteOff = async () => {
        await getWriteOffItemAsync({writeOffId: id})
            .then((response) => {
                setWriteOff(response.data, response.data.items.reverse())
            })
            .catch((error) => {
                setWriteOff(null)
            })
    }



    if (warehouseInitializationState !== WAREHOUSE_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />

    if (itemInitializationState !== ITEM_INITIALIZATION_STATE_COMPLETE)
        return <SpinnerComponent />


    if (warehouses?.length === 0)
        return (
            <>
                <WarehouseNo/>
            </>
        )

    if (items?.length === 0)
        return (
            <>
                <ItemNo/>
            </>
        )

    if(!writeOff)
        return (
            <SpinnerComponent />
        )




    return(
        <>
            <EditWriteOffForm writeOff={writeOff}/>
        </>
    )
}

export default EditWriteOffs;