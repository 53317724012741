import {selectLang} from "../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../lang/lang";
import IconAlert from "../common/IconAlert";
import { Alert } from "react-bootstrap";
import {
    EDI_SERVICE_CODE,
    POS_SERVICE_CODE,
    WAREHOUSE_MANAGEMENT_SERVICE_CODE
} from "../../store/reducers/billing/billingReducer";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faWarning} from "@fortawesome/free-solid-svg-icons";
import Badge from "../common/Badge";
import React, {Fragment} from "react";
import classNames from "classnames";
import IconAlertPrimary from "../../assets/icon/IconAlertPrimary";

const ActivateServicePackageAlert = ({serviceCode, className, ...rest}) => {
    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Fragment>
            <div className={classNames(className, 'd-flex mb-2 align-items-start alert-davr-primary py-1 px-2 rounded-3')} {...rest}>
                <div className={'mt-1 me-2'}>
                    <IconAlertPrimary/>
                </div>
                <div className={`me-1`}>
                    <Alert.Heading
                        className="mb-2 mb-sm-0 fs-sm-9 fs-md-7">{t(lang, 'edi.alert.activate_edi_service_package.title')}</Alert.Heading>
                    {serviceCode === EDI_SERVICE_CODE &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">
                            {t(lang, 'edi.alert.activate_edi_service_package.description')}
                            <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                                {t(lang, 'edi.alert.activate_edi_service_package.description.link_to_all_billing_packages')}
                                <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                            </Alert.Link>
                        </p>
                    }
                    {serviceCode === WAREHOUSE_MANAGEMENT_SERVICE_CODE &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">
                            {t(lang, 'warehouse.alert.activate_warehouse_service_package.description')}
                            <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                                {t(lang, 'warehouse.alert.activate_warehouse_service_package.description.link_to_all_billing_packages')}
                                <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                            </Alert.Link>
                        </p>
                    }
                    {serviceCode === POS_SERVICE_CODE &&
                        <p className="mb-0 fw-medium fs-md-8 fs-sm-10 text-wrap">
                            {t(lang, 'pos.alert.activate_pos_service_package.description')}
                            <Alert.Link as={Link} className="ms-2 fw-bold text-primary" to={'/user/billing'}>
                                {t(lang, 'pos.alert.activate_pos_service_package.description.link_to_all_billing_packages')}
                                <FontAwesomeIcon className='ms-1' icon={faArrowUpRightFromSquare}/>
                            </Alert.Link>
                        </p>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default ActivateServicePackageAlert;
