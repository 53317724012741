import {Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";


const ItemNo = () => {


    return(
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="text-center">
                            <Card.Title>
                                Товар не создан,
                                &nbsp;
                                <Link to="../../items">Пожалуйста создайте товар</Link>
                            </Card.Title>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default ItemNo;