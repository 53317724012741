import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {transliterate} from "transliteration";
import {loadItemsAsync} from "../../../store/reducers/item/itemReducer";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScaleUnbalanced, faSpinner} from "@fortawesome/free-solid-svg-icons";

const UploadItemsToScales = () => {

    const [loading, setLoading] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const uploadHandler = async () => {
        setLoading(true);

        loadItemsAsync()
            .then(({data: items}) => {
                const itemsWithSku = items.filter(i => i.item.sku);

                const scalesFormattedItems = itemsWithSku.map(i => {
                    return {
                        Sku: i.item.sku.replace(/ +/g, ""),
                        Name: transliterate(i.item.name),
                        Category: i.item.category ? transliterate(i.item.category.name) : '',
                        Price: i.price.common_price.amount,
                        x1: 0,
                        x2: 0,
                        x3: 0,
                        Sku2: i.item.sku.replace(/ +/g, ""),
                        x4: 0,
                        x5: 0,
                        x6: 0,
                        x7: '01.01.01',
                        x8: 0 + ';',
                    };
                });

                let str = '';
                for (let i = 0; i < scalesFormattedItems.length; i++) {
                    let line = '';
                    for (let index in scalesFormattedItems[i]) {
                        if (line !== '') line += ';'

                        line += scalesFormattedItems[i][index];
                    }
                    str += line + '\r\n';
                }

                const link = document.createElement("a");
                const file = new Blob([str], {type: 'text/plain'});
                link.href = URL.createObjectURL(file);
                link.download = 'products.txt';
                link.click();
                URL.revokeObjectURL(link.href);

                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    return (
        <Button  className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2 text-nowrap" variant={'falcon-warning'} size={'sm'} onClick={uploadHandler} disabled={loading}>
            {loading
                ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                : <FontAwesomeIcon className={'mx-1 text-primary-davr'} icon={faScaleUnbalanced}/>
            }
            <span className={'mx-1'}>{t(lang, 'items.common.datatable.upload_items_to_scales.title')}</span>
        </Button>
    );
};

export default UploadItemsToScales;
