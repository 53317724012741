import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, Modal, Spinner} from "react-bootstrap";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {activateDemoPeriodAsync} from "../../store/reducers/billing/billingReducer";

const StartTrialPeriodModal = ({serviceCode}) => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const lang = useSelector(selectLang);
    const t = Translate;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const startTrial = () => {
        setLoading(true);

        activateDemoPeriodAsync({
            serviceCode: serviceCode,
        })
            .then(() => handleClose())
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
    }

    return (
        <Fragment>
            <Button className="mt-3 mt-sm-0 align-self-end pb-0" variant="link" onClick={handleShow}>{t(lang, 'main.common.try')}</Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t(lang, 'main.modal.start_trial_period.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(lang, 'main.modal.start_trial_period.description')}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>{t(lang, 'main.common.back')}</Button>
                    <Button onClick={startTrial}>
                        {loading && <Spinner className="align-middle me-2" animation="border" role="switch" size="sm"/>}
                        {t(lang, 'main.common.start')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default StartTrialPeriodModal;
