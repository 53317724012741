import React, {Fragment} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat} from "../../../store/reducers/main/main";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import {Card, Col, Row} from "react-bootstrap";
import {useNavigate, Link} from "react-router-dom";
import ImageSlider from "../../../components/item/components/ImageSlider";
import dayjs from "dayjs";
import itemWrapper from "../../../enum/item/itemWrapper";
import numeral from "numeral";
import IconButton from "../../../components/IconButton";
import {faEdit, faPen} from "@fortawesome/free-solid-svg-icons";

const Item = ({item: {item, price, warehouse_states: {warehouse_items}}}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const navigate = useNavigate();
    const currencies = useSelector(selectCurrency);
    const numberFormat = useSelector(selectNumberFormat);

    const getCurrencyName = (id) => {
        const currency = currencies.find(c => c.id === id);
        if (currency)
            return currency.name;
        return "";
    };

    return (
        <Fragment>
            <Card className={'p-1'}>
                <div className={'d-flex justify-content-between align-items-center my-1'}>
                    <h4 className="fs-8 fw-semi-bold">{item.name}</h4>
                    <div className={'d-flex gap-2'}>
                        <IconButton as={Link}
                                    variant="falcon-warning"
                                    size="sm"
                                    icon={faPen}
                                    to={`edit/${item.id}`}
                                    iconClassName={'fs-9'}
                                    className="bg-card p-1 rounded-2 d-flex align-items-center px-2"
                        />
                        <div className={'bg-card p-1 rounded-2'}>
                            {dayjs(item.created_at).format('DD.MM.YY')}
                        </div>
                    </div>
                </div>
                <Row>
                    <Col sm={3}>
                        <ImageSlider product={{
                            images: item.images
                        }}/>
                        <h4 className="fs-9 mt-2 fw-light second-text">{t(lang, 'items.common.view.image.title')}</h4>
                    </Col>
                    <Col sm={9}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.price')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'d-block main-text text-truncate h-100'}>{price.common_price.amount} {getCurrencyName(price.common_price.currency.global_currency_id)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.bulk_price')}</span>
                                </Col>
                                <Col md={8}>
                                    <span
                                        className={'d-block main-text text-truncate h-100'}>{price.bulk_price.amount} {getCurrencyName(price.bulk_price.currency.global_currency_id)}</span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.purchase_price')}</span>
                                </Col>
                                <Col md={8}>
                                    {warehouse_items.length && warehouse_items?.map((warehouseItem) => {
                                        if (warehouseItem?.purchase_price) {
                                            return <span className={'d-inline-block main-text text-truncate h-100'}>
                                                {warehouseItem?.name} {t(lang, "items.common.purchase_price.warehouse")}: {warehouseItem?.purchase_price?.price?.amount} {warehouseItem?.purchase_price?.price?.currency?.name}<br/>
                                            </span>
                                        } else {
                                            return <span className={'d-inline-block main-text text-truncate h-100'}/>
                                        }
                                    })}
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col sm={12} className={'mt-2'}>
                        <div className={'bg-card p-2 rounded-2 text-center'}>
                            <p className={'fs-9'}>{t(lang, "warehouse.operation.item.common.total_sum")}</p>
                            <Fragment>
                                <strong className={'fs-8 ms-2'}>
                                    {numeral['formats'][numberFormat].format(price.common_price?.amount)}
                                </strong>
                                <span>
                                      {currencies.find(c => c.id === price.bulk_price?.currency?.global_currency_id)?.name}
                                </span>
                            </Fragment>
                        </div>
                    </Col>

                    <Col sm={12} className={'mt-2'}>
                        <div className={'bg-card p-2 rounded-2'}>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.measurement')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>
                                        {t(lang, itemWrapper.getMeasurement(item.measurement))}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.roaming_measurement')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>
                                        {item.tax?.measurement || item.tax?.catalog?.package?.name  || t(lang, "items.common.not_selected")}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.catalog')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>
                                        {item.tax?.catalog?.code ||  t(lang, "items.common.not_selected")}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.tax_rate')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>
                                        {(item.tax && item.tax?.tax_rate !== null) ? item.tax.tax_rate : t(lang, "items.common.tax_rate_options.0")}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                            <Row className={'py-1'}>
                                <Col md={4}>
                                    <span className={'second-text'}>{t(lang, 'items.common.description')}</span>
                                </Col>
                                <Col md={8}>
                                    <span className={'d-block main-text text-truncate h-100'}>
                                        {item.description ? item.description : t(lang, "items.common.not_selected")}
                                    </span>
                                    <hr className={'m-0 line-color'}/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

            </Card>
        </Fragment>
    );
};

export default Item;
