import React, {Fragment} from 'react';
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import {Button, Card, Col, Collapse, FloatingLabel, Form, Row} from "react-bootstrap";
import CustomFloatingLabelSelect from "../../../components/floating-custom-select/CustomFloatingLabelSelect";
import Flex from "../../../components/common/Flex";
import Cleave from "cleave.js/react";

const ContractorContactDataTableFilter = ({
                                              isOpenFilterCollapse,
                                              setSortByDesc,
                                              sortByDesc,
                                              setFilters,
                                              filters
                                          }) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    const sortAscOrDescOption = [
        {
            label: t(lang, "По убыванию"),
            value: true
        },
        {
            label: t(lang, 'По возрастанию'),
            value: false
        }
    ];

    const resetFilter = () => {
        setFilters({name: '', phone: '', email: '', filter: false})
        setSortByDesc(sortAscOrDescOption[1]);

    };

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div className={'mt-2'}>
                    <Card className={'p-2'}>
                        <Row className='mb-3 g-3'>
                            <Col sm={12} md={3}>
                                <FloatingLabel label={'Поиск по контрагенту'}>
                                    <Form.Control
                                        name={'search'}
                                        value={filters.name}
                                        onChange={event => {
                                            setFilters({...filters, name: event.target.value});
                                        }}
                                        type="search"
                                        className="search-input"
                                        placeholder="Поиск по контрагенту"
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12} md={3}>
                                <div className={'d-flex gap-2 align-items-center w-100'}>
                                    <FloatingLabel
                                        className={'w-30'}
                                        label={<>КОД <sup className={'text-primary-davr'}>*</sup></>}>
                                        <Form.Control
                                            name="organization_code"
                                            className={'text-truncate border-right-0'}
                                            type="text"
                                            value={"+998"}
                                            disabled
                                        />
                                    </FloatingLabel>
                                    <FloatingLabel
                                        className={'w-70'}
                                        label={<>{t(lang, 'crm.common.datatable.phone.number')} {"\u00A0"}
                                            <sup className={'text-primary-davr'}>*</sup></>}>
                                        <Cleave
                                            placeholder={"-- --- -- --"}
                                            options={{
                                                delimiters: [' ', ' ', ' ', ' '],
                                                blocks: [2, 3, 2, 2],
                                                numericOnly: true
                                            }}
                                            value={filters.phone}
                                            onChange={(event) => setFilters({...filters, phone: event.target.value})}
                                            className={'border-left-0 form-control w-100'}
                                        />
                                    </FloatingLabel>
                                </div>
                            </Col>
                            <Col sm={12} md={3}>
                                <FloatingLabel label={t(lang, 'edi.contract_formation.info.email')}>
                                    <Form.Control
                                        name={'email'}
                                        value={filters.email}
                                        onChange={event => {
                                            setFilters({...filters, email: event.target.value})
                                        }}
                                        type="email"
                                        className="search-input"
                                        placeholder="Поиск по контрагенту"
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col sm={12} md={3}>
                                <CustomFloatingLabelSelect
                                    value={sortByDesc}
                                    onChange={option => setSortByDesc(option)}
                                    options={sortAscOrDescOption}
                                    hideSelectedOptions
                                    isClearable
                                    label="Сорт."
                                    placeholder="Сорт."
                                    size={'sm'}
                                />
                            </Col>
                            <Col sm={12} md={12}>
                                <Flex className={'gap-2 align-items-center justify-content-end h-100'}>
                                    <Button className={'btn-davr bg-white-davr text-dark'} variant="phoenix-primary"
                                            type='button'
                                            onClick={() => resetFilter()}>{t(lang, "items.common.clear_imported_file")}</Button>
                                    <Button className={'btn-davr bg-primary-davr text-white'} variant="primary"
                                            onClick={() => setFilters({
                                                ...filters,
                                                filter: Symbol(true)
                                            })}>{t(lang, "roaming.invoices.table_header.show")}</Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default ContractorContactDataTableFilter;