import axios from "axios"

class SettingsService {
    getSettings() {
        return axios.get("/settings/organization/get");
    }

    getDefaultSettings() {
        return axios.get(`/settings/organization-account/get`)
    }
    updateEdiSettings({payload}) {
        return axios.post("/settings/organization/update-edi", payload);
    }

    updateEdoSettings({payload}) {
        return axios.post("/settings/organization/update-edo", payload)
    }

    updateLangSettings({payload}) {
        return axios.post("/settings/organization/update-lang", payload);
    }

    updateInvoiceRegistrySettings(payload) {
        return axios.post("/settings/organization/update-invoice-registry", payload);
    }

    updateWaybillRegistrySettings(payload) {
        return axios.post("/settings/organization/update-waybill-registry", payload);
    }

    updateWaybillItemRegistrySettings(payload) {
        return axios.post("/settings/organization/update-waybill-item-registry", payload);
    }

    updateCashBoxSettings(payload) {
        return axios.post(`/settings/organization-account/update-cash-box`, payload)
    }
    updateWarehouseSettings(payload) {
        return axios.post(`/settings/organization-account/update-warehouse`, payload)
    }
    updateBranchSettings(payload) {
        return axios.post(`/settings/organization-account/update-branch`, payload)
    }
}

const settingService = new SettingsService();
export default settingService;
