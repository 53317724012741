import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useState} from 'react';
import {useSelector} from "react-redux";
import {selectItems} from "../../../store/reducers/item/itemReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {measurements} from "../../../enum/item/itemWrapper";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";

const PackageTypeItem = forwardRef(({itemId, onChange, defaultValue, options, ...selectProps}, ref) => {

    const [packageMeasurement, setPackageMeasurement] = useState(null)
    const [label, setLabel] = useState(null)
    const [value, setValue] = useState(null)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate

    useImperativeHandle(ref, () => ({
        getPackageMeasurement: () => {
            return packageMeasurement
        }
    }), [packageMeasurement])

    useEffect(() => {
        if (itemId && items) {
            const pieceMeasurement = measurements?.find(m => +m?.value === items?.find(item => item?.item?.id === itemId)?.item?.measurement)

            setPackageMeasurement(items?.find(item => item?.item?.id === itemId)?.item?.package_measurements.concat({
                name: pieceMeasurement?.label,
                quantity: 1
            }))
        } else {
            setPackageMeasurement(null)
        }
    }, [itemId])

    useEffect(() => {
        if (packageMeasurement?.length > 0) {
            onChange(packageMeasurement[packageMeasurement?.length - 1])
            setValue(packageMeasurement[packageMeasurement?.length - 1]?.name)
        } else {
            onChange({name: "piece", quantity: 1})
        }
    }, [itemId, packageMeasurement])

    const onChangeHandler = (option) => {
        onChange(option)
        setValue(option?.name)
    }

    const formatOptionLabel = (option) => {
        setLabel(t(lang, option?.name))
        return (
            <div className="d-flex fw-bold flex-nowrap">
                {/*{t(lang, option?.name)}*/}
                {/* <Badge bg="primary-davr" className="d-flex justify-content-center align-items-center ms-2" pill>

                </Badge>*/}
                {option?.quantity}
            </div>
        )
    }


    return (
        <Fragment>
            {packageMeasurement?.length > 1 &&
                <CustomFloatingLabelSelect
                    label={label}
                    left={selectProps.left}
                    right={selectProps.right}
                    value={packageMeasurement?.find(o => o?.name === value)}
                    // defaultValue={packageMeasurement[packageMeasurement?.length - 1]}
                    options={packageMeasurement}
                    onChange={(option) => onChangeHandler(option)}
                    isSearchable={false}
                    hideSelectedOptions
                    menuPlacement="auto"
                    getOptionLabel={option => t(lang, option?.name)}
                    getOptionValue={option => option?.name}
                    formatOptionLabel={formatOptionLabel}
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            borderTopLeftRadius: '0px', // Removes the border radius
                            borderBottomLeftRadius: '0px', // Removes the border radius
                            borderBottomRightRadius: '8px', // Removes the border radius
                            borderTopRightRadius: '8px', // Removes the border radius
                            height: 40,
                        }),
                    }}
                    {...selectProps}
                />
            }
            {packageMeasurement?.length === 1 &&
                <span className="input-group-text">
                    {t(lang, measurements?.find(m => m?.label === packageMeasurement[packageMeasurement?.length - 1]?.name)?.label)}
                </span>
            }
        </Fragment>
    );
});

export default PackageTypeItem;
