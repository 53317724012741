import React, {useEffect, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";
import {exportItemsAsync, selectFilterOptions} from "../../../store/reducers/item/itemReducer";
import {downloadFile} from "../../../helpers/excel-uploader";
import {selectOrganizationTemplates} from "../../../store/reducers/excel/excelReducer";
import {TYPE_ITEMS} from "../../../enum/excel/ExcelTemplateTypes";
import {Dropdown} from "react-bootstrap";
import IconCloudUpload from "../../../assets/icon/IconCloudUpload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const UploadItemsToExcel = () => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const filters = useSelector(selectFilterOptions);

    const [itemTypeTemplates, setItemTypeTemplates] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const templates = organizationTemplates.filter(template => template.type === TYPE_ITEMS) || [];
        setItemTypeTemplates(templates);
    }, [organizationTemplates])

    const exportItems = (uri) => {
        setLoading(true);
        exportItemsAsync(uri, {
            category_id: filters?.categoryId || null
        })
            .then(({content, contentType}) => {
                downloadFile({
                    content: content,
                    contentType: contentType,
                    fileName: t(lang, 'items.table.title'),
                });
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            })
    }

    return (
        <Dropdown>
            <Dropdown.Toggle bsPrefix="toggle"
                             className={'btn-davr px-5 py-0 bg-white text-dark d-flex gap-2'}
                             variant="falcon-default"
                             size="sm"
                             disabled={loading}
            >
                {loading
                    ? <FontAwesomeIcon className="me-1" pulse={true} icon={faSpinner}/>
                    :  <IconCloudUpload />
                }
                {t(lang, 'items.common.download')}
            </Dropdown.Toggle>
            <Dropdown.Menu className='py-2'>
                {itemTypeTemplates.map((template, index) => {
                    return (
                        <Dropdown.Item as="button" key={index} onClick={() => exportItems(template.uri)}>
                            {t(lang, 'main.common.file.upload.template_name.prefix', {template_name: t(lang, `common.excel.template.name.${template.name}`)})}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UploadItemsToExcel;
