import React, {Fragment} from 'react';
import {Controller, useFormContext} from "react-hook-form";
import {selectLang} from "../../../store/reducers/main/main";
import {useSelector} from "react-redux";
import {Translate} from "../../../lang/lang";
import classNames from "classnames";
import Cleave from "cleave.js/react";
import {Card, FloatingLabel, Form} from "react-bootstrap";

const NotificationForm = () => {
    const {formState: {errors}, control} = useFormContext();
    const lang = useSelector(selectLang);
    const t = Translate;
    return (
        <Card className={'p-1'}>
            <Form.Group className="input-group gap-2 flex-nowrap">
                <FloatingLabel label={"Код"} className={'w-25'}>
                    <Form.Control value={"+998"} placeholder={"Код"}/>
                </FloatingLabel>
                <Controller
                    control={control}
                    name={'phoneNumber'}
                    rules={{
                        required: t(lang, "notification.common.forms.validations.is_required"),
                        minLength: {value: 12, message: t(lang, "notification.common.forms.validations.min_length")},
                    }}
                    render={({field}) => (
                        <FloatingLabel label={"Номер телефона"} className={'w-75'}>
                            <Cleave
                                value={field.value}
                                placeholder={"Номер телефона"}
                                options={{
                                    delimiters: [' ', ' ', ' ', ' '],
                                    blocks: [2, 3, 2, 2],
                                    numericOnly: true
                                }}
                                onChange={event => field.onChange(event.target.rawValue)}
                                className={classNames('form-control', {
                                    'is-invalid': errors?.phoneNumber,
                                    'is-valid': Object.keys(errors).length > 0 && !errors?.phoneNumber
                                })}
                            />
                        </FloatingLabel>
                    )}/>
                <Form.Control.Feedback type="invalid">
                    {errors?.phoneNumber?.message}
                </Form.Control.Feedback>
            </Form.Group>
        </Card>
    );
};

export default NotificationForm;
