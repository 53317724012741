import axios from "axios";


class WarehouseOperationService {

    // Sale start
    getSaleItems(params) {
        return axios.get(`/warehouse-operation/sale`, {
            params: params
        })
    }

    getSaleItemsCount(params) {
        return axios.get(`/warehouse-operation/sale/count`, {
            params: params
        })
    }

    getSaleItem({saleId}) {
        return axios.get(`/warehouse-operation/sale/${saleId}`)
    }

    saleRegister(payload) {
        return axios.post(`/warehouse-operation/sale/register`, payload)
    }

    saleAddItem({saleId, operation}) {
        return axios.post(`/warehouse-operation/sale/add-item/${saleId}`, operation)
    }

    saleUpdateItem({saleId, operation}) {
        return axios.post(`/warehouse-operation/sale/update-item/${saleId}`, operation)
    }

    saleDeleteItem({saleId, operationId}) {
        return axios.post(`/warehouse-operation/sale/delete-item/${saleId}`, {
            "operation_item_id": operationId
        })
    }

    saleApprove({saleId, payment}) {
        return axios.post(`/warehouse-operation/sale/approve/${saleId}`, {
            payment: payment
        })
    }

    saleDelete({saleId}) {
        return axios.post(`/warehouse-operation/sale/delete/${saleId}`)
    }

    saleUpdateNote({saleId, note}) {
        return axios.post(`/warehouse-operation/sale/change-note/${saleId}`, {
            note: note
        })
    }

    saleUpdateDate({saleId, date}) {
        return axios.post(`/warehouse-operation/sale/change-date/${saleId}`, {
            date: date
        })
    }

    saleUpdateNumber({saleId, number}) {
        return axios.post(`/warehouse-operation/sale/change-number/${saleId}`, {
            number: number
        })
    }

    saleChangeContractor({saleId, contractorId}) {
        return axios.post(`/warehouse-operation/sale/change-contractor/${saleId}`, {
            "contractor_id": contractorId
        })
    }

    saleChangeEmployee({saleId, employeeId}) {
        return axios.post(`/warehouse-operation/sale/change-employee/${saleId}`, {
            "employee_id": employeeId
        })
    }

    salePaymentRegister({saleId, payment}) {
        return axios.post(`/warehouse-operation/sale/payment/register/${saleId}`, {
            payment: payment
        })
    }

    salePaymentUpdate({saleId, payment}) {
        return axios.post(`/warehouse-operation/sale/payment/update/${saleId}`, {
            payment: payment
        })
    }

    deleteSalePayment(saleId) {
        return axios.post(`/warehouse-operation/sale/payment/delete/${saleId}`)
    }

    saleChangeExactDiscounts({saleId, exactDiscounts}) {
        return axios.post(`/warehouse-operation/sale/change-exact-discounts/${saleId}`, {
            exact_discounts: exactDiscounts
        })
    }

    saleChangePercentDiscount({saleId, percentDiscount}) {
        return axios.post(`/warehouse-operation/sale/change-percent-discount/${saleId}`, {
            percent_discount: percentDiscount
        })
    }

    saleDownloadPdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/default/${saleId}`, {
            responseType: "blob"
        })
    }

    saleDownloadSkuPdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/sku-package/${saleId}`, {
            responseType: "blob"
        })
    }

    saleDownloadReceipt80Pdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/receipt-80/default/${saleId}`, {
            responseType: "blob"
        })
    }

    saleDownloadReceipt58Pdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/receipt-58/default/${saleId}`, {
            responseType: "blob"
        })
    }

    saleDownloadItemWithImagePdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/item-with-image/${saleId}`, {
            responseType: "blob"
        })
    }

    saleDownloadMultiWarehousePdf({saleId}) {
        return axios.get(`/pdf/pdf/sale/multi-warehouse/${saleId}`, {
            responseType: "blob"
        })
    }

    // Purchase start
    getPurchases(params) {
        return axios.get('/warehouse-operation/purchase', {
            params: params
        })
    }

    getPurchasesCount(params) {
        return axios.get('/warehouse-operation/purchase/count', {
            params: params
        })
    }

    getPurchase({purchaseId}) {
        return axios.get(`/warehouse-operation/purchase/${purchaseId}`)
    }

    purchaseRegister(payload) {
        return axios.post(`/warehouse-operation/purchase/register`, payload)
    }

    addPurchaseItem({purchaseId, operation}) {
        return axios.post(`/warehouse-operation/purchase/add-item/${purchaseId}`, operation)
    }

    updatePurchaseItem({purchaseId, operation}) {
        return axios.post(`/warehouse-operation/purchase/update-item/${purchaseId}`, operation)
    }

    deletePurchaseItem({purchaseId, operationId}) {
        return axios.post(`/warehouse-operation/purchase/delete-item/${purchaseId}`, {
            "operation_item_id": operationId
        })
    }

    approvePurchase({purchaseId, payout}) {
        return axios.post(`/warehouse-operation/purchase/approve/${purchaseId}`, {
            payout: payout
        })
    }

    purchaseDelete({purchaseId}) {
        return axios.post(`/warehouse-operation/purchase/delete/${purchaseId}`)
    }

    purchaseUpdateNote({purchaseId, note}) {
        return axios.post(`/warehouse-operation/purchase/change-note/${purchaseId}`, {
            note: note
        })
    }

    purchaseUpdateDate({purchaseId, date}) {
        return axios.post(`/warehouse-operation/purchase/change-date/${purchaseId}`, {
            date: date
        })
    }

    purchaseUpdateNumber({purchaseId, number}) {
        return axios.post(`/warehouse-operation/purchase/change-number/${purchaseId}`, {
            number: number
        })
    }

    purchaseChangeContractor({purchaseId, contractorId}) {
        return axios.post(`/warehouse-operation/purchase/change-contractor/${purchaseId}`, {
            "contractor_id": contractorId
        })
    }

    purchaseChangeEmployee({purchaseId, employeeId}) {
        return axios.post(`/warehouse-operation/purchase/change-employee/${purchaseId}`, {
            "employee_id": employeeId
        })
    }

    purchasePayoutRegister({purchaseId, payout}) {
        return axios.post(`/warehouse-operation/purchase/payout/register/${purchaseId}`, {
            payout: payout
        })
    }

    purchasePayoutUpdate({purchaseId, payout}) {
        return axios.post(`/warehouse-operation/purchase/payout/update/${purchaseId}`, {
            payout: payout
        })
    }

    deletePurchasePayout(purchaseId) {
        return axios.post(`/warehouse-operation/purchase/payout/delete/${purchaseId}`)
    }

    purchaseDownloadPdf({purchaseId}) {
        return axios.get(`/pdf/pdf/purchase/default/${purchaseId}`, {
            responseType: "blob"
        })
    }



    // Refund start
    getRefunds(params) {
        return axios.get('/warehouse-operation/refund', {
            params: params
        })
    }

    getRefundsCount(params) {
        return axios.get('/warehouse-operation/refund/count', {
            params: params
        })
    }

    getRefund({refundId}) {
        return axios.get(`/warehouse-operation/refund/${refundId}`)
    }

    refundRegister(payload) {
        return axios.post(`/warehouse-operation/refund/register`, payload)
    }

    addRefundItem({refundId, operation}) {
        return axios.post(`/warehouse-operation/refund/add-item/${refundId}`, operation)
    }

    updateRefundItem({refundId, operation}) {
        return axios.post(`/warehouse-operation/refund/update-item/${refundId}`, operation)
    }

    deleteRefundItem({refundId, operationId}) {
        return axios.post(`/warehouse-operation/refund/delete-item/${refundId}`, {
            "operation_item_id": operationId
        })
    }

    approveRefund({refundId, payout}) {
        return axios.post(`/warehouse-operation/refund/approve/${refundId}`, {
            payout: payout
        })
    }

    refundDelete({refundId}) {
        return axios.post(`/warehouse-operation/refund/delete/${refundId}`)
    }

    refundUpdateNote({refundId, note}) {
        return axios.post(`/warehouse-operation/refund/change-note/${refundId}`, {
            note: note
        })
    }

    refundUpdateDate({refundId, date}) {
        return axios.post(`/warehouse-operation/refund/change-date/${refundId}`, {
            date: date
        })
    }

    refundUpdateNumber({refundId, number}) {
        return axios.post(`/warehouse-operation/refund/change-number/${refundId}`, {
            number: number
        })
    }

    refundChangeContractor({refundId, contractorId}) {
        return axios.post(`/warehouse-operation/refund/change-contractor/${refundId}`, {
            "contractor_id": contractorId
        })
    }

    refundChangeEmployee({refundId, employeeId}) {
        return axios.post(`/warehouse-operation/refund/change-employee/${refundId}`, {
            "employee_id": employeeId
        })
    }

    refundPayoutRegister({refundId, payout}) {
        return axios.post(`/warehouse-operation/refund/payout/register/${refundId}`, {
            payout: payout
        })
    }

    refundPayoutUpdate({refundId, payout}) {
        return axios.post(`/warehouse-operation/refund/payout/update/${refundId}`, {
            payout: payout
        })
    }

    deleteRefundPayout(refundId) {
        return axios.post(`/warehouse-operation/refund/payout/delete/${refundId}`)
    }

    refundDownloadPdf({refundId}) {
        return axios.get(`/pdf/pdf/refund/default/${refundId}`, {
            responseType: "blob"
        })
    }



    // Return-Purchase start
    getReturnPurchases(params) {
        return axios.get('/warehouse-operation/return', {
            params: params
        })
    }

    getReturnPurchasesCount(params) {
        return axios.get('/warehouse-operation/return/count', {
            params: params
        })
    }

    getReturnPurchase({returnPurchaseId}) {
        return axios.get(`/warehouse-operation/return/${returnPurchaseId}`)
    }

    returnPurchaseRegister(payload) {
        return axios.post(`/warehouse-operation/return/register`, payload)
    }

    addReturnPurchaseItem({returnPurchaseId, operation}) {
        return axios.post(`/warehouse-operation/return/add-item/${returnPurchaseId}`, operation)
    }

    updateReturnPurchaseItem({returnPurchaseId, operation}) {
        return axios.post(`/warehouse-operation/return/update-item/${returnPurchaseId}`, operation)
    }

    deleteReturnPurchaseItem({returnPurchaseId, operationId}) {
        return axios.post(`/warehouse-operation/return/delete-item/${returnPurchaseId}`, {
            "operation_item_id": operationId
        })
    }

    approveReturnPurchase({returnPurchaseId, payment}) {
        return axios.post(`/warehouse-operation/return/approve/${returnPurchaseId}`, {
            payment: payment
        })
    }

    returnPurchaseDelete({returnPurchaseId}) {
        return axios.post(`/warehouse-operation/return/delete/${returnPurchaseId}`)
    }

    returnPurchaseUpdateNote({returnPurchaseId, note}) {
        return axios.post(`/warehouse-operation/return/change-note/${returnPurchaseId}`, {
            note: note
        })
    }

    returnPurchaseUpdateDate({returnPurchaseId, date}) {
        return axios.post(`/warehouse-operation/return/change-date/${returnPurchaseId}`, {
            date: date
        })
    }

    returnPurchaseUpdateNumber({returnPurchaseId, number}) {
        return axios.post(`/warehouse-operation/return/change-number/${returnPurchaseId}`, {
            number: number
        })
    }

    returnPurchaseChangeContractor({returnPurchaseId, contractorId}) {
        return axios.post(`/warehouse-operation/return/change-contractor/${returnPurchaseId}`, {
            "contractor_id": contractorId
        })
    }

    returnPurchaseChangeEmployee({returnPurchaseId, employeeId}) {
        return axios.post(`/warehouse-operation/return/change-employee/${returnPurchaseId}`, {
            "employee_id": employeeId
        })
    }

    returnPurchasePaymentRegister({returnPurchaseId, payment}) {
        return axios.post(`/warehouse-operation/return/payment/register/${returnPurchaseId}`, {
            payment: payment
        })
    }

    returnPurchasePaymentUpdate({returnPurchaseId, payment}) {
        return axios.post(`/warehouse-operation/return/payment/update/${returnPurchaseId}`, {
            payment: payment
        })
    }

    deleteReturnPurchasePayment(returnPurchaseId) {
        return axios.post(`/warehouse-operation/return/payment/delete/${returnPurchaseId}`)
    }

    returnPurchaseDownloadPdf({returnPurchaseId}) {
        return axios.get(`/pdf/pdf/return/default/${returnPurchaseId}`, {
            responseType: "blob"
        })
    }




    // Revision start
    getRevisions(params) {
        return axios.get('/warehouse-operation/revision', {
            params: params
        })
    }

    getRevisionsCount(params) {
        return axios.get('/warehouse-operation/revision/count', {
            params: params
        })
    }

    getRevision({revisionId}) {
        return axios.get(`/warehouse-operation/revision/${revisionId}`)
    }

    revisionRegister(payload) {
        return axios.post(`/warehouse-operation/revision/register`, payload)
    }

    addRevisionItem({revisionId, operation}) {
        return axios.post(`/warehouse-operation/revision/add-item/${revisionId}`, operation)
    }

    updateRevisionItem({revisionId, operation}) {
        return axios.post(`/warehouse-operation/revision/update-item/${revisionId}`, operation)
    }

    deleteRevisionItem({revisionId, operationId}) {
        return axios.post(`/warehouse-operation/revision/delete-item/${revisionId}`, {
            "operation_item_id": operationId
        })
    }

    approveRevision({revisionId}) {
        return axios.post(`/warehouse-operation/revision/approve/${revisionId}`)
    }

    revisionDelete({revisionId}) {
        return axios.post(`/warehouse-operation/revision/delete/${revisionId}`)
    }

    revisionUpdateNote({revisionId, note}) {
        return axios.post(`/warehouse-operation/revision/change-note/${revisionId}`, {
            note: note
        })
    }

    revisionUpdateDate({revisionId, date}) {
        return axios.post(`/warehouse-operation/revision/change-date/${revisionId}`, {
            date: date
        })
    }

    revisionUpdateNumber({revisionId, number}) {
        return axios.post(`/warehouse-operation/revision/change-number/${revisionId}`, {
            number: number
        })
    }

    revisionChangeEmployee({revisionId, employeeId}) {
        return axios.post(`/warehouse-operation/revision/change-employee/${revisionId}`, {
            "employee_id": employeeId
        })
    }

    revisionDownloadPdf({revisionId}) {
        return axios.get(`/pdf/pdf/revision/default/${revisionId}`, {
            responseType: "blob"
        })
    }



    // Write-off start
    getWriteOffs(params) {
        return axios.get('/warehouse-operation/write-off', {
            params: params
        })
    }

    getWriteOffsCount(params) {
        return axios.get('/warehouse-operation/write-off/count', {
            params: params
        })
    }

    getWriteOff({writeOffId}) {
        return axios.get(`/warehouse-operation/write-off/${writeOffId}`)
    }

    writeOffRegister(payload) {
        return axios.post(`/warehouse-operation/write-off/register`, payload)
    }

    addWriteOffItem({writeOffId, operation}) {
        return axios.post(`/warehouse-operation/write-off/add-item/${writeOffId}`, operation)
    }

    updateWriteOffItem({writeOffId, operation}) {
        return axios.post(`/warehouse-operation/write-off/update-item/${writeOffId}`, operation)
    }

    deleteWriteOffItem({writeOffId, operationId}) {
        return axios.post(`/warehouse-operation/write-off/delete-item/${writeOffId}`, {
            operation_item_id: operationId
        })
    }

    approveWriteOff({writeOffId}) {
        return axios.post(`/warehouse-operation/write-off/approve/${writeOffId}`)
    }

    writeOffDelete({writeOffId}) {
        return axios.post(`/warehouse-operation/write-off/delete/${writeOffId}`)
    }

    writeOffUpdateNote({writeOffId, note}) {
        return axios.post(`/warehouse-operation/write-off/change-note/${writeOffId}`, {
            note: note
        })
    }

    writeOffUpdateDate({writeOffId, date}) {
        return axios.post(`/warehouse-operation/write-off/change-date/${writeOffId}`, {
            date: date
        })
    }

    writeOffUpdateNumber({writeOffId, number}) {
        return axios.post(`/warehouse-operation/write-off/change-number/${writeOffId}`, {
            number: number
        })
    }

    writeOffChangeEmployee({writeOffId, employeeId}) {
        return axios.post(`/warehouse-operation/write-off/change-employee/${writeOffId}`, {
            "employee_id": employeeId
        })
    }

    writeOffDownloadPdf({writeOffId}) {
        return axios.get(`/pdf/pdf/write-off/default/${writeOffId}`, {
            responseType: "blob"
        })
    }



    // Transfer start
    getTransfers(params) {
        return axios.get('/warehouse-operation/transfer', {
            params: params
        })
    }

    getTransfersCount(params) {
        return axios.get('/warehouse-operation/transfer/count', {
            params: params
        })
    }

    getTransfer({transferId}) {
        return axios.get(`/warehouse-operation/transfer/${transferId}`)
    }

    transferRegister(payload) {
        return axios.post(`/warehouse-operation/transfer/register`, payload)
    }

    addTransferItem({transferId, operation}) {
        return axios.post(`/warehouse-operation/transfer/add-item/${transferId}`, operation)
    }

    updateTransferItem({transferId, operation}) {
        return axios.post(`/warehouse-operation/transfer/update-item/${transferId}`,
            operation
        )
    }

    deleteTransferItem({transferId, operationId}) {
        return axios.post(`/warehouse-operation/transfer/delete-item/${transferId}`, {
            "operation_item_id": operationId
        })
    }

    approveTransfer({transferId}) {
        return axios.post(`/warehouse-operation/transfer/approve/${transferId}`)
    }

    transferDelete({transferId}) {
        return axios.post(`/warehouse-operation/transfer/delete/${transferId}`)
    }

    transferUpdateNote({transferId, note}) {
        return axios.post(`/warehouse-operation/transfer/change-note/${transferId}`, {
            note: note
        })
    }

    transferUpdateDate({transferId, date}) {
        return axios.post(`/warehouse-operation/transfer/change-date/${transferId}`, {
            date: date
        })
    }

    transferUpdateNumber({transferId, number}) {
        return axios.post(`/warehouse-operation/transfer/change-number/${transferId}`, {
            number: number
        })
    }

    transferChangeEmployee({transferId, employeeId}) {
        return axios.post(`/warehouse-operation/transfer/change-employee/${transferId}`, {
            "employee_id": employeeId
        })
    }

    transferDownloadPdf({transferId}) {
        return axios.get(`/pdf/pdf/transfer/default/${transferId}`, {
            responseType: "blob"
        })
    }

    exportSales(uri, params) {
        return axios.get(uri, {
            params: params,
            responseType: "arraybuffer"
        })
    }

}


const warehouseOperationService = new WarehouseOperationService()

export default warehouseOperationService
