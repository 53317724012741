import React, {Fragment, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {selectItems} from "../../../../store/reducers/item/itemReducer";
import {Translate} from "../../../../lang/lang";
import {FormProvider, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import {revisionRegisterAsync} from "../../../../store/reducers/warehouse/revisionReducer";
import {Button, Form, Modal, Spinner, Dropdown} from "react-bootstrap";
import FalconCloseButton from "../../../common/FalconCloseButton";
import AddRevisionModalForm from "./form/AddRevisionModalForm";

const AddRevisionModal = ({itemDisabled, item, ...props}) => {

    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate;
    const findItem = items?.find(x => x.item.id === item?.id)

    const form = useForm({
        defaultValues: {
            item_id: item?.id,
            quantity: 0,
            warehouse_id: findItem?.warehouse_states?.warehouse_items?.length > 1 ? null : findItem?.warehouse_states?.warehouse_items[0]?.id,
            warehouse: findItem?.warehouse_states?.warehouse_items,
            date: new Date(),
            number: null,
        }
    })

    const handlerShow = () => {
        setShow(true)
        form.setValue("item_id", item?.id)
    }

    const handlerClose = () => {
        setShow(false)
        form.reset()
    }

    const onSubmit = async (formData) => {
        setLoading(true)

        const item = {
            item_id: formData?.item_id,
            warehouse_id: formData?.warehouse_id,
            quantity: +formData?.quantity,
        }


        await revisionRegisterAsync({
            date: dayjs(formData?.date).format("YYYY-MM-DD HH:mm:ss"),
            note: formData?.note,
            number: formData?.number?.trim() ? formData?.number : null,
            items: [item],
            approve: true
        })
            .then((res) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                handlerClose()
            })
            .catch((err) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Fragment>
            <Dropdown.Item onClick={() => handlerShow()} className="border-bottom align-center">
                {t(lang, "warehouse.operation.item.common.new_revision")}
            </Dropdown.Item>

            <Modal className={'davr-modal'} show={show} onHide={handlerClose} size="lg">
                <Modal.Header>
                    <Modal.Title>Ревизия</Modal.Title>
                    <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...form}>
                        <Form id={'revision-modal'}>
                            <AddRevisionModalForm disabled={itemDisabled}/>
                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-white-davr'} variant="phoenix-secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.close")}
                    </Button>
                    <Button form={'revision-modal'} className={'btn-primary-davr'} variant="phoenix-secondary" type="button" onClick={form.handleSubmit(onSubmit)} disabled={loading}>
                        {loading &&
                            <Spinner className='align-middle me-2' animation='border' size="sm"
                                     role='switch'/>
                        }
                        {t(lang, "items.common.save")}
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
};

export default AddRevisionModal;
