import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectActiveOrganization} from "../../../store/reducers/auth/authReducer";
import {loadItemAsync} from "../../../store/reducers/item/itemReducer";
import EventBus from "../../../eventbus/EventBus";
import {PRICE_UPDATE_SUCCESS} from "../../../eventbus/price/priceEvents";
import {DELETE_ITEM_SUCCESS, EDIT_ITEM_SUCCESS, VALIDATE_ITEM_CATALOG_SUCCESS} from "../../../eventbus/itemEvents";
import Item from "./Item";
import {Col, Modal} from "react-bootstrap";
import IconArrowLeft from "../../../assets/icon/IconArrowLeft";
import {Translate} from "../../../lang/lang";
import {selectLang} from "../../../store/reducers/main/main";
import SpinnerComponent from "../../../components/common/SpinnerComponent";
import FalconCloseButton from "../../../components/common/FalconCloseButton";

const ViewItemModal = ({show, setShow, itemId}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const activeOrganization = useSelector(selectActiveOrganization);
    const navigate = useNavigate();
    const [item, setItem] = useState(null);

    const loadItem = async () => {
         await loadItemAsync(itemId)
                .then((item) => setItem(item))
                .catch(() => setItem(null));
    }

    useEffect(() => {
        loadItem();
    }, [itemId, activeOrganization])

    useEffect(() => {
        const onPriceUpdatedHandler = EventBus.on(PRICE_UPDATE_SUCCESS, loadItem);
        const onItemDeletedHandler = EventBus.on(DELETE_ITEM_SUCCESS, () => {
            navigate("./items");
        });
        const onItemUpdatedHandler = EventBus.on(EDIT_ITEM_SUCCESS, loadItem);
        const onCatalogValidatedHandler = EventBus.on(VALIDATE_ITEM_CATALOG_SUCCESS, loadItem);

        return () => {
            EventBus.remove(PRICE_UPDATE_SUCCESS, onPriceUpdatedHandler);
            EventBus.remove(DELETE_ITEM_SUCCESS, onItemDeletedHandler);
            EventBus.remove(VALIDATE_ITEM_CATALOG_SUCCESS, onCatalogValidatedHandler);
            EventBus.remove(EDIT_ITEM_SUCCESS, onItemUpdatedHandler);
        }
    }, [itemId])


    if (!item)
        return <SpinnerComponent />

    return (
        <Modal
            className={'davr-modal'}
            show={show}
            onHide={setShow}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Col xs={12} lg={2} className={'d-flex gap-3'}>
                    <button onClick={setShow} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "items.common.view.item.title")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'warehouse.navbar.routes.warehouse')}</span>
                    </div>
                </Col>
                <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={() => setShow(false)} />
            </Modal.Header>
            <Modal.Body>
                <Item item={item}/>
            </Modal.Body>
        </Modal>
    );
};

export default ViewItemModal;
