import React, {Fragment} from 'react';
import {Button, Card, Col, Collapse, FloatingLabel, Form, Row} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {selectLang} from "../../../store/reducers/main/main";

const WarehousesDataTableFilter = ({setFilters, filters, isOpenFilterCollapse}) => {

    const t = Translate;
    const lang = useSelector(selectLang);

    return (
        <Fragment>
            <Collapse in={isOpenFilterCollapse}>
                <div className={'mt-2'}>
                    <Card className={'p-2'}>
                        <Row className='mb-2 g-3 justify-content-end'>
                            <Col md={3} sm={12}>
                                <FloatingLabel label={'Поиск по склад'}>
                                    <Form.Control
                                        name={'name'}
                                        value={filters.name}
                                        onChange={e => setFilters({...filters, name: e.target.value})}
                                        type="search"
                                        className="search-input"
                                        placeholder="Поиск по склад"
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col>
                                <Flex className={'gap-2 align-items-center justify-content-end h-100'}>
                                    <Button className={'btn-davr bg-white-davr text-dark'} variant="phoenix-primary"
                                            type='button'
                                            onClick={() => setFilters({
                                                ...filters,
                                                name: '',
                                                filter: false
                                            })}>{t(lang, "items.common.clear_imported_file")}</Button>
                                    <Button className={'btn-davr bg-primary-davr text-white'} variant="primary"
                                            onClick={() => setFilters({
                                                ...filters,
                                                filter: Symbol(true)
                                            })}>{t(lang, "roaming.invoices.table_header.show")}</Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Collapse>
        </Fragment>
    );
};

export default WarehousesDataTableFilter;