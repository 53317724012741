// ROAMING INVOICE
export const PermissionRoamingInvoiceView = 1000
export const PermissionRoamingInvoiceCreate = 1001
export const PermissionRoamingInvoiceReject = 1002
export const PermissionRoamingInvoiceAccept = 1003
export const PermissionRoamingInvoiceUpdate = 1004
export const PermissionRoamingInvoiceDownloadExcel = 1005

// ROAMING CONTRACT
export const PermissionRoamingContractView = 1100
export const PermissionRoamingContractCreate = 1101
export const PermissionRoamingContractReject = 1102
export const PermissionRoamingContractAccept = 1103
export const PermissionRoamingContractUpdate = 1104

// ROAMING ACT
export const PermissionRoamingActView = 1200
export const PermissionRoamingActCreate = 1201
export const PermissionRoamingActReject = 1202
export const PermissionRoamingActAccept = 1203
export const PermissionRoamingActUpdate = 1204

// ROAMING VERIFICATION
export const PermissionRoamingVerificationActView = 1300
export const PermissionRoamingVerificationActCreate = 1301
export const PermissionRoamingVerificationActReject = 1302
export const PermissionRoamingVerificationActAccept = 1303
export const PermissionRoamingVerificationActUpdate = 1304

// ROAMING EMPOWERMENT
export const PermissionRoamingEmpowermentView = 1400
export const PermissionRoamingEmpowermentCreate = 1401
export const PermissionRoamingEmpowermentReject = 1402
export const PermissionRoamingEmpowermentAccept = 1403
export const PermissionRoamingEmpowermentUpdate = 1404

// ROAMING SHIPMENT
export const PermissionRoamingShipmentView = 1500
export const PermissionRoamingShipmentCreate = 1501
export const PermissionRoamingShipmentReject = 1502
export const PermissionRoamingShipmentAccept = 1503
export const PermissionRoamingShipmentUpdate = 1504


export const RoamingDefaultPermissions = [
    {
        text: 'edi.navbar.routes.edo.invoice',
        permissions: [
            {
                text: 'system.permission.roaming.invoice.view',
                value: PermissionRoamingInvoiceView
            },
            {
                text: 'system.permission.roaming.invoice.create',
                value: PermissionRoamingInvoiceCreate
            },
            {
                text: 'system.permission.roaming.invoice.reject',
                value: PermissionRoamingInvoiceReject
            },
            {
                text: 'system.permission.roaming.invoice.accept',
                value: PermissionRoamingInvoiceAccept
            },
            {
                text: 'system.permission.roaming.invoice.update',
                value: PermissionRoamingInvoiceUpdate
            },
            {
                text: 'system.permission.roaming.invoice.download_excel',
                value: PermissionRoamingInvoiceDownloadExcel
            },
        ]
    },
    {
        text: 'edi.navbar.routes.edo.contract',
        permissions: [
            {
                text: 'system.permission.roaming.contract.view',
                value: PermissionRoamingContractView
            },
            {
                text: 'system.permission.roaming.contract.create',
                value: PermissionRoamingContractCreate
            },
            {
                text: 'system.permission.roaming.contract.reject',
                value: PermissionRoamingContractReject
            },
            {
                text: 'system.permission.roaming.contract.accept',
                value: PermissionRoamingContractAccept
            },
            {
                text: 'system.permission.roaming.contract.update',
                value: PermissionRoamingContractUpdate
            },
        ]
    },
    {
        text: 'edi.navbar.routes.edo.empowerment',
        permissions: [
            {
                text: 'system.permission.roaming.empowerment.view',
                value: PermissionRoamingEmpowermentView
            },
            {
                text: 'system.permission.roaming.empowerment.create',
                value: PermissionRoamingEmpowermentCreate
            },
            {
                text: 'system.permission.roaming.empowerment.reject',
                value: PermissionRoamingEmpowermentReject
            },
            {
                text: 'system.permission.roaming.empowerment.accept',
                value: PermissionRoamingEmpowermentAccept
            },
            {
                text: 'system.permission.roaming.empowerment.update',
                value: PermissionRoamingEmpowermentUpdate
            },
        ]
    },
    {
        text: 'edi.navbar.routes.edo.verification_act',
        permissions: [
            {
                text: 'system.permission.roaming.verification_act.view',
                value: PermissionRoamingVerificationActView
            },
            {
                text: 'system.permission.roaming.verification_act.create',
                value: PermissionRoamingVerificationActCreate
            },
            {
                text: 'system.permission.roaming.verification_act.reject',
                value: PermissionRoamingVerificationActReject
            },
            {
                text: 'system.permission.roaming.verification_act.accept',
                value: PermissionRoamingVerificationActAccept
            },
            {
                text: 'system.permission.roaming.verification_act.update',
                value: PermissionRoamingVerificationActUpdate
            },
        ]
    },
    {
        text: 'edi.navbar.routes.edo.waybill',
        permissions: [
            {
                text: 'system.permission.roaming.shipment.view',
                value: PermissionRoamingShipmentView
            },
            {
                text: 'system.permission.roaming.shipment.create',
                value: PermissionRoamingShipmentCreate
            },
            {
                text: 'system.permission.roaming.shipment.reject',
                value: PermissionRoamingShipmentReject
            },
            {
                text: 'system.permission.roaming.shipment.accept',
                value: PermissionRoamingShipmentAccept
            },
            {
                text: 'system.permission.roaming.shipment.update',
                value: PermissionRoamingShipmentUpdate
            },
        ]
    },
    {
        text: 'edi.navbar.routes.edo.act',
        permissions: [
            {
                text: 'system.permission.roaming.act.view',
                value: PermissionRoamingActView
            },
            {
                text: 'system.permission.roaming.act.create',
                value: PermissionRoamingActCreate
            },
            {
                text: 'system.permission.roaming.act.reject',
                value: PermissionRoamingActReject
            },
            {
                text: 'system.permission.roaming.act.accept',
                value: PermissionRoamingActAccept
            },
            {
                text: 'system.permission.roaming.act.update',
                value: PermissionRoamingActUpdate
            },
        ]
    },
]
