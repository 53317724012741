import axios from "axios";

class EmployeeService {
    constructor() {
        this.baseURL = "/employee";
    }

    addEmployee(payload) {
        return axios.post(`${this.baseURL}/employee/register`, payload)
    }

    editEmployee(id, payload) {
        return axios.post(`${this.baseURL}/employee/update/${id}`, payload)
    }

    deleteEmployee(id) {
        return axios.post(`${this.baseURL}/employee/delete/${id}`)
    }

    getEmployee(id) {
        return axios.get(`${this.baseURL}/employee/get/${id}`)
    }

    getEmployees() {
        return axios.get(`${this.baseURL}/employee/get`)
    }
}

const employeeService = new EmployeeService();
export default employeeService;
