import React, {useState} from 'react';
import {Button, Card, FloatingLabel, Form, Modal, Spinner} from "react-bootstrap";
import {ErrorMessage} from "@hookform/error-message";
import {Controller, useForm} from "react-hook-form";
import classNames from "classnames";
import FalconCloseButton from "../../common/FalconCloseButton";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {getRandomNumber} from "../../../helpers/utils";
import {DELETE_ITEM_FAILED, DELETE_ITEM_REQUESTED, DELETE_ITEM_SUCCESS} from "../../../eventbus/itemEvents";
import EventBus from "../../../eventbus/EventBus";
import {deleteItemAsync} from "../../../store/reducers/item/itemReducer";
import Cleave from "cleave.js/react";
const withDeleteItem = (WrappedComponent) => {
    return (props) => {
        const t = Translate;
        const lang = useSelector(selectLang);
        const form = useForm();

        const [item, setItem] = useState(null);
        const [show, setShow] = useState(false);
        const [loading, setLoading] = useState(false);
        const [code] = useState(getRandomNumber(100000, 999999))

        const handleShow = (item) => {
            setItem(item);
            setShow(true);
        }

        const handleClose = () => {
            setItem(null);
            setShow(false);
        }

        const onDeleteItem = () => {
            setLoading(true);
            EventBus.dispatch(DELETE_ITEM_REQUESTED, item);
            deleteItemAsync(item.id)
                .then(() => {
                    EventBus.dispatch(DELETE_ITEM_SUCCESS, item.id);
                    setLoading(false);
                    handleClose();
                })
                .catch(() => {
                    EventBus.dispatch(DELETE_ITEM_FAILED, item);
                    setLoading(false);
                })
        }

        return (
            <React.Fragment>
                <WrappedComponent {...props} handleShow={handleShow}/>
                <Modal className={'davr-modal'} show={show} onHide={handleClose}>
                    <Form>
                        <Modal.Header>
                            <Modal.Title>
                                {t(lang, "items.common.delete_item")}
                            </Modal.Title>
                            <FalconCloseButton className={'bg-white m-0 btn-davr-modal-close'} onClick={handleClose}/>
                        </Modal.Header>
                        <Modal.Body>
                            <Card className={'p-1'}>
                                <Form.Group>
                                    <Form.Label>
                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                        <span className="ms-1 text-primary">{code}</span>
                                    </Form.Label>
                                    <Controller name={'code'}
                                                control={form.control}
                                                rules={{
                                                    required: t(lang, 'items.common.validations.is_required'),
                                                    validate: (value) => {
                                                        if (+value === code)
                                                            return true;
                                                        return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                    }
                                                }}
                                                render={({field}) => (
                                                    <FloatingLabel label={<>
                                                        <span>{t(lang, 'items.common.delete_item_modal.key_code')}</span>
                                                    </>}>
                                                        <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                                value={field.value}
                                                                htmlRef={field.ref}
                                                                placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                                className={classNames('form-control', {
                                                                    'is-invalid': form.formState.errors?.code
                                                                })}
                                                                options={{
                                                                    numeral: true,
                                                                    delimiter: ' ',
                                                                    numeralDecimalScale: 3
                                                                }}
                                                        />
                                                    </FloatingLabel>
                                                )}
                                    />
                                    <ErrorMessage name={'code'}
                                                  errors={form.formState.errors}
                                                  as={<Form.Control.Feedback type="invalid"/>}
                                    />
                                </Form.Group>
                            </Card>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className={'btn-white-davr'} variant="phoenix-secondary" onClick={handleClose}>
                                {t(lang, "items.common.cancel")}
                            </Button>
                            <Button className={'btn-primary-davr'} variant="phoenix-secondary" onClick={form.handleSubmit(onDeleteItem)} disabled={loading}>
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>
                                }
                                {t(lang, "items.common.delete")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    };
};

export default withDeleteItem;
