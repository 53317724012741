import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang} from "../../../../store/reducers/main/main";
import {Translate} from "../../../../lang/lang";
import {Link, useNavigate} from "react-router-dom";
import {scrollEvent} from "../../../../helpers/scrollEvent";
import {
    changeFilterOptionsAsync,
    selectRevisionFilterOptions
} from "../../../../store/reducers/warehouse/revisionReducer";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {Col, Form, Row} from "react-bootstrap";
import IconArrowLeft from "../../../../assets/icon/IconArrowLeft";
import IconButton from "../../../../components/IconButton";
import IconFilter from "../../../../assets/icon/IconFilter";
import IconStroke from "../../../../assets/icon/IconStroke";
import IconCaretDown from "../../../../assets/icon/IconCaretDown";
import IconPlus from "../../../../assets/icon/IconPlus";
import RevisionsDataTableFilter from "./RevisionsDataTableFilter"

const RevisionsDatatableHeader = () => {


    const lang = useSelector(selectLang);
    const t = Translate;
    const navigate = useNavigate()
    const filter = useSelector(selectRevisionFilterOptions)
    const dispatch = useDispatch()

    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-revision-data-table-header'))
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-revision-data-table-header'))
    }, []);

    const form = useForm({
        defaultValues: {
            date_time_start: filter?.date_time_start && dayjs(filter?.date_time_start, "YYYY-MM-DD HH:mm").toDate(),
            date_time_end: filter?.date_time_end && dayjs(filter?.date_time_end, "YYYY-MM-DD HH:mm").toDate(),
            status: null,
            warehouse_id: null
        }
    })

    const onSubmit = (formData) => {
        dispatch(changeFilterOptionsAsync({
            ...filter,
            date_time_start: formData?.date_time_start && dayjs(formData?.date_time_start).format("YYYY-MM-DD HH:mm:ss"),
            date_time_end: formData?.date_time_end && dayjs(formData?.date_time_end).format("YYYY-MM-DD HH:mm:ss"),
            is_approved: formData?.status,
            warehouse_id: formData?.warehouse_id?.id,
        }))
    }


    return (
        <Fragment>
            <Row id={'warehouse-revision-data-table-header'}
                 className="flex-between-center align-items-center my-3 position-sticky z-index-1"
                 style={{top: '4rem'}}>
                <Col cols={'auto'} className={'d-flex gap-3'}>
                    <button onClick={() => navigate(-1)} className={'main-text btn-back'}
                            style={{width: 35, height: 35}}>
                        <IconArrowLeft/>
                    </button>
                    <div>
                        <h5 className="fs-8 mb-0 text-nowrap py-2 py-xl-0">{t(lang, "warehouse.dashboard.items.title.transfer")}</h5>
                        <span className={'second-text fs-9'}>{t(lang, 'items.common.warehouse')}</span>
                    </div>
                </Col>
                <Col xs={"auto"} className={'d-flex justify-content-end gap-2'}>
                    <IconButton
                        onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                        className="btn-davr ps-2 pe-1 py-0 bg-white text-dark gap-2"
                    >
                        <div className={'d-flex align-items-center'}>
                            <IconFilter/>
                            <span
                                className="d-none d-sm-inline-block ms-1 fs-8 text-nowrap fs-md-9">{t(lang, "roaming.invoices.table_header.filter")}</span>
                        </div>
                        <div className={'d-flex justify-content-between gap-2'}>
                            <IconStroke/>
                            <IconCaretDown/>
                        </div>
                    </IconButton>

                    <IconButton
                        size={'sm'}
                        icon={''}
                        as={Link}
                        to={`register`}
                        variant="phoenix-primary"
                        className={'btn-davr bg-primary-davr text-white d-flex gap-2'}
                    >
                        <IconPlus/>
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "edi.common.add_button")}
                        </span>
                    </IconButton>
                </Col>
                <Col sm={12}>
                    <FormProvider {...form}>
                        <Form id={'revisions'} onSubmit={form.handleSubmit(onSubmit)}>
                            <RevisionsDataTableFilter
                                isOpenFilterCollapse={isOpenFilterCollapse}
                            />
                        </Form>
                    </FormProvider>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RevisionsDatatableHeader;
