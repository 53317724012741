import React, {Fragment, useRef, useState} from 'react';
import {Translate} from "../../../lang/lang";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {getRandomNumber} from "../../../helpers/utils";
import {BULK_DELETE_ITEMS_FINISHED, BULK_DELETE_ITEMS_STARTED} from "../../../eventbus/itemEvents";
import EventBus from "../../../eventbus/EventBus";
import {deleteItemAsync} from "../../../store/reducers/item/itemReducer";
import ResultCounter from "./ResultCounter";
import IconButton from "../../IconButton";
import IconCancel from "../../../assets/icon/IconCancel";
import {selectLang} from "../../../store/reducers/main/main";
import Button from "../../common/Button";
import {FloatingLabel, Form, Modal} from "react-bootstrap";
import classNames from "classnames";
import Cleave from "cleave.js/react";
const BulkDeleteItems = ({items}) => {

    const t = Translate;
    const lang = useSelector(selectLang);
    const form = useForm();
    const ref = useRef(null);

    const [show, setShow] = useState(false);
    const [code] = useState(getRandomNumber(100000, 999999))

    const onDeleteDraftItems = async (items) => {
        setShow(false);
        ref.current.updateLoadedCount(items.length);
        for (const {item} of items) {
            try {
                EventBus.dispatch(BULK_DELETE_ITEMS_STARTED, item.id);
                await deleteItemAsync(item.id);

                ref.current.incrementSucceedCount();
            } catch (e) {
                ref.current.incrementFailedCount();
            }
        }
        EventBus.dispatch(BULK_DELETE_ITEMS_FINISHED);
    }

    return (
        <Fragment>
            <ResultCounter ref={ref}/>
            <IconButton
                variant="falcon-danger"
                onClick={() => setShow(true)}
                size="sm"
                className="btn-davr px-2 py-0 bg-white text-dark d-flex gap-2"
            >
                <IconCancel width={15} height={15}/>
                {t(lang, 'items.common.delete')}
            </IconButton>
            {show &&
                <Modal show={true} onHide={() => setShow(false)} size="lg">
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {t(lang, "items.common.delete_item")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    <span>{t(lang, 'items.common.delete_item_modal.key_code')}:</span>
                                    <span className="ms-1 text-primary">{code}</span>
                                </Form.Label>
                                <Controller name={'code'}
                                            control={form.control}
                                            rules={{
                                                required: t(lang, 'items.common.validations.is_required'),
                                                validate: (value) => {
                                                    if (+value === code)
                                                        return true;
                                                    return t(lang, 'items.common.validations.delete_item.modal.invalid_key_code', {code: code});
                                                }
                                            }}
                                            render={({field}) => (
                                                <FloatingLabel label={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}>
                                                    <Cleave onChange={(e) => field.onChange(e.target.rawValue)}
                                                            value={field.value}
                                                            htmlRef={field.ref}
                                                            placeholder={t(lang, 'items.common.delete_item_modal.key_code.placeholder')}
                                                            className={classNames('form-control', {
                                                                'is-invalid': form.formState.errors?.code
                                                            })}
                                                            options={{
                                                                numeral: true,
                                                                delimiter: ' ',
                                                                numeralDecimalScale: 3
                                                            }}
                                                    />
                                                </FloatingLabel>
                                            )}
                                />
                               {/* <ErrorMessage name={'code'}
                                              errors={form.formState.errors}
                                              as={<Form.Control.Feedback type="invalid"/>}
                                />*/}
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                {t(lang, "items.common.cancel")}
                            </Button>
                            <Button variant="danger" onClick={form.handleSubmit(() => onDeleteDraftItems(items))}>
                                {t(lang, "items.common.delete")}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </Fragment>
    );
};

export default BulkDeleteItems;
