import {createSlice} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import warehouseOperationService from "../../services/warehouse/warehouseOperationService";
import EventBus from "../../../eventbus/EventBus";
import {
    WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_NOTE_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_NOTE_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_DATE_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS,
    WAREHOUSE_OPERATION_WRITE_OFF_DATE_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_FAILED,
    WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_REQUESTED,
    WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS, WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_FAILED
} from "../../../eventbus/warehouse/writeOffEvent";

const currentDate = new Date()
const dateTimeStartFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const dateTimeEndFormat = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);



const writeOffReducer = createSlice({
    name: "writeOff",
    initialState: {
        writeOffs: [],
        writeOffCount: 0,
        filterOptions: {
            page: 1,
            limit: 10,
            // date_time_start: "2023-12-01 00:00:00",
            // date_time_end: "2024-06-08 23:59:59",
            date_time_start: null,
            date_time_end: null,
        },
        writeOffDefaultsSettings: null,
    },
    reducers: {
        updateFilterOptions: (state, action) => {
            state.filterOptions = action.payload
        },
        updateWriteOffs: (state, action) => {
            state.writeOffs = action.payload
        },
        updateWriteOffCount: (state, action) => {
            state.writeOffCount = action.payload
        },
        setWriteOffDefaultsSettings: (state, action) => {
            state.writeOffDefaultsSettings = JSON.parse(action.payload)
            localStorage.setItem("WriteOffSettings", action.payload)
        },
        loadWriteOffDefaultsSettings: (state, action) => {
            const writeOffDefaults = JSON.parse(localStorage.getItem("WriteOffSettings"))
            state.writeOffDefaultsSettings = writeOffDefaults
        },
    },
})


export const changeFilterOptionsAsync = ({...params}) => dispatch => {
    dispatch(writeOffReducer.actions.updateFilterOptions({...params}))
}

export const getWriteOffsAsync = ({page, limit, ...filters}) => dispatch => {
    const skip = (page - 1) * limit
    const params = {skip, limit, ...filters}

    return new Promise((resolve, reject) => {
        warehouseOperationService.getWriteOffs(params)
            .then((response) => {
                dispatch(writeOffReducer.actions.updateWriteOffs(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const getWriteOffsCountAsync = (params) => dispatch => {
    let { limit, page, ...filters} = params

    return new Promise((resolve, reject) =>  {
        warehouseOperationService.getWriteOffsCount(filters)
            .then((response) => {
                dispatch(writeOffReducer.actions.updateWriteOffCount(response.data))
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const writeOffRegisterAsync = (payload) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffRegister(payload)
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_SUCCESS, response?.data?.id)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_REGISTER_FAILED)
                reject(error)
            })
    })
}

export const writeOffAddItemAsync = ({writeOffId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.addWriteOffItem({writeOffId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_ADD_ITEM_FAILED)
                reject(error)
            })
    })
}

export const getWriteOffItemAsync = ({writeOffId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.getWriteOff({writeOffId})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const writeOffDeleteItemAsync = ({writeOffId, operationId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.deleteWriteOffItem({writeOffId, operationId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const writeOffUpdateItemAsync = ({writeOffId, operation}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.updateWriteOffItem({writeOffId, operation})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_UPDATE_ITEM_FAILED)
                reject(error)
            })
    })
}

export const writeOffApproveAsync = ({writeOffId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.approveWriteOff({writeOffId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_APPROVED_ITEM_FAILED)
                reject(error)
            })
    })
}

export const writeOffDeleteAsync = ({writeOffId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffDelete({writeOffId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_SUCCESS)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DELETE_FAILED)
                reject(error)
            })
    })
}

export const writeOffUpdateNoteAsync = ({writeOffId, note}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NOTE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffUpdateNote({writeOffId, note})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NOTE_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NOTE_FAILED)
                reject(error)
            })
    })
}

export const writeOffUpdateDateAsync = ({writeOffId, date}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DATE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffUpdateDate({writeOffId, date})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DATE_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_DATE_FAILED)
                reject(error)
            })
    })
}

export const writeOffUpdateNumberAsync = ({writeOffId, number}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffUpdateNumber({writeOffId, number})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_SUCCESS, writeOffId)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_NUMBER_FAILED)
                reject(error)
            })
    })
}

export const writeOffChangeEmployeeAsync = ({writeOffId, employeeId}) => {
    EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_REQUESTED)
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffChangeEmployee({writeOffId, employeeId})
            .then((response) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_SUCCESS, response)
                resolve(response)
            })
            .catch((error) => {
                EventBus.dispatch(WAREHOUSE_OPERATION_WRITE_OFF_EMPLOYEE_FAILED)
                reject(error)
            })
    })
}

export const writeOffDownloadPdfAsync = ({writeOffId}) => {
    return new Promise((resolve, reject) => {
        warehouseOperationService.writeOffDownloadPdf({writeOffId})
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
    })
}





export const selectWriteOffFilterOptions = state => state.writeOff.filterOptions
export const selectWriteOffCount = state => state.writeOff.writeOffCount
export const selectWriteOffs = state => state.writeOff.writeOffs

export const selectWriteOffDefaultsSettings = state => state.writeOff.writeOffDefaultsSettings


export const {setWriteOffDefaultsSettings, loadWriteOffDefaultsSettings} = writeOffReducer.actions


export default writeOffReducer.reducer;
