import React, {useLayoutEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectCurrency} from "../../../store/reducers/currency/currencyReducer";
import CustomFloatingLabelSelect from "../../floating-custom-select/CustomFloatingLabelSelect";
import {UZS,USD, RUB} from "../../../enum/item/itemWrapper";

const SelectCurrency = ({defaultCurrencyId, onChange, ...selectProps}) => {
    const currencies = useSelector(selectCurrency);
    const [currency, setCurrency] = useState(null);
    const activeCurrency = currencies?.filter(x => x?.is_active)

    useLayoutEffect(() => {
        if (defaultCurrencyId) {
            if (defaultCurrencyId !== currency?.id) {
                setCurrency(currencies.find(c => c.id === defaultCurrencyId));
            }
        } else {
            let defaultCurrency = activeCurrency.find(c => c.code === UZS || c.code === USD || c.code === RUB) || currencies.find(c => c.code === UZS);
            setCurrency(defaultCurrency);
            onChange(currency, defaultCurrency);
        }
    }, [defaultCurrencyId, currencies])

    const onChangeHandler = (oldCurrency, newCurrency) => {
        setCurrency(newCurrency);
        onChange(oldCurrency, newCurrency);
    }

    return (
        <div className={'currency-select'}>
            {activeCurrency?.length <= 1 ?
                <span className="input-group-text">
                    {currency?.name}
                </span> :
                <CustomFloatingLabelSelect
                    left={selectProps.left}
                    right={selectProps.right}
                    value={currency}
                    options={activeCurrency}
                    onChange={(option) => onChangeHandler(currency, option)}
                    isSearchable={false}
                    hideSelectedOptions
                    menuPlacement="auto"
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    {...selectProps}
                />
            }
        </div>
    );
};

export default SelectCurrency;
