import React, {useEffect} from 'react';
import AddItemForm from "../../../components/item/components/forms/AddItemForm";
import {useNavigate} from "react-router-dom";
import EventBus from "../../../eventbus/EventBus";
import {ADD_ITEM_SUCCESS} from "../../../eventbus/itemEvents";

const AddItem = () => {

    const history = useNavigate();

    useEffect(() => {
        const onItemAddedHandler = EventBus.on(ADD_ITEM_SUCCESS, (id) => {
            history('/items');
        })

        return () => {
            EventBus.remove(ADD_ITEM_SUCCESS, onItemAddedHandler);
        }
    }, [])

    return (
        <AddItemForm />
    );
};

export default AddItem;
